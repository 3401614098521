import { StyleSheet } from "@react-pdf/renderer";

export const DefaultStyles = StyleSheet.create({
    card: {
        marginBottom: 10,
    },
    card_title: {
        fontSize: 14,
        fontWeight: 500,
        marginBottom: 8,
        paddingBottom: 4,
    },
    card_content_row: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 10,
    },
    card_content: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        marginBottom: 10,
    },
    avatar_container: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 8,
    },
    avatar_image: {
        width: "30px",
        height: "30px",
        objectFit: 'contain',
        backgroundColor: '#F2F5F5',
        borderRadius: 2,
        marginTop: 4
    },
    avatar_info: {
        marginLeft: 10,
    },
    avatar_title: {
        fontSize: 11,
        fontWeight: 500,
    },
    avatar_text: {
        fontWeight: 'light',
    },
    avatar_description: {
        marginTop: 4,
        fontWeight: 'light',
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 2,
    },
    listItem_bullet: {
        color: '#E9531D',
        fontWeight: 'medium',
        marginRight: 3,
    },
    projects_title: {
        fontSize: 13,
        marginBottom: 5,
    },
    projects_container: {
        fontWeight: 'light'
    },
    project_timeline: {
        width: 53,
        height: '100%',
    },
    project_wrapper: {
        display: 'flex'
    },
    project_info: {
        flexDirection: 'column',
        marginBottom: 20,
        fontSize: 9,
    },
    project_title: {
        marginBottom: 4,
        marginTop: 10,
        fontSize: 10,
        fontWeight: 500
    },
    link: {
        textDecoration: 'none',
        color: 'black',
    }
})
