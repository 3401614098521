import React from 'react';

import { trainingDateFormat } from "../../../common/utils/dates";
import { Training } from '../../model';
import CvElementView from '../CvElementView/CvElementView';

type UserTrainingViewProp = {
  training?: Training;
};

const UserTrainingView = ({ training }: UserTrainingViewProp) => {

    return (
      <CvElementView
        title={training?.trainingCourse?.name}
        subtitle={training?.trainingCenter?.name}
        photo={training?.trainingCenter?.pictureUrl}
        dates={training && trainingDateFormat(training)}
        description={training?.description}
      />
    );
};

export default UserTrainingView;

