import {Dropdown, FlexContainer, Input, Item} from "@axxes/design-system";
import React, {Dispatch, FunctionComponent} from "react";
import { AnyAction } from "redux";
import {Course, CourseNames, CourseType} from "../../../users/model";
import { setCourseType } from "../EditEducationCourses/editEducationCoursesState";

export interface EditCourseFormProps {
    newCourse?: Course;
    setNewCourse: (value: Course) => void;
    open: boolean;
    dispatch: Dispatch<AnyAction>;
    supportCourseTypes: boolean;
}


export const EditCourseForm: FunctionComponent<EditCourseFormProps> = ({newCourse, setNewCourse, open, dispatch, supportCourseTypes}) => {
    const courseTypes: Item[] = Object.keys(CourseNames).map((item) => ({
        name: CourseNames[item as CourseType],
        value: item,
    }));

    return (
        <div className={`axxes-cv-form__add ${open && '-open'}`}>
            <h5 className="axxes-border-title">
                <span className="axxes-border-title__text">Edit</span>
            </h5>
            <FlexContainer
                padding="0"
                direction="row"
                grow={1}
                customClasses="axxes-cv-form"
            >
                <FlexContainer
                    padding="0"
                    direction="column"
                    customClasses="axxes-cv-form__container"
                    grow={1}
                >
                    <Input
                        defaultValue={newCourse?.name || ''}
                        onChange={e =>
                            setNewCourse({
                                ...newCourse,
                                name: e.target.value
                            })
                        }
                    />

                    {supportCourseTypes
                        ? <Dropdown
                            items={courseTypes}
                            value={
                                newCourse?.courseType
                                    ? {name: newCourse.courseType, value: newCourse.courseType} as Item
                                    : {name: 'NO COURSE TYPE SPECIFIED', value: 'UNDEFINED'} as Item
                            }
                            autoComplete={false}
                            setValue={(i) => {
                                const type = (i as Item).value as CourseType;
                                dispatch(setCourseType(type));
                            }}
                            title="Type"
                            required={true}
                        />
                        : null}
                </FlexContainer>
            </FlexContainer>
        </div>
    );}