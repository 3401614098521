import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { APP_CONFIG } from '../../../app/config/config';
import { UserLink } from '../../model/userLink';

export interface UserLinkEditState {
  userLink: UserLink;
  editing: boolean;
}

export const INITIAL_USERLINK_EDIT_STATE: UserLinkEditState = {
  userLink: new UserLink(),
  editing: false,
};

const slice = createSlice({
  name: 'UserLinkEdit',
  initialState: INITIAL_USERLINK_EDIT_STATE,
  reducers: {
    setUserlink: {
      reducer: (state, action: PayloadAction<UserLink>): UserLinkEditState => ({...state, userLink: action.payload}),
      prepare: (link?: UserLink) => {
        let icon = APP_CONFIG.LINK_ICONS.DEFAULT;
        Object.entries(APP_CONFIG.LINK_ICONS).map(([key, value]) => {
          if(link && link.link?.includes(key.toLowerCase())) {
            icon = value;
          }
        })
        return {payload: {...link, icon}}
      }
    },
    setEdit: (state, action: PayloadAction<boolean>): UserLinkEditState => ({...state, editing: action.payload})
  }
})

export default slice.reducer;

export const {setUserlink, setEdit} = slice.actions;