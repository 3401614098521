import React, { useEffect } from 'react';
import DatePicker, { DatePickerProps } from 'react-date-picker';

import './datePicker.scss';

const FORMAT_MONTH = 'M/yyyy';
const FORMAT_DAY = 'd/M/yyyy';

type Detail = 'month' | 'year' | 'decade' | 'century';

interface CvDatePickerProp {
  title?: string;
  value: Date | null;
  onChange: (date: Date) => void;
  maxDate?: Date;
  minDate?: Date;
  maxDetail?: Detail;
  minDetail?: Detail;
  calendarIcon?: boolean;
  required?: boolean;
  warning?: boolean;
}

const CvDatePicker = ({
  title,
  value,
  onChange,
  maxDate,
  minDate,
  maxDetail,
  minDetail,
  required,
  warning,
}: CvDatePickerProp) => {
  const name = title?.replaceAll(' ', '').toLowerCase();
  const datePickerProps: DatePickerProps = {
    value,
    required,
    onChange: (dateValue) => {
      const date = Array.isArray(dateValue) ? dateValue[0] : dateValue;
      onChange(date);
    }
  };

  if (maxDate) {
    datePickerProps.maxDate = maxDate;
  }
  if (minDate) {
    datePickerProps.minDate = minDate;
  }
  if (maxDetail) {
    datePickerProps.maxDetail = maxDetail;
  }
  if (minDetail) {
    datePickerProps.minDetail = minDetail;
  }

  const isMobile = () => window.innerWidth <= 600;

  useEffect(() => {
    if (!isMobile()) return;

    const element = document.querySelector(`input[name=${name}]`);
    if (!element) return;

    const elements = element.parentElement?.getElementsByClassName(
      'react-date-picker__inputGroup__input'
    );
    if(!elements) return;

    for(let i = 0; i < elements.length; i++) {
      elements.item(i)?.setAttribute('readonly', 'readonly');
    }
  }, []);

  return (
    <div className={ `axxes-datepicker ${
      warning ? 'axxes-datepicker--error' : ''
    }`}>
      <label className={`${value ? 'label--float' : ''}`}>
        {title}
        {required && '*'}
      </label>
      <DatePicker
        name={name}
        className={`axxes-datepicker__control  ${value ? 'axxes-datepicker__control--filled' : ''}`}
        {...datePickerProps}
        calendarIcon={null}
        format={minDetail === 'decade' ? FORMAT_MONTH : FORMAT_DAY}
      />
      <div className={`${warning ? 'axxes-datepicker__warning' : ''}`} />
    </div>
  );
};

export default CvDatePicker;
