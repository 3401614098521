import { FlexContainer } from '@axxes/design-system';
import React from 'react';
import { LanguageLevel } from '../../model/language';

export interface UserLanguageTagProps {
  title?: string;
  level?: LanguageLevel;
}
export const UserLanguageTag = ({ title, level }: UserLanguageTagProps) => {
  let color: string = '';

  switch (level) {
    case 'NATIVE':
      color = '-native';
      break;
    case 'FLUENT':
      color = '-fluent';
      break;
    case 'INTERMEDIATE':
      color = '-intermediate';
      break;
    case 'BASIC':
      color = '-basic';
      break;
    default:
      color = '';
  }

  return (
    <FlexContainer padding='0' grow={1} customClasses={`axxes-language__tag ${color && color}`}>
      <div className={`axxes-language__tag-text ${color && color}`}>{title}</div>
    </FlexContainer>
  );
};

export default UserLanguageTag;
