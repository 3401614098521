import {
  Button,
  Card,
  CardAction,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  FlexContainer,
  Loading,
} from '@axxes/design-system';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useReducer } from 'react';
import EditOverlay from '../../../common/components/EditOverlay/EditOverlay';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import { sortLanguages } from "../../../common/utils/sorters";
import UserLanguageEdit from '../../components/UserLanguages/UserLanguageEdit';
import UserLanguageView from '../../components/UserLanguages/UserLanguageView';
import { UserRoutingProps } from '../../containers/UserDetailContainer';
import { Language } from '../../model/language';
import { toggleSectionFold } from '../../store/slice';
import {
  deleteLanguage,
  postLanguage,
  putLanguage,
} from '../../store/usersFacadeService';
import userLanguagesReducer, { init, INITIAL_LANGUAGES_STATE, setAddMode, setEdit, setMainEdit } from './state';

interface UserLanguageProps extends UserRoutingProps {
  languagesProp?: Language[];
  isLoading?: boolean;
}

export const UserLanguages = ({
  languagesProp,
  match,
  isLoading,
}: UserLanguageProps) => {
  const reduxDispatch = useAppDispatch();

  // Local state
  const [state, dispatch] = useReducer(
    userLanguagesReducer,
    INITIAL_LANGUAGES_STATE,
  );

  // API States
  const printMode = useAppSelector(
    (appState) => appState?.common?.printMode,
  );
  const languagesState = useAppSelector(
    (appState) => appState?.users?.languages,
  );
  const folded = useAppSelector((appState) => appState.users.foldedSections.includes('languages'));

  const isSaving =
    languagesState?.post?.isSaving || languagesState?.put?.isSaving || false;
  const isDeleting = languagesState?.delete?.isSaving || false;

  const removeLanguage = (language: Language) => {
    reduxDispatch(
      deleteLanguage(language.id!, match?.params?.userId, printMode),
    );
  };

  const saveLanguage = (_language?: Language, _new?: boolean) => {
    if (_language?.language?.trim()) {
      const languageToSave: Language = {language: _language?.language?.trim(), ..._language}
      _new
        ? reduxDispatch(
            postLanguage(languageToSave, match?.params?.userId, printMode),
          )
        : reduxDispatch(
            putLanguage(languageToSave, match?.params?.userId, printMode),
          );
    }
  };

  const cancelLanguage = () => {
    dispatch(setAddMode(false));
    dispatch(setMainEdit(false));
  };

  useEffect(() => {
    dispatch(init(languagesProp || []));
  }, [languagesProp]);

  useEffect(() => {
    if (
      languagesState?.put?.isSaving ||
      languagesState?.delete?.isSaving
    ) {
      dispatch(setAddMode(false));
      dispatch(setMainEdit(false));
    }
  }, [
    languagesState?.delete?.isSaving,
    languagesState?.put?.isSaving,
  ]);

  return (
    <div className={languagesProp?.length === 0 ? 'hideOnPrint' : ''}>
      <Card disableOnPrint={true}>
        <div onClick={() => reduxDispatch(toggleSectionFold('languages'))}>
          <CardHeader customClasses='axxes-foldable-card__header'>
            <CardTitle>Languages</CardTitle>
            <CardAction>
              <FontAwesomeIcon icon={folded ? faChevronDown : faChevronUp} />
            </CardAction>
          </CardHeader>
        </div>
        {!folded && (
          <>
            <CardContent customClasses='axxes-foldable-card__content'>
              {isLoading ? (
                <FlexContainer padding="0" customClasses="axxes-language-view">
                  <Loading width="200px" />
                </FlexContainer>
              ) : (
                <FlexContainer padding='0' direction='column' customClasses="axxes-languages">
                  {languagesProp?.length === 0 && !state.addMode ? (
                    'You have not submitted any languages.'
                  ) : (
                    <div className="axxes-language axxes-language__header">
                  <div />
                  <FlexContainer padding='0' customClasses='axxes-language__tag-container '>
                    <svg/>
                    <FlexContainer
                      padding='0'
                      grow={1}
                      customClasses='axxes-language__tag axxes-language__title'
                    >
                      <span>Understanding</span>
                    </FlexContainer>
                  </FlexContainer>

                  <FlexContainer padding='0' customClasses='axxes-language__tag-container '>
                    <svg/>
                    <FlexContainer
                      padding='0'
                      grow={1}
                      customClasses='axxes-language__tag axxes-language__title'
                    >
                      <span>Speaking</span>
                    </FlexContainer>
                  </FlexContainer>

                  <FlexContainer padding='0' customClasses='axxes-language__tag-container '>
                    <svg/>
                    <FlexContainer
                      padding='0'
                      grow={1}
                      customClasses='axxes-language__tag axxes-language__title'
                    >
                      <span>Writing</span>
                    </FlexContainer>
                  </FlexContainer>
                </div>
                  )}
                  {sortLanguages(languagesProp)?.map((language: Language, index: number) => (
                    <EditOverlay
                      isEditable={!state.mainEdit && !state.addMode}
                      edit={state.edits ? state?.edits[index] : false}
                      setEdit={(status: boolean) => dispatch(setEdit({index, status}))}
                      key={index}
                      viewElement={<UserLanguageView language={language} />}
                      editElement={
                        <UserLanguageEdit
                          language={language}
                          saveLanguage={(_language) => {
                            saveLanguage(_language);
                          }}
                          removeLanguage={() => removeLanguage(language)}
                          cancelLanguage={() => dispatch(setEdit({ index, status: false }))}
                          isSaving={isSaving}
                          isDeleting={isDeleting}
                        />
                      }
                    />
                  ))}
                  {state?.addMode && (
                    <UserLanguageEdit
                      saveLanguage={(_language) => {
                        saveLanguage(_language, true);
                      }}
                      cancelLanguage={cancelLanguage}
                      isSaving={isSaving}
                      isDeleting={isDeleting}
                    />
                  )}
                </FlexContainer>
              )}
            </CardContent>
            <CardFooter>
              <CardAction>
                {!state.addMode && !state.mainEdit && !isSaving && !isDeleting && (
                  <div className="axxes-card-actions">
                    <Button
                      variant="ghost"
                      accent={true}
                      onClick={() => dispatch(setAddMode(true))}
                    >
                      Add a language
                    </Button>
                  </div>
                )}
              </CardAction>
            </CardFooter>
          </>
        )}
      </Card>
    </div>
  );
};

export default UserLanguages;
