
import { FlexContainer, Progress } from '@axxes/design-system';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { APP_CONFIG } from '../../../app/config/config';
import { UserSkill } from '../../model';

import './userSkill.scss';

type UserSkillProps = {
    userSkill: UserSkill,
    shareView?: boolean,
    editOrder?: boolean,
    isActive?: boolean,
}

const UserSkillView = ({ userSkill, shareView, editOrder, isActive }: UserSkillProps) => {
    
    const filled = APP_CONFIG.SKILL_LEVEL[userSkill?.level || 'CONFIRMED'];
    const bars = Object.keys(APP_CONFIG.SKILL_LEVEL).length;

    return (
        <FlexContainer 
            direction="row" 
            customClasses={`axxes-skill ${shareView ? 'share-view': null} ${isActive ? 'axxes-skill-active' : null}`}
        >
            {editOrder && <div
                className='axxes-skill__drag-handle'
            >
                <FontAwesomeIcon icon={faEllipsisV} />
            </div>}
            <span className="axxes-skill__skill" title={userSkill.name}>{userSkill.name}</span>
            <Progress customClasses={`axxes-skill__level`} filled={filled} bars={bars} />
        </FlexContainer>
    )
}

export default UserSkillView;