import { FlexContainer } from '@axxes/design-system';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../common/store/hooks';
import AboutMe from '../components/About/AboutMe';
import DetailsHeader from '../components/DetailsHeader/DetailsHeader';
import UserCertificates from '../components/UserCertificates/UserCertificates';
import UserEducations from '../components/UserEducations/UserEducations';
import UserProjects from '../components/UserProjects/UserProjects';
import UserSkills from '../components/UserSkills/UserSkills';
import UserTrainings from '../components/UserTrainings/UserTrainings';
import { clearFoldedSections, fetchCvClearError } from '../store/slice';
import { fetchUserCV, fetchUserCvVersions } from '../store/usersFacadeService';
import './userDetail.scss';
import UserLanguages from './UserLanguages/UserLanguages';
import UserWeblinks from './UserWeblinks/UserWeblinks';

export interface UserRoutingProps {
  match?: {
    path?: string;
    params?: {
      userId?: string;
    };
  };
}

export const UserDetailContainer = ({ match }: UserRoutingProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const cv = useAppSelector((state) => state?.users?.cv?.result);
  const cvVersion = useAppSelector((state) => state.users.selectedCvVersion);
  const isLoading = useAppSelector(
    (state) => state?.users?.cv?.isLoading,
  );
  const userNotFoundError = useAppSelector((state) => state?.users?.cv?.error?.response?.data.description?.includes('User was not found'));
  const printMode = useAppSelector((state) => state.common.printMode);

  useEffect(() => {
    dispatch(fetchUserCvVersions(match?.params?.userId));
    dispatch(clearFoldedSections());
  }, []);

  useEffect(() => {
    dispatch(fetchUserCV(match?.params?.userId));
  }, [cvVersion])

  useEffect(() => {
    if (!printMode) dispatch(fetchUserCV(match?.params?.userId));
  }, [printMode])

  useEffect(() => {
    if(!userNotFoundError) return;
    dispatch(fetchCvClearError());
    history.push('/notfound');
  }, [userNotFoundError])

  return (
    <FlexContainer customClasses="axxes-user__detail" direction="column">
      <DetailsHeader
        userId={match?.params?.userId}
        user={cv?.user}
      />
      <AboutMe
        userInfoProp={cv?.userInfo}
        user={cv?.user}
        match={match}
        isLoading={isLoading}
      />
      <UserSkills
        userSkillsProp={cv?.skills}
        match={match}
        isLoading={isLoading}
      />
      <UserProjects
        projectsProp={cv?.projects}
        match={match}
        isLoading={isLoading}
      />
      <UserEducations
        educations={cv?.educations}
        match={match}
        isLoading={isLoading}
      />
      <UserCertificates certificatesProp={cv?.certificates} match={match} />
      <UserTrainings trainingsProp={cv?.trainings} match={match} />
      <UserLanguages languagesProp={cv?.languages} match={match} />
      <UserWeblinks userLinks={cv?.links} match={match} />
    </FlexContainer>
  );
};

export default UserDetailContainer;
