export const APP_CONFIG = {
  HEADER_NAVS: [
    { name: 'My Profile', url: '/profile' },
    { name: 'Manage', url: '/manage', isSecured: true },
  ],
  SIDE_NAVS: [
    {
      title: 'Users',
      url: '/manage',
      icon: 'users',
    },
    {
      title: 'Clients',
      url: '/manage/edit/clients',
      icon: 'user',
      isSecured: true
    },
    {
      title: 'Issuers',
      url: '/manage/edit/issuers',
      icon: 'bank',
      isSecured: true
    },
    {
      title: 'Schools',
      url: '/manage/edit/schools',
      icon: 'home',
      isSecured: true
    },
    {
      title: 'Educations',
      url: '/manage/edit/educations',
      icon: 'calendar',
      isSecured: true
    },
    {
      title: 'Trainings',
      url: '/manage/edit/trainings',
      icon: 'docs',
      isSecured: true
    },
    {
      title: 'Skills',
      url: '/manage/edit/skills',
      icon: 'tags',
      isSecured: true
    },
    {
      title: 'Danger Zone',
      url: '/manage/dangerzone/',
      icon: 'attention-circled',
      isSecured: true
    },
  ],
  SKILL_LEVEL: {
    JUNIOR: 1,
    CONFIRMED: 2,
    ADVANCED: 3,
    EXPERT: 4,
  },
  LINK_ICONS: {
    LINKEDIN: 'linkedin',
    GITHUB: 'github',
    DEFAULT: 'globe',
  },
  DEFAULT_IMAGE: '/assets/images/default.png',
};
