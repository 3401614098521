import { AxiosError, AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import ErrorHandler from '../../common/api/errorHandler';
import ProfileDTO from "../model/dto-get/profileDTO";
import {Profile} from "../model/profile";
import { AppState } from './../../common/store/index';

import _ from 'lodash';
import { ErrorResponse } from '../../common/models/errorResponse';
import { EventResponse } from '../../common/models/eventResponse';
import store from '../../common/store';
import { sortCvVersions } from '../../common/utils/sorters';
import {
  Certificate,
  Education,
  School,
  Training,
  User,
  UserSkill,
} from '../model';
import { Course } from '../model/course';
import { CvVersion } from '../model/cvVersion';
import CertificateDTO from '../model/dto-get/certificateDTO';
import ClientDTO from '../model/dto-get/clientDTO';
import CourseDTO from '../model/dto-get/courseDTO';
import { CvVersionDTO } from '../model/dto-get/cvVersionDTO';
import { LanguageDTO } from '../model/dto-get/languageDTO';
import SchoolDTO from '../model/dto-get/schoolDTO';
import SkillDTO from '../model/dto-get/skillDTO';
import TrainingCenterDTO from '../model/dto-get/trainingCenterDTO';
import UserCvDTO from '../model/dto-get/userCvDTO';
import UserDTO from '../model/dto-get/userDTO';
import UserInfoDTO from '../model/dto-get/userInfoDTO';
import UserProjectDTO from '../model/dto-get/userProjectDTO';
import UserSkillDTO from '../model/dto-get/userSkillDTO';
import UserTrainingDTO from '../model/dto-get/userTrainingDTO';
import UserEducationDTOPost from '../model/dto-post/userEducationDTO-post';
import UserEducationDTOPut from '../model/dto_put/userEducationDTO-put';
import {Image} from "../model/image";
import { Language } from '../model/language';
import {ShareRequest} from "../model/shareRequest";
import {ShareResponse} from "../model/shareResponse";
import { TrainingCenter } from '../model/trainingCenter';
import UserInfo from '../model/userInfo';
import { UserLink } from '../model/userLink';
import { ClientsApiService } from '../services/clientsApiService';
import { CoursesApiService } from '../services/coursesApiService';
import {FilesApiService} from "../services/filesApiService";
import { SchoolsApiService } from '../services/schoolsApiService';
import {ShareApiService} from "../services/shareApiService";
import { SkillsApiService } from '../services/skillsApiService';
import { TrainingCentersApiService } from '../services/trainingCentersApiService';
import { UsersApiService } from '../services/usersApiService';
import { Client } from './../model/client';
import { Project } from './../model/project';
import { Skill } from './../model/skill';
import { UserCV } from './../model/userCv';
import {
  deleteCertificateAction,
  deleteCertificateFail,
  deleteCertificateSuccess,
  deleteEducationAction,
  deleteEducationFail,
  deleteEducationSuccess,
  deleteLanguageAction,
  deleteLanguageFail,
  deleteLanguageSuccess,
  deleteLinkAction,
  deleteLinkFail,
  deleteLinkSuccess,
  deleteProjectAction,
  deleteProjectFail,
  deleteProjectSuccess,
  deleteTrainingAction,
  deleteTrainingFail,
  deleteTrainingSuccess,
  deleteUserSkillAction,
  deleteUserSkillFail,
  deleteUserSkillSuccess,
  fetchClientsAction,
  fetchClientsFail,
  fetchClientsSuccess,
  fetchCoursesAction,
  fetchCoursesFail,
  fetchCoursesSuccess,
  fetchCvAction,
  fetchCvFail,
  fetchCvSuccess,
  fetchCvVersionsAction,
  fetchCvVersionsFail,
  fetchCvVersionsSuccess,
  fetchProfileAction,
  fetchProfileFail,
  fetchProfileSuccess,
  fetchSchoolsAction,
  fetchSchoolsFail,
  fetchSchoolsSuccess,
  fetchSkillsAction,
  fetchSkillsFail,
  fetchSkillsSuccess,
  fetchTrainingCentersAction,
  fetchTrainingCentersFail,
  fetchTrainingCentersSuccess,
  fetchTrainingCoursesAction,
  fetchTrainingCoursesFail,
  fetchTrainingCoursesSuccess,
  postCertificateAction,
  postCertificateFail,
  postCertificateSuccess,
  postClientAction,
  postClientFail,
  postClientSuccess,
  postCourseAction,
  postCourseFail,
  postCourseSuccess,
  postEducationAction,
  postEducationFail,
  postEducationSuccess,
  postLanguageAction,
  postLanguageFail,
  postLanguageSuccess,
  postLinkAction,
  postLinkFail,
  postLinkSuccess,
  postProjectAction,
  postProjectFail,
  postProjectSuccess,
  postSchoolAction,
  postSchoolFail,
  postSchoolSuccess,
  postSkillAction,
  postSkillFail,
  postSkillSuccess,
  postTrainingAction,
  postTrainingCenterAction,
  postTrainingCenterFail,
  postTrainingCenterSuccess,
  postTrainingCourseAction,
  postTrainingCourseFail,
  postTrainingCourseSuccess,
  postTrainingFail,
  postTrainingSuccess,
  postUserSkillAction,
  postUserSkillFail,
  postUserSkillSuccess,
  putCertificateAction,
  putCertificateFail,
  putCertificateSuccess,
  putEducationAction,
  putEducationFail,
  putEducationSuccess,
  putLanguageAction,
  putLanguageFail,
  putLanguageSuccess,
  putLinkAction,
  putLinkFail,
  putLinkSuccess,
  putProjectAction,
  putProjectFail,
  putProjectPrioritiesAction,
  putProjectPrioritiesFail,
  putProjectPrioritiesSucces,
  putProjectSuccess,
  putTrainingAction,
  putTrainingFail,
  putTrainingSuccess,
  putUserInfoAction,
  putUserInfoFail,
  putUserInfoSuccess,
  putUserSkillAction,
  putUserSkillFail,
  putUserSkillPrioritiesAction,
  putUserSkillPrioritiesFail,
  putUserSkillPrioritiesSuccess,
  putUserSkillSuccess,
} from './slice';

export const fetchUserCV = (userId?: string) => (dispatch: any) => {
  dispatch(fetchCvAction());
  UsersApiService.fetchUserCV(userId)
    .then((response: AxiosResponse<UserCvDTO>) => {
      dispatch(fetchCvSuccess(UserCV.mapFromDto(response.data)));
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      if (err.response?.data.description?.includes('User was not found')) {
        ErrorHandler.handle('User was not found', dispatch, fetchCvFail, err);
      }
      else {
        ErrorHandler.handle('Failed to fetch CV', dispatch, fetchCvFail, err);
      } 
    });
};

export const fetchUserCvVersions = (userId?: string, selectVersionTag?: string) => (dispatch: any) => {
  dispatch(fetchCvVersionsAction());
  UsersApiService.fetchUserCvVersions(userId)
    .then((response: AxiosResponse<CvVersionDTO[]>) => {
      const versions = response.data.map(CvVersion.mapFromDto);
      const sortedVersions = sortCvVersions(versions);
      dispatch(fetchCvVersionsSuccess({versions: sortedVersions, selectVersionTag}));
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      ErrorHandler.handle(
        'Failed to fetch CV tags',
        dispatch,
        fetchCvVersionsFail,
        err,
      );
    });
};

export const copyCv = (id?: string, tag?: string) => {
  const state: AppState = store.getState();
  const userId = state.profile.currentProfile?.result?.id;
  return UsersApiService.copyCv(id || userId, tag);
}

export const deleteCv = (id?: string, version?: CvVersion) => {
  const state: AppState = store.getState();
  const userId = state.profile.currentProfile?.result?.id;
  return UsersApiService.deleteCv(id || userId, version?.tag);
}

// ----- SKILLS
export const postUserSkill = (
  userSkill: UserSkill,
  userId?: string,
  printMode?: boolean,
) => (dispatch: any) => {
  dispatch(postUserSkillAction());
  if (!printMode) {
    UsersApiService.postUserSkill(UserSkillDTO.mapToDTO(userSkill), userId)
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(postUserSkillSuccess({
          data: { ...userSkill, id: response.data.id },
          response: response.data,
        }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to save skill ${userSkill.name}`,
          dispatch,
          postUserSkillFail,
          err,
        );
      });
  } else {
    const id = uuidv4();
    dispatch(postUserSkillSuccess({ data: { ...userSkill, id }, response: { id } }));
  }
};

export const putUserSkill = (
  userSkill: UserSkill,
  userId?: string,
  printMode?: boolean,
) => (dispatch: any) => {
  dispatch(putUserSkillAction());
  if (!printMode) {
    UsersApiService.putUserSkill(UserSkillDTO.mapToDTO(userSkill), userId)
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(putUserSkillSuccess({ data: userSkill, response: response.data }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to update skill ${userSkill.name}`,
          dispatch,
          putUserSkillFail,
          err,
        );
      });
  } else {
    dispatch(putUserSkillSuccess({ data: userSkill, response: { id: userSkill.id } }));
  }
};

export const updateUserSkillPriorities = (userSkills: UserSkill[], userId?: string, printMode?: boolean) => async (dispatch: any) => {

  dispatch(putUserSkillPrioritiesAction())
  let newUserSkills = [...userSkills];  
  newUserSkills = newUserSkills.map((userSkill, index) => ({...userSkill, priority: index}));

  if(!printMode) {
    try {
      for (const userSkill of userSkills) {
        const index = userSkills.indexOf(userSkill);
        if (userSkill.priority === index) continue;
        const newUserSkill = {...userSkill, priority: index};
        await UsersApiService.putUserSkill({priority: newUserSkill.priority, id: newUserSkill.id}, userId);
      }
      dispatch(putUserSkillPrioritiesSuccess({data: newUserSkills, response: {id: ''}}));
    } catch(err) {
      ErrorHandler.handle(
        `Failed to update skills order`,
        dispatch,
        putUserSkillPrioritiesFail,
        err as AxiosError<ErrorResponse>,
      );
    }
  } else {
    dispatch(putUserSkillPrioritiesSuccess({data: newUserSkills, response: {id: ''}}));
  } 
}

export const deleteUserSkill = (
  id: string,
  userId?: string,
  printMode?: boolean,
) => (dispatch: any) => {
  dispatch(deleteUserSkillAction());
  if (!printMode) {
    UsersApiService.deleteUserSkill(id, userId)
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(deleteUserSkillSuccess({ data: { id }, response: response.data }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to delete skill`,
          dispatch,
          deleteUserSkillFail,
          err,
        );
      });
  } else {
    dispatch(deleteUserSkillSuccess({ data: { id }, response: { id } }));
  }
};

// LANGUAGES
export const postLanguage = (
  language: Language,
  userId?: string,
  printMode?: boolean,
) => (dispatch: any) => {
  dispatch(postLanguageAction());
  if (!printMode) {
    UsersApiService.postUserLanguage(LanguageDTO.mapToDTO(language), userId)
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(postLanguageSuccess({
          data: { ...language, id: response.data.id },
          response: response.data,
        }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to save language`,
          dispatch,
          postLanguageFail,
          err,
        );
      });
  } else {
    const id = uuidv4();
    dispatch(postLanguageSuccess({ data: { ...language, id }, response: { id } }));
  }
};

export const putLanguage = (
  language: Language,
  userId?: string,
  printMode?: boolean,
) => (dispatch: any) => {
  dispatch(putLanguageAction());
  if (!printMode) {
    UsersApiService.putUserLanguage(
      language.id!,
      LanguageDTO.mapToDTO(language),
      userId,
    )
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(putLanguageSuccess({ data: language, response: response.data }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to update language`,
          dispatch,
          putLanguageFail,
          err,
        );
      });
  } else {
    dispatch(putLanguageSuccess({ data: language, response: { id: language.id } }));
  }
};

export const deleteLanguage = (
  id: string,
  userId?: string,
  printMode?: boolean,
) => (dispatch: any) => {
  dispatch(deleteLanguageAction());
  if (!printMode) {
    UsersApiService.deleteUserLanguage(id, userId)
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(deleteLanguageSuccess({ data: { id }, response: response.data }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to delete language`,
          dispatch,
          deleteLanguageFail,
          err,
        );
      });
  } else {
    dispatch(deleteLanguageSuccess({ data: { id }, response: { id } }));
  }
};

// PROJECTS
export const postProject = (
  project: Project,
  userId?: string,
  printMode?: boolean,
) => async (dispatch: any) => {
  if (!printMode) {
    dispatch(postProjectAction());

    const newProject = _.cloneDeep(project);

    const result = await prepareProject(newProject, dispatch);

    if (result[0]) newProject.client!.id = (result[0] as Client).id;

    UsersApiService.postProject(UserProjectDTO.mapToDTO(newProject), userId)
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(postProjectSuccess({
          data: { ...newProject, id: response.data.id },
          response: response.data,
        }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to save project ${newProject?.client?.name}`,
          dispatch,
          postProjectFail,
          err,
        );
      });
  } else {
    const id = uuidv4();
    dispatch(postProjectSuccess({ data: { ...project, id }, response: { id } }));
  }
};

export const putProject = (
  project: Project,
  userId?: string,
  printMode?: boolean,
) => async (dispatch: any) => {
  dispatch(putProjectAction());

  const newProject = _.cloneDeep(project);

  const result = await prepareProject(newProject, dispatch);

  if (result[0]) newProject.client!.id = (result[0] as Client).id;

  if (!printMode) {
    UsersApiService.putProject(UserProjectDTO.mapToDTO(newProject), userId)
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(putProjectSuccess({ data: newProject, response: response.data }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to update project ${newProject?.client?.name}`,
          dispatch,
          putProjectFail,
          err,
        );
      });
  } else {
    dispatch(putProjectSuccess({ data: project, response: { id: project.id } }));
  }
};

export const updateProjectPriorities = (projects: Project[], userId?: string, printMode?: boolean) => async (dispatch: any) => {

  dispatch(putProjectPrioritiesAction())
  let newProjects = [...projects];  
  newProjects = newProjects.map((project, index) => ({...project, priority: index}));

  if(!printMode) {
    try {
      for (let i = 0; i < projects.length; i++) {
        if (projects[i].priority === newProjects[i].priority) continue;
        await UsersApiService.putProject(UserProjectDTO.mapToDTO(newProjects[i]), userId);
      }
      dispatch(putProjectPrioritiesSucces({response: {code: 200}, data: newProjects}));
    } catch(err) {
      ErrorHandler.handle(
        `Failed to update project order`,
        dispatch,
        putProjectPrioritiesFail,
        err as AxiosError<ErrorResponse>,
      );
    }
  } else {
    dispatch(putProjectPrioritiesSucces({response: {code: 200}, data: newProjects}));
  } 
}

export const deleteProject = (
  id: string,
  userId?: string,
  printMode?: boolean,
) => (dispatch: any) => {
  dispatch(deleteProjectAction());
  if (!printMode) {
    UsersApiService.deleteProject(id, userId)
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(deleteProjectSuccess({ data: { id }, response: response.data }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to delete project`,
          dispatch,
          deleteProjectFail,
          err,
        );
      });
  } else {
    dispatch(deleteProjectSuccess({ data: { id }, response: { id } }));
  }
};

// ----- EDUCATIONS
export const postUserEducation = (
  education: Education,
  userId?: string,
  printMode?: boolean,
) => async (dispatch: any) => {
  if (!printMode) {
    dispatch(postEducationAction());

    const newEducation = _.cloneDeep(education);

    const result = await prepareEducation(newEducation, dispatch);

    if (result[0]) newEducation.school!.id = (result[0] as School).id;
    if (result[1]) newEducation.course!.id = (result[1] as Course).id;

    UsersApiService.postUserEducation(
      UserEducationDTOPost.mapToDTO(newEducation),
      userId,
    )
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(postEducationSuccess({
          data: { ...newEducation, id: response.data.id },
          response: response.data,
        }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to save education ${newEducation?.course?.name} - ${newEducation.school?.name}`,
          dispatch,
          postEducationFail,
          err,
        );
      });
  } else {
    const id = uuidv4();
    dispatch(postEducationSuccess({ data: { ...education, id }, response: { id } }));
  }
};

export const putUserEducation = (
  education: Education,
  userId?: string,
  printMode?: boolean,
) => async (dispatch: any) => {
  if (!printMode) {
    dispatch(putEducationAction());

    const newEducation = _.cloneDeep(education);

    const result = await prepareEducation(education, dispatch);

    if (result[0]) newEducation.school!.id = (result[0] as School).id;
    if (result[1]) newEducation.course!.id = (result[1] as Course).id;

    UsersApiService.putUserEcucation(
      newEducation.id!,
      UserEducationDTOPut.mapToDTO(newEducation),
      userId,
    )
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(putEducationSuccess({ data: newEducation, response: response.data }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to update education ${newEducation?.course?.name} - ${newEducation.school?.name}`,
          dispatch,
          putEducationFail,
          err,
        );
      });
  } else {
    dispatch(putEducationSuccess({ data: education, response: { id: education.id } }));
  }
};

export const deleteUserEducation = (
  id: string,
  userId?: string,
  printMode?: boolean,
) => (dispatch: any) => {
  if (!printMode) {
    dispatch(deleteEducationAction());
    UsersApiService.deleteUserEducation(id, userId)
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(deleteEducationSuccess({ data: { id }, response: response.data }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to delete education`,
          dispatch,
          deleteEducationFail,
          err,
        );
      });
  } else {
    dispatch(deleteEducationSuccess({ data: { id }, response: { id } }));
  }
};

// CERTIFICATES
export const postCertificate = (
  certificate: Certificate,
  userId?: string,
  printMode?: boolean,
) => async (dispatch: any) => {
  if (!printMode) {
    dispatch(postCertificateAction());
  
    const newCertificate = _.cloneDeep(certificate);

    const result = await prepareCertificate(newCertificate, dispatch);

    if (result[0]) newCertificate.trainingCenter!.id = (result[0] as Certificate).id;

    UsersApiService.postUserCertificate(
        CertificateDTO.mapToDTO(newCertificate),
        userId,
    )
        .then((response: AxiosResponse<EventResponse>) => {
          dispatch(postCertificateSuccess({
            data: {...newCertificate, id: response.data.id},
            response: response.data,
          }));
        })
        .catch((err: AxiosError<ErrorResponse>) => {
          ErrorHandler.handle(
              `Failed to save certificate ${newCertificate?.name}`,
              dispatch,
              postCertificateFail,
              err,
          );
        });
  } else {
    const id = uuidv4();
    dispatch(postCertificateSuccess({data: {...certificate, id}, response: {id}}));
  }
};

export const putCertificate = (
  certificate: Certificate,
  userId?: string,
  printMode?: boolean,
) => async (dispatch: any) => {
  if (!printMode) {
    dispatch(putCertificateAction());

    const newCertificate = _.cloneDeep(certificate);

    const result = await prepareCertificate(newCertificate, dispatch);

    if (result[0]) newCertificate.trainingCenter!.id = (result[0] as Certificate).id;

    UsersApiService.putUserCertificate(
      newCertificate.id!,
      CertificateDTO.mapToDTO(newCertificate),
      userId,
    )
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(putCertificateSuccess({ data: newCertificate, response: response.data }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to update certificate ${newCertificate?.name}`,
          dispatch,
          putCertificateFail,
          err,
        );
      });
  } else {
    dispatch(putCertificateSuccess({ data: certificate, response: { id: certificate.id } }));
  }
};

export const deleteCertificate = (
  id: string,
  userId?: string,
  printMode?: boolean,
) => (dispatch: any) => {
  dispatch(deleteCertificateAction());
  if (!printMode) {
    UsersApiService.deleteUserCertificate(id, userId)
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(deleteCertificateSuccess({ data: { id }, response: response.data }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to delete certificate`,
          dispatch,
          deleteCertificateFail,
          err,        );
      });
  } else {
    dispatch(deleteCertificateSuccess({ data: { id }, response: { id } }));
  }
};

export const putUserInfo = (
  userInfo: UserInfo,
  userId?: string,
  printMode?: boolean,
) => (dispatch: any) => {
  if (!printMode) {
    dispatch(putUserInfoAction());
    UsersApiService.putUserInfo(UserInfoDTO.mapToDTO(userInfo), userId)
      .then((response: AxiosResponse<UserInfo>) => {
        dispatch(putUserInfoSuccess({ data: userInfo, response: {id: userInfo.id, code: response.status} }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to save personal info`,
          dispatch,
          putUserInfoFail,
          err,
        );
      });
  } else {
    dispatch(putUserInfoSuccess({ data: userInfo, response: userInfo }));
  }
};

export const fetchSkills = () => (dispatch: any) => {
  dispatch(fetchSkillsAction());
  SkillsApiService.fetchSkills()
    .then((response: AxiosResponse<SkillDTO[]>) => {
      dispatch(fetchSkillsSuccess(response?.data?.map(Skill.mapFromDto)));
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      ErrorHandler.handle(
        `Failed to fetch skills`,
        dispatch,
        fetchSkillsFail,
        err,
      );
    });
};

export const postSkill = (skill: Skill, printMode?: boolean) => (
  dispatch: any,
) => {
  dispatch(postSkillAction());
  if (!printMode) {
    SkillsApiService.postSkill(SkillDTO.mapToDTO(skill))
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(postSkillSuccess({
          data: { ...skill, id: response.data.id },
          response: response.data,
        }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to save skill`,
          dispatch,
          postSkillFail,
          err,
        );
      });
  } else {
    const id = uuidv4();
    dispatch(postSkillSuccess({ data: { ...skill, id }, response: { id } }));
  }
};

// ----- SCHOOLS
export const postSchool = (school: School, printMode?: boolean) => async (
  dispatch: any,
) => {
  if (!printMode) {
    dispatch(postSchoolAction());
    return await SchoolsApiService.postSchool(school)
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(postSchoolSuccess({
          data: { ...school, id: response.data?.id },
          response: response.data,
        }));
        dispatch(fetchSchools());
        return response.data;
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to save school`,
          dispatch,
          postSchoolFail,
          err,
        );
      });
  } else {
    const id = uuidv4();
    dispatch(postSchoolSuccess({ data: { ...school, id }, response: { id } }));
    return { ...school, id };
  }
};

// CLIENTS
export const fetchClients = () => (dispatch: any) => {
  dispatch(fetchClientsAction());
  ClientsApiService.fetchClients()
    .then((response: AxiosResponse<ClientDTO[]>) => {
      dispatch(fetchClientsSuccess(response?.data.map(Client.mapFromDTO)));
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      ErrorHandler.handle(
        `Failed to fetch clients`,
        dispatch,
        fetchClientsFail,
        err,
      );
    });
};

export const postClient = (client: Client, printMode?: boolean) =>
    async (dispatch: any) =>
{
  if (!printMode) {
    dispatch(postClientAction());
    return await ClientsApiService.postClient(ClientDTO.mapToDTO(client))
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(postClientSuccess({
          data: { ...client, id: response.data?.id },
          response: response.data,
        }));
        dispatch(fetchClients());
        return response.data;
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to save client`,
          dispatch,
          postClientFail,
          err,
        );
      });
  } else {
    const id = uuidv4();
    dispatch(postClientSuccess({ data: { ...client, id }, response: { id } }));
    return { ...client, id}
  }
};

export const fetchSchools = () => (dispatch: any) => {
  dispatch(fetchSchoolsAction());
  SchoolsApiService.fetchSchools()
    .then((response: AxiosResponse<SchoolDTO[]>) => {
      dispatch(fetchSchoolsSuccess(response?.data?.map(School.mapFromDto)));
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      ErrorHandler.handle(
        `Failed to fetch schools`,
        dispatch,
        fetchSchoolsFail,
        err,
      );
    });
};

export const postCourse = (course: Course, printMode?: boolean) => async (
  dispatch: any,
) => {
  if (!printMode) {
    dispatch(postCourseAction());
    return await CoursesApiService.postCourse(course)
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(postCourseSuccess({
          data: { ...course, id: response.data?.id },
          response: response.data,
        }));
        dispatch(fetchCourses());
        return response.data;
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to save course`,
          dispatch,
          postCourseFail,
          err,
        );
      });
  } else {
    const id = uuidv4();
    dispatch(postCourseSuccess({ data: { ...course, id }, response: { id } }));
    return { ...course, id };
  }
};

export const fetchCourses = () => (dispatch: any) => {
  dispatch(fetchCoursesAction());
  CoursesApiService.fetchCourses()
    .then((response: AxiosResponse<CourseDTO[]>) => {
      dispatch(fetchCoursesSuccess(response?.data?.map(Course.mapFromDto)));
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      ErrorHandler.handle(
        `Failed to fetch courses`,
        dispatch,
        fetchCoursesFail,
        err,
      );
    });
};

// TRAINING_CENTER
export const fetchTrainingCenters = () => (dispatch: any) => {
  dispatch(fetchTrainingCentersAction());
  TrainingCentersApiService.fetchTrainingCenters()
    .then((response: AxiosResponse<TrainingCenterDTO[]>) => {
      dispatch(fetchTrainingCentersSuccess(response?.data));
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      ErrorHandler.handle(
        `Failed to fetch trainingcenters`,
        dispatch,
        fetchTrainingCentersFail,
        err,
      );
    });
};

export const postTrainingCenter = (trainingCenter: TrainingCenter, printMode?: boolean) => async (
    dispatch: any
) => {
  if (!printMode) {
    dispatch(postTrainingCenterAction());
    return await TrainingCentersApiService.postTrainingCenter(
      TrainingCenterDTO.mapToDTO(trainingCenter),
    )
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(postTrainingCenterSuccess({
          data: { ...trainingCenter, id: response?.data?.id },
          response: response.data,
        }));
        dispatch(fetchTrainingCenters());
        return response.data;
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to save trainingcenter`,
          dispatch,
          postTrainingCenterFail,
          err,
        );
      });
  } else {
    const id = uuidv4();
    dispatch(postTrainingCenterSuccess({ data: { ...trainingCenter, id }, response: { id } }));
    return {...trainingCenter, id}
  }
};

// TRAININGS

export const postTrainingCourse = (
    course: Course,
    printMode?: boolean
) => async ( dispatch: any, ) => {
  if (!printMode) {
    dispatch(postTrainingCourseAction());
    return await CoursesApiService.postTrainingCourse(course)
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(postTrainingCourseSuccess({
          data: { ...course, id: response.data?.id },
          response: response.data,
        }));
        dispatch(fetchTrainingCourses());
        return response.data;
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to save course`,
          dispatch,
          postTrainingCourseFail,
          err,
        );
      });
  } else {
    const id = uuidv4();
    dispatch(postTrainingCourseSuccess({ data: { ...course, id }, response: { id } }));
    return { ...course, id}
  }
};

export const fetchTrainingCourses = () => (dispatch: any) => {
  dispatch(fetchTrainingCoursesAction());
  CoursesApiService.fetchTrainingCourses()
    .then((response: AxiosResponse<CourseDTO[]>) => {
      dispatch(
        fetchTrainingCoursesSuccess(response?.data?.map(Course.mapFromDto)),
      );
    })
    .catch((err: AxiosError<ErrorResponse>) => {
      ErrorHandler.handle(
        `Failed to fetch courses`,
        dispatch,
        fetchTrainingCoursesFail,
        err,
      );
    });
};

// TRAININGS
export const postTraining = (
  training: Training,
  userId?: string,
  printMode?: boolean,
) => async (dispatch: any) => {
  if (!printMode) {
    dispatch(postTrainingAction());

    const newTraining = _.cloneDeep(training);

    const result = await prepareTraining(newTraining, dispatch);

    if (result[0]) newTraining.trainingCenter!.id = (result[0] as TrainingCenter).id;
    if (result[1]) newTraining.trainingCourse!.id = (result[1] as Course).id;

    UsersApiService.postUserTraining(UserTrainingDTO.mapToDTO(newTraining), userId)
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(postTrainingSuccess({
          data: { ...newTraining, id: response.data.id },
          response: response.data,
        }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to save training`,
          dispatch,
          postTrainingFail,
          err,
        );
      });
  } else {
    const id = uuidv4();
    dispatch(postTrainingSuccess({ data: { ...training, id }, response: { id } }));
  }
};

export const putTraining = (
  training: Training,
  userId?: string,
  printMode?: boolean,
) => async (dispatch: any) => {
  if (!printMode) {
    dispatch(putTrainingAction());

    const newTraining = _.cloneDeep(training);

    const result = await prepareTraining(newTraining, dispatch);

    if (result[0]) newTraining.trainingCenter!.id = (result[0] as TrainingCenter).id;
    if (result[1]) newTraining.trainingCourse!.id = (result[1] as Course).id;

    UsersApiService.putUserTraining(
      newTraining.id!,
      UserTrainingDTO.mapToDTO(newTraining),
      userId,
    )
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(putTrainingSuccess({ data: newTraining, response: response.data }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to update training`,
          dispatch,
          putTrainingFail,
          err,
        );
      });
  } else {
    dispatch(putTrainingSuccess({ data: training, response: { id: training.id } }));
  }
};

export const deleteTraining = (
  id: string,
  userId?: string,
  printMode?: boolean,
) => (dispatch: any) => {
  if (!printMode) {
    dispatch(deleteTrainingAction());
    UsersApiService.deleteUserTraining(id, userId)
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(deleteTrainingSuccess({ data: { id }, response: response.data }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to delete training`,
          dispatch,
          deleteTrainingFail,
          err,
        );
      });
  } else {
    dispatch(deleteTrainingSuccess({ data: { id }, response: { id } }));
  }
};

export const postLink = (
  userLink: UserLink,
  userId?: string,
  printMode?: boolean,
) => (dispatch: any) => {
  dispatch(postLinkAction());
  if (!printMode) {
    UsersApiService.postUserLink(userLink, userId)
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(postLinkSuccess({
          data: { ...userLink, id: response.data.id },
          response: response.data,
        }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to save weblink ${userLink?.name}`,
          dispatch,
          postLinkFail,
          err,
        );
      });
  } else {
    const id = uuidv4();
    dispatch(postLinkSuccess({ data: { ...userLink, id }, response: { id } }));
  }
};

export const putLink = (
  userLink: UserLink,
  userId?: string,
  printMode?: boolean,
) => (dispatch: any) => {
  dispatch(putLinkAction());
  if (!printMode) {
    UsersApiService.putUserLink(userLink.id!, userLink, userId)
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(putLinkSuccess({ data: userLink, response: response.data }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to update weblink ${userLink?.name}`,
          dispatch,
          putLinkFail,
          err,
        );
      });
  } else {
    dispatch(putLinkSuccess({ data: userLink, response: { id: userLink.id } }));
  }
};

export const deleteLink = (
  id: string,
  userId?: string,
  printMode?: boolean,
) => (dispatch: any) => {
  dispatch(deleteLinkAction());
  if (!printMode) {
    UsersApiService.deleteUserLink(id, userId)
      .then((response: AxiosResponse<EventResponse>) => {
        dispatch(deleteLinkSuccess({ data: { id }, response: response.data }));
      })
      .catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
          `Failed to delete weblink`,
          dispatch,
          deleteLinkFail,
          err,
        );
      });
  } else {
    dispatch(deleteLinkSuccess({ data: { id }, response: { id } }));
  }
};

export const uploadImage = (
    file: File
) => {
  return FilesApiService.upload(file).then((response: AxiosResponse<Image>) => {
      return response.data
  });
}

export const createShareUrl = (recipient: string, userId: string, tag: string) => {
  return ShareApiService.generate(new ShareRequest(userId, tag, recipient)).then((response: AxiosResponse<ShareResponse>) => {
    return response.data
  });
}

async function prepareEducation(education: Education, dispatch: any) {
  const promises = [];

  if (education.school?.id === '' || !education.school?.id)
    promises.push(dispatch(postSchool(education.school!)));
  else promises.push(null);

  promises.push(dispatch(postCourse(education.course!)));

  return Promise.all(promises);
}

async function prepareCertificate(certificate: Certificate, dispatch: any) {
  const promises = [];

  if (certificate.trainingCenter?.id === '' || !certificate.trainingCenter?.id)
    promises.push(dispatch(postTrainingCenter(certificate.trainingCenter!)));
  else promises.push(null);

  return Promise.all(promises);
}

async function prepareProject(project: Project, dispatch: any) {
  const promises = [];

  if (project.client?.id === '' || !project.client?.id)
    promises.push(dispatch(postClient(project.client!)));
  else promises.push(null);

  return Promise.all(promises);
}

async function prepareTraining(training: Training, dispatch: any) {
  const promises = [];

  if (training.trainingCenter?.id === '' || !training.trainingCenter?.id)
    promises.push(dispatch(postTrainingCenter(training.trainingCenter!)));
  else promises.push(null);

  if (training.trainingCourse?.id === '' || !training.trainingCourse?.id)
    promises.push(dispatch(postTrainingCourse(training.trainingCourse!)));
  else promises.push(null);

  return Promise.all(promises);
}

