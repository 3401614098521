import CourseDTO from './dto-get/courseDTO';
import { CourseType } from './school';

export class Course {
  id?: string;
  name?: string;
  pictureUrl?: string;
  courseType?: CourseType;
  description?: string;

  constructor(name?: string) {
    this.id = '';
    this.name = name || '';
    this.pictureUrl = '';
    this.description = '';
    this.courseType = CourseType.HIGH_SCHOOL;
  }

  static mapFromDto(course: CourseDTO) {
    return {
      ...course,
    };
  }
}
