import { Image, StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  header: {
    position: 'absolute',
    top: 30,
    left: 30,
  },
  image: {
    width: 100,
    height: 'auto',
  },
});

export const Header = () => (
  <View fixed={true} style={styles.header}>
    <Image style={styles.image} src="https://images.aws.axxes.com/Logo_Axxes.png" />
  </View>
);

export default Header;
