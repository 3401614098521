import {Card, CardContent, CardTitle} from "@axxes/design-system";
import React, {useEffect} from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/store/hooks";
import { Education, UserCV} from "../../../../users/model";
import {SectionTypes} from "../../../models/models";
import {getInvisibles, getInvisibleSections} from "../../../store/selectors";
import {addInvisibleSection, removeInvisibleSection, updateCvCopy} from "../../../store/slice";
import EditorSortableContainer from "../common/EditorSortableContainer";

interface EducationsViewProps {
    editingCV?: UserCV,
    onSelect: () => void;
}

export const EducationsView = ({editingCV, onSelect}: EducationsViewProps) => {
    const dispatch = useAppDispatch();
    const invisibles = useAppSelector(getInvisibles);
    const invisbleSections = useAppSelector(getInvisibleSections);

    const updateReorderedEducations = (educations: Education[]) => {
      const newCV: UserCV = { ...editingCV, educations };
      dispatch(updateCvCopy(newCV));
    };

    useEffect(() => {
        const visibles: Education[] | undefined= editingCV?.educations?.filter((e:Education) => !invisibles.includes(e.id ?? ''));
        if(visibles?.length === 0) {
            if(!invisbleSections.includes(SectionTypes.EDUCATIONS)) dispatch(addInvisibleSection(SectionTypes.EDUCATIONS));
        }
        else {
            dispatch(removeInvisibleSection(SectionTypes.EDUCATIONS))
        }
    }, [invisibles]);

    return <Card>
            <div onClick={onSelect}>
                <CardTitle customClasses={'axxes-edit-pdf-pdf-block-title'}>Educations</CardTitle>
            </div>
        <CardContent>
            <EditorSortableContainer
                items={editingCV?.educations || []}
                type={SectionTypes.EDUCATIONS}
                updateReorderedItems={updateReorderedEducations}
            />
        </CardContent>
    </Card>
}
