import { FlexContainer } from '@axxes/design-system';
import React from 'react';
import { Node } from 'slate';
import { APP_CONFIG } from '../../../app/config/config';
import CvTextArea from '../../../common/components/TextArea/CvTextarea';
import { hasDescription } from '../../../common/utils/util';
import Avatar from '../Avatar/Avatar';
import AvatarSubtitle from '../Avatar/AvatarSubtitle';
import AvatarText from '../Avatar/AvatarText';
import AvatarTitle from '../Avatar/AvatarTitle';
import './cvElement.scss';

type CvElementProps = {
  title?: string;
  subtitle?: string;
  photo?: string;
  dates?: string;
  link?: string;
  description?: Node[];
}

function CvElementView({title, subtitle, dates, link, description, photo}: CvElementProps) {
  return (
    <FlexContainer padding='0' direction='column' customClasses='axxes-cv-element'>
      <Avatar
        photo={photo || APP_CONFIG.DEFAULT_IMAGE}
      >
        {title && <AvatarTitle>{title}</AvatarTitle>}
        {subtitle && <AvatarSubtitle>{subtitle}</AvatarSubtitle>}
        {link && <AvatarText>{link}</AvatarText>}
        {dates && <AvatarText>{dates}</AvatarText>}
        {description && hasDescription(description) && (
          <div className="axxes-cv-element__description">
            <CvTextArea
                title="Description"
                value={description}
                onChange={() => { /**/ }}
                readonly={true}
            />
          </div>
        )}
      </Avatar>
      {description && hasDescription(description) && (
        <div className="axxes-cv-element__description--mobile">
          <CvTextArea
              title="Description"
              value={description}
              onChange={() => { /**/ }}
              readonly={true}
          />
        </div>
      )}
    </FlexContainer>
  )
}

export default CvElementView

