import ReactPDF, {Text, View} from "@react-pdf/renderer";
import React from "react";
import {Node} from "slate";

export function treeToText(tree: Node[] | undefined, styles: ReactPDF.Styles) : JSX.Element[] {
    let viewComponents = [];
    let textComponents = [];
    if (tree) {
        viewComponents = [];
        for (let i = 0; i < tree?.length; i++) {
            const node: any = tree[i];
            if (node?.children && node?.children?.length > 0) {
                textComponents = [];
                for (let j = 0; j < node?.children?.length; j++) {
                    const child: any = node?.children[j];

                    if (child?.text) {
                        textComponents.push(
                            <Text wrap={true} key={i + j} style={getStyles(child)}>
                            {child?.text}
                        </Text>,
                    );
                    } else if (child.type === 'list-item') {
                        const listItems = [];
                        for (let k = 0; k < child?.children?.length; k++) {
                            listItems.push(
                                <Text
                                    wrap={false}
                            key={i + j + k}
                            style={{ ...getStyles(child?.children[k]) }}
                        >
                            {child?.children[k]?.text}
                            </Text>,
                        );
                        }
                        textComponents.push(
                            <View style={styles.listItem} key={i + j}>
                        <Text style={styles.listItem_bullet}>- </Text>
                        {listItems?.map((item) => item)}
                        <Text>{"\n"}</Text>
                        </View>
                    );
                    }
                }
            }
            viewComponents.push(
                <Text
                    style={{
                marginTop: '2px',
                    marginBottom: '2px',
            }}
            key={i}
                >
                {textComponents}
                </Text>,
        );
        }
    }
    return viewComponents;
};

const getStyles = (node: any) => {
    const _styles: any = {
        display: 'inline',
        width: 'auto',
        marginRight: '2px',
        marginTop: '2px',
        marginBottom: '2px',
    };
    if (node.bold) {
        _styles.fontWeight = 600;
    }
    if (node.code) {
        _styles.background = '#f4f4f4';
        _styles.border = '1px solid #ddd';
        _styles.borderLeft = '3px solid #f36d33';
        _styles.color = '#666';
        _styles.pageBreakInside = 'avoid';
        _styles.fontFamily = 'monospace';
        _styles.fontSize = '15px';
        _styles.lineHeight = '1.6';
        _styles.marginBottom = '1.6em';
        _styles.maxWidth = '100%';
        _styles.overflow = 'auto';
        _styles.padding = '1em 1.5em';
        _styles.display = 'block';
        _styles.wordWrap = 'break-word';
    }
    if (node.italic) {
        _styles.fontStyle = 'italic';
    }
    if (node.underline) {
        _styles.textDecoration = 'underline';
    }
    return _styles;
};