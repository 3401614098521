import {Card, CardContent, FlexContainer} from "@axxes/design-system";
import React from "react";
import {EditIssuers} from "../components/EditIssuers/EditIssuers";

export const ManageIssuerDataContainer = () => {
    return <FlexContainer
        direction="column"
        customClasses="axxes-manage__edit"
        padding="0"
    >
        <FlexContainer
            direction="row"
            padding="0"
        >
            <h1>Manage Issuer/Training Center Data</h1>
        </FlexContainer>
        <FlexContainer
            direction="column"
            padding="0"
            customClasses="axxes-manage__card"
        >
            <Card>
                <CardContent>
                    <EditIssuers/>
                </CardContent>
            </Card>
        </FlexContainer>
    </FlexContainer>
}