import React, { Dispatch } from "react";
import { AnyAction } from "redux";
import {APP_CONFIG} from "../../../app/config/config";
import ErrorHandler from "../../../common/api/errorHandler";
import {Image} from "../../model/image";

type ImageUploadProps = {
    upload: (file: File) => Promise<Image>
    onUploadSuccess: (image: Image) => void;
    dispatch: Dispatch<AnyAction>;
}

const ImageUpload = ({
    upload,
    onUploadSuccess,
    dispatch
}: ImageUploadProps) => {

    const supportedTypes: string[] = ['image/png','image/jpg','image/jpeg']

    return (
        <div className="axxes-input__container">
            <div className="axxes-input__field">
                <input 
                    accept={supportedTypes.join(',')} 
                    type='file'
                    onChange={(e) => {
                        const selectedFile = e.target.files?.[0];
                        if (selectedFile) {
                            upload(selectedFile)
                                .then((response: Image) => onUploadSuccess(response))
                                .catch((err) => {
                                    ErrorHandler.handle(`Failed to upload image ${selectedFile?.name}`);
                                })
                        } else {
                            onUploadSuccess(new Image(APP_CONFIG.DEFAULT_IMAGE))
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default ImageUpload;