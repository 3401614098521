import {Image, StyleSheet, Text, View} from "@react-pdf/renderer";
import React from "react";
import {APP_CONFIG} from "../../../app/config/config";
import { dateAvatarFormat } from "../../../common/utils/dates";
import {Project, Skill} from "../../../users/model";
import {DefaultStyles} from "./classes";
import {treeToText} from "./richTextUtils";

const styles = StyleSheet.create({
  ...DefaultStyles,
  avatar_container: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  avatar_image: {
    width: "30px",
    height: "30px",
    objectFit: 'contain',
    backgroundColor: '#F2F5F5',
    borderRadius: 2,
    marginRight: '4px'
  },
  avatar_info: { marginTop: 5 },
  avatar_title: {
    fontSize: 11,
    fontWeight: 'medium',
  },
  avatar_subtitle: {
    fontSize: 9,
  },
  avatar_desc: {
    fontWeight: 'light',
    fontSize: 9,
  },
  avatar_text: {
    fontWeight: 'light',
    fontSize: 9,
  },
  description_wrapper: {
    marginBottom: 5,
  },
  bold: {
    fontWeight: 'medium',
  },
  skills: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 0,
    flexWrap: 'wrap',
  },
  skill: {
    fontSize: 8,
    backgroundColor: '#e7e7e7',
    borderRadius: 10,
    padding: 2,
    paddingHorizontal: 5,
    marginRight: 3,
    fontWeight: 'light',
    marginBottom: 2,
  },
});

type SingleProjectProps = {
  project: Project
  minimizeds: string[]
}

export const SingleProject = ({project, minimizeds}: SingleProjectProps) => {

  const minimalMode = minimizeds.includes(project.id ?? '');

  return <View wrap={true} minPresenceAhead={1} key={project.id} style={styles.project_wrapper}>
    <View style={styles.avatar_container}>
      <Image
          style={styles.avatar_image}
          src={project?.client?.pictureUrl || APP_CONFIG.DEFAULT_IMAGE}
      />
      <View style={styles.avatar_info}>
        <Text style={styles.avatar_title}>{project?.client?.name}</Text>
        <Text style={styles.avatar_subtitle}>{project?.role}</Text>
        <Text style={styles.avatar_text}>
          {dateAvatarFormat(project)}
        </Text>
      </View>
    </View>
    {!minimalMode
        ? <View style={styles.project_info}>
          <View wrap={true} style={styles.description_wrapper}>
            {treeToText(project?.description, styles).map((text) => {
              return text;
            })}
          </View>
          {project.tasks?.length !== 0
              ? <>
                  <Text style={styles.project_title}>Tasks</Text>
                  <View>
                    {project.tasks?.map((task: string, index: number) => {
                      return (
                          <View style={styles.listItem} key={index}>
                            <Text style={styles.listItem_bullet}>-</Text>
                            <Text>{task}</Text>
                          </View>
                      );
                    })}
                  </View>
                </>
              : null
          }
          {project.skills?.length !== 0
              ? <>
                  <Text style={styles.project_title}>Skills</Text>
                  <View style={styles.skills}>
                    {project.skills?.map((skill: Skill, index: number) => {
                      return (
                          <View key={index} style={styles.skill}>
                            <Text>{skill.name}</Text>
                          </View>
                      );
                    })}
                  </View>
                </>
              : null
          }
        </View>
        : null
    }
  </View>
}
