import { Input } from '@axxes/design-system';
import React from 'react';

import { Address } from '../../model/address';

type AddressFormProps = {
  address: Address;
  setAddress?: (adress: Address) => void;
  open: boolean;
};

const AddressForm = ({ address, setAddress, open }: AddressFormProps) => {
  return (
    <div className={`axxes-cv-form__add ${open && '-open'}`}>
      <div className="axxes-cv-form__container axxes-cv-form__dual-form">
        <Input
          value={address.city || ''}
          onChange={(e) =>
            setAddress?.({ ...address, city: e.target.value })
          }
          title="City"
          type="text"
        />
        <Input
          value={address.province || ''}
          onChange={(e) =>
            setAddress?.({ ...address, province: e.target.value })
          }
          title="Province"
          type="text"
        />
        <Input
          value={address.country || ''}
          onChange={(e) =>
            setAddress?.({ ...address, country: e.target.value })
          }
          title="Country"
          type="text"
        />
      </div>
    </div>
  );
};

export default AddressForm;
