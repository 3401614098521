import {Profile} from "../users/model/profile";

export const currentProfileHasAnyRole = (currentProfile: Profile | undefined, roles: any[]) => {
    if (!currentProfile) {
        return false;
    }

    return rolesContainAnyRoleOf(currentProfile.roles || [], roles)
}

export const rolesContainAnyRoleOf = (currentRoles: string[], roles: any[]) => {
    const actualRoles = roles.filter(role => role !== undefined);
    return currentRoles?.some(appRole => actualRoles.indexOf(appRole) >= 0)
}