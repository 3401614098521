import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { sortLanguages } from "../../../common/utils/sorters";
import { Language, LanguageLevel } from '../../../users/model/language';
import { CVProps } from '../CV';
import { DefaultStyles} from './classes';

const styles = StyleSheet.create({
  ...DefaultStyles,
  languages_title: {
    fontSize: 13,
  },
  languages_container: {
    marginBottom: 10,
  },
  languages_wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 3,
  },
  languages_name: {
    minWidth: 100,
    fontWeight: 500,
    textTransform: 'capitalize',
  },
  languages_tag: {
    color: 'white',
    width: 90,
    marginHorizontal: 5,
    borderRadius: 3,
    padding: 2,
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  languages_header: {
    width: 100,
    textAlign: 'center',
    textTransform: 'capitalize',
  },
});

export const Languages = ({ cv }: CVProps) => (
  <View wrap={false} style={styles.card}>
    <Text style={styles.card_title}>Languages</Text>
    <View style={styles.card_content_row}>
      <Text style={styles.languages_header} />
      <Text style={styles.languages_header}>Understanding</Text>
      <Text style={styles.languages_header}>Speaking</Text>
      <Text style={styles.languages_header}>Writing</Text>
    </View>
    {sortLanguages(cv?.languages)?.map((language: Language) => {
      const color = (level?: LanguageLevel) => {
        switch (level) {
          case 'NATIVE':
            return { backgroundColor: '#E9531D' };
          case 'FLUENT':
            return { backgroundColor: '#1d242b' };
          case 'INTERMEDIATE':
            return { backgroundColor: '#5B6770' };
          case 'BASIC':
            return { backgroundColor: '#A1A8AC' };
          default:
            return { backgroundColor: '#A1A8AC' };
        }
      };
      return (
        <View key={language.id} style={styles.languages_wrapper}>
          <Text style={styles.languages_name}>{language.language} </Text>
          <Text
            style={[styles.languages_tag, color(language.understandingLevel)]}
          >
            {language.understandingLevel?.toLowerCase()}
          </Text>
          <Text style={[styles.languages_tag, color(language.speakingLevel)]}>
            {language.speakingLevel?.toLowerCase()}
          </Text>
          <Text style={[styles.languages_tag, color(language.writingLevel)]}>
            {language.writingLevel?.toLowerCase()}
          </Text>
        </View>
      );
    })}
  </View>
);

export default Languages;
