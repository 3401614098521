import { FetchState } from '../../common/store/state';
import { User } from '../../users/model';
import { MultiResponse } from './../../common/models/multiResponse';

export interface ManageState {
    usersResponse?: FetchState<MultiResponse<User>>
    search?: { query : string };
}

export const manageInitialState: ManageState = {
    usersResponse: { isLoading: false }
}