import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { APP_CONFIG } from '../../../app/config/config';
import {Certificate} from '../../../users/model';
import { CVProps } from '../CV';
import Avatar from './Avatar';

import { certificateDateFormat } from "../../../common/utils/dates";
import { DefaultStyles} from './classes';
import {treeToText} from "./richTextUtils";

const styles = StyleSheet.create({
  ...DefaultStyles,
    avatar_description: {
      width: '92%',
    }
});

export const Certificates = ({ cv, invisibles, minimizeds }: CVProps) => {
  const visibles: Certificate[] = cv?.certificates ?
      cv?.certificates?.filter((e: Certificate) => !invisibles.includes(e.id ?? '')) : [];

  return visibles.length > 0 ? (
    <View style={styles.card}>
      <Text style={styles.card_title}>Certificates</Text>
      {visibles.map((certificate: Certificate) => {
        return (
          <Avatar
            key={certificate.id}
            content={
              <View wrap={true}>
                <Text style={styles.avatar_title}>{certificate?.name}</Text>
                <Text>{certificate?.trainingCenter?.name}</Text>
                <Text style={styles.avatar_text}>
                  { certificate?.certificateUrl }
                </Text>
                <Text style={styles.avatar_text}>
                  {certificateDateFormat(certificate)}
                </Text>
                  {!minimizeds.includes(certificate.id ?? '') ?
                    <View wrap={true} style={[{ flexWrap: 'wrap'}, styles.avatar_description]}>
                      {treeToText(certificate?.description, styles).map((text) => {
                      return text;})}
                    </View> : null}
              </View>
            }
            pictureUrl={certificate?.trainingCenter?.pictureUrl || APP_CONFIG.DEFAULT_IMAGE}
          />)
    })}
    </View>) : <React.Fragment/>
  };

  export default Certificates;
