import {
  Button,
  CardContent,
  CardHeader,
  CardTitle,
  Dropdown,
  FlexContainer,
  Item,
} from '@axxes/design-system';
import React, { useState } from 'react';
import { ReplaceContent } from '../../models/modals';

type ReplaceModalProps = {
  content?: ReplaceContent;
  onReplace: (replaceWithId: string) => void;
  onCancel?: () => void;
};

function ReplaceModal({ content, onReplace, onCancel }: ReplaceModalProps) {
  const [selectedObject, setSelectedObject] = useState<Item>({name: '', value: ''});

  return (
    <div>
      <CardHeader>
        <CardTitle>Replace {content?.objToReplace?.name}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="axxes-edit__destruction-warn">
          WARNING: You are about to perform a DESTRUCTIVE change. Please verify
          the changes you make before saving them!
        </div>
        <div>
          <p>
            This action will replace the {content?.label}{' '}
            <b>{content?.objToReplace?.name}</b> with the one selected below. As
            a result of this change, every CV that references the current{' '}
            {content?.label} will be modified to use the selected{' '}
            {content?.label}.
          </p>
          <p>
            The {content?.label} that needs to be replaced{' '}
            <b>will be deleted.</b>
            This action cannot be undone.
          </p>
        </div>
        {content?.dropDownItems && (
          <Dropdown
            items={content.dropDownItems?.map((c) => {
              const item = new Item();
              item.name = c.name ?? 'UNDEFINED NAME';
              item.value = c.value ?? 'UNDEFINED ID';
              return item;
            })}
            value={selectedObject}
            setValue={(i) => {
              let newSelected = i as Item;
              const dropdownItem = content.dropDownItems?.find(
                (c) => c.value === newSelected.value,
              );
              if (dropdownItem) newSelected = dropdownItem;
              setSelectedObject(newSelected);
            }}
            autoComplete={true}
            autoFocus={true}
            required={true}
          />
        )}
        <FlexContainer>
          <Button
            disabled={false}
            accent={false}
            type="submit"
            isSaving={false}
            onClick={onCancel}
            customClasses="axxes-edit__item-button"
          >
            <span>Cancel</span>
          </Button>
          <Button
            disabled={!selectedObject}
            accent={true}
            type="submit"
            isSaving={false}
            onClick={() => onReplace(selectedObject.value.toString())}
            customClasses="axxes-edit__item-button"
          >
            <span>
              {!selectedObject
                ? `Replace ${content?.objToReplace?.name}`
                : `Replace ${content?.objToReplace?.name} with ${selectedObject.name}`}
            </span>
          </Button>
        </FlexContainer>
      </CardContent>
    </div>
  );
}

export default ReplaceModal;
