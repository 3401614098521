import {
    Button,
    FlexContainer,
    Input,
    Item,
} from "@axxes/design-system";
import React, {ReactElement, useEffect, useReducer, useState} from "react";
import {APP_CONFIG} from "../../../app/config/config";
import EditOverlay from "../../../common/components/EditOverlay/EditOverlay";
import ItemForm from "../../../common/components/ItemForm/ItemForm";
import { ModalTypes } from "../../../common/models/modals";
import { useAppDispatch, useAppSelector } from "../../../common/store/hooks";
import { showModal } from "../../../common/store/slice";
import { sortByName } from "../../../common/utils/sorters";
import { truncate } from "../../../common/utils/strings";
import Avatar from "../../../users/components/Avatar/Avatar";
import AvatarSubtitle from "../../../users/components/Avatar/AvatarSubtitle";
import AvatarTitle from "../../../users/components/Avatar/AvatarTitle";
import {Client} from "../../../users/model";
import {fetchClients} from "../../../users/store/usersFacadeService";
import {replaceClient, updateClient} from "../../store/manageFacadeService";
import '../edit.scss'
import editClientReducer, {
    INITIAL_EDIT_CLIENTS_STATE, resetClient, setClient,
} from "./editClientsState";


export const EditClients = () => {

    const reduxDispatch = useAppDispatch();

    const [search, setSearch] = useState<string>('')

    const [editing, setEditing] = useState<string | null>(null)

    const resetEditing = () => setEditing(null)

    const [state, dispatch] = useReducer(editClientReducer, INITIAL_EDIT_CLIENTS_STATE);

    const saveChanges = () => {
        if (state.editingClient) reduxDispatch(updateClient(state.editingClient));
        cancel();
    }

    const cancel = () => {
        dispatch(resetClient());
        resetEditing();
    }

    const clients = useAppSelector(
        (appState) => appState?.users?.clients?.fetch?.result || [],
    );

    const loadingClients = useAppSelector(
        (appState) => appState?.users?.clients?.fetch?.isLoading,
    );


    useEffect(() => {
        reduxDispatch(fetchClients())
    }, [])

    const submitReplace = (replaceId: string) => {
      const newClient = clients.find(c => c.id === replaceId);
      if (state.editingClient && newClient) {
        reduxDispatch(
          replaceClient(state.editingClient, newClient),
        );
      }
      cancel();
    };

    const openModal = () => {
      reduxDispatch(
        showModal(ModalTypes.REPLACE, {
          label: 'client',
          onReplace: submitReplace,
          dropDownItems: clients.map(client => ({value: client.id || '', name: client.name || ''})),
          objToReplace: state?.editingClient,
        }),
      );
    };

    const mapClient = (client: Client) => {
        return (
            <div className="axxes-edit__item" key={client?.id}>
                <EditOverlay
                    isEditable={editing === null}
                    setEdit={() => {
                        dispatch(setClient(client));
                        setEditing(client?.id || null)
                    }}
                    edit={editing === client?.id}
                    viewElement={
                        <Avatar photo={client?.pictureUrl || APP_CONFIG.DEFAULT_IMAGE}>
                            <AvatarTitle>{truncate(client?.name || "", 32)}</AvatarTitle>
                            <AvatarSubtitle>{truncate(client?.url || "No Url Specified", 64)}</AvatarSubtitle>
                        </Avatar>
                    }
                    editElement={
                        <div>
                            <ItemForm
                                newItem={state?.editingClient}
                                open={editing === client?.id}
                                withName={true}
                                title="Edit"
                                setNewItem={(value: Client) => dispatch(setClient(value))}
                                withPhoto={true}
                                withLink={true}
                            />
                            <FlexContainer
                                direction="row"
                                customClasses="axxes-edit__item-actions"
                            >
                                <Button
                                    disabled={false}
                                    accent={false}
                                    type="submit"
                                    isSaving={false}
                                    onClick={cancel}
                                    customClasses="axxes-edit__item-button"
                                >
                                    <span>Cancel</span>
                                </Button>
                                <Button
                                    disabled={false}
                                    accent={true}
                                    variant={"default"}
                                    type="submit"
                                    isSaving={false}
                                    onClick={openModal}
                                    customClasses="axxes-edit__item-button"
                                >
                                    <span>Replace</span>
                                </Button>
                                <Button
                                    disabled={false}
                                    accent={true}
                                    variant={"default"}
                                    type="submit"
                                    isSaving={false}
                                    onClick={saveChanges}
                                >
                                    <span>Save</span>
                                </Button>
                            </FlexContainer>
                        </div>
                    }
                />
            </div>
        )
    };

    const filterClient = (client: Client) => {
        if (search === '') return true;
        return client?.name?.toLowerCase().includes(search);
    };
    const mappedClients : ReactElement[] = clients.filter(filterClient).sort(sortByName).map(mapClient);
    return(
        <>
            {loadingClients ? <div>Loading Clients...</div> :
                <>
                    <div className="axxes-edit__search">
                        <Input title="Search Clients"
                               defaultValue={search}
                               onChange={((e) => setSearch(e.target.value.toLowerCase()))}
                        />
                    </div>
                    <div>Found {mappedClients.length} clients: </div>
                    <FlexContainer
                    padding="0"
                    customClasses="axxes-edit__container"
                    >
                        {mappedClients}
                    </FlexContainer>
                </>
            }
        </>
    )
}