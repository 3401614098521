import WebLinkDTO from './dto-get/webLinkDTO';

export class UserLink {
  id?: string;
  link?: string;
  icon?: string;
  name?: string;

  constructor() {
    this.name = '';
    this.link = '';
    this.icon = 'globe';
  }

  static mapFromDTO(webLinkDTO: WebLinkDTO) {
    return {
      ...webLinkDTO,
    };
  }
}
