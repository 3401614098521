import {Card, CardContent, CardHeader, CardTitle, FlexContainer} from "@axxes/design-system";
import React from "react";
import { sortTrainings } from "../../common/utils/sorters";
import UserTrainingView from "../../users/components/UserTrainings/UserTrainingView";
import {Training} from "../../users/model";

export interface ShareTrainingsProps {
    trainings?: Training[];
}

export const ShareTainings = ({trainings}: ShareTrainingsProps) => {
    return (
            trainings?.length === 0 ? null :
            <Card>
                <CardHeader>
                    <CardTitle>Trainings</CardTitle>
                </CardHeader>
                <CardContent>
                    <FlexContainer customClasses='axxes-cv-element-list' padding='0' direction='column'>
                        {trainings?.length === 0
                            ? null
                            : sortTrainings(trainings).map((training: Training, index: number) => {
                                    return (<UserTrainingView training={training} key={training.id}/>
                                    );
                                })}
                    </FlexContainer>
                </CardContent>
            </Card>
    );
}