import {
  Book,
  Certificate,
  Education,
  Project,
  Training,
  User,
  UserSkill,
} from '.';
import UserCvDTO from './dto-get/userCvDTO';
import { Language } from './language';
import UserInfo from './userInfo';
import { UserLink } from './userLink';

export class UserCV {
  user?: User;
  userInfo?: UserInfo;
  books?: Book[];
  projects?: Project[];
  skills?: UserSkill[];
  educations?: Education[];
  trainings?: Training[];
  certificates?: Certificate[];
  links?: UserLink[];
  languages?: Language[];

  static mapFromDto(user: UserCvDTO) {
    return {
      user: user.user,
      books: user.books?.map(Book.mapFromDTO),
      projects: user.projects?.map(Project.mapFromDTO),
      skills: user.skills?.map(UserSkill.mapFromDTO),
      educations: user.education?.map(Education.mapFromDTO),
      trainings: user.trainings?.map(Training.mapFromDTO),
      certificates: user.certificates?.map(Certificate.mapFromDTO),
      links: user.weblinks?.map(UserLink.mapFromDTO),
      userInfo: UserInfo.mapFromDTO(user.userInfo),
      languages: user.languages?.map(Language.mapFromDTO),
    };
  }
}
