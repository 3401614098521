import {FlexContainer, SideNav} from '@axxes/design-system';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { APP_CONFIG } from '../../app/config/config';
import {GenerateContainer} from '../../generate/containers/GenerateContainer';
import {ManageClientDataContainer} from "../../manage/containers/ManageClientDataContainer";
import {ManageEdcuationDataContainer} from "../../manage/containers/ManageEducationDataContainer";
import {ManageIssuerDataContainer} from "../../manage/containers/ManageIssuerDataContainer";
import {ManageSchoolDataContainer} from "../../manage/containers/ManageSchoolDataContainer";
import {ManageSkillsDataContainer} from "../../manage/containers/ManageSkillsDataContainer";
import {ManageTrainingDataContainer} from "../../manage/containers/ManageTrainingDataContainer";
import ManageUsersContainer from '../../manage/containers/ManageUsersContainer';
import {fetchProfile} from "../../profile/ProfileService";
import {currentProfileHasAnyRole} from "../../profile/RoleService";
import UserDetailContainer from '../../users/containers/UserDetailContainer';
import {ROLES} from "../auth/RoleConfig";

import { DangerZoneContainer } from '../../manage/containers/DangerZoneContainer';
import NotFound from '../components/NotFound/NotFound';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import AdminRoute from './AdminRoute';
import "./routes.scss";

const Main = () => {
  const profileState = useAppSelector((state) => state?.profile?.currentProfile);
  const canManage = currentProfileHasAnyRole(profileState?.result, [ROLES.HR, ROLES.ADMINISTRATOR])
  const dispatch = useAppDispatch();


  const sideNavs = APP_CONFIG.SIDE_NAVS.filter(
      (nav) => (!canManage && !nav.isSecured) || canManage,
  );

  useEffect(() => {
    dispatch(fetchProfile());
  }, []);

  if (!profileState?.isLoading && profileState?.result) {
    return (
      <FlexContainer customClasses="axxes-root__wrapper" padding="0px" grow={1}>
        <Switch>
          <Route exact={true} path="/">
            <Redirect to="/profile" />
          </Route>
          <Route exact={true} path="/profile" component={UserDetailContainer} />

          <Route
            path="/manage"
            render={({ match: { url, params } }) => (
              <FlexContainer padding="0" grow={1}>
                <SideNav links={sideNavs} />
                <Route path={`${url}`} exact={true}>
                  <Redirect to={`${url}/users`} />
                </Route>
                <AdminRoute
                  path={`${url}/users`}
                  component={ManageUsersContainer}
                  accessibleBy={ROLES.NOT_CONSULTANT}
                />
                <AdminRoute
                    path={`${url}/edit/clients`}
                    component={ManageClientDataContainer}
                    accessibleBy={[ROLES.HR, ROLES.ADMINISTRATOR]}
                />
                <AdminRoute
                    path={`${url}/edit/issuers`}
                    component={ManageIssuerDataContainer}
                    accessibleBy={[ROLES.HR, ROLES.ADMINISTRATOR]}
                />
                <AdminRoute
                    path={`${url}/edit/schools`}
                    component={ManageSchoolDataContainer}
                    accessibleBy={[ROLES.HR, ROLES.ADMINISTRATOR]}
                />
                <AdminRoute
                    path={`${url}/edit/educations`}
                    component={ManageEdcuationDataContainer}
                    accessibleBy={[ROLES.HR, ROLES.ADMINISTRATOR]}
                />
                <AdminRoute
                    path={`${url}/edit/trainings`}
                    component={ManageTrainingDataContainer}
                    accessibleBy={[ROLES.HR, ROLES.ADMINISTRATOR]}
                />
                <AdminRoute
                    path={`${url}/edit/skills`}
                    component={ManageSkillsDataContainer}
                    accessibleBy={[ROLES.HR, ROLES.ADMINISTRATOR]}
                />
                <AdminRoute
                  path={`${url}/dangerzone`}
                  component={DangerZoneContainer}
                  accessibleBy={[ROLES.ADMINISTRATOR]}
                />
              </FlexContainer>
            )}
          />

          <Route exact={true} path="/users">
            <Redirect to="/manage/users" />
          </Route>
          <Route
            exact={true}
            path="/users/:userId"
            component={UserDetailContainer}
          />
          <AdminRoute
            exact={true}
            path="/generate/:userId/:modified?"
            component={GenerateContainer}
            accessibleBy={[ROLES.SALES, ROLES.HR, ROLES.ADMINISTRATOR]}
          />
          <Route exact={true} path="/generate" component={GenerateContainer} />
          <Route exact={true} path="/notfound" component={NotFound} />
          <Route path="*">
            <Redirect to="/notfound" />
          </Route>
        </Switch>
      </FlexContainer>
    );
  } else {
    return (
        <FlexContainer
            customClasses="axxes-loading__container"
            grow={1}
            padding="0px"
            direction="column"
        >
          <span className="axxes-loading__loading-text">Loading...</span>
        </FlexContainer>
    );
  }
};

export default Main;
