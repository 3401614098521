import {
  Button,
  Dropdown,
  FlexContainer,
  Item,
} from '@axxes/design-system';
import {yupResolver} from "@hookform/resolvers/yup";
import _ from 'lodash';
import React, { useReducer } from 'react';
import {Controller, useForm} from "react-hook-form";
import {useRouteMatch} from "react-router-dom";
import * as yup from "yup";

import { APP_CONFIG } from '../../../app/config/config';
import CvDatePicker from '../../../common/components/DatePicker/DatePicker';
import ItemForm from '../../../common/components/ItemForm/ItemForm';
import CvTextArea from "../../../common/components/TextArea/CvTextarea";
import { ModalTypes } from '../../../common/models/modals';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import { showModal } from '../../../common/store/slice';
import { dateToString } from "../../../common/utils/dates";
import { DropdownSchema } from "../../../common/utils/validations";
import { updateIssuer } from '../../../manage/store/manageFacadeService';
import { Course, Training } from '../../model';
import { TrainingCenter } from '../../model/trainingCenter';
import { postTraining, putTraining } from "../../store/usersFacadeService";
import { MatchParams } from "../UserEducations/UserEducationsEdit";
import trainingReducer, { INITIAL_TRAINING_EDIT_STATE, openTrainingCenterForm, setTraining, setTrainingCenter, setTrainingCourse, TrainingEditState } from './state';

type UserTrainingEditProps = {
  trainingCenters?: TrainingCenter[];
  trainingCourses?: Course[];
  trainingProp?: Training;
  remove?: (training?: Training) => void;
  cancel?: () => void;
  isSaving?: boolean;
  isDeleting?: boolean;
  creating: boolean;
  postSave: () => void
};

const UserTrainingEdit = ({
  trainingCenters,
  trainingCourses,
  trainingProp,
  remove,
  cancel,
  isSaving,
  isDeleting,
  creating,
  postSave
}: UserTrainingEditProps) => {
  const { handleSubmit, errors, control } = useForm({
    criteriaMode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(
        yup.object().shape({
          trainingCourse: DropdownSchema,
          trainingCenter: DropdownSchema
        }),
    ),
  });

  const [state, dispatch] = useReducer(
    trainingReducer,
    creating
      ? { ...INITIAL_TRAINING_EDIT_STATE, trainingCenters, trainingCourses }
      : ({
          training: trainingProp,
          openCourseForm: false,
          openTrainingCenterForm: false,
          trainingCenters,
          trainingCourses
      } as TrainingEditState),
  );

  const printMode = useAppSelector(
      (appState) => appState?.common?.printMode,
  );

  const reduxDispatch = useAppDispatch();
  const match = useRouteMatch<MatchParams>('/users/:userId');

  const save = handleSubmit(() => {
    if (creating) {
      reduxDispatch(postTraining(state.training!, match?.params?.userId, printMode))
    } else {
      const oldTrainingCenter = trainingCenters?.find(t => t.id === state.training?.trainingCenter?.id)
      if (!_.isEqual(state.training?.trainingCenter, oldTrainingCenter) && state.training?.trainingCenter && oldTrainingCenter) {
        reduxDispatch(updateIssuer(state?.training?.trainingCenter));
      };
      reduxDispatch(putTraining(state.training!, match?.params?.userId, printMode));
    }
    postSave()
  })

  const removeTraining = () => {
    reduxDispatch(
      showModal(ModalTypes.DELETE, {
        label: 'training',
        name: state?.training?.trainingCourse?.name,
        onConfirm: () => {
          remove?.(state?.training);
        },
      }),
    );
  };

  return (
      <div className="axxes-cv-form axxes-cv-form__container">
        <img
          className="axxes-cv-form__photo"
          alt="avatar-img"
          src={state?.training?.trainingCenter?.pictureUrl || APP_CONFIG.DEFAULT_IMAGE}
        />
        <FlexContainer padding="0" direction="column" grow={1}>
          <FlexContainer
            padding="0"
            direction="column"
            customClasses="axxes-cv-form__container"
            grow={1}
          >
            <Controller
              name="trainingCenter"
              defaultValue={{
                name: state?.training?.trainingCenter?.name || '',
                value: state?.training?.trainingCenter?.id || '',
              } as Item}
              control={control}
              render={({ onChange, value }) => (
                  <Dropdown
                      items={
                        trainingCenters?.map(
                            (tc) => ({ value: tc.id, name: tc.name } as Item),
                        ) || []
                      }
                      autoComplete={true}
                      value={value}
                      setValue={(newValue) => {
                        const item = newValue as Item;
                        onChange(item)
                        const newTrainingCenter =
                            trainingCenters?.find((center) => center.id === item?.value) ??
                            new TrainingCenter();
                        if ((!newTrainingCenter.name || newTrainingCenter.name === '') && item?.name !== '') {
                          newTrainingCenter.name = item?.name;
                        }
                        dispatch(setTrainingCenter(newTrainingCenter));
                        dispatch(openTrainingCenterForm((!item?.value || !newTrainingCenter.pictureUrl) && item?.name?.length! >= 3));
                      }}
                      title="Training center"
                      required={true}
                      warning={errors.trainingCenter}
                  />
              )}
            />
            <ItemForm
              newItem={state?.training?.trainingCenter}
              setNewItem={(newTrainingCenter: TrainingCenter) => {
                dispatch(setTraining({
                  ...state.training,
                  trainingCenter: newTrainingCenter,
                }))
              }}
              open={state?.openTrainingCenterForm === true}
              title={
                !state?.training?.trainingCenter?.id ? 'New training center' : 'Update training center'
              }
            />
            <Controller
              name="trainingCourse"
              defaultValue={
                {
                  value: state?.training?.trainingCourse?.id || '',
                  name: state?.training?.trainingCourse?.name || '',
                } as Item
              }
              control={control}
              render={({ onChange, value }) => (
                  <Dropdown
                      items={
                        trainingCourses?.map(
                            (cc) => ({ value: cc.id, name: cc.name } as Item),
                        ) || []
                      }
                      autoComplete={true}
                      value={value}
                      setValue={(item) => {
                        onChange(item)
                        const course = trainingCourses?.find(c => c.id === (item as Item).value) || new Course((item as Item).name);
                        dispatch(setTrainingCourse(course));
                      }}
                      title="Course"
                      required={true}
                      warning={errors.trainingCourse}
                  />
              )}
            />
            <div className="axxes-cv-form__dual-form">
              <Controller
                name="startDate"
                defaultValue={
                  state?.training?.fromDate
                      ? new Date(state?.training?.fromDate)
                      : null
                }
                control={control}
                render={({ onChange, value }) => (
                    <CvDatePicker
                        title="Start date"
                        onChange={(date) => {
                          onChange(date)
                          dispatch(setTraining({
                            ...state.training,
                            fromDate: dateToString(date),
                          }));
                        }}
                        value={value}
                        maxDate={
                          state?.training?.toDate
                              ? new Date(state?.training?.toDate)
                              : undefined
                        }
                    />
                )}
              />
              <Controller
                name="endDate"
                defaultValue={
                  state?.training?.toDate
                      ? new Date(state?.training?.toDate)
                      : null
                }
                control={control}
                render={({ onChange, value }) => (
                    <CvDatePicker
                        title="End date"
                        onChange={(date) => {
                          onChange(date)
                          dispatch(setTraining({
                            ...state.training,
                            toDate: dateToString(date),
                          }));
                        }}
                        value={value}
                        minDate={
                          state?.training?.fromDate
                              ? new Date(state?.training?.fromDate)
                              : undefined
                        }
                    />
                )}
              />
            </div>
            <Controller
                name="description"
                defaultValue={state?.training?.description || ''}
                control={control}
                render={({ onChange, value}) => (
                    <CvTextArea
                        title="Description"
                        value={value}
                        onChange={(e) => {
                            onChange(e)
                            dispatch(setTraining({
                              ...state.training,
                              description: e,
                            }))
                        }}
                    />
             )}
            />
          </FlexContainer>

          <div className="axxes-cv-form__actions axxes-about__actions">
            {!creating && (
              <Button
                disabled={isSaving}
                isSaving={isDeleting}
                variant="subtle"
                accent={true}
                onClick={removeTraining}
              >
                <span>Delete</span>
              </Button>
            )}
            <Button
                variant="subtle"
                disabled={isSaving || isDeleting}
                onClick={cancel}
            >
              <span>Cancel</span>
            </Button>
            <Button
              disabled={isDeleting || errors.trainingCenter || errors.trainingCourse}
              isSaving={isSaving}
              accent={true}
              type="submit"
              onClick={save}
            >
              <span>Save</span>
            </Button>
          </div>
        </FlexContainer>
      </div>
  );
};

export default UserTrainingEdit;
