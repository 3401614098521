import {
    Button,
    FlexContainer,
    Input,
    Item,
} from "@axxes/design-system";
import React, {ReactElement, useEffect, useReducer, useState} from "react";
import {APP_CONFIG} from "../../../app/config/config";
import EditOverlay from "../../../common/components/EditOverlay/EditOverlay";
import ItemForm from "../../../common/components/ItemForm/ItemForm";
import { ModalTypes } from "../../../common/models/modals";
import { useAppDispatch, useAppSelector } from "../../../common/store/hooks";
import { showModal } from "../../../common/store/slice";
import { sortByName } from "../../../common/utils/sorters";
import { truncate } from "../../../common/utils/strings";
import Avatar from "../../../users/components/Avatar/Avatar";
import AvatarTitle from "../../../users/components/Avatar/AvatarTitle";
import {TrainingCenter} from "../../../users/model/trainingCenter";
import {fetchTrainingCenters} from "../../../users/store/usersFacadeService";
import {replaceIssuer, updateIssuer} from "../../store/manageFacadeService";
import '../edit.scss'
import editIssuersReducer, {
    INITIAL_EDIT_ISSUERS_STATE, resetIssuer, setIssuer,
} from "./editIssuersState";

export const EditIssuers = () => {

    const reduxDispatch = useAppDispatch();

    const [search, setSearch] = useState<string>('')

    const [editing, setEditing] = useState<string | null>(null)

    const [state, dispatch] = useReducer(
        editIssuersReducer,
        INITIAL_EDIT_ISSUERS_STATE,
    );

    const resetEditing = () => setEditing(null)

    const cancel = () => {
        resetEditing();
        dispatch(resetIssuer());
    }

    const saveChanges = () => {
        if (state.editingIssuer) reduxDispatch(updateIssuer(state.editingIssuer))
        cancel();
    }

    const issuers = useAppSelector(
        (appState) => appState?.users?.trainingCenters?.fetch?.result || [],
    );

    const loadingIssuers = useAppSelector(
        (appState) => appState?.users?.trainingCenters?.fetch?.isLoading,
    );

    const openModal = () => {
        reduxDispatch(
          showModal(ModalTypes.REPLACE, {
            label: 'issuer',
            onReplace: submitReplace,
            dropDownItems: issuers.map(issuer => ({value: issuer.id || '', name: issuer.name || ''})),
            objToReplace: state?.editingIssuer,
          }),
        );
    }

    const submitReplace = (replaceId: string) => {
      const newIssuer = issuers.find(i => i.id === replaceId);
      if (state.editingIssuer && newIssuer) {
        reduxDispatch(
          replaceIssuer(state.editingIssuer, newIssuer),
        );
      }
      cancel();
    }

    useEffect(() => {
        reduxDispatch(fetchTrainingCenters())
    }, [])

    const mapIssuer = (issuer: TrainingCenter) => {
        return (
            <div className="axxes-edit__item" key={issuer?.id}>
                <EditOverlay
                    isEditable={editing === null}
                    setEdit={() => {
                        dispatch(setIssuer(issuer));
                        setEditing(issuer?.id || null)
                    }}
                    edit={editing === issuer?.id}
                    viewElement={
                        <Avatar photo={issuer?.pictureUrl || APP_CONFIG.DEFAULT_IMAGE}>
                            <AvatarTitle>{truncate(issuer?.name || "", 32)}</AvatarTitle>
                        </Avatar>
                    }
                    editElement={
                        <div>
                            <ItemForm
                                newItem={state?.editingIssuer}
                                open={editing === issuer?.id}
                                withName={true}
                                withPhoto={true}
                                title="Edit"
                                setNewItem={(value: TrainingCenter) => dispatch(setIssuer(value))}
                            />
                            <FlexContainer
                                direction="row"
                                customClasses="axxes-edit__item-actions"
                            >
                                <Button
                                    disabled={false}
                                    accent={false}
                                    type="submit"
                                    isSaving={false}
                                    onClick={cancel}
                                    customClasses="axxes-edit__item-button"
                                >
                                    <span>Cancel</span>
                                </Button>
                                <Button
                                    disabled={false}
                                    accent={true}
                                    variant={"default"}
                                    type="submit"
                                    isSaving={false}
                                    onClick={openModal}
                                    customClasses="axxes-edit__item-button"
                                >
                                    <span>Replace</span>
                                </Button>
                                <Button
                                    disabled={false}
                                    accent={true}
                                    variant={"default"}
                                    type="submit"
                                    isSaving={false}
                                    onClick={saveChanges}
                                >
                                    <span>Save</span>
                                </Button>
                            </FlexContainer>
                        </div>
                    }
                />
            </div>
        )
    };

    const filterIssuer = (issuer: TrainingCenter) => {
        if (search === '') return true;
        return issuer?.name?.toLowerCase().includes(search);
    };
    const mappedIssuers : ReactElement[] = issuers.filter(filterIssuer).sort(sortByName).map(mapIssuer);
    return <>
        {loadingIssuers ? <div>Loading Issuers</div>
            : <>
                <div className="axxes-edit__search">
                    <Input title="Search Issuers"
                           defaultValue={search}
                           onChange={((e) => setSearch(e.target.value.toLowerCase()))}
                    />
                </div>
                <div>Found {mappedIssuers.length} issuers: </div>
                <FlexContainer
                    padding="0"
                    customClasses="axxes-edit__container"
                >
                    {mappedIssuers}
                </FlexContainer>
            </>}
    </>
}