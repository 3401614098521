import {Node} from "slate";

export function mapRichTextToObject(val: string | undefined) : Node[] {
    if (!val) {
        return [{
            type: 'paragraph',
            children: [
                { text: '' },
            ]}];
    }

    let parse;
    try {
        parse = JSON.parse(val || '');
    } catch(e) {
        // console.log(e);
    }
    let parsedNode: Node[] = [];
    if (parse || parse?.length > 0) {
        parsedNode = parse
    } else {
        parsedNode = [{
            type: 'paragraph',
            children: [
                { text: val },
            ],
        }]
    }
    return parsedNode;
}