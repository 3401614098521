import {
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  FlexContainer,
  Input,
} from '@axxes/design-system';
import { yupResolver } from '@hookform/resolvers/yup';
import React  from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Config } from '../../../common/api/const';
import CvDatePicker from '../../../common/components/DatePicker/DatePicker';
import CvTextArea from '../../../common/components/TextArea/CvTextarea';
import { dateToString } from "../../../common/utils/dates";

import { User } from '../../model';
import { Address } from '../../model/address';
import UserInfo from '../../model/userInfo';
import AddressForm from '../AddressForm/AddressForm';
import './aboutMe.scss';

interface AboutMeEditProps {
  userInfo?: UserInfo;
  user?: User;
  isSaving?: boolean;
  printMode?: boolean;
  saveUserInfo: (data: UserInfo) => void;
  saveUser: (data: User) => void;
  cancel: () => void;
  submit: () => void;
}

const AboutMeEdit = ({
  userInfo,
  user,
  printMode,
  saveUser,
  saveUserInfo,
  cancel,
  submit,
  isSaving,
}: AboutMeEditProps) => {
  const picture = userInfo?.pictureUrl
    ? Config.api + userInfo?.pictureUrl
    : '/profile-grey.png';

  const { handleSubmit, errors, control } = useForm({
    criteriaMode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({}),
    ),
  });

  const submitForm = handleSubmit(() => {
    submit();
  });

  return (
    <React.Fragment>
      <div className="axxes-about__card">
        <Card>
          <CardHeader customClasses="axxes-about__header">
            <img
              className="axxes-about__photo"
              alt="axxes-about_photo"
              src={picture}
              onError={(e) =>
                (e.currentTarget.src = '/profile-grey.png')
              }
            />

            <div className="axxes-about__header-text">
              <div className="axxes-about__title">
                <h1 className="axxes-about__name">
                  {user?.name || ''}
                </h1>
                <span className="axxes-about__job">
                  {user?.jobTitle || ''}
                </span>
              </div>
              <div className="axxes-about__card-info" />
            </div>
          </CardHeader>
          <CardContent>
            <div className="axxes-about__card-content">
              {printMode && (
                <Input
                  title="Job Title"
                  autoFocus={true}
                  value={user?.jobTitle || ''}
                  onChange={(e) =>
                    saveUser({ ...user, jobTitle: e.target.value })
                  }
                  customClasses="axxes-location__input"
                />
              )}

              <AddressForm
                open={true}
                setAddress={(address: Address) => {
                  saveUserInfo({
                    ...userInfo,
                    city: address.city,
                    country: address.country,
                    province: address.province,
                  });
                }}
                address={{
                  city: userInfo?.city,
                  province: userInfo?.province,
                  country: userInfo?.country,
                }}
              />
              <FlexContainer
                customClasses="axxes-about__card-content__general"
                padding="0"
                direction="column"
              >
                <Controller
                  name="birthDate"
                  defaultValue={
                    userInfo?.birthDate ? new Date(userInfo.birthDate) : null
                  }
                  control={control}
                  render={({ onChange, value }) => (
                    <CvDatePicker
                      title="Date of birth"
                      onChange={(date) => {
                        onChange(date);
                        saveUserInfo({
                          ...userInfo,
                          birthDate: dateToString(date),
                        });
                      }}
                      value={value}
                      maxDate={new Date()}
                      warning={errors.birthDate}
                    />
                  )}
                />

                <Controller
                  name="description"
                  defaultValue={userInfo?.about}
                  control={control}
                  render={({ onChange, value }) => (
                    <CvTextArea
                      title="Description"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        saveUserInfo({ ...userInfo, about: e });
                      }}
                      warning={errors.description}
                    />
                  )}
                />
                {/*<Input
                  multiline={true}
                  rows={3}
                  title="Description"
                  autoFocus={true}
                  value={userInfo?.about || ''}
                  onChange={(e: any) => {
                    saveUserInfo({ ...userInfo, about: e.target.value });
                  }}
                  customClasses="axxes-location__input"
                />*/}
              </FlexContainer>
            </div>
          </CardContent>
          <CardFooter>
            <div className="axxes-cv-form__actions axxes-about__actions">
              <Button variant="subtle" onClick={cancel} disabled={isSaving}>
                <span>Cancel</span>
              </Button>
              <Button
                onClick={() => submitForm()}
                disabled={
                  errors.name ||
                  errors.jobTitle ||
                  errors.birthDate ||
                  errors.description
                }
                accent={true}
                type="submit"
                isSaving={isSaving}
              >
                Save
              </Button>
            </div>
          </CardFooter>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default AboutMeEdit;
