import { TrainingCenter } from '../trainingCenter';

export default class TrainingCenterDTO {
    id?: string;
    name?: string;
    pictureUrl?: string

    static mapToDTO(trainingCenter: TrainingCenter): TrainingCenterDTO {
        return {
            id: trainingCenter?.id,
            name: trainingCenter?.name,
            pictureUrl: trainingCenter?.pictureUrl,
        }
    }
}