import { FetchState, SaveState } from '../../common/store/state';
import { Certificate, Client, Education, Project, School, Skill, Training, User, UserCV, UserSkill } from '../model';
import { Course } from '../model/course';
import { CvVersion } from '../model/cvVersion';
import UserInfo from '../model/userInfo';
import { UserLink } from '../model/userLink';
import { Language } from './../model/language';

export interface UsersState {
  user?: FetchState<User>;
  userInfo?: {
    put?: SaveState<UserInfo>;
  };
  cv?: FetchState<UserCV>;
  cvVersions?: FetchState<CvVersion[]>;
  selectedCvVersion?: CvVersion;
  skills?: {
    fetch?: FetchState<Skill[]>;
    post?: SaveState<Skill>;
  };
  userSkills?: {
    post?: SaveState<UserSkill | UserSkill[]>;
    put?: SaveState<UserSkill | UserSkill[]>;
    delete?: SaveState<UserSkill | UserSkill[]>;
  };
  educations?: {
    post?: SaveState<Education>;
    put?: SaveState<Education>;
    delete?: SaveState<Education>;
  };
  projects?: {
    post?: SaveState<Project>;
    put?: SaveState<Project | Project[]>;
    delete?: SaveState<Project>;
  };
  certificates?: {
    post?: SaveState<Certificate>;
    put?: SaveState<Certificate>;
    delete?: SaveState<Certificate>;
  };
  trainings?: {
    post?: SaveState<Training>;
    put?: SaveState<Training>;
    delete?: SaveState<Training>;
  };
  trainingCourses?: {
    fetch?: FetchState<Course[]>;
    post?: SaveState<Course>;
  };
  clients?: {
    fetch?: FetchState<Client[]>;
    post?: SaveState<Client>;
  };
  schools?: {
    fetch?: FetchState<School[]>;
    post?: SaveState<School>;
    put?: SaveState<School>;
    delete?: SaveState<School>;
  };
  courses?: {
    fetch?: FetchState<Course[]>;
    post?: SaveState<Course>;
    put?: SaveState<Course>;
    delete?: SaveState<Course>;
  };
  trainingCenters?: {
    fetch?: FetchState<Client[]>;
    post?: SaveState<Client>;
  };
  links?: {
    fetch?: FetchState<UserLink[]>;
    post?: SaveState<UserLink>;
    put?: SaveState<UserLink>;
    delete?: SaveState<UserLink>;
  };
  languages?: {
    post?: SaveState<Language>;
    put?: SaveState<Language>;
    delete?: SaveState<Language>;
  };
  sharedCv?: FetchState<UserCV>;
  foldedSections: string[];
}

export const usersInitialState: UsersState = {
  user: { isLoading: false },
  userInfo: {
    put: { isSaving: false },
  },
  cv: { isLoading: false },
  cvVersions: { isLoading: false },
  selectedCvVersion: { tag: 'master', displayTag: 'Master' },
  skills: {
    fetch: { isLoading: false },
    post: { isSaving: false },
  },
  userSkills: {
    post: { isSaving: false },
    put: { isSaving: false },
    delete: { isSaving: false },
  },
  educations: {
    post: { isSaving: false },
    put: { isSaving: false },
    delete: { isSaving: false },
  },
  projects: {
    post: { isSaving: false },
    put: { isSaving: false },
    delete: { isSaving: false },
  },
  certificates: {
    post: { isSaving: false },
    put: { isSaving: false },
    delete: { isSaving: false },
  },
  trainings: {
    post: { isSaving: false },
    put: { isSaving: false },
    delete: { isSaving: false },
  },
  schools: {
    fetch: { isLoading: false },
    post: { isSaving: false },
    put: { isSaving: false },
    delete: { isSaving: false },
  },
  clients: {
    fetch: { isLoading: false },
    post: { isSaving: false },
  },
  courses: {
    fetch: { isLoading: false },
    post: { isSaving: false },
    put: { isSaving: false },
    delete: { isSaving: false },
  },
  trainingCenters: {
    fetch: { isLoading: false },
    post: { isSaving: false },
  },
  trainingCourses: {
    fetch: { isLoading: false },
    post: { isSaving: false },
  },
  links: {
    post: { isSaving: false },
    put: { isSaving: false },
    delete: { isSaving: false },
  },
  languages: {
    post: { isSaving: false },
    put: { isSaving: false },
    delete: { isSaving: false },
  },
  foldedSections: [],
};
