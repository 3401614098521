import { Button, FlexContainer, Input, Item } from '@axxes/design-system';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {
  faCheck,
  faGlobe,
  faHome,
  faInfo,
  faNewspaper,
  faTimes,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { AnyAction } from 'redux';
import { APP_CONFIG } from '../../../app/config/config';
import { ModalTypes } from '../../../common/models/modals';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import { showModal } from '../../../common/store/slice';
import { UserRoutingProps } from '../../containers/UserDetailContainer';
import { setEdit, setUserlink, UserLinkEditState } from '../../containers/UserWeblinks/state';
import { UserLink } from '../../model/userLink';
import { deleteLink, postLink, putLink } from '../../store/usersFacadeService';
import './link.scss';

library.add(faGlobe, faLinkedin, faHome, faInfo, faGlobe, faNewspaper);

export interface WeblinkProps extends UserRoutingProps {
  dispatch: Dispatch<AnyAction>;
  state: UserLinkEditState;
  creating?: boolean;
  setCreating?: (status: boolean) => void;
  userLink?: UserLink;
}

const UserWeblinkEdit = ({
  dispatch,
  state,
  creating = false,
  setCreating,
  userLink,
  match,
}: WeblinkProps) => {
  const icons: Item[] = [];
  Object.entries(APP_CONFIG.LINK_ICONS).map(([key, value]) => {
    return icons.push({ name: key, value });
  });
  const init = useRef<boolean>(false);

  const printMode = useAppSelector(
    (appState) => appState?.common?.printMode,
  );

  useEffect(() => {
    if (!creating) dispatch(setUserlink(userLink));
  }, [creating, dispatch, userLink]);

  const { register, handleSubmit, errors } = useForm();
  const reduxDispatch = useAppDispatch();

  const linksState = useAppSelector((_state) => _state?.users?.links);

  const isSaving = linksState?.post?.isSaving || linksState?.put?.isSaving;

  const isDeleting = linksState?.delete?.isSaving;

  const cleanup = useCallback(() => {
    if (creating) {
      setCreating?.(false);
      dispatch(setUserlink(new UserLink()));
    } else {
      dispatch(setEdit(false));
    }
  }, [creating, dispatch, setCreating]);

  const save = handleSubmit(() => {
    if (creating) {
      reduxDispatch(
        postLink(state?.userLink, match?.params?.userId, printMode),
      );
    } else {
      reduxDispatch(putLink(state?.userLink, match?.params?.userId, printMode));
    }
  });

  const remove = () => {
    reduxDispatch(
      showModal(ModalTypes.DELETE, {
        label: 'weblink',
        name: state?.userLink?.name,
        onConfirm: () => {
          reduxDispatch(
            deleteLink(state?.userLink?.id!, match?.params?.userId, printMode),
          );
        },
      }),
    );
  };

  const cancel = () => {
    creating ? setCreating?.(false) : cleanup();
  };

  useEffect(() => {
    if (
      init.current &&
      (linksState?.delete?.result ||
        linksState?.put?.result ||
        linksState?.post?.result)
    ) {
      cleanup();
    }
    init.current = true;
  }, [
    cleanup,
    linksState?.delete?.result,
    linksState?.post?.result,
    linksState?.put?.result,
  ]);

  return (
    <FlexContainer padding="0" direction="column" customClasses="axxes-link">
      <Input
        warning={errors.name}
        required={true}
        name="name"
        reference={register({ required: true })}
        title="Name"
        defaultValue={state?.userLink?.name || ''}
        onChange={(e) => {
          dispatch(setUserlink({ ...state?.userLink, name: e.target.value }));
        }}
      />
      <div className="axxes-link__form">
        <Input
          warning={errors.link}
          name="link"
          required={true}
          reference={register({ required: true })}
          title="Url"
          defaultValue={state?.userLink?.link || ''}
          onChange={(e) => {
            dispatch(setUserlink({ ...state?.userLink, link: e.target.value }));
          }}
        />
        <FlexContainer
          padding="0"
          direction="row"
          customClasses="axxes-link__actions"
        >
          <Button
            variant="subtle"
            onClick={cancel}
            disabled={isSaving || isDeleting}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          {!creating && (
            <Button
              variant="subtle"
              accent={true}
              onClick={remove}
              disabled={isSaving}
              isSaving={isDeleting}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          )}
          <Button
            accent={true}
            onClick={save}
            disabled={isDeleting || errors.name || errors.link}
            isSaving={isSaving}
          >
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </FlexContainer>
      </div>
    </FlexContainer>
  );
};

export default UserWeblinkEdit;
