import {
  Button,
  Card,
  CardAction,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  FlexContainer,
} from '@axxes/design-system';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import EditOverlay from '../../../common/components/EditOverlay/EditOverlay';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import { sortTrainings } from "../../../common/utils/sorters";
import { UserRoutingProps } from '../../containers/UserDetailContainer';
import { Training } from '../../model';
import { toggleSectionFold } from '../../store/slice';
import {
  deleteTraining,
  fetchTrainingCenters,
  fetchTrainingCourses,
} from '../../store/usersFacadeService';
import UserTrainingEdit from './UserTrainingEdit';
import UserTrainingView from './UserTrainingView';

interface UserTrainingsProps extends UserRoutingProps {
  trainingsProp?: Training[];
}

export const UserTrainings = ({ trainingsProp, match }: UserTrainingsProps) => {
  const dispatch = useAppDispatch();

  const trainingCentersState = useAppSelector(
    (state) => state?.users?.trainingCenters,
  );
  const trainingCourses = useAppSelector(
    (state) => state?.users?.trainingCourses,
  );
  const trainingsState = useAppSelector(
    (state) => state?.users?.trainings,
  );

  const printMode = useAppSelector((state) => state?.common?.printMode);

  const folded = useAppSelector((state) => state.users.foldedSections.includes('trainings'));

  const [addMode, setAddMode] = useState<boolean>(false);
  const [edits, setEdits] = useState<boolean[]>([]);
  const [mainEdit, setMainEdit] = useState<boolean>(false);

  const removeTraining = (tr: Training) => {
    dispatch(deleteTraining(tr.id!, match?.params?.userId, printMode));
  };

  const editMode = (index: number, status: boolean) => {
    const newEdits: boolean[] = [...edits];
    newEdits[index] = status;
    setEdits(newEdits);
    const filter: boolean[] = newEdits?.filter((edit) => !!edit);
    setMainEdit(filter?.length > 0);
  };

  const cancelTraining = () => {
    setAddMode(false);
  };

  useEffect(() => {
    dispatch(fetchTrainingCenters());
    dispatch(fetchTrainingCourses());
  }, [dispatch]);

  useEffect(() => {
    const newEdits: boolean[] = [];
    trainingsProp?.forEach(() => newEdits?.push(false));
    setEdits(newEdits);
  }, [trainingsProp]);

  useEffect(() => {
    if (
      trainingsState?.post?.result ||
      trainingsState?.put?.result ||
      trainingsState?.delete?.result
    ) {
      setMainEdit(false);
      setAddMode(false);
    }
  }, [trainingsState]);

  return (
    <div className={trainingsProp?.length === 0 ? 'hideOnPrint' : ''}>
      <Card>
        <div onClick={() => dispatch(toggleSectionFold('trainings'))}>
          <CardHeader customClasses='axxes-foldable-card__header'>
            <CardTitle>Trainings</CardTitle>
            <CardAction>
              <FontAwesomeIcon icon={folded ? faChevronDown : faChevronUp} />
            </CardAction>
          </CardHeader>
        </div>
        {!folded && (
          <>
            <CardContent customClasses='axxes-foldable-card__content'>
              <FlexContainer customClasses='axxes-cv-element-list' padding='0' direction='column'>
                {trainingsProp?.length === 0 && !addMode
                  ? 'You have not submitted any trainings.'
                  : sortTrainings(trainingsProp).map((training: Training, index: number) => {
                          return (<EditOverlay
                              isEditable={!mainEdit && !addMode}
                              edit={edits[index]}
                              setEdit={(status: boolean) => editMode(index, status)}
                              key={training.id}
                              viewElement={<UserTrainingView training={training} />}
                              editElement={
                                <div className="axxes-trainings__edit">
                                  <UserTrainingEdit
                                    trainingProp={training}
                                    trainingCenters={
                                      trainingCentersState?.fetch?.result
                                    }
                                    trainingCourses={trainingCourses?.fetch?.result}
                                    remove={(tr?: Training) => {if (tr) removeTraining(tr);}}
                                    cancel={() => editMode(index, false)}
                                    postSave={() => setMainEdit(false)}
                                    creating={false}
                                  />
                                </div>
                              }
                            />
                          );
                    })}
                {addMode && (
                  <UserTrainingEdit
                    trainingCenters={trainingCentersState?.fetch?.result}
                    trainingCourses={trainingCourses?.fetch?.result}
                    cancel={cancelTraining}
                    postSave={() => setMainEdit(false)}
                    creating={true}
                  />
                )}
              </FlexContainer>
            </CardContent>
            <CardFooter>
              <CardAction>
                {!addMode && !mainEdit && (
                  <div className="axxes-card-actions">
                    <Button
                      variant="ghost"
                      accent={true}
                      onClick={() => {
                        setAddMode(true);
                      }}
                    >
                      Add a training
                    </Button>
                  </div>
                )}
              </CardAction>
            </CardFooter>
          </>
        )}
      </Card>
    </div>
  );
};

export default UserTrainings;
