import {
  Button,
  Dropdown,
  FlexContainer,
  Input,
  Item,
} from '@axxes/design-system';
import { Badge } from '@axxes/design-system/dist/components/Badge/Badge';
import { DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import {yupResolver} from "@hookform/resolvers/yup";
import { arrayMoveImmutable } from 'array-move';
import _ from 'lodash';
import React, {useEffect, useReducer} from 'react';
import { Controller, useForm } from "react-hook-form";
import {useRouteMatch} from "react-router-dom";
import * as yup from "yup";

import CvDatePicker from '../../../common/components/DatePicker/DatePicker';
import ItemForm from '../../../common/components/ItemForm/ItemForm';
import CvTextArea from "../../../common/components/TextArea/CvTextarea";
import { ModalTypes } from '../../../common/models/modals';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import { showModal } from '../../../common/store/slice';
import { dateToString } from "../../../common/utils/dates";
import {getLengthOfNodeArr, projectIsExpandable} from '../../../common/utils/util';
import {DropdownSchema} from "../../../common/utils/validations";
import { updateClient } from '../../../manage/store/manageFacadeService';
import { Client, DisplayOption, Project, Skill } from '../../model';
import {
  deleteProject,
  fetchClients,
  fetchSkills,
  postProject,
  postSkill,
  putProject,
} from '../../store/usersFacadeService';
import {MatchParams} from "../UserEducations/UserEducationsEdit";
import SkillEdit from './SkillEdit';
import TaskEdit from './TaskEdit';
import TaskView from './TaskView';

import   projectReducer, {
  addSkill,
  INITIAL_PROJECT_EDIT_STATE,
  openProjectClientForm,
  ProjectEditState,
  setEditingTask,
  setProject,
  setTasks,
  Task,
} from './userProjectEditState';

type UserProjectEditProps = {
  project?: Project;
  isSaving: boolean;
  isDeleting: boolean;
  creating: boolean;
  cancel: () => void;
};

const UserProjectEdit = ({
  project,
  isSaving,
  isDeleting,
  creating,
  cancel
}: UserProjectEditProps) => {
  const { handleSubmit, errors, control } = useForm({
    criteriaMode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(
        yup.object().shape({
          role: yup.string().required(),
          client: DropdownSchema
        }),
    ),
  });

  const reduxDispatch = useAppDispatch();
  const match = useRouteMatch<MatchParams>('/users/:userId');

  const [state, dispatch] = useReducer(
    projectReducer,
    creating
      ? INITIAL_PROJECT_EDIT_STATE
      : {
          project,
          openClientForm: false,
          addSkill: { open: false, skill: new Skill() },
          editingTask: null,
        } as ProjectEditState,
  );

  const printMode = useAppSelector(
      (appState) => appState?.common?.printMode,
  );
  const clients = useAppSelector(
    (appState) => appState?.users?.clients?.fetch?.result || [],
  );
  const skills = useAppSelector(
    (appState) => appState?.users?.skills?.fetch?.result || [],
  );
  const skillsState = useAppSelector(
    (appState) => appState?.users?.skills,
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {activationConstraint: {delay: 100, tolerance: 30}}),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const create = handleSubmit(() => {
    if (!state.project) return;
    const newProject = {...state.project};
    if (!projectIsExpandable(newProject)) {
      newProject.displayOption = DisplayOption.MINIMIZED;
    }
    if (creating) {
      reduxDispatch(postProject(newProject, match?.params?.userId, printMode));
    } else {
      const oldClient = clients.find(c => c.id === state.project?.client?.id);
      if (!_.isEqual(state.project?.client, oldClient) && state.project.client && oldClient) {
        reduxDispatch(updateClient(state.project?.client));
      };
      reduxDispatch(putProject(state.project, match?.params?.userId, printMode));
    }
    cancel();
  });

  const removeProject = () => {
    reduxDispatch(
      showModal(ModalTypes.DELETE, {
        label: 'project',
        name: `${project?.role} at ${project?.client?.name}`,
        onConfirm: () => {
          reduxDispatch(
            deleteProject(state.project?.id || '', match?.params?.userId, printMode),
          );
          cancel();
        },
      }),
    );
  };

  const setSkill = (skill: Skill) => {
    if (!skill?.id && state?.addSkill?.open) {
      reduxDispatch(postSkill(skill));
    } else {
      dispatch(setProject({
        ...state.project,
        skills: [...(state.project?.skills || []), skill],
      }));
      dispatch(addSkill({
        open: true,
        skill: new Skill(),
      }));
    }
  };

  const cancelSkill = () => {
    dispatch(addSkill({
      open: false,
      skill: new Skill(),
    }));
  }

  const addTask = (task?: Task | null) => {
    if (task && task.task?.trim()) {
      dispatch(setTasks([
        ...(state?.project?.tasks || []),
        task?.task?.trim(),
      ]));
    }
    dispatch(setEditingTask({index: -1, task: ''}));
  };

  const saveEditingTask = (task?: Task | null) => {
    if (task && task.task?.trim() && task.index !== undefined) {
      const newTasks = [...(state?.project?.tasks || [])];
      newTasks[task.index] = task.task?.trim();
      dispatch(setTasks(newTasks));
    }
    dispatch(setEditingTask(null));
  };

  const cancelEditingTask = () => {
    dispatch(setEditingTask(null));
  };

  const removeTask = (task?: Task | null) => {
    if (task && task.index !== undefined) {
      const newTasks = [...(state?.project?.tasks || [])];
      newTasks.splice(task.index, 1);
      dispatch(setTasks(newTasks));
    }
    dispatch(setEditingTask(null));
  };

  const reorderTasks = ({active, over}: DragEndEvent) => {
    if(active.id === over?.id) return;
    const oldIndex = state?.project?.tasks?.findIndex((task: string) => task === active.id) || 0;
    const newIndex = state?.project?.tasks?.findIndex((task: string) => task === over?.id) || 0;
    const newTasks = arrayMoveImmutable(state?.project?.tasks || [], oldIndex, newIndex);

    dispatch(setTasks(newTasks));
  };

  useEffect(() => {
    if (skillsState?.post?.result && state?.addSkill?.open) {
      setSkill({
        ...state?.addSkill?.skill,
        id: skillsState.post.result.data.id,
      });
    }
  }, [skillsState]);

  useEffect(() => {
    if (project) {
      dispatch(setProject(project));
    }
    reduxDispatch(fetchSkills());
    reduxDispatch(fetchClients());
  }, []);

  const MAX_LEN = 3200;

  return (
      <div className="axxes-project-edit axxes-cv-form axxes-cv-form__container">
        <img
          className="axxes-cv-form__photo"
          alt="client-img"
          src={
            state?.project?.client?.pictureUrl || '/assets/images/default.png'
          }
        />
        <FlexContainer padding="0" direction={'column'} grow={1}>
          <FlexContainer
            padding="0"
            direction={'column'}
            customClasses="axxes-cv-form__container"
            grow={1}
          >
            <Controller
                name="client"
                defaultValue={{
                    value: state?.project?.client?.id || '',
                    name: state?.project?.client?.name || '',
                  } as Item
                }
                control={control}
                render={({ onChange, value}) => (
                  <Dropdown
                      items={
                        clients?.map(
                            (_client) =>
                                ({ value: _client.id, name: _client.name } as Item),
                        ) || []
                      }
                      autoComplete={true}
                      value={value}
                      warning={errors.client}
                      setValue={(newValue) => {
                        const item = newValue as Item;
                        onChange(item)
                        const newClient =
                            clients?.find((client) => client.id === item?.value) ??
                            new Client();
                        if (newClient.name === '') {
                          newClient.name = item?.name;
                        }
                        dispatch(setProject({
                          ...state?.project,
                          client: newClient,
                        }));
                        dispatch(openProjectClientForm((!item?.value || newClient.pictureUrl === '') && item?.name?.length! >= 3));
                      }}
                      title="Client"
                      required={true}
                  />
                )}
            />
            <ItemForm
              newItem={state?.project?.client}
              setNewItem={(client: Client) => {
                dispatch(setProject({
                  ...state.project,
                  client,
                }));
              }}
              open={state.openClientForm === true}
              title={
                !state?.project?.client?.id
                  ? 'New client'
                  : 'Update client'
              }
              withLink={!state?.project?.client?.id}
            />
            <Controller
              name="role"
              defaultValue={state?.project?.role || ''}
              control={control}
              render={({ onChange, value }) => (
                  <Input
                      value={value}
                      warning={errors.role}
                      onChange={(e) => {
                        onChange(e)
                        dispatch(setProject({
                          ...state.project,
                          role: e.target.value,
                        }))
                      }}
                      title="Role"
                      type="text"
                      required={true}
                  />
              )}
            />
            <div className="axxes-cv-form__dual-form">
              <Controller
                name="startDate"
                defaultValue={
                  state?.project?.fromDate
                      ? new Date(state?.project?.fromDate)
                      : null
                }
                control={control}
                render={({ onChange, value }) => (
                    <CvDatePicker
                        title="Start date"
                        onChange={(date) => {
                          onChange(date)
                          dispatch(setProject({
                            ...state.project,
                            fromDate: dateToString(date),
                          }));
                        }}
                        value={value}
                        maxDetail="year"
                        minDetail="decade"
                    />
                )}
              />
              <Controller
                  name="toDate"
                  defaultValue={
                    state?.project?.toDate
                        ? new Date(state?.project?.toDate)
                        : null
                  }
                  control={control}
                  render={({ onChange, value }) => (
                      <CvDatePicker
                          title="End date"
                          onChange={(date) => {
                            onChange(date)
                            dispatch(setProject({
                              ...state.project,
                              toDate: dateToString(date),
                            }));
                          }}
                          value={value}
                          maxDetail="year"
                          minDetail="decade"
                          minDate={
                            state?.project?.fromDate
                                ? new Date(state?.project?.fromDate)
                                : undefined
                          }
                          maxDate={new Date()}
                      />
                  )}
              />
            </div>
          </FlexContainer>
          <FlexContainer
            padding="0"
            direction={'column'}
            customClasses="axxes-cv-form__container axxes-edit-form__description"
            grow={1}
          >
            <Controller
              name="description"
              defaultValue={state?.project?.description || ''}
              control={control}
              render={({ onChange, value }) => (
                <CvTextArea
                    title="Description"
                    value={value}
                    onChange={(e) => {
                      onChange(e)
                      dispatch(setProject({
                        ...state.project,
                        description: e,
                      }))
                    }}
                    maxLen={MAX_LEN}
                    actualLen={getLengthOfNodeArr(value)}
                    warning={getLengthOfNodeArr(value) > MAX_LEN}
                />
              )}
            />
          </FlexContainer>
          <FlexContainer
            direction={'column'}
            customClasses="axxes-cv-form__container"
            padding="0"
            grow={1}
          >
            <h5>Tasks</h5>
            {state?.project?.tasks?.length ? (
              <div>
                <DndContext
                  sensors={sensors}
                  modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                  onDragEnd={(event) => {
                    document.body.style.cursor = 'default';
                    reorderTasks(event);
                  }}
                  onDragStart={() => {
                    document.body.style.cursor = 'grabbing';
                  }}
                >
                  <SortableContext 
                    items={state?.project?.tasks} 
                    strategy={verticalListSortingStrategy}
                  >
                    {
                      state?.project?.tasks?.map(
                        (task: string, listIndex: number) => (
                          state?.editingTask?.index === listIndex ? (
                            <TaskEdit
                              key={listIndex}
                              index={listIndex}
                              isSaving={isSaving}
                              isDeleting={isDeleting}
                              saveTask={saveEditingTask}
                              cancelTask={cancelEditingTask}
                              removeTask={removeTask}
                              state={state}
                              dispatch={dispatch}
                            />
                          ) : (
                            <TaskView
                              key={listIndex}
                              task={task}
                              isDragDisabled={state?.editingTask !== null}
                              setEdit={() =>
                                dispatch(setEditingTask({
                                  index: listIndex,
                                  task,
                                }))
                              }
                            />
                          )
                        ),
                      )
                    }
                  </SortableContext>
                </DndContext>
              </div>
            ) : (
              <span>No tasks yet</span>
            )}
            {state?.editingTask?.index === -1 && (
              <TaskEdit
                index={-1}
                isSaving={isSaving}
                isDeleting={isDeleting}
                saveTask={addTask}
                cancelTask={cancelEditingTask}
                state={state}
                dispatch={dispatch}
              />
            )}
            {state?.editingTask?.index !== -1 && (
              <FlexContainer
                padding="0"
                direction={'row'}
                customClasses="axxes-cv-form__inline-actions"
              >
                <Button
                  variant={'ghost'}
                  accent={true}
                  onClick={() => {
                    dispatch(setEditingTask({index: -1, task: ''}));
                  }}
                >
                  Add new task
                </Button>
              </FlexContainer>
            )}
          </FlexContainer>
          <FlexContainer
            direction={'column'}
            customClasses="axxes-cv-form__container"
            padding="0"
            grow={1}
          >
            <h5>Skills</h5>

            <FlexContainer padding="0" direction={'column'}>
              <FlexContainer
                padding="0"
                direction={'row'}
                customClasses="axxes-cv-form__badges"
              >
                {state?.project?.skills?.length ? (
                  state?.project?.skills?.map(
                    (skill: Skill, listIndex: number) => (
                      <div key={listIndex}>
                        <Badge
                          value={skill.name || ''}
                          onRemove={() => {
                            const newSkills = [
                              ...(state?.project?.skills || []),
                            ];
                            const _index = newSkills.findIndex(
                              (_skill) => _skill.id === skill.id,
                            );
                            if (_index > -1) {
                              newSkills.splice(_index, 1);
                            }
                            dispatch(setProject({
                              ...state.project,
                              skills: newSkills,
                            }));
                          }}
                        />
                      </div>
                    ),
                  )
                ) : (
                  <span>No skills yet</span>
                )}
              </FlexContainer>

              {state?.addSkill?.open && (
                <SkillEdit 
                  addSkill={state?.addSkill} 
                  control={control} 
                  dispatch={dispatch}
                  skills={skills}
                  setSkill={setSkill}
                  cancelSkill={cancelSkill}
                />
              )}
              {!state?.addSkill?.open && (
                <FlexContainer
                  padding="0"
                  direction={'row'}
                  customClasses="axxes-cv-form__inline-actions"
                >
                  <Button
                    variant={'ghost'}
                    accent={true}
                    onClick={() => {
                      dispatch(addSkill({
                        ...state.addSkill,
                        open: true,
                      }));
                    }}
                  >
                    Add new skill
                  </Button>
                </FlexContainer>
              )}
            </FlexContainer>
          </FlexContainer>
          <div className="axxes-cv-form__actions">
            {!creating && (
              <Button
                accent={true}
                variant={'subtle'}
                onClick={removeProject}
                disabled={isSaving}
                isSaving={isDeleting}
              >
                <span>Delete</span>
              </Button>
            )}
            <Button
              variant={'subtle'}
              onClick={cancel}
              disabled={isDeleting || isSaving}
            >
              <span>Cancel</span>
            </Button>
            <Button
              disabled={isDeleting || (getLengthOfNodeArr(state?.project?.description) > MAX_LEN) || errors.role || errors.client}
              accent={true}
              type="submit"
              isSaving={isSaving}
              onClick={create}
            >
              <span>Save</span>
            </Button>
          </div>
        </FlexContainer>
      </div>
  );
};

export default UserProjectEdit;
