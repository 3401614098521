import {
  Button,
  Dropdown,
  FlexContainer,
  Input,
  Item,
} from '@axxes/design-system';
import React, { useEffect, useReducer } from 'react';
import { useForm } from 'react-hook-form';

import { faBook, faBullhorn, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import { ModalTypes } from '../../../common/models/modals';
import { useAppDispatch } from '../../../common/store/hooks';
import { showModal } from '../../../common/store/slice';
import { Language, LanguageLevel } from '../../model/language';
import   languageReducer, { INITIAL_LANGUAGE_EDIT_STATE, setLanguage } from './state';

type UserLanguageEditProps = {
  language?: Language;
  saveLanguage?: (language?: Language) => void;
  removeLanguage?: (language?: Language) => void;
  cancelLanguage?: () => void;
  isSaving: boolean;
  isDeleting: boolean;
};

const UserLanguageEdit = ({
  language,
  saveLanguage,
  removeLanguage,
  cancelLanguage,
  isSaving,
  isDeleting,
}: UserLanguageEditProps) => {
  const { register, handleSubmit, errors } = useForm({
    criteriaMode: 'all',
    reValidateMode: 'onChange',
  });
  const reduxDispatch = useAppDispatch();

  const [state, dispatch] = useReducer(
    languageReducer,
    INITIAL_LANGUAGE_EDIT_STATE,
  );

  const levels = Object.keys(LanguageLevel).map((item) => ({
    name: LanguageLevel[item as LanguageLevel],
    value: item,
  }));

  const onSubmit = () => {
    saveLanguage?.(state.language);
    dispatch(setLanguage(new Language()))
  };

  const deleteHandler = () => {
    reduxDispatch(
      showModal(ModalTypes.DELETE, {
        label: 'language',
        name: language?.language,
        onConfirm: () => removeLanguage?.(state?.language),
      }),
    );
  };

  useEffect(() => {
    if (language) {
      dispatch(setLanguage(language));
    }
  }, [language]);

  return (
    <FlexContainer
      padding="0"
      direction={'row'}
      customClasses="axxes-cv-form"
      grow={1}
    >
      <FlexContainer padding="0" direction={'column'} grow={1}>
        <div className="axxes-language axxes-language--edit">
          <FlexContainer padding='0' grow={1} direction='column' >
            <Input
              required={true}
              name="language"
              reference={register({
                required: '⚠ Language is required',
                validate: (value: string) => {
                  if (value.length > 0 && !value.trim())
                    return "⚠ Language can't contain only whitespaces";
                  return true;
                },
              })}
              title="Language"
              value={state?.language?.language || ''}
              autoFocus={true}
              onChange={(e) => {
                dispatch(setLanguage({
                  ...state?.language,
                  language: e.target.value,
                }));
              }}
              onKeyDown={(
                e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => {
                if (e.key === 'Enter') {
                  handleSubmit(onSubmit)();
                }
                if (e.key === 'Escape' && cancelLanguage) {
                  cancelLanguage();
                }
              }}
            />
            <ErrorMessage
              errors={errors}
              name="language"
              as="p"
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <span key={type} className='axxes-language__error'>{message}</span>
                ))
              }
            />
          </FlexContainer>
            
          <FlexContainer padding='0' grow={1} customClasses='axxes-language__tag-container'>
            <FontAwesomeIcon icon={faBook} size='3x' />
            <Dropdown
              customClasses="axxes-language__form axxes-language__level"
              items={levels}
              autoComplete={false}
              value={
                {
                  value: state?.language?.understandingLevel || '',
                  name: state?.language?.understandingLevel || '',
                } as Item
              }
              setValue={(item) => {
                const level = (item as Item).value as LanguageLevel;
                dispatch(setLanguage({
                  ...state?.language,
                  understandingLevel: level,
                }));
              }}
            />
          </FlexContainer>
          <FlexContainer padding='0' grow={1} customClasses='axxes-language__tag-container'>
            <FontAwesomeIcon icon={faBullhorn} size='3x' />
            <Dropdown
              customClasses="axxes-language__form axxes-language__level"
              items={levels}
              autoComplete={false}
              value={
                {
                  value: state?.language?.speakingLevel || '',
                  name: state?.language?.speakingLevel || '',
                } as Item
              }
              setValue={(item) => {
                const level = (item as Item).value as LanguageLevel;
                dispatch(setLanguage({
                  ...state.language,
                  speakingLevel: level,
                }));
              }}
            />
          </FlexContainer>
          <FlexContainer padding='0' grow={1} customClasses='axxes-language__tag-container'>
            <FontAwesomeIcon icon={faPencilAlt} size='3x' />
            <Dropdown
              customClasses="axxes-language__form axxes-language__level"
              items={levels}
              autoComplete={false}
              value={
                {
                  value: state?.language?.writingLevel || '',
                  name: state?.language?.writingLevel || '',
                } as Item
              }
              setValue={(item) => {
                const level = (item as Item).value as LanguageLevel;
                dispatch(setLanguage({
                  ...state.language,
                  writingLevel: level,
                }));
              }}
            />
          </FlexContainer>
        </div>
        <div className='axxes-cv-form__actions'>
          {removeLanguage && (
            <Button
              accent={true}
              variant="subtle"
              onClick={deleteHandler}
              disabled={isSaving}
              isSaving={isDeleting}
            >
              <span>Delete</span>
            </Button>
          )}

          {cancelLanguage && (
            <Button
              variant="subtle"
              onClick={() => cancelLanguage()}
              disabled={isDeleting || isSaving}
            >
              <span>Cancel</span>
            </Button>
          )}
          <Button
            disabled={
              isDeleting || errors.name
            }
            accent={true}
            type="submit"
            isSaving={isSaving}
            onClick={handleSubmit(onSubmit)}
          >
            <span>Save</span>
          </Button>
        </div>
      </FlexContainer>
    </FlexContainer>
  );
};

export default UserLanguageEdit;
