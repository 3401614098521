import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react'
import UserProjectItem, { UserProjectItemProps } from './UserProjectItem'

interface UserProjectSortableItemProps extends UserProjectItemProps{
    disabled?: boolean;
}

function UserProjectSortableItem({
    disabled,
    ...itemProps
}: UserProjectSortableItemProps) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
    } = useSortable({id: itemProps.project.id || '', disabled});

    const style = {
        transform: CSS.Translate.toString(transform),
    };
    return (
        <div
            className={`axxes-projects-list__sortable-item ${!disabled ? '--draggable' : ''}`}
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
        >
            <UserProjectItem {...itemProps} isDraggable={!disabled} />
        </div>
    )
}

export default UserProjectSortableItem
