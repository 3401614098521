import {Course} from "../course";
import { CourseType } from "../school";

export default class CourseDTO {
    id?: string;
    name?: string;
    pictureUrl?: string
    courseType?: CourseType;
    description?: string;

    static mapToDTO(course: Course): CourseDTO {
        return {
            id: course?.id,
            name: course?.name,
            pictureUrl: course?.pictureUrl,
            courseType: course?.courseType,
            description: course?.description,
        }
    }
}