import BookDTO from "./dto-get/bookDTO";

export class Book {
    id?: string;
    title?: string;
    author?: string;
    picture?: string;

    static mapFromDTO(bookDto: BookDTO) {
        return {
            ...bookDto
        }
    }
}