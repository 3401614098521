import { Canvas, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { APP_CONFIG } from '../../../app/config/config';
import {UserSkill} from '../../../users/model';
import { CVProps } from '../CV';
import { DefaultStyles } from './classes'

const styles = StyleSheet.create({
  ...DefaultStyles,
  skills_container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '50%',
    marginBottom: 4,
  },
  skills_name: {
    width: '40%',
  },
  skills_level: {
    width: 25,
    height: 5,
    marginLeft: 5,
  },
  skills_level_container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginRight: 10,
    width: '60%',
  }
});

export const Skills = ({ cv, invisibles }: CVProps) => {

  const visibles: UserSkill[] = cv?.skills ?
      cv?.skills?.filter((e: UserSkill) => !invisibles.includes(e.id ?? '')) : [];

  const bars = Object.keys(APP_CONFIG.SKILL_LEVEL).length;

  return visibles.length > 0 ?(
    <View wrap={false}>
      <Text style={styles.card_title}>Skills</Text>
      <View style={styles.card_content_row}>
        {visibles.map((skill: UserSkill) => {
          const filled =
            APP_CONFIG.SKILL_LEVEL[skill?.level || 'CONFIRMED'];
          const elements = [];
          for (let i = 0; i < bars; i++) {
            elements.push(
              <Canvas
                key={`${skill.id}-${i}`}
                style={styles.skills_level}
                paint={(painter) =>
                  painter
                    .roundedRect(0, 0, 25, 5, 5)
                    .fill(i < filled ? '#E9531D' : '#D0D2D3')
                }
              />,
            );
          }
          return (
            <View key={skill?.id} style={styles.skills_container}>
              <Text style={styles.skills_name} wrap={false}>{skill?.name}</Text>
              <View style={styles.skills_level_container} wrap={false}>
                {elements}
              </View>
            </View>
          )
        })}
      </View>
    </View>
  ) : <React.Fragment/>;
};

export default Skills;
