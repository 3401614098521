import {Card, CardContent, FlexContainer} from "@axxes/design-system";
import React from "react";
import {EditEducationCourses} from "../components/EditEducationCourses/EditEducationCourses";

export const ManageEdcuationDataContainer = () => {
    return <FlexContainer
        direction="column"
        customClasses="axxes-manage__edit"
        padding="0"
    >
        <FlexContainer
            direction="row"
            padding="0"
        >
            <h1>Manage Education Data</h1>
        </FlexContainer>
        <FlexContainer
            direction="column"
            padding="0"
            customClasses="axxes-manage__card"
        >
            <Card>
                <CardContent>
                    <EditEducationCourses/>
                </CardContent>
            </Card>
        </FlexContainer>
    </FlexContainer>
}