import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { User } from '../../users/model/user';
import './grid.scss';
import UserRow from './UserRow';

type UserGridProps = {
  users?: User[];
  fetchMoreData: () => void;
  totalItems?: number;
};

export const UserGrid = ({
  users,
  totalItems,
  fetchMoreData,
}: UserGridProps) => {
  return (
    <div className="axxes-manage-users__grid">
      {totalItems ? (
        <InfiniteScroll
          dataLength={users?.length || 0}
          next={fetchMoreData}
          hasMore={(totalItems && users && totalItems > users?.length) || false}
          loader={<p>Loading...</p>}
        >
          {users?.map((user: User, index: number) => {
            return <UserRow user={user} key={index} />;
          })}
        </InfiniteScroll>
      ) : (
        <p>Nothing found</p>
      )}
    </div>
  );
};

export default UserGrid;
