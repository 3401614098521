import React from "react";
import AboutMeView from "../../users/components/About/AboutMeView";
import {User} from "../../users/model";
import UserInfo from "../../users/model/userInfo";

export interface ShareAboutMeProps {
    user?: User;
    userInfo?: UserInfo;
    isLoading?: boolean;
}

export const ShareAboutMe = ({user, userInfo, isLoading}: ShareAboutMeProps) => {

    return <div className="axxes-about__container">
        <AboutMeView
            isLoading={isLoading}
            user={user}
            userInfo={userInfo}
        />
    </div>
}