import { Certificate, Training } from "../../users/model";


export const dateToString = (date?: Date | null): string | undefined => {
    return date !== null ? new Date(date!.getTime() - (date!.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0] : undefined;
};

export const dateAvatarFormat = (obj?: {fromDate?: string | null, toDate?: string | null}, day?: boolean): string => {
    if (!obj?.fromDate && !obj?.toDate)
        return '';

    const fromDateFormatted = obj?.fromDate && dateFormat(obj?.fromDate, day);
    const toDateFormatted = obj?.toDate && dateFormat(obj?.toDate, day);

    if (fromDateFormatted === toDateFormatted)
        return fromDateFormatted || '';

    return `${fromDateFormatted ? fromDateFormatted : 'Until'} ${fromDateFormatted ? '-' : ''} ${toDateFormatted || 'Present'}`;
};

export const trainingDateFormat = (training: Training): string => {
    const fromDateFm = training?.fromDate && dateFormat(training?.fromDate, true);
    const toDateFm = training?.toDate && dateFormat(training?.toDate, true);
    return `${fromDateFm ? fromDateFm : ''} ${toDateFm ? `${fromDateFm ? ' -' : 'Until'} ${toDateFm}` : ''}`;
};

export const certificateDateFormat = (certificate: Certificate): string => {
    const achieved = certificate?.dateOfAchievement && dateFormat(certificate?.dateOfAchievement);
    const expires = certificate?.dateOfExpiration && dateFormat(certificate?.dateOfExpiration);
    return `${achieved ? `achieved: ${achieved}` : ''} ${achieved ? ' - ' : ''} ${expires ? `expires: ${expires}` : 'expires: never'}`;
};

export function dateFormat(date: string, day?: boolean): string {
    const formattedDate = new Intl.DateTimeFormat('en-GB').format(new Date(date));
    if (!day)
        return formattedDate.substring(3, formattedDate.length);
    return formattedDate;
}
