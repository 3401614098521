import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import './sortableItem.scss';
import SortableItemView from './SortableItemView';

interface SortableItemProps {
  id: string;
  isActive?: boolean;
  isDragging?: boolean;
  isInvisible?: boolean;
}

const SortableItem: React.FunctionComponent<SortableItemProps> = ({
  id,
  isActive,
  isDragging,
  isInvisible,
  children,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <SortableItemView isActive={isActive} isDragging={isDragging} isInvisible={isInvisible}>{children}</SortableItemView>
    </div>
  );
};

export default SortableItem;
