import {FlexContainer} from "@axxes/design-system";
import React from "react";
import {Route, Switch} from "react-router-dom";
import {ShareContainer} from "../../share/ShareContainer";


export const ShareRoutes = () => {
    return (
        <FlexContainer customClasses="axxes-root__wrapper" padding="0px" grow={1}>
            <Switch>
                <Route exact={true} path="/share/:cvId/:token" component={ShareContainer}/>
            </Switch>
        </FlexContainer>
    );
}