import { SkillLevelType } from '../skillLevel';
import { UserSkill } from '../userSkill';

export default class UserSkillDTO {
    id?: string;
    name?: string;
    level?: SkillLevelType;
    skillId?: string;
    category?: string;
    priority?: number;

    static mapToDTO(userSkill: UserSkill): UserSkillDTO {
        return {
            id: userSkill?.id,
            skillId: userSkill.skillId,
            level: userSkill?.level,
            priority: userSkill.priority,
        }
    }
}