import { Image, StyleSheet, View } from '@react-pdf/renderer';
import React, { ReactElement } from 'react';

import { DefaultStyles} from './classes';
interface AvatarProps {
  content?: ReactElement;
  pictureUrl: string;
}

const styles = StyleSheet.create({
  ...DefaultStyles
});

export const Avatar = ({
  content,
  pictureUrl,
}: AvatarProps) => (
  <View wrap={true} minPresenceAhead={1} style={styles.avatar_container}>
    <Image style={styles.avatar_image} source={pictureUrl} />
    <View style={styles.avatar_info}>
      { content }
    </View>
  </View>
);

export default Avatar;
