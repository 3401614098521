import { Node } from "slate";
import UserInfoDTO from "./dto-get/userInfoDTO";
import {mapRichTextToObject} from "./map";

export default class UserInfo {
    id?: string;
    city?: string;
    province?: string;
    country?: string;
    telephoneNumber?: string;
    primaryLanguage?: string;
    about?: Node[];
    pictureUrl?: string;
    birthDate?: string;

    static mapFromDTO(userInfo?: UserInfoDTO): UserInfo {
        const aboutMapped = mapRichTextToObject(userInfo?.about);
        return {
            ...userInfo,
            about: aboutMapped,
        }
    }
}