import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AxiosError} from "axios";
import {ErrorResponse} from "../../common/models/errorResponse";
import {User} from "../../users/model";
import {ProfileInitialState, ProfileState} from "./state";

const slice = createSlice({
    name: 'profile',
    initialState: ProfileInitialState,
    reducers: {
        fetchProfileAction: (state): ProfileState => ({
            ...state,
            currentProfile: {
                ...state.currentProfile,
                result: undefined,
                isLoading: true,
                error: undefined,
            },
        }),
        fetchProfileSuccess: (state, action: PayloadAction<User>): ProfileState => ({
            ...state,
            currentProfile: {
                ...state.currentProfile,
                result: action.payload,
                isLoading: false,
                error: undefined,
            },
        }),
        fetchProfileFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): ProfileState => ({
            ...state,
            currentProfile: {
                ...state.currentProfile,
                result: undefined,
                isLoading: false,
                error: action.payload,
            },
        }),
    },
});

export default slice.reducer;

export const {
    fetchProfileAction,
    fetchProfileSuccess,
    fetchProfileFail
} = slice.actions