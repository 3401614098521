import { Footer, Header, RootContainer } from "@axxes/design-system";
import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {ShareRoutes} from "../common/routes/ShareRoutes";

export const ShareApp = () => {
  return (
        <RootContainer>
            <Router>
                    <RootContainer>
                        <Router>
                            <Header logo="https://images.aws.axxes.com/Logo_Axxes.png"/>
                            <ShareRoutes />
                            <Footer customClasses={'custom-footer'}>Copyright © Axxes {new Date().getFullYear()}</Footer>
                        </Router>
                    </RootContainer>
                    <ToastContainer
                        position="bottom-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick={true}
                        rtl={false}
                        pauseOnFocusLoss={true}
                        draggable={true}
                        pauseOnHover={true}
                    />
            </Router>
        </RootContainer>
    );
}
