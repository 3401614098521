import {Button, FlexContainer, Input, Item} from "@axxes/design-system";
import React, {ReactElement, useEffect, useReducer, useState} from "react";
import EditOverlay from "../../../common/components/EditOverlay/EditOverlay";
import { ModalTypes } from "../../../common/models/modals";
import { useAppDispatch, useAppSelector } from "../../../common/store/hooks";
import { showModal } from "../../../common/store/slice";
import { sortByName } from "../../../common/utils/sorters";
import { truncate } from "../../../common/utils/strings";
import AvatarTitle from "../../../users/components/Avatar/AvatarTitle";
import {Skill} from "../../../users/model";
import {fetchSkills} from "../../../users/store/usersFacadeService";
import {replaceSkill, updateSkill} from "../../store/manageFacadeService";
import '../edit.scss'
import { EditNameForm } from "../SimpleForms/EditNameForm";
import editSkillsReducer, {
    INITIAL_EDIT_SKILLS_STATE, resetSkill, setSkill
} from "./editSkillsState";

export const EditSkills = () => {

    const reduxDispatch = useAppDispatch();

    const [search, setSearch] = useState<string>('')

    const [editing, setEditing] = useState<string | null>(null)

    const [state, dispatch] = useReducer(
        editSkillsReducer,
        INITIAL_EDIT_SKILLS_STATE,
    );

    const resetEditing = () => setEditing(null)

    const cancel = () => {
        resetEditing();
        dispatch(resetSkill());
    }

    const saveChanges = () => {
        if (state.editingSkill) reduxDispatch(updateSkill(state.editingSkill));
        cancel();
    }

    const skills = useAppSelector(
        (appState) => appState?.users?.skills?.fetch?.result || [],
    );

    const loadingSkills = useAppSelector(
        (appState) => appState?.users?.skills?.fetch?.isLoading,
    );

    const openModal = () => {
        reduxDispatch(
          showModal(ModalTypes.REPLACE, {
            label: 'skill',
            onReplace: submitReplace,
            dropDownItems: skills.map(skill => ({value: skill.id || '', name: skill.name || ''})),
            objToReplace: state?.editingSkill,
          }),
        );
    }

    const submitReplace = (replaceId: string) => {
      const newSkill = skills.find(s => s.id === replaceId);
      if (state.editingSkill && newSkill) {
        reduxDispatch(
          replaceSkill(state?.editingSkill, newSkill),
        );
      }
      cancel();
    }

    useEffect(() => {
        reduxDispatch(fetchSkills())
    }, [])

    const mapCourse = (skill: Skill) => {
        return (
            <div className="axxes-edit__item" key={skill?.id}>
                <EditOverlay
                    isEditable={editing === null}
                    setEdit={() => {
                        dispatch(setSkill(skill));
                        setEditing(skill?.id || null)
                    }}
                    edit={editing === skill?.id}
                    viewElement={
                        <div className="axxes-edit__singleField">
                            <AvatarTitle>{truncate(skill?.name || "", 32)}</AvatarTitle>
                        </div>
                    }
                    editElement={
                        <div>
                            <EditNameForm
                                newObj={state?.editingSkill}
                                open={editing === skill?.id}
                                setNewObj={(value) => dispatch(setSkill(value))}
                            />
                            <FlexContainer
                                direction="row"
                            >
                                <Button
                                    disabled={false}
                                    accent={false}
                                    type="submit"
                                    isSaving={false}
                                    onClick={cancel}
                                    customClasses="axxes-edit__item-button"
                                >
                                    <span>Cancel</span>
                                </Button>
                                <Button
                                    disabled={false}
                                    accent={true}
                                    variant={"default"}
                                    type="submit"
                                    isSaving={false}
                                    onClick={openModal}
                                    customClasses="axxes-edit__item-button"
                                >
                                    <span>Replace</span>
                                </Button>
                                <Button
                                    disabled={false}
                                    accent={true}
                                    variant={"default"}
                                    type="submit"
                                    isSaving={false}
                                    onClick={saveChanges}
                                >
                                    <span>Save</span>
                                </Button>
                            </FlexContainer>
                        </div>
                    }
                />
            </div>
        )
    };

    const filterSkill = (skill: Skill) => {
        if (search === '') return true;
        return skill?.name?.toLowerCase().includes(search);
    };
    const mappedCourses : ReactElement[] = skills.filter(filterSkill).sort(sortByName).map(mapCourse);
    return <>
        {loadingSkills ? <div>Loading Skills</div>
            : <>
                <div className="axxes-edit__search">
                    <Input title="Search Skills"
                           defaultValue={search}
                           onChange={((e) => setSearch(e.target.value.toLowerCase()))}
                    />
                </div>
                <div>Found {mappedCourses.length} skills: </div>
                <FlexContainer
                    padding="0"
                    customClasses="axxes-edit__container"
                >
                    {mappedCourses}
                </FlexContainer>
            </>}
    </>
}