import { Api } from "../../common/api/const";
import { Course } from "../model/course";

export class CoursesApiService {
    static fetchCourses = () => Api().get(`/education/courses`);
    static postCourse = (course: Course) => Api().post(`/education/courses`, course);
    static putCourse = (course: Course) => Api().put(`/education/courses/${course?.id}`, course);
    static replaceEducationCourse = (o: string, n: string) => Api().post(`course/${o}/to/${n}`);

    static fetchTrainingCourses = () => Api().get(`/trainings/courses`);
    static postTrainingCourse = (course: Course) => Api().post(`/trainings/courses`, course);
    static putTrainingCourse = (course: Course) => Api().put(`/trainings/courses/${course?.id}`, course);
    static replaceTrainingCourse = (o: string, n: string) => Api().post(`/trainingCourse/${o}/to/${n}`);
}