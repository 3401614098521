import { FlexContainer, Loading } from '@axxes/design-system';
import React, { FunctionComponent } from 'react';
import './avatar.scss'

type AvatarProps = {
    photo: string,
    isLoading?: boolean
    size?: AvatarSize
}

type AvatarSize = "small" | "medium" | "large"

const Avatar: FunctionComponent<AvatarProps> = ({ photo, children, isLoading= false, size = "medium" }) => {

    return (
        <FlexContainer padding="0" direction="row" customClasses="axxes-avatar">
            {
                isLoading
                    ?
                    <Loading customClasses="axxes-avatar__photo"/>
                    :
                    <img className={`axxes-avatar__photo axxes-avatar__photo__${size}`} alt="avatar-img" src={photo || "/assets/images/default.png"} />
            }
            <FlexContainer padding="0" direction="column" customClasses="axxes-avatar__container">
                {children}
            </FlexContainer>
        </FlexContainer>
    )
}

export default Avatar;
