import {Card, CardContent, CardTitle, FlexContainer} from "@axxes/design-system";
import React from "react";
import UserWeblinkView from "../../../../users/components/UserWeblinks/UserWeblinkView";
import {UserCV} from "../../../../users/model";
import {UserLink} from "../../../../users/model/userLink";

interface WeblinksViewProps {
    editingCV?: UserCV
    onSelect: () => void;
}

export const WeblinksView = ({editingCV, onSelect}: WeblinksViewProps) => {
    return (
        <Card>
            <CardTitle>Weblinks</CardTitle>
            <CardContent>
                <FlexContainer padding="0" direction="row" customClasses="axxes-links">
                    {editingCV?.links?.length === 0
                        ? 'No weblinks submitted.'
                        : editingCV?.links?.map((userLink: UserLink) => {
                            return (
                                <UserWeblinkView
                                    key={userLink?.id}
                                    userLink={userLink}
                                />
                            );
                        })}
                </FlexContainer>
            </CardContent>
        </Card>
    );
}
