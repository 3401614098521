import React from "react";
import { dateAvatarFormat } from "../../../../common/utils/dates";
import Avatar from "../../../../users/components/Avatar/Avatar";
import AvatarSubtitle from "../../../../users/components/Avatar/AvatarSubtitle";
import AvatarText from "../../../../users/components/Avatar/AvatarText";
import AvatarTitle from "../../../../users/components/Avatar/AvatarTitle";
import {CourseNames, Education} from "../../../../users/model";
import { formatDescriptionSummary } from "../../../generateUtils";
import {MinimizeButton} from "../common/MinimizeButton";
import {VisibilityButton} from "../common/VisibilityButton";
import '../pdfEditor.scss';

interface EducationViewItemProps {
    education: Education
    invisibles: string[]
    minimizeds:string[]
}

export const EducationViewItem = ({education, invisibles, minimizeds}: EducationViewItemProps) => {
    return (
        <div className={"axxes-project-item-container"}>
            <div className={`axxes-project-item-view`}>
                <Avatar
                    photo={
                        education?.school?.pictureUrl ||
                        '/assets/images/default.png'
                    }
                >
                    <AvatarTitle>
                        {education?.school?.name} -{' '}
                        {education?.course?.name}
                    </AvatarTitle>
                    <AvatarSubtitle>
                        {education?.course?.courseType &&
                        CourseNames[education?.course?.courseType]}
                    </AvatarSubtitle>
                    <AvatarText>{ dateAvatarFormat(education) }</AvatarText>
                </Avatar>
                <div className="axxes-project-item-view__content">
                    {minimizeds.includes(education.id ?? '') ? <span className='axxes-edit-pdf__minimized'>MINIMIZED</span> : null}
                    <span>{formatDescriptionSummary(education.description)}</span>
                </div>
            </div>
            <div className='axxes-project-item-view__actions'>
                <VisibilityButton cvDataId={education.id} invisibles={invisibles}/>
                <MinimizeButton cvDataId={education.id} minimizeds={minimizeds}/>
            </div>
        </div>
    )
}
