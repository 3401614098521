import { FlexContainer } from '@axxes/design-system';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../common/store/hooks';
import UserGrid from '../components/UserGrid';
import { fetchUsers } from '../store/manageFacadeService';
import './manageUserContainer.scss';

export const ManageUsersContainer = () => {
  const dispatch = useAppDispatch();
  const usersResultState = useAppSelector(
    (state) => state?.manage?.usersResponse?.result,
  );

  const searchParam = useAppSelector((state) => state?.manage?.search);

  const fetchMoreUsers = () => {
    let page = usersResultState?.current || 0;
    if(usersResultState!.totalNumberOfItems! > usersResultState!.values!.length) {
      dispatch(fetchUsers({ page: ++page, q: searchParam?.query }));
    }
  };

  useEffect(() => {
    dispatch(fetchUsers({ q: searchParam?.query }));
  }, [searchParam]);

  return (
    <FlexContainer
      direction="column"
      customClasses="axxes-manage__users"
      padding="0"
    >
      <FlexContainer customClasses="axxes-manage__header">
        <div className="axxes-manage__header__list-items">
          total {usersResultState?.totalNumberOfItems}
        </div>
      </FlexContainer>
      <FlexContainer
        padding="0"
        customClasses="axxes-manage__list"
        direction="column"
      >
        <UserGrid
          fetchMoreData={() => fetchMoreUsers()}
          users={usersResultState?.values}
          totalItems={usersResultState?.totalNumberOfItems}
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export default ManageUsersContainer;
