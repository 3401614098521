import _ from 'lodash';
import React, { FormEventHandler, FunctionComponent, useEffect } from 'react';
import { FormValidation, validateField, ValidationErrors } from '../../utils/validations';

export interface FormType {
  fields: {
    [key: string]: {
      form?: FormType,
      formGroup?: boolean,
      validations?: FormValidation[];
      value?: any;
      errors?: ValidationErrors;
    };
  };
  errors: ValidationErrors[];
}

interface FormProps {
  id: string;
  form: FormType;
  setForm: (form: FormType) => void;
  onSubmit?: () => void;
  customClasses?: string;
}
const Form: FunctionComponent<FormProps> = ({
  children,
  id,
  form,
  setForm,
  onSubmit,
  customClasses,
}) => {
  const submit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    validateForm();
    onSubmit?.();
  };

  useEffect(() => {
    const newForm = validateForm();
    if (!_.isEqual(newForm, form)) setForm(newForm);
  }, [form.fields]);

  const validateForm = () => {
    const newForm: FormType = _.cloneDeep(form);
    const formErrors: ValidationErrors[] = [];

    Object.entries(newForm.fields).forEach(([key, value]) => {
      const errors = validateField(value.value, value.validations || []);
      newForm.fields[key] = {...value, errors};
      if (Object.keys(errors).length > 0 && errors.constructor === Object) {
        formErrors.push(errors);
      }
    })
    return { ...newForm, errors: formErrors };
  };

  return (
    <form
      className={`axxes-cv-form ${customClasses}`}
      name={id}
      id={id}
      onSubmit={submit}
    >
      {children}
    </form>
  );
};

export { Form };
