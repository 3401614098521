import {Card, CardContent, CardTitle, FlexContainer} from "@axxes/design-system";
import React from "react";
import UserLanguageView from "../../../../users/components/UserLanguages/UserLanguageView";
import {UserCV} from "../../../../users/model";

interface LanguagesViewProps {
    editingCV: UserCV | undefined,
    onSelect: () => void
}

export const LanguagesView = ({editingCV, onSelect}: LanguagesViewProps) => {
    return <Card>
        <div onClick={onSelect}>
            <CardTitle customClasses={'axxes-edit-pdf-pdf-block-title'}>Languages</CardTitle>
        </div>
        <CardContent>
            <FlexContainer padding='0' direction='column' customClasses="axxes-languages">
                <div className="axxes-language axxes-language__header">
                    <div />
                    <FlexContainer padding='0' customClasses='axxes-language__tag-container '>
                    <svg/>
                    <FlexContainer 
                        padding='0' 
                        grow={1} 
                        customClasses='axxes-language__tag axxes-language__title'
                    >
                        <span>Understanding</span>
                    </FlexContainer>
                    </FlexContainer>
                    
                    <FlexContainer padding='0' customClasses='axxes-language__tag-container '>
                    <svg/>
                    <FlexContainer 
                        padding='0' 
                        grow={1} 
                        customClasses='axxes-language__tag axxes-language__title'
                    >
                        <span>Speaking</span>
                    </FlexContainer>
                    </FlexContainer>

                    <FlexContainer padding='0' customClasses='axxes-language__tag-container '>
                    <svg/>
                    <FlexContainer 
                        padding='0' 
                        grow={1} 
                        customClasses='axxes-language__tag axxes-language__title'
                    >
                        <span>Writing</span>
                    </FlexContainer>
                    </FlexContainer>
                </div>
                {editingCV?.languages?.map(l => <UserLanguageView key={l.id} language={l}/>)}
            </FlexContainer>
        </CardContent>
    </Card>
}
