import {AxiosError, AxiosResponse} from "axios";
import {Api} from "../common/api/const";
import ErrorHandler from "../common/api/errorHandler";
import {ErrorResponse} from "../common/models/errorResponse";
import ProfileDTO from "../users/model/dto-get/profileDTO";
import {Profile} from "../users/model/profile";
import {fetchProfileAction, fetchProfileFail, fetchProfileSuccess} from "./store/slice";

export const fetchProfile = () => (dispatch: any) => {
    dispatch(fetchProfileAction());
    ProfileService.fetchProfile()
        .then((response: AxiosResponse<ProfileDTO>) => {
            dispatch(fetchProfileSuccess(Profile.mapFromDTO(response.data)));
        })
        .catch((err: AxiosError<ErrorResponse>) => {
            ErrorHandler.handle(
                'Failed to fetch profile',
                dispatch,
                fetchProfileFail,
                err,
            );
        });
};

class ProfileService {
    static fetchProfile = () => Api().get('/profile');
}