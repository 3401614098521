import { Client } from './../client';

export default class ClientDTO {
    id?: string;
    name?: string;
    url?: string;
    pictureUrl?: string;
    description?: string;

    static mapToDTO(client: Client) : ClientDTO {
        return { ...client }
    }
};