import {Api} from "../../common/api/const";

const BASE_PATH = "/images"

export class FilesApiService {

    static upload = (file: File) => {
        const formData = new FormData();
        formData.append('file', file, file.name)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return Api().post(BASE_PATH, formData, config);
    }
}