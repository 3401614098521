import {Card, CardContent, CardTitle} from "@axxes/design-system";
import React, {useEffect} from "react"
import { useAppDispatch, useAppSelector } from "../../../../common/store/hooks";
import {Project, UserCV} from "../../../../users/model";
import {SectionTypes} from "../../../models/models";
import {getInvisibles, getInvisibleSections} from "../../../store/selectors";
import {addInvisibleSection, removeInvisibleSection, updateCvCopy} from "../../../store/slice";
import EditorSortableContainer from "../common/EditorSortableContainer";
import './projectsView.scss'

interface ProjectsViewProps {
    editingCV?: UserCV
    onSelect: () => void;
}

export const ProjectsView = ({editingCV, onSelect}: ProjectsViewProps) => {
    const dispatch = useAppDispatch();
    const invisibles = useAppSelector(getInvisibles);
    const invisbleSections = useAppSelector(getInvisibleSections);

    const updateReorderedProjects = (projects: Project[]) => {
        const newCV: UserCV = {...editingCV, projects};
        dispatch(updateCvCopy(newCV));
    }

    useEffect(() => {
        const visibles: Project[] | undefined= editingCV?.projects?.filter((e:Project) => !invisibles.includes(e.id ?? ''));
        if(visibles?.length === 0) {
            if(!invisbleSections.includes(SectionTypes.PROJECTS)) dispatch(addInvisibleSection(SectionTypes.PROJECTS));
        }
        else {
            dispatch(removeInvisibleSection(SectionTypes.PROJECTS))
        }
    }, [invisibles]);

    return (
      <Card>
        <div onClick={onSelect}>
          <CardTitle customClasses={'axxes-edit-pdf-pdf-block-title'}>
            Projects
          </CardTitle>
        </div>
        <CardContent>
          <EditorSortableContainer 
            items={editingCV?.projects || []}
            type={SectionTypes.PROJECTS}
            updateReorderedItems={updateReorderedProjects}
          />
        </CardContent>
      </Card>
    );
}
