import {
  Card,
  CardContent,
  CardHeader,
  Loading,
  LoadingTheme,
} from '@axxes/design-system';
import {
  faCalendarAlt,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Config } from '../../../common/api/const';
import CvTextArea from '../../../common/components/TextArea/CvTextarea';

import { dateFormat } from "../../../common/utils/dates";
import { User } from '../../model';
import UserInfo from '../../model/userInfo';
import './aboutMe.scss';

interface AboutMeViewProps {
  userInfo?: UserInfo;
  user?: User;
  isLoading?: boolean;
}

const AboutMeView = ({ userInfo, user, isLoading }: AboutMeViewProps) => {
  const picture = userInfo?.pictureUrl
    ? Config.api + userInfo?.pictureUrl
    : '/profile-grey.png';

  return (
    <React.Fragment>
      <div className="axxes-about__card">
        <Card>
          <CardHeader customClasses="axxes-about__header">
            {!isLoading ? (
              <img
                className="axxes-about__photo"
                alt="axxes-about_photo"
                src={picture}
                onError={(e) =>
                  (e.currentTarget.src = '/profile-grey.png')
                }
              />
            ) : (
              <Loading
                theme={LoadingTheme.WHITE}
                customClasses="axxes-about__photo"
                height="192px"
              />
            )}

            <div className="axxes-about__header-text">
              <div className="axxes-about__title">
                <h1 className="axxes-about__name">
                  {!isLoading ? (
                    user?.name || ''
                  ) : (
                    <Loading theme={LoadingTheme.WHITE} width="220px" />
                  )}
                </h1>
                <span className="axxes-about__job">
                  {!isLoading ? (
                    <span>{user?.jobTitle || ''}</span>
                  ) : (
                    <Loading theme={LoadingTheme.WHITE} width="150px" />
                  )}
                </span>
              </div>
              <div className="axxes-about__card-info">
                {!isLoading ? (
                  <React.Fragment>
                    {userInfo?.city && (
                      <span className="axxes-about__card-info__item">
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                        <span className="axxes-about__card-info--city">
                          {' '}
                          {userInfo?.city || ''}
                        </span>
                        , {userInfo?.country || ''}
                      </span>
                    )}
                    {userInfo?.birthDate && (
                      <span className="axxes-about__card-info__item">
                        <FontAwesomeIcon icon={faCalendarAlt} />
                        <span>{dateFormat(userInfo?.birthDate, true) || ''}</span>
                      </span>
                    )}
                  </React.Fragment>
                ) : (
                  <Loading width="100px" />
                )}
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <div className="axxes-about__card-content">
              {!isLoading ? (
                <React.Fragment>
                  {userInfo?.about && (
                    <CvTextArea
                      title="Description"
                      value={userInfo?.about}
                      onChange={() => { /**/ }}
                      readonly={true}
                      readonlyPlaceholder="Tell something about yourself.."
                    />
                  )}
                </React.Fragment>
              ) : (
                <Loading height="80px" />
              )}
            </div>
          </CardContent>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default AboutMeView;
