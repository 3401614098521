import { ActionCreatorWithOptionalPayload, ActionCreatorWithoutPayload, ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ErrorResponse } from "../models/errorResponse";
import { AppDispatch } from "../store";
import { toastError } from "../utils/util";

type Action = ActionCreatorWithPayload<any, any> | ActionCreatorWithoutPayload<any> | ActionCreatorWithOptionalPayload<any, any>
export default class ErrorHandler {
    static handle = (message: string, dispatch?: AppDispatch, action?: Action, err?: AxiosError<ErrorResponse>, ) => {
        if ((!err) || (err && err.code !== '304')) {
            toastError(message);
        }
        if (dispatch && action && err) {
            dispatch(action(err));
        } 
    }
}