import {Button} from "@axxes/design-system";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import { useAppDispatch } from "../../../../common/store/hooks";
import {updateMinimizeds} from "../../../store/slice";
import "./UtilButtons.scss"

type MinimizeButtonProps = {
    cvDataId?: string,
    minimizeds: string[]
}

export const MinimizeButton = ({cvDataId, minimizeds}: MinimizeButtonProps) => {
    const dispatch = useAppDispatch()
    const [isMinimized, setIsMinimized] = useState<boolean>(false);

    useEffect(() => {
        if(cvDataId) setIsMinimized(minimizeds.includes(cvDataId));
    }, [cvDataId, minimizeds]);
    
    const minimizeMaximize = () => {
        if(cvDataId) {
            if(cvDataId) {
                let newMinimizeds: string[] = [];
                if (isMinimized) {
                    newMinimizeds = minimizeds.filter((s: string) => s !== cvDataId);
                } else {
                    newMinimizeds = [...minimizeds, cvDataId];
                }
                dispatch(updateMinimizeds(newMinimizeds));
            }
        }
    };
    return (
        <Button
            variant={"ghost"}
            onClick={minimizeMaximize}
            customClasses={"axxes-visibility-minimization-button"}
        >
            <FontAwesomeIcon icon={isMinimized ? faPlus : faMinus} />
        </Button>
    );
}
