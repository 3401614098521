import * as yup from 'yup';

export interface FormValidation {
  validation?: Validation;
  param?: string | number;
}
export enum Validation {
  Required = 'required',
  MaxLength = 'maxLength',
  MinLength = 'minLength',
}

export type ValidationErrors = {[key in Validation]?: boolean};

export const validateField = (value: string, validations: FormValidation[]) => {
  const errors: ValidationErrors = {};
  validations?.map((val: FormValidation) => {
    switch (val.validation) {
      case Validation.Required:
        if (!value) errors[Validation.Required] = true;
        break;
      case Validation.MaxLength:
        if (value?.length > (val.param as number))
          errors[Validation.MaxLength] = true;
        break;
      case Validation.MinLength:
        if (value?.length < (val.param as number))
          errors[Validation.MinLength] = true;
        break;
      default:
        return true;
    }
  });
  return errors;
};

export const DropdownSchema = yup.object().shape({
  name: yup.string().required(),
  value: yup.string(),
});

export const RichDescriptionSchema = yup.array(
  yup.object({
    children: yup.array(
      yup.object({
        text: yup.string().required(),
      })
    ),
  })
);
