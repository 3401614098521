import { Language, LanguageLevel } from './../language';

export class LanguageDTO {
    id?: string;
    language?: string;
    understandingLevel?: LanguageLevel;
    speakingLevel?: LanguageLevel;
    writingLevel?: LanguageLevel;

    static mapToDTO(language: Language) : LanguageDTO {
        return {
            ...language
        }
    }
}