import React from 'react'
import { dateAvatarFormat } from '../../../common/utils/dates'
import { CourseNames, Education } from '../../model'
import CvElementView from '../CvElementView/CvElementView'

type UserEducationsViewProps = {
    education?: Education;
}

function UserEducationsView({education}: UserEducationsViewProps) {
    return (
        <CvElementView
            title={education?.school?.name && education.course?.name && `${education?.school?.name} - ${education?.course?.name}`}
            subtitle={education?.course?.courseType && 
                CourseNames[education.course.courseType]}
            photo={education?.school?.pictureUrl}
            dates={dateAvatarFormat(education)}
            description={education?.description}
        />
    )
}

export default UserEducationsView
