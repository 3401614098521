import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import AppInfo from "../model/AppInfo";
import {InitialAppInfoState} from "./state";

const slice = createSlice({
    name: 'appInfo',
    initialState: InitialAppInfoState,
    reducers: {
        updateAppInfo: (state, action: PayloadAction<AppInfo>) => ({
            ...state,
            version: action.payload.version,
        })
    },
});

export default slice.reducer;

export const {
    updateAppInfo
} = slice.actions;