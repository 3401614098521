import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalContent, ModalState, ModalTypes } from '../models/modals';
import { commonInitialState } from './state';

const slice = createSlice({
  name: 'common',
  initialState: commonInitialState,
  reducers: {
    setPrintMode: (state, action: PayloadAction<boolean>) => ({
      ...state,
      printMode: action.payload,
    }),
    showModal: {
      reducer: (state, action: PayloadAction<ModalState>) => ({
        ...state,
        modal: action.payload,
      }),
      prepare: (type: ModalTypes, content: ModalContent) => ({
        payload: { type, content, open: true },
      }),
    },
    hideModal: (state) => ({ ...state, modal: { open: false } }),
    setFooter: (state, action: PayloadAction<boolean>) => ({
      ...state,
      footer: action.payload,
    }),
  },
});

export default slice.reducer;

export const { setPrintMode, showModal, hideModal, setFooter } = slice.actions;