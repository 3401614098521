import React, { useEffect } from 'react';
import { useReducer } from 'react';

import EditOverlay from '../../../common/components/EditOverlay/EditOverlay';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import { setPrintMode } from '../../../common/store/slice';
import { UserRoutingProps } from '../../containers/UserDetailContainer';
import { User } from '../../model';
import UserInfo from '../../model/userInfo';
import { putUserSuccess } from '../../store/slice';
import {putUserInfo} from '../../store/usersFacadeService';
import './aboutMe.scss';
import AboutMeEdit from './AboutMeEdit';
import AboutMeView from './AboutMeView';
import aboutReducer, { INITIAL_ABOUT_STATE, setEdit, setUser, setUserInfo } from './state';

interface AboutMeProps extends UserRoutingProps {
  userInfoProp?: UserInfo;
  user?: User;
  isLoading?: boolean;
}

const AboutMe = ({ userInfoProp, user, match, isLoading }: AboutMeProps) => {
  const reduxDispatch = useAppDispatch();
  const [state, dispatch] = useReducer(aboutReducer, INITIAL_ABOUT_STATE);

  const printMode = useAppSelector(
    (appState) => appState?.common?.printMode,
  );

  const currentUser = useAppSelector(
      (appState) => appState?.profile.currentProfile?.result,
  );

  const userInfoState = useAppSelector(
    (appState) => appState?.users?.userInfo,
  );
  const isSaving = userInfoState?.put?.isSaving || false;

  const submit = () => {
    if (state.userInfo) reduxDispatch(putUserInfo(state.userInfo, match?.params?.userId, printMode));
    if (state.user) reduxDispatch(putUserSuccess(state.user));
  }

  const cancel = () => {
    dispatch(setUserInfo(userInfoProp));
    dispatch(setEdit(false));
  }
  
  useEffect(() => {
    dispatch(setUserInfo(userInfoProp));
  }, [userInfoProp]);

  useEffect(() => {
    dispatch(setUser(user));
  }, [user]);

  useEffect(() => {
    const cvUserId = match?.params?.userId;
    if (cvUserId && cvUserId !== currentUser?.id) {
      reduxDispatch(setPrintMode(true))
    }
  }, [match?.params?.userId, reduxDispatch, currentUser])

  useEffect(() => {
    if (userInfoState?.put?.result) {
      dispatch(setEdit(false));
    }
  }, [userInfoState?.put?.result]);

  return (
    <div className="axxes-about__container">
      <EditOverlay
        isLoading={isLoading}
        edit={!!state?.edit}
        setEdit={(payload: boolean) => dispatch(setEdit(payload))}
        viewElement={
          <AboutMeView
            isLoading={isLoading}
            user={state?.user}
            userInfo={state?.userInfo}
          />
        }
        editElement={
          <AboutMeEdit
            isSaving={isSaving}
            user={state?.user}
            printMode={printMode}
            userInfo={state?.userInfo}
            saveUser={(_user) => dispatch(setUser(_user))}
            saveUserInfo={(_userInfo) => dispatch(setUserInfo(_userInfo))}
            submit={() => submit()}
            cancel={() => cancel()}
          />
        }
      />
    </div>
  );
};

export default AboutMe;
