import React from "react"
import {APP_CONFIG} from "../../../../app/config/config";
import { trainingDateFormat } from "../../../../common/utils/dates";
import Avatar from "../../../../users/components/Avatar/Avatar";
import AvatarSubtitle from "../../../../users/components/Avatar/AvatarSubtitle";
import AvatarText from "../../../../users/components/Avatar/AvatarText";
import AvatarTitle from "../../../../users/components/Avatar/AvatarTitle";
import {Training} from "../../../../users/model";
import { formatDescriptionSummary } from "../../../generateUtils";
import {MinimizeButton} from "../common/MinimizeButton";
import {VisibilityButton} from "../common/VisibilityButton";


interface TrainingsViewItemProps {
    training: Training;
    invisibles: string[],
    minimizeds:string[]
}

export const TrainingsViewItem = ({training, invisibles, minimizeds}: TrainingsViewItemProps) => {
    return (
        <div className={"axxes-project-item-container"}>
            <div className={`axxes-project-item-view`}>
                <Avatar photo={training?.trainingCenter?.pictureUrl || APP_CONFIG.DEFAULT_IMAGE}>
                    <AvatarTitle>{training?.trainingCourse?.name}</AvatarTitle>
                    <AvatarSubtitle>
                        {training?.trainingCenter?.name}
                    </AvatarSubtitle>
                    <AvatarText>
                        { training && trainingDateFormat(training) }
                    </AvatarText>
                </Avatar> 
                <div className="axxes-project-item-view__content">
                    {minimizeds.includes(training.id ?? '') ? <span className='axxes-edit-pdf__minimized'>MINIMIZED</span> : null}
                    <span>{formatDescriptionSummary(training.description)}</span>
                </div>
            </div>
            <div className='axxes-project-item-view__actions'>
                <VisibilityButton cvDataId={training.id} invisibles={invisibles}/>
                <MinimizeButton cvDataId={training.id} minimizeds={minimizeds}/>
            </div>
        </div>
    )
}
