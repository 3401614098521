export type Page = {
    sections: SectionTypes[],
    pageNumber: number,
}

export enum SectionTypes {
    ABOUT_ME,
    PROJECTS,
    EDUCATIONS,
    SKILLS,
    TRAININGS,
    CERTIFICATES,
    LANGUAGES,
    WEBLINKS
}