import { Skill } from "..";

export default class SkillDTO {
    id?: string;
    name?: string;
    category?: string

    static mapToDTO(skill: Skill) : SkillDTO {
        return {
            id: skill?.id,
            name: skill?.name,
            category: skill?.category
        }
    }
}