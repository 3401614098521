import { convertVersionTagToDisplay } from '../utils/util';
import { CvVersionDTO } from './dto-get/cvVersionDTO';

export class CvVersion {
  tag?: string;
  displayTag?: string;
  dateCreation?: string;
  dateLastUpdated?: string;

  static mapFromDto(version: CvVersionDTO): CvVersion {
    return {
      tag: version.tag,
      displayTag: convertVersionTagToDisplay(version.tag),
      dateCreation: version.dateCreation,
      dateLastUpdated: version.dateLastUpdated,
    };
  }
}
