import {Node} from "slate";
import { Course } from './course';
import UserEducationDTO from './dto-get/userEducationDTO';
import {mapRichTextToObject} from "./map";
import { School } from './school';

export class Education {
  id?: string;
  toDate?: string | null;
  fromDate?: string | null;
  description?: Node[];
  coursePicture?: string;
  school?: School;
  course?: Course;
  certificateUrl?: string;
  city?: string;
  province?: string;
  country?: string;

  constructor() {
    this.id = '';
    this.toDate = '';
    this.fromDate = '';
    this.description = [{type: 'paragraph',children: [{ text: '' }]}]; // Init empty state for Slate
    this.coursePicture = '';
    this.school = new School();
    this.course = new Course();
    this.certificateUrl = '';
    this.city = '';
    this.province = '';
    this.country = '';
  }

  static mapFromDTO(educationDTO: UserEducationDTO) {
    const desc = mapRichTextToObject(educationDTO?.description);
    return {
      ...educationDTO,
      description: desc,
    }
  }
}
