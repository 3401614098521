import { FlexContainer } from '@axxes/design-system';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {
  faGlobe,
  faHome,
  faInfo,
  faNewspaper,
  IconName,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { UserLink } from '../../model/userLink';
import './link.scss';

library.add(faGlobe, faLinkedin, faHome, faInfo, faNewspaper, faGithub);

export interface WeblinkProps {
  userLink: UserLink;
}

const UserWeblinkView = ({ userLink }: WeblinkProps) => {
  const prefix =
    userLink.icon === 'linkedin' || userLink.icon === 'github' ? 'fab' : 'fas';

  const checkIfLinkIsAbsolute = () => {
    if (!userLink.link) return false;
    const expression = new RegExp('^(?:[a-z]+:)?//', 'i');
    return expression.test(userLink.link);
  }

  return (
    <FlexContainer direction="row" customClasses="axxes-link">
      <FontAwesomeIcon
        icon={[prefix, userLink?.icon as IconName]}
        className="axxes-link__icon"
        size="lg"
      />
      <FlexContainer
        direction="column"
        padding="0"
        customClasses="axxes-link__text"
      >
        <div className="axxes-link__name">{userLink?.name}</div>
        <a href={`${checkIfLinkIsAbsolute() ? '' : '//'}${userLink.link}`} target='_blank' rel='noreferrer'>
            <div className="axxes-link__link">{userLink?.link}</div>
        </a>
      </FlexContainer>
    </FlexContainer>
  );
};

export default UserWeblinkView;
