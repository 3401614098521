import {StyleSheet, Text, View} from '@react-pdf/renderer';
import React from 'react';
import {Project, Project as ProjectProps} from '../../../users/model';
import {CVProps} from '../CV';
import {DefaultStyles} from './classes';
import {SingleProject} from "./SingleProject";

const styles = StyleSheet.create({
  ...DefaultStyles,
  projects_title: {
    fontSize: 13,
    marginBottom: 5,
  },
  projects_container: {},
  project_timeline: {
    width: 53,
    height: '100%',
  },
  project_wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  project_info: {
    width: '70%',
    marginBottom: 20,
    fontSize: 9,
  },
  project_title: {
    marginBottom: 4,
    marginTop: 10,
    fontSize: 10,
    fontWeight: 500
  },
  avatar_container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 5,
    width: '22%',
  },
  avatar_image: {
    width: 50,
    height: 50,
    borderRadius: 2,
  },
  avatar_info: { marginTop: 5 },
  avatar_title: {
    fontSize: 11,
    fontWeight: 'medium',
  },
  avatar_subtitle: {
    fontSize: 9,
  },
  avatar_desc: {
    fontWeight: 'light',
    fontSize: 9,
  },
  avatar_text: {
    fontWeight: 'light',
    fontSize: 9,
  },
  description_wrapper: {
    marginBottom: 5,
  },
  bold: {
    fontWeight: 'medium',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 2,
  },
  listItem_bullet: {
    color: '#E9531D',
    fontWeight: 'medium',
    marginRight: 3,
  },
  skills: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 0,
    flexWrap: 'wrap',
  },
  skill: {
    fontSize: 8,
    backgroundColor: '#e7e7e7',
    borderRadius: 10,
    padding: 2,
    paddingHorizontal: 5,
    marginRight: 3,
    fontWeight: 'light',
    marginBottom: 2,
  },
});

export const Projects = ({ cv, minimizeds, invisibles }: CVProps) => {

  const visibles: Project[] = cv?.projects ?
      cv?.projects?.filter((e: Project) => !invisibles.includes(e.id ?? '')) : [];

  return visibles.length > 0 ? (
      <View style={styles.card}>
        <Text style={styles.card_title}>Projects</Text>
        {visibles.map((project: ProjectProps) => {
              return (
                  <SingleProject key={project.id} project={project} minimizeds={minimizeds}/>)
            })
        }
      </View>) : <React.Fragment/>
};

export default Projects;
