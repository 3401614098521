import {Card, CardContent, CardTitle} from "@axxes/design-system";
import React, {useEffect} from "react"
import { useAppDispatch, useAppSelector } from "../../../../common/store/hooks";
import {Training, UserCV} from "../../../../users/model";
import {SectionTypes} from "../../../models/models";
import {getInvisibles, getInvisibleSections} from "../../../store/selectors";
import {addInvisibleSection, removeInvisibleSection, updateCvCopy} from "../../../store/slice";
import EditorSortableContainer from "../common/EditorSortableContainer";

interface TrainingsViewProps {
    editingCV: UserCV | undefined,
    onSelect: () => void;
}

export const TrainingsView = ({editingCV, onSelect}: TrainingsViewProps) => {
    const dispatch = useAppDispatch();
    const invisibles = useAppSelector(getInvisibles);
    const invisbleSections = useAppSelector(getInvisibleSections);

    const updateReorderedTrainings = (trainings: Training[]) => {
        const newCV: UserCV = { ...editingCV, trainings };
        dispatch(updateCvCopy(newCV));
    };

    useEffect(() => {
        const visibles: Training[] | undefined= editingCV?.trainings?.filter((e:Training) => !invisibles.includes(e.id ?? ''));
        if(visibles?.length === 0) {
            if(!invisbleSections.includes(SectionTypes.TRAININGS)) dispatch(addInvisibleSection(SectionTypes.TRAININGS));
        }
        else {
            dispatch(removeInvisibleSection(SectionTypes.TRAININGS))
        }
    }, [invisibles]);

    return (
        <Card>
            <div onClick={onSelect}>
                <CardTitle customClasses={'axxes-edit-pdf-pdf-block-title'}>Trainings</CardTitle>
            </div>
                <CardContent>
                    <EditorSortableContainer
                        items={editingCV?.trainings || []}
                        type={SectionTypes.TRAININGS}
                        updateReorderedItems={updateReorderedTrainings}
                    />
                </CardContent>
        </Card>
    )
}
