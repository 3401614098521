import React from 'react';

import { Badge, FlexContainer } from "@axxes/design-system"
import {Node} from "slate";
import { APP_CONFIG } from '../../../app/config/config';
import CvTextArea from "../../../common/components/TextArea/CvTextarea";
import { dateAvatarFormat } from "../../../common/utils/dates";
import { projectIsExpandable } from '../../../common/utils/util';
import { DisplayOption, Project, Skill } from '../../model';
import Avatar from '../Avatar/Avatar';
import AvatarSubtitle from '../Avatar/AvatarSubtitle';
import AvatarText from '../Avatar/AvatarText';
import AvatarTitle from '../Avatar/AvatarTitle';
import './userProject.scss';

type UserProjectViewProp = {
  project?: Project;
  isDraggable?: boolean;
};

const UserProjectView = ({ project, isDraggable }: UserProjectViewProp) => {
  return (
    <FlexContainer direction='column' padding='0' customClasses="axxes-project-view__container">
      <div className="axxes-project-view__header">
        <h2 className="axxes-project-view__print-title">Project</h2>
        <Avatar photo={project?.client?.pictureUrl || APP_CONFIG.DEFAULT_IMAGE}>
          <AvatarTitle>{project?.client?.name}</AvatarTitle>
          <AvatarSubtitle>{project?.role}</AvatarSubtitle>
          <AvatarText>
            { dateAvatarFormat(project) }
          </AvatarText>
        </Avatar>
      </div>
      {project?.displayOption !== DisplayOption.MINIMIZED && !isDraggable && projectIsExpandable(project) && (
        <div className="axxes-project-view__content">
            <CvTextArea
                title="Description"
                value={project?.description as Node[]}
                onChange={() => { /**/ }}
                readonly={true}
            />
          {project?.tasks?.length! > 0 &&
            <div>
              <h5>Tasks</h5>
              <ul className="axxes-list axxes-project-view__tasks">
                {project?.tasks?.map((task: string, index: number) => (
                  <li key={index}>{task}</li>
                ))}
              </ul>
            </div>
          }
          {project?.skills?.length! > 0 &&
            <div>
              <h5>Skills</h5>
              <FlexContainer
                padding="0"
                grow={1}
                customClasses="axxes-cv-form__badges"
              >
                {project?.skills?.map((skill: Skill, index: number) => (
                  <div key={index}>
                    <Badge value={skill.name || ''} />
                  </div>
                ))}
              </FlexContainer>
            </div>
          }
        </div>
      )}
    </FlexContainer>
  );
};

export default UserProjectView;
