export enum SkillLevel {
    JUNIOR = "JUNIOR",
    CONFIRMED = "CONFIRMED",
    ADVANCED = "ADVANCED",
    EXPERT = "EXPERT"
}

export type SkillLevelType = "JUNIOR" | "CONFIRMED" | "ADVANCED" | "EXPERT";

export function mapToNumericalValue(s: string | undefined): number {
    switch (s) {
        case "JUNIOR":
            return 0;
        case "ADVANCED":
            return 2;
        case "EXPERT":
            return 3;
        default:
            return 1;
    }
}
