import { toast } from "react-toastify";
import {Node} from "slate";
import {DisplayOption, Project} from "../../users/model";

export const toastSucces = (message?: string) => {
    toast.success(message, {
        position: 'bottom-left', autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export const toastError = (message?: string) => {
    toast.error(message, {
        position: 'bottom-left', autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

// Node[] is a structured set of text that can be parsed by slate to allow formatting.
// Basically the text you write gets put into a json object.
//          The object is simple if it's a paragraph; it's just an array of child objects.
//
//          If it's a list, the items get put in an array of children under the initial child, so the array is nested
//          under the original child.
// All of this mapping is being done to reduce the Node[] to a flat string to determine the length of what the user typed.
export function getLengthOfNodeArr(arr: Node[] | undefined) {
    if (!arr) {
        return 0;
    }

    const flatArr = arr.map(v => {
        if (v.type === "paragraph") {
            // If it's a paragraph, get all the values of the text prop of all the children.
            // @ts-ignore slate typing issue
            return v.children.map(c => c.text);
        } else if (v.type === "numbered-list" || v.type === "bulleted-list") {
            // If it's a list, get all the values of the text prop of all the items nested under all the children.
            // Then make it flat by joining all the items of one child into a single array.
            // @ts-ignore slate typing issue
            return v.children.map(c => c.children.map(c2 => c2.text)).map(a => a.join(''));
        }
        // If we allow a different type of formatting, you might need to add mapping for it here.
        // At the time of writing this will never be called since we only allow paragraph and lists.
        return '';
    }).map(v => v.join('')); // Now we have an array of arrays, join them together.
    return flatArr.join('').length // Join the final array together to get a string, and return the length.
}

export function hasDescription (description: Node[]) {
    const emptyDescription: Node[] = [{
        type: 'paragraph',
        children: [{ text: '' }],
      }];
    return JSON.stringify(description) !== JSON.stringify(emptyDescription);
}

export const projectIsExpandable = (project?: Project) => {
    if (!project) return false;
    const description = project.description && hasDescription(project.description);
    const tasks = project.tasks && project.tasks.length > 0;
    const skills = project.skills && project.skills.length > 0;
    return description || tasks || skills;
}

export const toggleMinimizeOfProjectInArray = (projects: Project[], project: Project) => {
    const newProjects = [...projects];
    const index = newProjects.findIndex((p: Project) => p.id === project.id);
    newProjects[index] = {
      ...newProjects[index],
      displayOption: newProjects[index].displayOption === DisplayOption.STANDARD ? DisplayOption.MINIMIZED : DisplayOption.STANDARD,
    }
    return newProjects;
}