import ClientDTO from "./dto-get/clientDTO";

export class Client {
    id?: string;
    name?: string;
    url?: string;
    pictureUrl?: string;
    description?: string;

    constructor() {
        this.name = '';
        this.url = '';
        this.pictureUrl = '';
        this.description = '';
    }
    static mapFromDTO(clientDTO: ClientDTO): Client {
        return { ...clientDTO }
    }
}