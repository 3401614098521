import React from 'react';
import { certificateDateFormat } from "../../../common/utils/dates";
import { Certificate } from '../../model';
import CvElementView from '../CvElementView/CvElementView';

type UserCertificateViewProp = {
  certificate?: Certificate;
};

const UserCertificateView = ({ certificate }: UserCertificateViewProp) => {
  return (
    <CvElementView
      title={certificate?.name}
      subtitle={certificate?.trainingCenter?.name}
      photo={certificate?.trainingCenter?.pictureUrl}
      link={certificate?.certificateUrl}
      dates={certificate && certificateDateFormat(certificate)}
      description={certificate?.description}
    />
  );
};

export default UserCertificateView;
