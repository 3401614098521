import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Project } from './../../model/project';

export interface UserProjectsState {
    edits?: boolean[];
    projects?: Project[];
    originalProjectOrder?: Project[] | null;
    draggingId?: string | null;
}

export const INITIAL_PROJECTS_STATE: UserProjectsState = {
    edits: [],
    projects: [],
    originalProjectOrder: null,
    draggingId: null,
};

const slice = createSlice({
  name: 'UserProjects',
  initialState: INITIAL_PROJECTS_STATE,
  reducers: {
    init: (state, action: PayloadAction<Project[]>): UserProjectsState => ({
      ...state,
      edits: action?.payload?.map(() => false) || [],
      projects: action?.payload,
    }),
    setProjects: (
      state,
      action: PayloadAction<Project[]>,
    ): UserProjectsState => ({ ...state, projects: action.payload }),
    setOriginalProjectOrder: (state, action: PayloadAction<Project[] | null>): UserProjectsState => ({...state, originalProjectOrder: action.payload}),
    setDragging: (state, action: PayloadAction<string | null>): UserProjectsState => ({...state, draggingId: action.payload})
  },
});

export default slice.reducer;

export const {
    init,
    setProjects,
    setOriginalProjectOrder,
    setDragging,
} = slice.actions;