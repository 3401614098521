
export function truncate(s: string, len: number) {
    if (s.length <= len) {
        return s;
    }
    return s.slice(0, len) + '...';
}

export function capitalizeEveryWord(s: string) {
    const words = s.split(/\s+/);
    return words.map((word) => {
        return word[0].toUpperCase() + word.substring(1);
    }).join(" ");
}
