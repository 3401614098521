import { BreadCrumbs, Crumb } from "@axxes/design-system";
import React from "react";
import { useLocation } from "react-router-dom";
import { ManageApiService } from "../../manage/services/manageApiService";

const BreadCrumbsContainer = () => {
    const params = useLocation();
    const url: string[] = params?.pathname?.split("/");
    let newCrumbs: Crumb[] = [];
    url?.shift();
    const url0 = url[0];
    url[0] = "/" + url0;
    url?.forEach((el: string, index: number) => { newCrumbs.push({ title: el, url: url.slice(0, index + 1).join("/") }); });

    newCrumbs[0].title = url0;
    if (newCrumbs[0]?.title === "users" && newCrumbs[1] !== undefined) {
        ManageApiService.fetchUser(newCrumbs[1].title)
            .then((res) => newCrumbs[1].title = res.data?.name || '')
            .catch();
    }

    if (newCrumbs[0].title === "notfound") {
        newCrumbs = [];
    };

    return (
        <BreadCrumbs crumbs={newCrumbs || []} />
    )
}

export default BreadCrumbsContainer;