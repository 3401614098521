import React, {useEffect} from "react"
import { useAppDispatch, useAppSelector } from "../common/store/hooks";
import {SharedCvDetail} from "./components/SharedCvDetail";
import ShareError from "./components/ShareError";
import {fetchSharedCv} from "./service/facade";

export interface ShareRoutingProps {
    match?: {
        path?: '/share/:cvId/:token';
        params?: {
            cvId?: string;
            token?: string;
        };
    };
}

export const ShareContainer = ({match} : ShareRoutingProps) => {

    const reduxDispatch = useAppDispatch();

    const sharedCv = useAppSelector((state) => state?.users?.sharedCv?.result);
    const sharedCvError = useAppSelector((state) => state?.users?.sharedCv?.error)
    const isLoading = useAppSelector((state) => state?.users?.sharedCv?.isLoading);


    useEffect(() => {
        reduxDispatch(
          fetchSharedCv(match?.params?.cvId || ''),
        );
    }, [match?.params?.cvId, reduxDispatch]);

    if (sharedCvError){
        return <ShareError />
    }

    return (
        <SharedCvDetail cv={sharedCv} isLoading={isLoading}/>
    );
}