import React, { FunctionComponent, ReactElement } from 'react';

type EditOverlayProps = {
  viewElement?: ReactElement;
  editElement?: ReactElement;
  onClose?: () => void;
  edit: boolean;
  setEdit: (status: boolean) => void;
  customclass?: string;
  isLoading?: boolean;
  isEditable?: boolean;
};

const EditOverlay: FunctionComponent<EditOverlayProps> = ({
  viewElement,
  editElement,
  edit,
  setEdit,
  customclass,
  isLoading = false,
  isEditable = true,
}) => {
  return (
    <div
      className={customclass || ''}
      onClick={() => {
        if (!edit && isEditable) {
          setEdit(true);
        }
      }}
    >
      {edit ? (
        editElement
      ) : (
        <div
          className={
            !isLoading && isEditable
              ? 'edit-overlay edit-overlay--has-editing'
              : 'edit-overlay'
          }
        >
          {viewElement}
          <span className="edit-text">
            <p>
              Edit <span className="axxes-icons axxes-icon-pencil" />
            </p>
          </span>
        </div>
      )}
    </div>
  );
};

export default EditOverlay;
