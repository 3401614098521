import {Button, FlexContainer, Input, Item} from "@axxes/design-system";
import React, {ReactElement, useEffect, useReducer, useState} from "react";
import EditOverlay from "../../../common/components/EditOverlay/EditOverlay";
import { ModalTypes } from "../../../common/models/modals";
import { useAppDispatch, useAppSelector } from "../../../common/store/hooks";
import { showModal } from "../../../common/store/slice";
import { sortByName } from "../../../common/utils/sorters";
import { truncate } from "../../../common/utils/strings";
import AvatarTitle from "../../../users/components/Avatar/AvatarTitle";
import {Course} from "../../../users/model";
import {fetchTrainingCourses} from "../../../users/store/usersFacadeService";
import {replaceTrainingCourse, updateTrainingCourse} from "../../store/manageFacadeService";
import '../edit.scss'
import {EditCourseForm} from "../SimpleForms/EditCourseForm";
import editTrainingCoursesReducer, {
    INITIAL_EDIT_COURSES_STATE, resetCourse, setCourse,
} from "./editTrainingCoursesState";

export const EditTrainingCourses = () => {

    const reduxDispatch = useAppDispatch();

    const [search, setSearch] = useState<string>('')

    const [editing, setEditing] = useState<string | null>(null)

    const [state, dispatch] = useReducer(
        editTrainingCoursesReducer,
        INITIAL_EDIT_COURSES_STATE,
    );

    const resetEditing = () => setEditing(null)

    const cancel = () => {
        resetEditing();
        dispatch(resetCourse());
    }

    const saveChanges = () => {
        if (state.editingCourse) reduxDispatch(updateTrainingCourse(state.editingCourse));
        cancel();
    }

    const courses = useAppSelector(
        (appState) => appState?.users?.trainingCourses?.fetch?.result || [],
    );

    const loadingCourses = useAppSelector(
        (appState) => appState?.users?.trainingCourses?.fetch?.isLoading,
    );

    const openModal = () => {
      reduxDispatch(
        showModal(ModalTypes.REPLACE, {
          label: 'course',
          onReplace: submitReplace,
          dropDownItems: courses.map(course => ({value: course.id || '', name: course.name || ''})),
          objToReplace: state?.editingCourse,
        }),
      );
    };

    const submitReplace = (replaceId: string) => {
      const newCourse = courses.find(c => c.id === replaceId)
      if (state.editingCourse && newCourse) {
        reduxDispatch(
          replaceTrainingCourse(state?.editingCourse, newCourse),
        );
      }
      cancel();
    }

    useEffect(() => {
        reduxDispatch(fetchTrainingCourses())
    }, [])

    const mapCourse = (course: Course) => {
        return (
            <div className="axxes-edit__item" key={course?.id}>
                <EditOverlay
                    isEditable={editing === null}
                    setEdit={() => {
                        dispatch(setCourse(course));
                        setEditing(course?.id || null)
                    }}
                    edit={editing === course?.id}
                    viewElement={
                        <div className="axxes-edit__singleField">
                            <AvatarTitle>{truncate(course?.name || "", 32)}</AvatarTitle>
                        </div>
                    }
                    editElement={
                        <div>
                            <EditCourseForm
                                newCourse={state?.editingCourse}
                                open={editing === course?.id}
                                dispatch={dispatch}
                                setNewCourse={(value: Course) => dispatch(setCourse(value))}
                                supportCourseTypes={false}
                            />
                            <FlexContainer
                                direction="row"
                            >
                                <Button
                                    disabled={false}
                                    accent={false}
                                    type="submit"
                                    isSaving={false}
                                    onClick={cancel}
                                    customClasses="axxes-edit__item-button"
                                >
                                    <span>Cancel</span>
                                </Button>
                                <Button
                                    disabled={false}
                                    accent={true}
                                    variant={"default"}
                                    type="submit"
                                    isSaving={false}
                                    onClick={openModal}
                                    customClasses="axxes-edit__item-button"
                                >
                                    <span>Replace</span>
                                </Button>
                                <Button
                                    disabled={false}
                                    accent={true}
                                    variant={"default"}
                                    type="submit"
                                    isSaving={false}
                                    onClick={saveChanges}
                                >
                                    <span>Save</span>
                                </Button>
                            </FlexContainer>
                        </div>
                    }
                />
            </div>
        )
    };

    const filterCourse = (course: Course) => {
        if (search === '') return true;
        return course?.name?.toLowerCase().includes(search);
    };
    const mappedCourses : ReactElement[] = courses.filter(filterCourse).sort(sortByName).map(mapCourse);
    return <>
        {loadingCourses ? <div>Loading Courses</div>
            : <>
                <div className="axxes-edit__search">
                    <Input title="Search Training Courses"
                           defaultValue={search}
                           onChange={((e) => setSearch(e.target.value.toLowerCase()))}
                    />
                </div>
                <div>Found {mappedCourses.length} training courses: </div>
                <FlexContainer
                    padding="0"
                    customClasses="axxes-edit__container"
                >
                    {mappedCourses}
                </FlexContainer>
            </>}
    </>
}