import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserSkill } from './../../model/userSkill';

type SaveState = {
  new?: boolean;
  data?: UserSkill;
};
export interface UserSkillsState {
    addMode?: boolean;
    edits?: boolean[];
    mainEdit?: boolean;
    saveState?: SaveState | null;
    draggingId: string | null;
    userSkills?: UserSkill[];
    editOrder?: boolean;
}

export const INITIAL_USER_SKILLS_STATE: UserSkillsState = {
    saveState: { new: false },
    addMode: false,
    edits: [],
    mainEdit: false,
    draggingId: null,
    userSkills: [],
    editOrder: false,
};

const slice = createSlice({
  name: 'UserSkills',
  initialState: INITIAL_USER_SKILLS_STATE,
  reducers: {
    init: (state, action: PayloadAction<UserSkill[]>): UserSkillsState => ({
      ...state,
      userSkills: action.payload,
      edits: action?.payload?.map(() => false) || [],
      mainEdit: false,
    }),
    setSaveState: (
      state,
      action: PayloadAction<SaveState | null>,
    ): UserSkillsState => ({ ...state, saveState: action.payload }),
    setEdit: (
      state,
      action: PayloadAction<{ index: number; status: boolean }>,
    ): UserSkillsState => {
      const newEdits: boolean[] = [...(state.edits || [])];
      newEdits[action?.payload?.index] = action?.payload?.status;
      return {
        ...state,
        edits: newEdits,
        mainEdit: newEdits?.filter((edit) => !!edit).length > 0,
      };
    },
    setAddMode: (state, action: PayloadAction<boolean>): UserSkillsState => ({
      ...state,
      addMode: action.payload,
    }),
    setMainEdit: (state, action: PayloadAction<boolean>): UserSkillsState => ({
      ...state,
      mainEdit: action.payload,
    }),
    setDragging: (
      state,
      action: PayloadAction<string | null>,
    ): UserSkillsState => ({ ...state, draggingId: action.payload }),
    setUserSkills: (
      state,
      action: PayloadAction<UserSkill[]>,
    ): UserSkillsState => ({ ...state, userSkills: action.payload }),
    setEditOrder: (state, action: PayloadAction<boolean>): UserSkillsState => ({
      ...state,
      editOrder: action.payload,
    }),
  },
});

export default slice.reducer;

export const {
    init,
    setSaveState,
    setEdit,
    setAddMode,
    setMainEdit,
    setDragging,
    setUserSkills,
    setEditOrder,
} = slice.actions;
