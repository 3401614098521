import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {Client} from "../../../users/model";

export interface EditClientsState {
    editingClient?: Client
}

export const INITIAL_EDIT_CLIENTS_STATE: EditClientsState = {
    editingClient: undefined,
}

const slice = createSlice({
  name: 'EditClients',
  initialState: INITIAL_EDIT_CLIENTS_STATE,
  reducers: {
    setClient: (state, action: PayloadAction<Client>): EditClientsState => ({
      ...state,
      editingClient: action.payload,
    }),
    resetClient: (state): EditClientsState => ({
      ...state,
      editingClient: undefined,
    }),
  },
});

export default slice.reducer;

export const {setClient, resetClient} = slice.actions;