import {Card, CardContent, FlexContainer} from "@axxes/design-system";
import React from "react";
import {EditSkills} from "../components/EditSkills/EditSkills";

export const ManageSkillsDataContainer = () => {
    return <FlexContainer
        direction="column"
        customClasses="axxes-manage__edit"
        padding="0"
    >
        <FlexContainer
            direction="row"
            padding="0"
        >
            <h1>Manage Skills Data</h1>
        </FlexContainer>
        <FlexContainer
            direction="column"
            padding="0"
            customClasses="axxes-manage__card"
        >
            <Card>
                <CardContent>
                    <EditSkills/>
                </CardContent>
            </Card>
        </FlexContainer>
    </FlexContainer>
}