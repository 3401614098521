import React, { useEffect } from 'react'
import Modal from 'react-modal'
import DeleteModal from '../components/Modals/DeleteModal';
import InfoModal from '../components/Modals/InfoModal';
import '../components/Modals/modals.scss';
import ReplaceModal from '../components/Modals/ReplaceModal';
import { ModalTypes } from '../models/modals';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { hideModal as reduxHideModal } from '../store/slice';

const modalStyles: Modal.Styles = {
  overlay: {
    zIndex: 1000,
  },
};

function ModalContainer() {
  const dispatch = useAppDispatch();
  const modal = useAppSelector((state) => state.common.modal);

  const modalIsOpen = modal.open && modal.type !== undefined;

  const hideModal = () => dispatch(reduxHideModal());

  const onConfirm = () => {
    modal.content?.onConfirm?.();
    hideModal();
  };

  const onCancel = () => {
    modal.content?.onCancel?.();
    hideModal();
  };

  const onReplace = (replaceWithId: string) => {
    modal.content?.onReplace?.(replaceWithId);
    hideModal();
  };

  const ModalContent = () => {
    switch (modal.type) {
      case ModalTypes.DELETE:
        return (
          <DeleteModal
            content={modal.content}
            onDelete={onConfirm}
            onCancel={onCancel}
          />
        );
      case ModalTypes.REPLACE:
        return (
          <ReplaceModal
            content={modal.content}
            onReplace={onReplace}
            onCancel={onCancel}
          />
        );
      case ModalTypes.INFO:
        return (
          <InfoModal
            content={modal.content}
            onClose={onCancel}
          />
        )
      default:
        return <></>;
    }
  };

  const disableScrolling = () => (document.body.style.overflow = 'hidden');
  const enableScrolling = () => (document.body.style.overflow = 'unset');

  useEffect(() => {
    if (modalIsOpen) {
      disableScrolling();
    } else {
      enableScrolling();
    }
  }, [modalIsOpen]);

  return (
    <Modal
      isOpen={modalIsOpen}
      style={modalStyles}
      className="axxes-card modal"
    >
      <ModalContent />
    </Modal>
  );
}

export default ModalContainer;
