import AppInfoDto from "./AppInfoDto";

export default class AppInfo {

    version: string;

    constructor(version: string) {
        this.version = version
    }

    static mapFromDto(appInfo: AppInfoDto): AppInfo {
        return new AppInfo(appInfo.build.version)
    }


}