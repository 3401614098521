import {CookieBanner, FlexContainer} from "@axxes/design-system";
import GA4React from "ga-4-react";
import Cookies from "js-cookie";
import React, {useEffect, useState} from "react";
import { getShareClient, getShareCreated, getShareRequester } from "../../common/auth/GuestConfig";
import {UserCV} from "../../users/model";
import {ShareAboutMe} from "./ShareAboutMe";
import {ShareCertificates} from "./ShareCertificates";
import {ShareEducations} from "./ShareEducations";
import {ShareLanguages} from "./ShareLanguages";
import {ShareTainings} from "./ShareTrainings";
import {ShareUserProjects} from "./ShareUserProjects";
import {ShareUserSkills} from "./ShareUserSkills";
import {ShareWeblinks} from "./ShareWeblinks";

export interface SharedCvDetailProps {
    cv?: UserCV;
    isLoading?: boolean;
}

export const SharedCvDetail = ({cv, isLoading}: SharedCvDetailProps) => {
    const [cookiesAccepted, setCookiesAccepted] = useState(false);

    const getCvId = () => {
        const [,,cvId,] = window.location.pathname.split('/');
        return cvId;
    }

    const getCreatedOn = () => {
        return getShareCreated()?.split('T')[0] || 'no date';
    }

    const acceptCookies = () => {
        Cookies.set('cookie-consent', 'accepted');
        setCookiesAccepted(true);
    }

    useEffect(() => {
        const cookieConsent = Cookies.get('cookie-consent');
        setCookiesAccepted(cookieConsent === 'accepted');
    }, [])

    useEffect(() => {
        if(!isLoading && cookiesAccepted) {
            const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '');   
            ga4react.initialize().then((ga4) => {
                const url = `/${getCreatedOn()}/${getShareRequester()}/${getCvId()}/${getShareClient()}`;
                const title = `${getCreatedOn()} | ${getShareRequester()} | ${getCvId()} | ${getShareClient()}`
                ga4.pageview(url, url, title);
            })
        };
    }, [cookiesAccepted])

    return (
    <>
        <FlexContainer customClasses="axxes-user__detail" direction="column">
            <ShareAboutMe
                userInfo={cv?.userInfo}
                user={cv?.user}
                isLoading={isLoading}
            />
            <ShareUserSkills
                userSkills={cv?.skills}
                isLoading={isLoading}
            />
            <ShareUserProjects
                projects={cv?.projects}
                isLoading={isLoading}
            />
            <ShareEducations
                educations={cv?.educations}
                isLoading={isLoading}
            />
            <ShareCertificates
                certificates={cv?.certificates}
            />
            <ShareTainings
                trainings={cv?.trainings}
            />
            <ShareLanguages
                languages={cv?.languages}
            />
            <ShareWeblinks
                userLinks={cv?.links}
            />
        </FlexContainer>
        {!cookiesAccepted && !isLoading && <CookieBanner onAccept={acceptCookies} className='custom-cookie-banner' />}
    </>)
}