import SkillDTO from "./dto-get/skillDTO";

export class Skill {
    id?: string;
    name?: string;
    category?: string;

    static mapFromDto(skillDTO: SkillDTO) {
        return {
            id: skillDTO.id,
            name: skillDTO.name,
            category: skillDTO.category,
        }
    }
}