import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ErrorResponse } from '../../common/models/errorResponse';
import { Params, ResponsePayload } from '../../common/store/state';
import {
  Client,
  Course,
  Education,
  Project,
  School,
  Skill,
  Training,
  User,
  UserCV,
  UserSkill,
} from '../model';
import { Certificate } from '../model/certificate';
import { CvVersion } from '../model/cvVersion';
import { Language } from '../model/language';
import UserInfo from '../model/userInfo';
import { UserLink } from '../model/userLink';
import { convertVersionTagToDisplay } from '../utils/util';
import { usersInitialState, UsersState } from './state';

function spliceResult<T extends {id?: string}>(items?: T[], data?: T) {
  if (!items || !data) return [];
  const list = [...(items || [])];
  const index = list.findIndex((item) => item.id === data?.id);
  if (index > -1) {
    list.splice(index, 1, data);
  }
  return list;
};

const slice = createSlice({
  name: 'users',
  initialState: usersInitialState,
  reducers: {
    fetchCvAction: (state): UsersState => ({
      ...state,
      cv: { ...state.cv, result: undefined, isLoading: true, error: undefined },
    }),
    fetchCvSuccess: (state, action: PayloadAction<UserCV>): UsersState => ({
      ...state,
      cv: {
        ...state.cv,
        result: action.payload,
        isLoading: false,
        error: undefined,
      },
    }),
    fetchCvFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      cv: {
        ...state.cv,
        result: undefined,
        isLoading: false,
        error: action.payload,
      },
    }),
    fetchCvClearError: (state): UsersState => ({
      ...state,
      cv: { ...state.cv, error: undefined },
    }),

    fetchCvVersionsAction: (state): UsersState => ({
      ...state,
      cvVersions: {
        ...state.cvVersions,
        result: undefined,
        isLoading: true,
        error: undefined,
      },
      selectedCvVersion: { tag: 'master', displayTag: 'Master' },
    }),
    fetchCvVersionsSuccess: (
      state,
      action: PayloadAction<{
        versions: CvVersion[];
        selectVersionTag?: string;
      }>,
    ): UsersState => ({
      ...state,
      cvVersions: {
        ...state.cvVersions,
        result: action?.payload?.versions,
        isLoading: false,
        error: undefined,
      },
      selectedCvVersion: {
        tag: action?.payload?.selectVersionTag || 'master',
        displayTag:
          convertVersionTagToDisplay(action?.payload?.selectVersionTag) ||
          'Master',
      },
    }),
    fetchCvVersionsFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      cvVersions: {
        ...state.cvVersions,
        result: undefined,
        isLoading: false,
        error: action.payload,
      },
    }),
    setSelectedCvVersion: (
      state,
      action: PayloadAction<CvVersion | undefined>,
    ): UsersState => ({ ...state, selectedCvVersion: action.payload }),

    fetchProfileAction: (state): UsersState => ({
      ...state,
      user: {
        ...state.user,
        result: undefined,
        isLoading: true,
        error: undefined,
      },
    }),
    fetchProfileSuccess: (state, action: PayloadAction<User>): UsersState => ({
      ...state,
      user: {
        ...state.user,
        result: action.payload,
        isLoading: false,
        error: undefined,
      },
    }),
    fetchProfileFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      user: {
        ...state.user,
        result: undefined,
        isLoading: false,
        error: action.payload,
      },
    }),
    putUserSuccess: (state, action: PayloadAction<User>): UsersState => ({
      ...state,
      user: {
        ...state.user,
        ...action.payload,
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          user: action.payload,
        },
      },
    }),

    putUserInfoAction: (state): UsersState => ({
      ...state,
      userInfo: {
        ...state.userInfo,
        put: {
          ...state.userInfo?.put,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    putUserInfoSuccess: (
      state,
      action: PayloadAction<ResponsePayload<UserInfo>>,
    ): UsersState => ({
      ...state,
      userInfo: {
        ...state.userInfo,
        put: {
          ...state.userInfo?.put,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          userInfo: action.payload.data,
        },
      },
    }),
    putUserInfoFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      userInfo: {
        ...state.userInfo,
        put: {
          ...state.userInfo?.put,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),

    postUserSkillAction: (state): UsersState => ({
      ...state,
      userSkills: {
        ...state.userSkills,
        post: {
          ...state.userSkills?.post,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    postUserSkillSuccess: (
      state,
      action: PayloadAction<ResponsePayload<UserSkill>>,
    ): UsersState => ({
      ...state,
      userSkills: {
        ...state.userSkills,
        post: {
          ...state.userSkills?.post,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          skills: [...(state?.cv?.result?.skills || []), action.payload.data],
        },
      },
    }),
    postUserSkillFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      userSkills: {
        ...state.userSkills,
        post: {
          ...state.userSkills?.post,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),
    putUserSkillAction: (state): UsersState => ({
      ...state,
      userSkills: {
        ...state.userSkills,
        put: {
          ...state.userSkills?.put,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    putUserSkillSuccess: (
      state,
      action: PayloadAction<ResponsePayload<UserSkill>>,
    ): UsersState => ({
      ...state,
      userSkills: {
        ...state.userSkills,
        put: {
          ...state.userSkills?.put,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          skills: spliceResult(state.cv?.result?.skills, action.payload.data),
        },
      },
    }),
    putUserSkillFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      userSkills: {
        ...state.userSkills,
        put: {
          ...state.userSkills?.put,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),
    putUserSkillPrioritiesAction: (state): UsersState => ({
      ...state,
      userSkills: {
        ...state.userSkills,
        put: {
          ...state.userSkills?.put,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    putUserSkillPrioritiesSuccess: (
      state,
      action: PayloadAction<ResponsePayload<UserSkill[]>>,
    ): UsersState => ({
      ...state,
      userSkills: {
        ...state.userSkills,
        put: {
          ...state.userSkills?.put,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          skills: action.payload.data,
        },
      },
    }),
    putUserSkillPrioritiesFail: (
      state,
      action: PayloadAction<AxiosError<ErrorResponse>>,
    ): UsersState => ({
      ...state,
      userSkills: {
        ...state.userSkills,
        put: {
          ...state.userSkills?.put,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),
    deleteUserSkillAction: (state): UsersState => ({
      ...state,
      userSkills: {
        ...state.userSkills,
        delete: {
          ...state.userSkills?.delete,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    deleteUserSkillSuccess: (
      state,
      action: PayloadAction<ResponsePayload<UserSkill>>,
    ): UsersState => ({
      ...state,
      userSkills: {
        ...state.userSkills,
        delete: {
          ...state.userSkills?.delete,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          skills: state.cv?.result?.skills?.filter(
            (skill) => skill.id !== action.payload?.data?.id,
          ),
        },
      },
    }),
    deleteUserSkillFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      userSkills: {
        ...state.userSkills,
        delete: {
          ...state.userSkills?.delete,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),

    postLanguageAction: (state): UsersState => ({
      ...state,
      languages: {
        ...state.languages,
        post: {
          ...state.languages?.post,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    postLanguageSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Language>>,
    ): UsersState => ({
      ...state,
      languages: {
        ...state.languages,
        post: {
          ...state.languages?.post,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          languages: [
            ...(state?.cv?.result?.languages || []),
            action.payload?.data,
          ],
        },
      },
    }),
    postLanguageFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      languages: {
        ...state.languages,
        post: {
          ...state.languages?.post,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),
    putLanguageAction: (state): UsersState => ({
      ...state,
      languages: {
        ...state.languages,
        put: {
          ...state.languages?.put,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    putLanguageSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Language>>,
    ): UsersState => ({
      ...state,
      languages: {
        ...state.languages,
        put: {
          ...state.languages?.put,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          languages: spliceResult(
            state.cv?.result?.languages,
            action.payload.data,
          ),
        },
      },
    }),
    putLanguageFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      languages: {
        ...state.languages,
        put: {
          ...state.languages?.put,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),
    deleteLanguageAction: (state): UsersState => ({
      ...state,
      languages: {
        ...state.languages,
        delete: {
          ...state.languages?.delete,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    deleteLanguageSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Language>>,
    ): UsersState => ({
      ...state,
      languages: {
        ...state.languages,
        delete: {
          ...state.languages?.delete,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          languages: state.cv?.result?.languages?.filter(
            (proj) => proj.id !== action.payload?.data?.id,
          ),
        },
      },
    }),
    deleteLanguageFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      languages: {
        ...state.languages,
        delete: {
          ...state.languages?.delete,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),

    fetchSkillsAction: (state): UsersState => ({
      ...state,
      skills: {
        ...state.skills,
        fetch: {
          ...state.skills?.fetch,
          result: undefined,
          isLoading: true,
          error: undefined,
        },
      },
    }),
    fetchSkillsSuccess: (
      state,
      action: PayloadAction<Skill[]>,
    ): UsersState => ({
      ...state,
      skills: {
        ...state.skills,
        fetch: {
          ...state.skills?.fetch,
          result: action.payload,
          isLoading: false,
          error: undefined,
        },
      },
    }),
    fetchSkillsFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      skills: {
        ...state.skills,
        fetch: {
          ...state.skills?.fetch,
          result: undefined,
          isLoading: false,
          error: action.payload,
        },
      },
    }),
    setFetchSkillsParams: (
      state,
      action: PayloadAction<Params>,
    ): UsersState => ({
      ...state,
      skills: {
        ...state.skills,
        fetch: { ...state.skills?.fetch, params: action.payload },
      },
    }),
    postSkillAction: (state): UsersState => ({
      ...state,
      skills: {
        ...state.skills,
        post: {
          ...state.skills?.post,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    postSkillSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Skill>>,
    ): UsersState => ({
      ...state,
      skills: {
        ...state.skills,
        post: {
          ...state.skills?.post,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
    }),
    postSkillFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      skills: {
        ...state.skills,
        post: {
          ...state.skills?.post,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),

    postProjectAction: (state): UsersState => ({
      ...state,
      projects: {
        ...state.projects,
        post: {
          ...state.projects?.post,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    postProjectSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Project>>,
    ): UsersState => ({
      ...state,
      projects: {
        ...state.projects,
        post: {
          ...state.projects?.post,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          projects: [
            ...(state?.cv?.result?.projects || []),
            action.payload?.data,
          ],
        },
      },
    }),
    postProjectFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      projects: {
        ...state.projects,
        post: {
          ...state.projects?.post,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),
    putProjectAction: (state): UsersState => ({
      ...state,
      projects: {
        ...state.projects,
        put: {
          ...state.projects?.put,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    putProjectSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Project>>,
    ): UsersState => ({
      ...state,
      projects: {
        ...state.projects,
        put: {
          ...state.projects?.put,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          projects: spliceResult(
            state.cv?.result?.projects,
            action.payload.data,
          ),
        },
      },
    }),
    putProjectFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      projects: {
        ...state.projects,
        put: {
          ...state.projects?.put,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),
    putProjectPrioritiesAction: (state): UsersState => ({
      ...state,
      projects: {
        ...state.projects,
        put: {
          ...state.projects?.put,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    putProjectPrioritiesSucces: (
      state,
      action: PayloadAction<ResponsePayload<Project[]>>,
    ): UsersState => ({
      ...state,
      projects: {
        ...state.projects,
        put: {
          ...state.projects?.put,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          projects: action.payload.data,
        },
      },
    }),
    putProjectPrioritiesFail: (
      state,
      action: PayloadAction<AxiosError<ErrorResponse>>,
    ): UsersState => ({
      ...state,
      projects: {
        ...state.projects,
        put: {
          ...state.projects?.put,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),
    deleteProjectAction: (state): UsersState => ({
      ...state,
      projects: {
        ...state.projects,
        delete: {
          ...state.projects?.delete,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    deleteProjectSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Project>>,
    ): UsersState => ({
      ...state,
      projects: {
        ...state.projects,
        delete: {
          ...state.projects?.delete,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          projects: state.cv?.result?.projects?.filter(
            (proj) => proj.id !== action.payload?.data?.id,
          ),
        },
      },
    }),
    deleteProjectFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      projects: {
        ...state.projects,
        delete: {
          ...state.projects?.delete,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),

    fetchClientsAction: (state): UsersState => ({
      ...state,
      clients: {
        ...state.clients,
        fetch: {
          ...state.clients?.fetch,
          result: undefined,
          isLoading: true,
          error: undefined,
        },
      },
    }),
    fetchClientsSuccess: (
      state,
      action: PayloadAction<Client[]>,
    ): UsersState => ({
      ...state,
      clients: {
        ...state.clients,
        fetch: {
          ...state.clients?.fetch,
          result: action.payload,
          isLoading: false,
          error: undefined,
        },
      },
    }),
    fetchClientsFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      clients: {
        ...state.clients,
        fetch: {
          ...state.clients?.fetch,
          result: undefined,
          isLoading: false,
          error: action.payload,
        },
      },
    }),
    setFetchClientParams: (
      state,
      action: PayloadAction<Params>,
    ): UsersState => ({
      ...state,
      clients: {
        ...state.clients,
        fetch: { ...state.clients?.fetch, params: action.payload },
      },
    }),
    postClientAction: (state): UsersState => ({
      ...state,
      clients: {
        ...state.clients,
        post: {
          ...state.clients?.post,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    postClientSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Client>>,
    ): UsersState => ({
      ...state,
      clients: {
        ...state.clients,
        post: {
          ...state.clients?.post,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
    }),
    postClientFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      clients: {
        ...state.clients,
        post: {
          ...state.clients?.post,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),

    postEducationAction: (state): UsersState => ({
      ...state,
      educations: {
        ...state.educations,
        post: {
          ...state.educations?.post,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    postEducationSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Education>>,
    ): UsersState => ({
      ...state,
      educations: {
        ...state.educations,
        post: {
          ...state.educations?.post,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          educations: [
            ...(state?.cv?.result?.educations || []),
            action.payload?.data,
          ],
        },
      },
    }),
    postEducationFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      educations: {
        ...state.educations,
        post: {
          ...state.educations?.post,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),
    putEducationAction: (state): UsersState => ({
      ...state,
      educations: {
        ...state.educations,
        put: {
          ...state.educations?.put,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    putEducationSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Education>>,
    ): UsersState => ({
      ...state,
      educations: {
        ...state.educations,
        put: {
          ...state.educations?.put,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          educations: spliceResult(
            state.cv?.result?.educations,
            action.payload.data,
          ),
        },
      },
    }),
    putEducationFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      educations: {
        ...state.educations,
        put: {
          ...state.educations?.put,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),
    deleteEducationAction: (state): UsersState => ({
      ...state,
      educations: {
        ...state.educations,
        delete: {
          ...state.educations?.delete,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    deleteEducationSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Education>>,
    ): UsersState => ({
      ...state,
      educations: {
        ...state.educations,
        delete: {
          ...state.educations?.delete,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          educations: state.cv?.result?.educations?.filter(
            (ed) => ed.id !== action.payload.data.id,
          ),
        },
      },
    }),
    deleteEducationFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      educations: {
        ...state.educations,
        delete: {
          ...state.educations?.delete,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),

    fetchSchoolsAction: (state): UsersState => ({
      ...state,
      schools: {
        ...state.schools,
        fetch: {
          ...state.schools?.fetch,
          result: undefined,
          isLoading: true,
          error: undefined,
        },
      },
    }),
    fetchSchoolsSuccess: (
      state,
      action: PayloadAction<School[]>,
    ): UsersState => ({
      ...state,
      schools: {
        ...state.schools,
        fetch: {
          ...state.schools?.fetch,
          result: action.payload,
          isLoading: false,
          error: undefined,
        },
      },
    }),
    fetchSchoolsFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      schools: {
        ...state.schools,
        fetch: {
          ...state.schools?.fetch,
          result: undefined,
          isLoading: false,
          error: action.payload,
        },
      },
    }),
    postSchoolAction: (state): UsersState => ({
      ...state,
      schools: {
        ...state.schools,
        post: {
          ...state.schools?.post,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    postSchoolSuccess: (
      state,
      action: PayloadAction<ResponsePayload<School>>,
    ): UsersState => ({
      ...state,
      schools: {
        ...state.schools,
        post: {
          ...state.schools?.post,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
    }),
    postSchoolFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      schools: {
        ...state.schools,
        post: {
          ...state.schools?.post,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),
    putSchoolAction: (state): UsersState => ({
      ...state,
      schools: {
        ...state.schools,
        put: {
          ...state.schools?.put,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    putSchoolSuccess: (
      state,
      action: PayloadAction<ResponsePayload<School>>,
    ): UsersState => ({
      ...state,
      schools: {
        ...state.schools,
        put: {
          ...state.schools?.put,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
    }),
    putSchoolFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      schools: {
        ...state.schools,
        put: {
          ...state.schools?.put,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),
    deleteSchoolAction: (state): UsersState => ({
      ...state,
      schools: {
        ...state.schools,
        delete: {
          ...state.schools?.delete,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    deleteSchoolSuccess: (
      state,
      action: PayloadAction<ResponsePayload<School>>,
    ): UsersState => ({
      ...state,
      schools: {
        ...state.schools,
        delete: {
          ...state.schools?.delete,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
    }),
    deleteSchoolFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      schools: {
        ...state.schools,
        delete: {
          ...state.schools?.delete,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),

    fetchCoursesAction: (state): UsersState => ({
      ...state,
      courses: {
        ...state.courses,
        fetch: {
          ...state.courses?.fetch,
          result: undefined,
          isLoading: true,
          error: undefined,
        },
      },
    }),
    fetchCoursesSuccess: (
      state,
      action: PayloadAction<Course[]>,
    ): UsersState => ({
      ...state,
      courses: {
        ...state.courses,
        fetch: {
          ...state.courses?.fetch,
          result: action.payload,
          isLoading: false,
          error: undefined,
        },
      },
    }),
    fetchCoursesFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      courses: {
        ...state.courses,
        fetch: {
          ...state.courses?.fetch,
          result: undefined,
          isLoading: false,
          error: action.payload,
        },
      },
    }),
    postCourseAction: (state): UsersState => ({
      ...state,
      courses: {
        ...state.courses,
        post: {
          ...state.courses?.post,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    postCourseSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Course>>,
    ): UsersState => ({
      ...state,
      courses: {
        ...state.courses,
        post: {
          ...state.courses?.post,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
    }),
    postCourseFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      courses: {
        ...state.courses,
        post: {
          ...state.courses?.post,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),
    putCourseAction: (state): UsersState => ({
      ...state,
      courses: {
        ...state.courses,
        put: {
          ...state.courses?.put,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    putCourseSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Course>>,
    ): UsersState => ({
      ...state,
      courses: {
        ...state.courses,
        put: {
          ...state.courses?.put,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
    }),
    putCourseFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      courses: {
        ...state.courses,
        put: {
          ...state.courses?.put,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),
    deleteCourseAction: (state): UsersState => ({
      ...state,
      courses: {
        ...state.courses,
        delete: {
          ...state.courses?.delete,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    deleteCourseSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Course>>,
    ): UsersState => ({
      ...state,
      courses: {
        ...state.courses,
        delete: {
          ...state.courses?.delete,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
    }),
    deleteCourseFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      courses: {
        ...state.courses,
        delete: {
          ...state.courses?.delete,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),

    postTrainingAction: (state): UsersState => ({
      ...state,
      trainings: {
        ...state.trainings,
        post: {
          ...state.trainings?.post,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    postTrainingSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Training>>,
    ): UsersState => ({
      ...state,
      trainings: {
        ...state.trainings,
        post: {
          ...state.trainings?.post,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          trainings: [
            ...(state?.cv?.result?.trainings || []),
            action.payload?.data,
          ],
        },
      },
    }),
    postTrainingFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      trainings: {
        ...state.trainings,
        post: {
          ...state.trainings?.post,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),
    putTrainingAction: (state): UsersState => ({
      ...state,
      trainings: {
        ...state.trainings,
        put: {
          ...state.trainings?.put,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    putTrainingSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Training>>,
    ): UsersState => ({
      ...state,
      trainings: {
        ...state.trainings,
        put: {
          ...state.trainings?.put,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          trainings: spliceResult(
            state.cv?.result?.trainings,
            action.payload.data,
          ),
        },
      },
    }),
    putTrainingFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      trainings: {
        ...state.trainings,
        put: {
          ...state.trainings?.put,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),
    deleteTrainingAction: (state): UsersState => ({
      ...state,
      trainings: {
        ...state.trainings,
        delete: {
          ...state.trainings?.delete,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    deleteTrainingSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Training>>,
    ): UsersState => ({
      ...state,
      trainings: {
        ...state.trainings,
        delete: {
          ...state.trainings?.delete,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          trainings: state.cv?.result?.trainings?.filter(
            (tr) => tr.id !== action.payload.data.id,
          ),
        },
      },
    }),
    deleteTrainingFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      trainings: {
        ...state.trainings,
        delete: {
          ...state.trainings?.delete,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),

    postCertificateAction: (state): UsersState => ({
      ...state,
      certificates: {
        ...state.certificates,
        post: {
          ...state.certificates?.post,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    postCertificateSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Certificate>>,
    ): UsersState => ({
      ...state,
      certificates: {
        ...state.certificates,
        post: {
          ...state.certificates?.post,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          certificates: [
            ...(state?.cv?.result?.certificates || []),
            action.payload?.data,
          ],
        },
      },
    }),
    postCertificateFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      certificates: {
        ...state.certificates,
        post: {
          ...state.certificates?.post,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),
    putCertificateAction: (state): UsersState => ({
      ...state,
      certificates: {
        ...state.certificates,
        put: {
          ...state.certificates?.put,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    putCertificateSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Certificate>>,
    ): UsersState => ({
      ...state,
      certificates: {
        ...state.certificates,
        put: {
          ...state.certificates?.put,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          certificates: spliceResult(
            state.cv?.result?.certificates,
            action.payload.data,
          ),
        },
      },
    }),
    putCertificateFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      certificates: {
        ...state.certificates,
        put: {
          ...state.certificates?.put,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),
    deleteCertificateAction: (state): UsersState => ({
      ...state,
      certificates: {
        ...state.certificates,
        delete: {
          ...state.certificates?.delete,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    deleteCertificateSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Certificate>>,
    ): UsersState => ({
      ...state,
      certificates: {
        ...state.certificates,
        delete: {
          ...state.certificates?.delete,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          certificates: state.cv?.result?.certificates?.filter(
            (cert) => cert.id !== action.payload.data.id,
          ),
        },
      },
    }),
    deleteCertificateFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      certificates: {
        ...state.certificates,
        delete: {
          ...state.certificates?.delete,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),

    fetchTrainingCoursesAction: (state): UsersState => ({
      ...state,
      trainingCourses: {
        ...state.trainingCourses,
        fetch: {
          ...state.trainingCourses?.fetch,
          result: undefined,
          isLoading: true,
          error: undefined,
        },
      },
    }),
    fetchTrainingCoursesSuccess: (
      state,
      action: PayloadAction<Course[]>,
    ): UsersState => ({
      ...state,
      trainingCourses: {
        ...state.trainingCourses,
        fetch: {
          ...state.trainingCourses?.fetch,
          result: action.payload,
          isLoading: false,
          error: undefined,
        },
      },
    }),
    fetchTrainingCoursesFail: (
      state,
      action: PayloadAction<AxiosError<ErrorResponse>>,
    ): UsersState => ({
      ...state,
      trainingCourses: {
        ...state.trainingCourses,
        fetch: {
          ...state.trainingCourses?.fetch,
          result: undefined,
          isLoading: false,
          error: action.payload,
        },
      },
    }),
    postTrainingCourseAction: (state): UsersState => ({
      ...state,
      trainingCourses: {
        ...state.trainingCourses,
        post: {
          ...state.trainingCourses?.post,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    postTrainingCourseSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Course>>,
    ): UsersState => ({
      ...state,
      trainingCourses: {
        ...state.trainingCourses,
        post: {
          ...state.trainingCourses?.post,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
    }),
    postTrainingCourseFail: (
      state,
      action: PayloadAction<AxiosError<ErrorResponse>>,
    ): UsersState => ({
      ...state,
      trainingCourses: {
        ...state.trainingCourses,
        post: {
          ...state.trainingCourses?.post,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),

    fetchTrainingCentersAction: (state): UsersState => ({
      ...state,
      trainingCenters: {
        ...state.trainingCenters,
        fetch: {
          ...state.trainingCenters?.fetch,
          result: undefined,
          isLoading: true,
          error: undefined,
        },
      },
    }),
    fetchTrainingCentersSuccess: (
      state,
      action: PayloadAction<Client[]>,
    ): UsersState => ({
      ...state,
      trainingCenters: {
        ...state.trainingCenters,
        fetch: {
          ...state.trainingCenters?.fetch,
          result: action.payload,
          isLoading: false,
          error: undefined,
        },
      },
    }),
    fetchTrainingCentersFail: (
      state,
      action: PayloadAction<AxiosError<ErrorResponse>>,
    ): UsersState => ({
      ...state,
      trainingCenters: {
        ...state.trainingCenters,
        fetch: {
          ...state.trainingCenters?.fetch,
          result: undefined,
          isLoading: false,
          error: action.payload,
        },
      },
    }),
    setFetchTrainingCentersParams: (
      state,
      action: PayloadAction<Params>,
    ): UsersState => ({
      ...state,
      trainingCenters: {
        ...state.trainingCenters,
        fetch: { ...state.trainingCenters?.fetch, params: action.payload },
      },
    }),
    postTrainingCenterAction: (state): UsersState => ({
      ...state,
      trainingCenters: {
        ...state.trainingCenters,
        post: {
          ...state.trainingCenters?.post,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    postTrainingCenterSuccess: (
      state,
      action: PayloadAction<ResponsePayload<Client>>,
    ): UsersState => ({
      ...state,
      trainingCenters: {
        ...state.trainingCenters,
        post: {
          ...state.trainingCenters?.post,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
    }),
    postTrainingCenterFail: (
      state,
      action: PayloadAction<AxiosError<ErrorResponse>>,
    ): UsersState => ({
      ...state,
      trainingCenters: {
        ...state.trainingCenters,
        post: {
          ...state.trainingCenters?.post,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),

    postLinkAction: (state): UsersState => ({
      ...state,
      links: {
        ...state.links,
        post: {
          ...state.links?.post,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    postLinkSuccess: (
      state,
      action: PayloadAction<ResponsePayload<UserLink>>,
    ): UsersState => ({
      ...state,
      links: {
        ...state.links,
        post: {
          ...state.links?.post,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          links: [...(state?.cv?.result?.links || []), action.payload?.data],
        },
      },
    }),
    postLinkFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      links: {
        ...state.links,
        post: {
          ...state.links?.post,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),
    putLinkAction: (state): UsersState => ({
      ...state,
      links: {
        ...state.links,
        put: {
          ...state.links?.put,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    putLinkSuccess: (
      state,
      action: PayloadAction<ResponsePayload<UserLink>>,
    ): UsersState => ({
      ...state,
      links: {
        ...state.links,
        put: {
          ...state.links?.put,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          links: spliceResult(state.cv?.result?.links, action.payload.data),
        },
      },
    }),
    putLinkFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      links: {
        ...state.links,
        put: {
          ...state.links?.put,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),
    deleteLinkAction: (state): UsersState => ({
      ...state,
      links: {
        ...state.links,
        delete: {
          ...state.links?.delete,
          result: undefined,
          isSaving: true,
          error: undefined,
        },
      },
    }),
    deleteLinkSuccess: (
      state,
      action: PayloadAction<ResponsePayload<UserLink>>,
    ): UsersState => ({
      ...state,
      links: {
        ...state.links,
        delete: {
          ...state.links?.delete,
          result: action.payload,
          isSaving: false,
          error: undefined,
        },
      },
      cv: {
        ...state.cv,
        result: {
          ...state?.cv?.result,
          links: state.cv?.result?.links?.filter(
            (cert) => cert.id !== action.payload.data.id,
          ),
        },
      },
    }),
    deleteLinkFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      links: {
        ...state.links,
        delete: {
          ...state.links?.delete,
          result: undefined,
          isSaving: false,
          error: action.payload,
        },
      },
    }),

    fetchSharedCvSuccess: (
      state,
      action: PayloadAction<UserCV>,
    ): UsersState => ({
      ...state,
      sharedCv: {
        result: action.payload,
        isLoading: false,
        error: undefined,
      },
    }),
    fetchSharedCvFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>): UsersState => ({
      ...state,
      sharedCv: {
        result: undefined,
        isLoading: false,
        error: action.payload,
      },
    }),

    toggleSectionFold: (state, action: PayloadAction<string>): UsersState => {
      if (state.foldedSections.includes(action.payload)) {
        return {
          ...state,
          foldedSections: state.foldedSections.filter(
            (section) => section !== action.payload,
          ),
        };
      }
      return {
        ...state,
        foldedSections: [...state.foldedSections, action.payload],
      };
    },
    clearFoldedSections: (state): UsersState => ({
      ...state,
      foldedSections: [],
    }),
  },
});

export default slice.reducer;

export const {
  fetchCvAction,
  fetchCvSuccess,
  fetchCvFail,
  fetchCvClearError,
  fetchCvVersionsAction,
  fetchCvVersionsSuccess,
  fetchCvVersionsFail,
  setSelectedCvVersion,
  fetchProfileAction,
  fetchProfileSuccess,
  fetchProfileFail,
  putUserSuccess,
  putUserInfoAction,
  putUserInfoSuccess,
  putUserInfoFail,
  postUserSkillAction,
  postUserSkillSuccess,
  postUserSkillFail,
  putUserSkillAction,
  putUserSkillSuccess,
  putUserSkillFail,
  putUserSkillPrioritiesAction,
  putUserSkillPrioritiesSuccess,
  putUserSkillPrioritiesFail,
  deleteUserSkillAction,
  deleteUserSkillSuccess,
  deleteUserSkillFail,
  postLanguageAction,
  postLanguageSuccess,
  postLanguageFail,
  putLanguageAction,
  putLanguageSuccess,
  putLanguageFail,
  deleteLanguageAction,
  deleteLanguageSuccess,
  deleteLanguageFail,
  fetchSkillsAction,
  fetchSkillsSuccess,
  fetchSkillsFail,
  setFetchSkillsParams,
  postSkillAction,
  postSkillSuccess,
  postSkillFail,
  postProjectAction,
  postProjectSuccess,
  postProjectFail,
  putProjectAction,
  putProjectSuccess,
  putProjectFail,
  putProjectPrioritiesAction,
  putProjectPrioritiesSucces,
  putProjectPrioritiesFail,
  deleteProjectAction,
  deleteProjectSuccess,
  deleteProjectFail,
  fetchClientsAction,
  fetchClientsSuccess,
  fetchClientsFail,
  setFetchClientParams,
  postClientAction,
  postClientSuccess,
  postClientFail,
  postEducationAction,
  postEducationSuccess,
  postEducationFail,
  putEducationAction,
  putEducationSuccess,
  putEducationFail,
  deleteEducationAction,
  deleteEducationSuccess,
  deleteEducationFail,
  fetchSchoolsAction,
  fetchSchoolsSuccess,
  fetchSchoolsFail,
  postSchoolAction,
  postSchoolSuccess,
  postSchoolFail,
  putSchoolAction,
  putSchoolSuccess,
  putSchoolFail,
  deleteSchoolAction,
  deleteSchoolSuccess,
  deleteSchoolFail,
  fetchCoursesAction,
  fetchCoursesSuccess,
  fetchCoursesFail,
  postCourseAction,
  postCourseSuccess,
  postCourseFail,
  putCourseAction,
  putCourseSuccess,
  putCourseFail,
  deleteCourseAction,
  deleteCourseSuccess,
  deleteCourseFail,
  postTrainingAction,
  postTrainingSuccess,
  postTrainingFail,
  putTrainingAction,
  putTrainingSuccess,
  putTrainingFail,
  deleteTrainingAction,
  deleteTrainingSuccess,
  deleteTrainingFail,
  postCertificateAction,
  postCertificateSuccess,
  postCertificateFail,
  putCertificateAction,
  putCertificateSuccess,
  putCertificateFail,
  deleteCertificateAction,
  deleteCertificateSuccess,
  deleteCertificateFail,
  fetchTrainingCoursesAction,
  fetchTrainingCoursesSuccess,
  fetchTrainingCoursesFail,
  postTrainingCourseAction,
  postTrainingCourseSuccess,
  postTrainingCourseFail,
  fetchTrainingCentersAction,
  fetchTrainingCentersSuccess,
  fetchTrainingCentersFail,
  setFetchTrainingCentersParams,
  postTrainingCenterAction,
  postTrainingCenterSuccess,
  postTrainingCenterFail,
  postLinkAction,
  postLinkSuccess,
  postLinkFail,
  putLinkAction,
  putLinkSuccess,
  putLinkFail,
  deleteLinkAction,
  deleteLinkSuccess,
  deleteLinkFail,
  fetchSharedCvSuccess,
  fetchSharedCvFail,
  toggleSectionFold,
  clearFoldedSections
} = slice.actions;