export class TrainingCenter {
    id?: string;
    name?: string;
    pictureUrl?: string;
    url?: string;

    constructor(id?: string, name?: string, pictureUrl?: string, url?: string) {
        this.id = id;
        this.name = name;
        this.pictureUrl = pictureUrl;
        this.url = url;
    }
}