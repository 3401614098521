import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import appInfoReducer from '../../appInfo/store/slice';
import generatorReducer from '../../generate/store/slice';
import manageReducer from '../../manage/store/slice';
import profileReducer from '../../profile/store/slice';
import usersReducer from '../../users/store/slice';
import commonReducer from './slice';

const store = configureStore({
  reducer: {
    users: usersReducer,
    common: commonReducer,
    manage: manageReducer,
    generator: generatorReducer,
    appInfo: appInfoReducer,
    profile: profileReducer,
  },
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware();
    if (process.env.NODE_ENV !== 'production') {
      middleware.push(createLogger())
    }
    return middleware;
  }
})

export type AppState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch;

export default store;
