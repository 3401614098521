import { isLocalhost } from './AuthConfig';

const consultant = isLocalhost ? "cv.dev.consultant" : process.env.REACT_APP_ROLE_CONSULTANT as string;
const captain = isLocalhost ? "cv.dev.captain" : process.env.REACT_APP_ROLE_CAPTAIN as string;
const coach = isLocalhost ? "cv.dev.coach" : process.env.REACT_APP_ROLE_COACH as string;
const staff = isLocalhost ? "cv.dev.staff" : process.env.REACT_APP_ROLE_STAFF as string;
const sales = isLocalhost ? "cv.dev.sales" : process.env.REACT_APP_ROLE_SALES as string;
const hr = isLocalhost ? "cv.dev.hr" : process.env.REACT_APP_ROLE_HR as string;
const administrator = isLocalhost ? "cv.dev.administrator" : process.env.REACT_APP_ROLE_ADMINISTRATOR as string;

export const ROLES = {
    CONSULTANT: consultant,
    CAPTAIN : captain,
    COACH: coach,
    STAFF: staff,
    SALES: sales,
    HR: hr,
    ADMINISTRATOR: administrator,
    NOT_CONSULTANT: [captain, coach, staff, sales, hr, administrator]
}