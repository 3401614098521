import { FlexContainer, Input } from '@axxes/design-system';
import React from 'react';
import { APP_CONFIG } from '../../../app/config/config';
import ImageUpload from '../../../users/components/ImageUpload/ImageUpload';
import { Client, School } from '../../../users/model';
import { Image } from '../../../users/model/image';
import { TrainingCenter } from '../../../users/model/trainingCenter';
import { uploadImage } from '../../../users/store/usersFacadeService';
import { useAppDispatch } from '../../store/hooks';

type Item = Client | School | TrainingCenter;

type ItemFormProps = {
  newItem?: Item;
  setNewItem: (value: Item) => void;
  title: string;
  open: boolean;
  withName?: boolean;
  withLink?: boolean;
  withPhoto?: boolean;
};

const ItemForm = ({
  newItem,
  setNewItem,
  open,
  title,
  withName,
  withLink,
  withPhoto,
}: ItemFormProps) => {
  const reduxDispatch = useAppDispatch();
  const upload = (file: File) => uploadImage(file);

  return (
    <div className={`axxes-cv-form__add ${open && '-open'}`}>
      <h5 className="axxes-border-title">
        <span className="axxes-border-title__text">{title}</span>
      </h5>
      <FlexContainer
        customClasses="axxes-cv-form"
        padding="0"
        direction="row"
        grow={1}
      >
        {withPhoto && (
          <img
            className="axxes-cv-form__photo"
            alt="client-img"
            src={newItem?.pictureUrl || APP_CONFIG.DEFAULT_IMAGE}
          />
        )}

        <FlexContainer
          padding="0"
          direction="column"
          customClasses="axxes-cv-form__container"
          grow={1}
        >
          {withName ? (
            <Input
              value={newItem?.name || ''}
              onChange={(e) =>
                setNewItem({
                  ...newItem,
                  name: e.target.value,
                })
              }
              title="Name"
              type="text"
            />
          ) : undefined}
          <ImageUpload
            upload={upload}
            onUploadSuccess={(result: Image) => {
              setNewItem({
                ...newItem,
                pictureUrl: result.url,
              });
            }}
            dispatch={reduxDispatch}
          />
          {withLink && (
            <Input
              value={newItem?.url || ''}
              onChange={(e) =>
                setNewItem({
                  ...newItem,
                  url: e.target.value,
                })
              }
              title="Link"
              type="text"
            />
          )}
        </FlexContainer>
      </FlexContainer>
      {!withName && <p className="axxes-border-title" />}
    </div>
  );
};

export default ItemForm;
