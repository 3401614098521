import React, { FunctionComponent } from 'react';
import './MobileHeader.scss';

interface MobileHeaderProps {
  isAdmin?: boolean;
}

const MobileHeader: FunctionComponent<MobileHeaderProps> = () => (
  <div className="axxes-mobile-header">
    <a href="/profile"><i className="axxes-icons axxes-icon-home" /></a>
  </div>
);

export default MobileHeader;
