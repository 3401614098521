import {Card, CardContent, CardHeader, CardTitle, FlexContainer, Loading} from "@axxes/design-system";
import React from "react";
import {APP_CONFIG} from "../../app/config/config";
import { sortEducations } from "../../common/utils/sorters";
import Avatar from "../../users/components/Avatar/Avatar";
import AvatarSubtitle from "../../users/components/Avatar/AvatarSubtitle";
import AvatarText from "../../users/components/Avatar/AvatarText";
import AvatarTitle from "../../users/components/Avatar/AvatarTitle";
import UserEducationsView from "../../users/components/UserEducations/UserEducationsView";
import {Education} from "../../users/model";


export interface ShareEducationsProps {
    educations?: Education[];
    isLoading?: boolean;
}

export const ShareEducations = ({educations, isLoading}: ShareEducationsProps) => {

    return (
            educations?.length === 0 ? null :
            <Card>
                <CardHeader>
                    <CardTitle>Education</CardTitle>
                </CardHeader>
                <CardContent>
                    {isLoading ? (
                        <Avatar photo={APP_CONFIG.DEFAULT_IMAGE} isLoading={isLoading}>
                            <AvatarTitle>
                                <Loading width="400px" />
                            </AvatarTitle>
                            <AvatarSubtitle>
                                <Loading width="300px" />
                            </AvatarSubtitle>
                            <AvatarText>
                                <Loading width="300px" />
                            </AvatarText>
                        </Avatar>
                    ) : (
                        <FlexContainer customClasses='axxes-cv-element-list' padding='0' direction='column'>
                            {educations?.length === 0
                                ? null
                                : sortEducations(educations).map((education: Education) => {
                                        return <UserEducationsView education={education} key={education.id} />;
                                    })}
                        </FlexContainer>
                    )}
                </CardContent>
            </Card>
    );
}