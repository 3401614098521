import {
  Button,
  Card,
  CardAction,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  FlexContainer,
} from '@axxes/design-system';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useReducer, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import UserWeblinkEdit from '../../components/UserWeblinks/UserWeblinkEdit';
import { UserLink } from '../../model/userLink';
import { toggleSectionFold } from '../../store/slice';
import { UserRoutingProps } from '../UserDetailContainer';
import userLinkReducer, { INITIAL_USERLINK_EDIT_STATE } from './state';
import UserWeblink from './UserWeblink';

interface UserWeblinksProps extends UserRoutingProps {
  userLinks?: UserLink[];
}

const UserWeblinks = ({ userLinks = [], match }: UserWeblinksProps) => {
  const reduxDispatch = useAppDispatch();

  const folded = useAppSelector((appState) => appState.users.foldedSections.includes('weblinks'));

  const [editable, setEditable] = useState(true);
  const [creating, setCreating] = useState(false);

  const [state, dispatch] = useReducer(
    userLinkReducer,
    INITIAL_USERLINK_EDIT_STATE,
  );

  return (
    <Card>
      <div onClick={() => reduxDispatch(toggleSectionFold('weblinks'))}>
        <CardHeader customClasses='axxes-foldable-card__header'>
          <CardTitle>Weblinks</CardTitle>
          <CardAction>
            <FontAwesomeIcon icon={folded ? faChevronDown : faChevronUp} />
          </CardAction>
        </CardHeader>
      </div>
      {!folded && (
        <>
          <CardContent customClasses='axxes-foldable-card__content'>
            <FlexContainer padding="0" direction="row" customClasses="axxes-links">
              {userLinks?.length === 0
                ? !creating && 'You have not submitted any weblinks.'
                : userLinks.map((userLink: UserLink) => {
                    return (
                      <UserWeblink
                        key={userLink?.id}
                        userLink={userLink}
                        editable={editable && !creating}
                        setEditable={setEditable}
                      />
                    );
                  })}
              {creating && (
                <UserWeblinkEdit
                  match={match}
                  dispatch={dispatch}
                  state={state}
                  creating={true}
                  setCreating={setCreating}
                />
              )}
            </FlexContainer>
          </CardContent>
          <CardFooter>
            {!creating && editable && (
              <div className="axxes-card-actions">
                <Button
                  variant="ghost"
                  accent={true}
                  onClick={() => setCreating(!creating)}
                >
                  Add a weblink
                </Button>
              </div>
            )}
          </CardFooter>
        </>
      )}
    </Card>
  );
};

export default UserWeblinks;
