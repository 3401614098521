import { Button, CardContent, CardFooter, CardTitle } from '@axxes/design-system';
import React from 'react'
import { DeleteContent } from '../../models/modals';
import './modals.scss';

type DeleteModalProps = {
    content?: DeleteContent;
    onDelete?: () => void;
    onCancel?: () => void;
}

function DeleteModal({content, onDelete, onCancel}: DeleteModalProps) {
    return (
      <div>
        <CardTitle>Delete {content?.label}</CardTitle>
        <CardContent customClasses="delete-modal__content">
          <span>
            Are you sure you want to delete the {content?.label} <strong>{content?.name}</strong>?
          </span>
          <span>You can't undo this action.</span>
        </CardContent>
        <CardFooter customClasses="delete-modal__buttons">
          <Button accent={true} onClick={onDelete}>
            Delete
          </Button>
          <Button variant="ghost" onClick={onCancel}>
            Cancel
          </Button>
        </CardFooter>
      </div>
    );
}

export default DeleteModal
