import { FlexContainer} from "@axxes/design-system";
import React from "react"
import {UserSkill} from "../../../../users/model";
import {VisibilityButton} from "../common/VisibilityButton";
import './skillsViewItem.scss'
interface SkillsViewItemProps {
    skill: UserSkill,
    invisibles: string[],
}

export const SkillsViewItem = ({skill, invisibles}: SkillsViewItemProps) => {
    return (
        <div className={'axxes-edit-pdf-skill-item'}>
            <FlexContainer customClasses={`${invisibles.includes(skill.id ?? '') ? 'axxes-edit-pdf-invisible': ''}`}>
                <div className='axxes-edit-pdf-skill-container'>
                    <div className={'axxes-edit-pdf-skill-name'} title={skill.name}>{skill.name}</div>
                    <div className={'axxes-edit-pdf-skill-level'}>{skill.level}</div>
                </div>
                <div className={'axxes-edit-pdf-skill-del'}>
                    <VisibilityButton cvDataId={skill.id} invisibles={invisibles}/>
                </div>
            </FlexContainer>
        </div>)
}
