import UserSkillDTO from './dto-get/userSkillDTO';
import { SkillLevel, SkillLevelType } from './skillLevel';

export class UserSkill {
  id?: string;
  skillId?: string;
  name?: string;
  level?: SkillLevelType;
  category?: string;
  priority?: number;

  constructor() {
    this.name = '';
    this.level = SkillLevel.JUNIOR;
  }

  static mapFromDTO(userSkillDTO: UserSkillDTO) {
    return {
      id: userSkillDTO.id,
      name: userSkillDTO?.name,
      level: userSkillDTO.level,
      skillId: userSkillDTO.skillId,
      category: userSkillDTO.category,
      priority: userSkillDTO.priority,
    };
  }
}
