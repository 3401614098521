export class ShareRequest {
    userId: string;
    tag: string;
    recipient: string;


    constructor(userId: string, tag: string, recipient: string) {
        this.userId = userId;
        this.tag = tag;
        this.recipient = recipient;
    }
}