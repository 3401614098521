import { Search } from '@axxes/design-system';
import React  from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../common/store/hooks';
import { setSearchParam } from '../store/slice';

const SearchContainer = () => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string>('');
  const history = useHistory();

  const submit = () => {
    dispatch(setSearchParam(search));
    history.push('/manage/users');
  };

  const onEnter = (e: any) => {
    if (e.which === 13) {
      submit();
      e.preventDefault();
    }
  };

  return (
      <Search
        value={search}
        onChange={(e: any) => {
          setSearch(e.target.value);
        }}
        onKeyDown={onEnter}
        onSubmit={(e: any) => {
          submit();
        }}
      />
  );
};

export default SearchContainer;
