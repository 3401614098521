import { Node } from "slate";
import CertificateDTO from "./dto-get/certificateDTO";
import {mapRichTextToObject} from "./map";
import { TrainingCenter } from "./trainingCenter";

export class Certificate {
    id?: string;
    pictureUrl?: string;
    description?: Node[];
    name?: string;
    trainingCenter?: TrainingCenter;
    certificateUrl?: string;
    dateOfAchievement?: string;
    dateOfExpiration?: string;

    constructor() {
        this.pictureUrl = '';
        this.description = [{type: 'paragraph',children: [{ text: '' }]}]; // Init empty state for Slate
        this.name = '';
        this.certificateUrl = '';
        this.trainingCenter = new TrainingCenter();
    }

    static mapFromDTO(certificateDTO: CertificateDTO): Certificate {
        const desc = mapRichTextToObject(certificateDTO?.description);
        return {
            ...certificateDTO,
            description: desc
        }
    }
}