import { DisplayOption } from "..";
import { Project } from "../project";
import { Skill } from './../skill';
import ClientDTO from "./clientDTO";
import SkillDTO from "./skillDTO";
import TaskDTO from "./taskDTO";

export default class UserProjectDTO {
    id?: string;
    description?: string;
    role?: string;
    fromDate?: string;
    toDate?: string;
    client?: ClientDTO;
    skills?: SkillDTO[];
    tasks?: TaskDTO[];
    city?: string;
    province?: string;
    country?: string;
    displayOption?: DisplayOption;
    priority?: number;

    static mapToDTO(userProject: Project) : any {
        return {
            id: userProject?.id,
            description: JSON.stringify(userProject?.description || ''),
            role: userProject?.role,
            fromDate: userProject?.fromDate,
            toDate: userProject?.toDate,
            clientId: userProject?.client?.id,
            skills: userProject?.skills?.map((skill) => (skill as Skill)?.id),
            tasks: userProject.tasks?.map((task, index) => ({ priority: index, name: task })),
            city: userProject.city,
            province: userProject.province,
            country: userProject.country,
            displayOption: userProject.displayOption,
            priority: userProject.priority,
        }
    }
}