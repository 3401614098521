import React from "react";
import {APP_CONFIG} from "../../../../app/config/config";
import { dateAvatarFormat } from "../../../../common/utils/dates";
import Avatar from "../../../../users/components/Avatar/Avatar";
import AvatarSubtitle from "../../../../users/components/Avatar/AvatarSubtitle";
import AvatarText from "../../../../users/components/Avatar/AvatarText";
import AvatarTitle from "../../../../users/components/Avatar/AvatarTitle";
import {Project} from "../../../../users/model";
import {MinimizeButton} from "../common/MinimizeButton";
import {VisibilityButton} from "../common/VisibilityButton";
import '../pdfEditor.scss'
import './projectViewItem.scss'

interface ProjectViewItemProp {
    project: Project;
    invisibles: string[];
    minimizeds:string[];
}

export const ProjectViewItem = ({ project, invisibles, minimizeds }: ProjectViewItemProp) => {    
    return (
        <div className={"axxes-project-item-container"}>
            <div className={`axxes-project-item-view`}>
                <Avatar photo={project?.client?.pictureUrl || APP_CONFIG.DEFAULT_IMAGE}>
                    <AvatarTitle>{project?.client?.name}</AvatarTitle>
                    <AvatarSubtitle>{project?.role}</AvatarSubtitle>
                    <AvatarText>
                        { dateAvatarFormat(project) }
                    </AvatarText>
                </Avatar>
                <div className="axxes-project-item-view__content">
                    {minimizeds.includes(project.id ?? '') ? <span className='axxes-edit-pdf__minimized'>MINIMIZED</span> : null}
                    <div className="axxes-project-item-view__tasks-skills">
                        <span>Tasks: {project?.tasks?.length}</span>
                        <span>Skills: {project?.skills?.length}</span>
                    </div>    
                </div>
            </div>
            <div className='axxes-project-item-view__actions'>
                <VisibilityButton cvDataId={project.id} invisibles={invisibles}/>
                <MinimizeButton cvDataId={project.id} minimizeds={minimizeds}/>
            </div>
        </div>
    );
};
