import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TrainingCenter } from './../../../users/model/trainingCenter';


export interface EditIssuersState {
    editingIssuer?: TrainingCenter
}

export const INITIAL_EDIT_ISSUERS_STATE: EditIssuersState = {
    editingIssuer: undefined,
}

const slice = createSlice({
  name: 'EditIssuers',
  initialState: INITIAL_EDIT_ISSUERS_STATE,
  reducers: {
    setIssuer: (
      state,
      action: PayloadAction<TrainingCenter>,
    ): EditIssuersState => ({ ...state, editingIssuer: action.payload }),
    resetIssuer: (state): EditIssuersState => ({
      ...state,
      editingIssuer: undefined,
    }),
  },
});

export default slice.reducer;

export const { setIssuer, resetIssuer } = slice.actions;