import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    FlexContainer,
    Loading
} from "@axxes/design-system";
import React, { useEffect, useState } from "react";
import {APP_CONFIG} from "../../app/config/config";
import { sortProjects } from "../../common/utils/sorters";
import {toggleMinimizeOfProjectInArray} from "../../common/utils/util";
import Avatar from "../../users/components/Avatar/Avatar";
import AvatarSubtitle from "../../users/components/Avatar/AvatarSubtitle";
import AvatarText from "../../users/components/Avatar/AvatarText";
import AvatarTitle from "../../users/components/Avatar/AvatarTitle";
import UserProjectItem from "../../users/components/UserProjects/UserProjectItem";
import {Project} from "../../users/model";

export interface ShareUserProjectsProps {
    projects?: Project[];
    isLoading?: boolean;
}

export const ShareUserProjects = ({projects, isLoading}: ShareUserProjectsProps) => {
    const [projectsState, setProjectsState] = useState<Project[]>();

    const toggleMinimize = (project: Project) => {
        if (!projectsState) return;
        const newProjects = toggleMinimizeOfProjectInArray(projectsState, project);
        setProjectsState(newProjects);
    }

    useEffect(() => {
        setProjectsState(sortProjects(projects, true));
    }, [projects])

    return (
            projectsState?.length === 0 ? null :
            <Card disableOnPrint={true}>
                <CardHeader>
                    <CardTitle>Projects</CardTitle>
                </CardHeader>
                <CardContent>
                    {isLoading ? (
                        <FlexContainer padding="0" customClasses="axxes-project-view">
                            <div className="axxes-project-view__header">
                                <Avatar photo={APP_CONFIG.DEFAULT_IMAGE} isLoading={isLoading}>
                                    <AvatarTitle>
                                        <Loading width="200px" />
                                    </AvatarTitle>
                                    <AvatarSubtitle>
                                        <Loading width="150px" />
                                    </AvatarSubtitle>
                                    <AvatarText>
                                        <Loading width="150px" />
                                    </AvatarText>
                                </Avatar>
                            </div>
                            <div className="axxes-project-view__content">
                                <Loading height="275px" />
                                <Loading height="50px" />
                                <Loading height="50px" />
                            </div>
                        </FlexContainer>
                    ) : (
                        <div className="axxes-projects-list">
                            {projectsState?.length === 0
                                ? null
                                : projectsState?.map((project: Project) => (
                                    <UserProjectItem
                                        key={project.id}
                                        project={project}
                                        isDeleting={false}
                                        isSaving={false}
                                        toggleMinimize={() => toggleMinimize(project)}
                                        isEditable={false}
                                    />
                                ))}
                        </div>
                    )}
                </CardContent>
            </Card>
    );
}