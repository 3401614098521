import { AxiosError, AxiosResponse } from "axios";
import ErrorHandler from "../../common/api/errorHandler";
import { ErrorResponse } from "../../common/models/errorResponse";

import { HttpFetchParams } from '../../common/models/fetchParams';
import { MultiResponse } from '../../common/models/multiResponse';
import {Client, Course, School, Skill, User} from "../../users/model";
import ClientDTO from "../../users/model/dto-get/clientDTO";
import CourseDTO from "../../users/model/dto-get/courseDTO";
import SchoolDTO from "../../users/model/dto-get/schoolDTO";
import SkillDTO from "../../users/model/dto-get/skillDTO";
import TrainingCenterDTO from "../../users/model/dto-get/trainingCenterDTO";
import UserDTO from "../../users/model/dto-get/userDTO";
import {TrainingCenter} from "../../users/model/trainingCenter";
import {ClientsApiService} from "../../users/services/clientsApiService";
import {CoursesApiService} from "../../users/services/coursesApiService";
import {SchoolsApiService} from "../../users/services/schoolsApiService";
import {SkillsApiService} from "../../users/services/skillsApiService";
import {TrainingCentersApiService} from "../../users/services/trainingCentersApiService";
import {
    fetchClients,
    fetchCourses,
    fetchSchools, fetchSkills,
    fetchTrainingCenters,
    fetchTrainingCourses
} from "../../users/store/usersFacadeService";
import { ManageApiService } from "../services/manageApiService";
import { fetchUsersResponse, fetchUsersResponseFail, fetchUsersResponseSuccess, updateFail } from "./slice";

// ----- USERS
export const fetchUsers = (params?: HttpFetchParams) => (dispatch: any) => {
    dispatch(fetchUsersResponse());
    if(!params || !params.size) {
        params = { ...params, size: 25 };
    }
    ManageApiService.fetchUsers(params).then((response: AxiosResponse<MultiResponse<UserDTO>>) => {
        dispatch(fetchUsersResponseSuccess({ ...response?.data, values: response.data?.values?.map(User.mapFromDTO) }));
    }).catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
            `Failed to fetch users`,
            dispatch,
            fetchUsersResponseFail,
            err
        );
    });
}

// ----- CLIENTS
export const updateClient = (client: Client) => (dispatch: any) => {
    ClientsApiService.putClient(ClientDTO.mapToDTO(client)).then(() => {
        dispatch(fetchClients());
    }).catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
            `Failed to update client ${client?.name}`,
            dispatch,
            updateFail,
            err,
        );
    });
}

export const replaceClient = (oldClient: Client, newClient: Client) => (dispatch: any) => {
    ClientsApiService.replace(oldClient.id || '', newClient.id || '').then(() => {
        dispatch(fetchClients());
    }).catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
            `Failed to replace client ${oldClient?.name} with ${newClient?.name}`,
            dispatch,
            updateFail,
            err,
        );
    });
}

// ----- ISSUERS/TRAININCENTERS
export const updateIssuer = (trainingCenter: TrainingCenter) => (dispatch: any) => {
    TrainingCentersApiService.putTrainingCenter(TrainingCenterDTO.mapToDTO(trainingCenter)).then(() => {
        dispatch(fetchTrainingCenters());
    }).catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
            `Failed to update issuer ${trainingCenter?.name}`,
            dispatch,
            updateFail,
            err,
        );
    });
}

export const replaceIssuer = (oldTrainingCenter: TrainingCenter, newTrainingCenter: TrainingCenter) => (dispatch: any) => {
    TrainingCentersApiService.replace(oldTrainingCenter.id || '', newTrainingCenter.id || '').then(() => {
        dispatch(fetchTrainingCenters());
    }).catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
            `Failed to replace issuer ${oldTrainingCenter?.name} with ${newTrainingCenter?.name}`,
            dispatch,
            updateFail,
            err,
        );
    });
}

// ----- SCHOOLS
export const updateSchool = (school: School) => (dispatch: any) => {
    SchoolsApiService.putSchool(SchoolDTO.mapToDTO(school)).then(() => {
        dispatch(fetchSchools());
    }).catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
            `Failed to update school ${school?.name}`,
            dispatch,
            updateFail,
            err,
        );
    });
}

export const replaceSchool = (oldSchool: School, newSchool: School) => (dispatch: any) => {
    SchoolsApiService.replace(oldSchool.id || '', newSchool.id || '').then(() => {
        dispatch(fetchSchools());
    }).catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
            `Failed to replace school ${oldSchool?.name} with ${newSchool?.name}`,
            dispatch,
            updateFail,
            err,
        );
    });
}

// ----- EDUCATION COURSES
export const updateEducationCourse = (course: Course) => (dispatch: any) => {
    CoursesApiService.putCourse(CourseDTO.mapToDTO(course)).then(() => {
        dispatch(fetchCourses());
    }).catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
            `Failed to update course ${course?.name}`,
            dispatch,
            updateFail,
            err,
        );
    });
}

export const replaceEducationCourse = (oldCourse: Course, newCourse: Course) => (dispatch: any) => {
    CoursesApiService.replaceEducationCourse(oldCourse.id || '', newCourse.id || '').then(() => {
        dispatch(fetchCourses());
    }).catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
            `Failed to replace course ${oldCourse?.name} with ${newCourse?.name}`,
            dispatch,
            updateFail,
            err,
        );
    });
}

// ----- TRAINING COURSES
export const updateTrainingCourse = (course: Course) => (dispatch: any) => {
    CoursesApiService.putTrainingCourse(CourseDTO.mapToDTO(course)).then(() => {
        dispatch(fetchTrainingCourses());
    }).catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
            `Failed to update course ${course?.name}`,
            dispatch,
            updateFail,
            err,
        );
    });
}

export const replaceTrainingCourse = (oldCourse: Course, newCourse: Course) => (dispatch: any) => {
    CoursesApiService.replaceTrainingCourse(oldCourse.id || '', newCourse.id || '').then(() => {
        dispatch(fetchTrainingCourses());
    }).catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
            `Failed to replace course ${oldCourse?.name} with ${newCourse?.name}`,
            dispatch,
            updateFail,
            err,
        );
    });
}

// ----- EDUCATION COURSES
export const updateSkill = (skill: Skill) => (dispatch: any) => {
    SkillsApiService.putSkill(SkillDTO.mapToDTO(skill)).then(() => {
        dispatch(fetchSkills());
    }).catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
            `Failed to update skill ${skill?.name}`,
            dispatch,
            updateFail,
            err,
        );
    });
}

export const replaceSkill = (oldSkill: Skill, newSkill: Skill) => (dispatch: any) => {
    SkillsApiService.replace(oldSkill.id || '', newSkill.id || '').then(() => {
        dispatch(fetchSkills());
    }).catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
            `Failed to replace skill ${oldSkill?.name} with ${newSkill?.name}`,
            dispatch,
            updateFail,
            err,
        );
    });
}

// ----- DANGER ZONE
export const rebuildSearchIndex = () => (dispatch: any) => {
    ManageApiService.rebuildSearchIndex().catch((err: AxiosError<ErrorResponse>) => {
        ErrorHandler.handle(
            `Failed to rebuild search index`,
            dispatch,
            updateFail,
            err,
        );
    });
}
