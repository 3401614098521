import React, { useEffect, useReducer, useRef } from 'react';
import EditOverlay from '../../../common/components/EditOverlay/EditOverlay';
import UserWeblinkEdit from '../../components/UserWeblinks/UserWeblinkEdit';
import UserWeblinkView from '../../components/UserWeblinks/UserWeblinkView';
import { UserLink } from '../../model/userLink';
import userLinkReducer, { setEdit } from './state';

interface UserWeblinkProps {
  userLink: UserLink;
  editable?: boolean;
  setEditable: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserWeblink = ({
  userLink,
  editable = true,
  setEditable,
}: UserWeblinkProps) => {
  const [state, dispatch] = useReducer(
    userLinkReducer,
    { userLink, editing: false },
  );

  const init = useRef<boolean>(false);

  useEffect(() => {
    if (init.current) {
      setEditable(!state.editing);
    }
    init.current = true;
  }, [setEditable, state.editing]);

  return (
    <EditOverlay
      customclass="axxes-link"
      isEditable={editable}
      edit={state.editing}
      setEdit={() => dispatch(setEdit(true))}
      editElement={
        <UserWeblinkEdit
          userLink={userLink}
          state={state}
          dispatch={dispatch}
        />
      }
      viewElement={<UserWeblinkView userLink={userLink} />}
    />
  );
};

export default UserWeblink;
