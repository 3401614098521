import { AxiosError } from "axios";
import { ErrorResponse } from "../models/errorResponse";
import { EventResponse } from "../models/eventResponse";
import { ModalState } from "../models/modals";

export interface Params {
    search: string,
}
export interface FetchState<T> {
    result?: T;
    isLoading?: boolean;
    params?: Params;
    error?: AxiosError<ErrorResponse>;
  }
  
export interface SaveState<T> {
  result?: ResponsePayload<T>;
  isSaving?: boolean;
  error?: AxiosError<ErrorResponse>;
}

export interface ResponsePayload<T> {
  data: T;
  response: EventResponse;
}
export interface CommonState {
    match: string;
    search: string;
    printMode?: boolean;
    modal: ModalState;
    footer?: boolean;
}

export const commonInitialState: CommonState = {
    match: 'TEST',
    search: '',
    printMode: false,
    modal: { open: false },
    footer: true,
}