import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { Config } from '../../../common/api/const';
import { dateFormat } from "../../../common/utils/dates";
import { CVProps } from '../CV';
import {treeToText} from "./richTextUtils";

const styles = StyleSheet.create({
  about_picture: {
    width: 120,
    height: 'auto',
    position: 'absolute',
    left: 36,
    borderRadius: 2,
  },
  about: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    marginLeft: 174,
  },
  h1: {
    fontSize: 23,
    fontWeight: 500,
  },
  h2: {
    fontSize: 14,
  },
  about_card: {
    marginTop: 10,
    backgroundColor: '#F2F5F5',
    borderTop: 1,
    borderColor: '#E9531D',
    paddingHorizontal: 20,
    paddingBottom: 20,
    paddingTop: 10,
    marginBottom: 20,
    position: 'relative',
  },
  icon: {
    fontFamily: 'fontawesome-solid',
  },
  bold: {
    fontWeight: 'medium',
  },
  about_description: {
    paddingTop: 62,
  },
  about_info: {
    position: 'absolute',
    left: 174,
    top: 7,
  },
  birthdate_info: {
    marginTop: 6,
  },
  about_infoLocation: {
    marginBottom: 1,
  }
});

export const About = ({ cv }: CVProps) => {
  return (
    <View wrap={true}>
      <View style={styles.about}>
        <View>
          <Text style={styles.h1}>{cv?.user?.name}</Text>
          <Text style={styles.h2}>{cv?.user?.jobTitle}</Text>
        </View>
      </View>
      <View style={styles.about_card}>
        <View style={styles.about_info}>
          {(cv?.userInfo?.city || cv?.userInfo?.country) && (
            <Text style={styles.about_infoLocation}>
              <Text style={styles.icon}>{''} </Text>
              <Text>
                {' '}
                <Text style={styles.bold}>{cv?.userInfo?.city || ''}</Text>
                {cv?.userInfo?.country &&
                  `${cv?.userInfo?.city && ', '}${cv?.userInfo?.country || ''}`}
              </Text>
            </Text>
          )}
          {cv?.userInfo?.birthDate && (
            <View style={styles.birthdate_info}>
              <Text>
                <Text style={styles.icon}>{''} </Text>
                <Text>
                  {' '}
                  {(cv?.userInfo?.birthDate &&
                    dateFormat(cv?.userInfo?.birthDate, true)) ||
                    ''}
                </Text>
              </Text>
            </View>
          )}
        </View>
        <View wrap={true} style={{ paddingTop: 62 }}>
          {treeToText(cv?.userInfo?.about, styles).map((text) => {
            return text;
          })}
        </View>
      </View>
      <Image
        cache={false}
        style={styles.about_picture}
        src={Config.api! + cv?.userInfo?.pictureUrl}
      />
    </View>
  );
};

export default About;
