import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Skill} from "../../../users/model";


export interface EditSkillsState {
    editingSkill?: Skill
}

export const INITIAL_EDIT_SKILLS_STATE: EditSkillsState = {
    editingSkill: undefined,
}

const slice = createSlice({
  name: 'EditSkills',
  initialState: INITIAL_EDIT_SKILLS_STATE,
  reducers: {
    setSkill: (state, action: PayloadAction<Skill>): EditSkillsState => ({
      ...state,
      editingSkill: action.payload,
    }),
    resetSkill: (state): EditSkillsState => ({
      ...state,
      editingSkill: undefined,
    }),
  },
});

export default slice.reducer;

export const {setSkill, resetSkill} = slice.actions;