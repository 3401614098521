import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Certificate } from "../../model";
import { TrainingCenter } from './../../model/trainingCenter';

export interface CertificateEditState {
    certificate?: Certificate;
    openTrainingCenterForm?: boolean;
    openCourseForm?: boolean;
  }
  
export const INITIAL_CERTIFICATE_EDIT_STATE: CertificateEditState = {
    certificate: new Certificate(),
    openTrainingCenterForm: false,
  };

  const slice = createSlice({
    name: 'CertificateEdit',
    initialState: INITIAL_CERTIFICATE_EDIT_STATE,
    reducers: {
      setCertificate: (
        state,
        action: PayloadAction<Certificate>,
      ): CertificateEditState => ({
        ...state,
        certificate: action?.payload,
      }),
      setCertificateName: (
        state,
        action: PayloadAction<string | undefined>,
      ): CertificateEditState => ({
        ...state,
        certificate: {
          ...state?.certificate,
          name: action?.payload,
        },
      }),
      openTrainingCenterForm: (
        state,
        action: PayloadAction<TrainingCenter>,
      ): CertificateEditState => ({
        ...state,
        certificate: {
          ...state.certificate,
          trainingCenter: action.payload,
        },
        openTrainingCenterForm:
          (!action.payload?.id || !action.payload?.pictureUrl) &&
          action.payload.name !== undefined &&
          action.payload.name.length >= 3,
      }),
    },
  });

  export default slice.reducer;

  export const {
    setCertificate,
    setCertificateName,
    openTrainingCenterForm,
  } = slice.actions;