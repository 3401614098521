import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Language } from '../../model/language';

export interface LanguageEditState {
  language?: Language;
}

export const INITIAL_LANGUAGE_EDIT_STATE: LanguageEditState = {
  language: new Language(),
};

const slice = createSlice({
  name: 'LanguageEdit',
  initialState: INITIAL_LANGUAGE_EDIT_STATE,
  reducers: {
    setLanguage: (state, action: PayloadAction<Language>): LanguageEditState => ({...state, language: action.payload})
  }
})

export default slice.reducer;

export const {setLanguage} = slice.actions;
