import { Button, Dropdown, FlexContainer, Item } from '@axxes/design-system';
import {
  faCheck,
  faTimes,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useReducer, useRef } from 'react';

import { Form } from '../../../common/components/Form/Form';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import { Skill, SkillLevel, SkillLevelType, UserSkill } from '../../model';
import { fetchSkills } from '../../store/usersFacadeService';
import './userSkill.scss';
import userSkillEditReducer, { INITIAL_USER_SKILL_EDIT_STATE, setForm, setUserSkill } from './userSkillEditState';

type UserSkillEditProps = {
  index?: number;
  userSkill?: UserSkill;
  skills?: Skill[];
  levels?: SkillLevelType[];
  removeSkill?: (skill?: UserSkill) => void;
  saveSkill?: (skill?: UserSkill) => void;
  cancelSkill?: () => void;
  isSaving?: boolean;
  isDeleting?: boolean;
};

const UserSkillEdit = ({
  index,
  skills,
  levels,
  userSkill,
  removeSkill,
  cancelSkill,
  saveSkill,
  isSaving,
  isDeleting,
}: UserSkillEditProps) => {
  const reduxDispatch = useAppDispatch();
  const dropdownRef = useRef<HTMLInputElement>();

  const [state, dispatch] = useReducer(
    userSkillEditReducer,
    INITIAL_USER_SKILL_EDIT_STATE,
  );

  const userSkills = useAppSelector((appState) => appState.users.cv?.result?.skills);

  const submit = () => {
    const newUserSkill = index === -1 ? {...state.userSkill, priority: userSkills?.length} : state.userSkill;
    saveSkill?.(newUserSkill);
    if (index === -1) dispatch(setUserSkill(null))
    dropdownRef.current?.focus();
  }

  useEffect(() => {
    dispatch(setUserSkill(userSkill));
    reduxDispatch(fetchSkills());
  }, [userSkill]);

  return (
    <Form
      id={`userSkillForm${index}`}
      form={state.form}
      setForm={(payload) => dispatch(setForm(payload))}
      onSubmit={submit}
    >
      <FlexContainer padding="0" customClasses="axxes-skill-edit">
        <div className="axxes-skill-edit__add">
          <div className="axxes-skill-edit__skill">
            <Dropdown
              reference={dropdownRef}
              autoFocus={true}
              items={
                skills?.map((skill) => ({
                  value: skill?.id || '',
                  name: skill?.name || '',
                })) || []
              }
              autoComplete={true}
              value={{
                value: state?.userSkill?.skillId || '',
                name: state?.userSkill?.name || '',
              }}
              setValue={(item) => {
                dispatch(setUserSkill({
                  ...state.userSkill,
                  name: (item as Item).name,
                  skillId: (item as Item).value.toString(),
                }));
                dropdownRef.current?.focus();
              }}
              title="Skill"
              required={true}
              typeAheadLen={1}
              onKeyDown={(e) => {
                if (e.key === 'Enter') submit();
                if (e.key === 'Escape') cancelSkill?.();
              }}
            />
          </div>
          <div className="axxes-skill-edit__level">
            <Dropdown
              items={
                levels?.map((level: SkillLevelType) => ({
                  value: level || '',
                  name: level || '',
                })) || []
              }
              autoComplete={false}
              value={{
                value: state?.userSkill?.level || '',
                name: state?.userSkill?.level || '',
              }}
              setValue={(item) => {
                const level = (item as Item).name as SkillLevelType;
                dispatch(setUserSkill({ ...state.userSkill, level }))
              }}
              title="Level"
            />
          </div>
        </div>
        <FlexContainer
          padding="0"
          direction="row"
          customClasses="axxes-skill-edit__actions"
        >
          {removeSkill && (
            <Button
              variant="subtle"
              accent={true}
              onClick={() => removeSkill(state.userSkill)}
              disabled={isSaving}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          )}
          {cancelSkill && (
            <Button variant="subtle" onClick={() => cancelSkill()} disabled={isSaving || isDeleting}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          )}

          <Button
            disabled={state?.form?.errors!.length > 0 || isDeleting}
            accent={true}
            type="submit"
            form={`userSkillForm${index}`}
            isSaving={isSaving}
          >
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </FlexContainer>
      </FlexContainer>
    </Form>
  );
};

export default UserSkillEdit;
