import { Item } from '@axxes/design-system';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Course, CourseType, Education, School } from '../../model';

export interface EducationEditState {
  education: Education;
  formSchool: boolean;
  schools: School[];
  courses: Course[];
}

export const INITIAL_EDUCATION_EDIT_STATE: EducationEditState = {
  education: new Education(),
  formSchool: false,
  schools: [],
  courses: [],
};

const slice = createSlice({
  name: 'EducationEdit',
  initialState: INITIAL_EDUCATION_EDIT_STATE,
  reducers: {
    setEducation: (
      state,
      action: PayloadAction<Education>,
    ): EducationEditState => ({
      ...state,
      education: action?.payload,
    }),
    setDropdownSchool: (
      state,
      action: PayloadAction<Item>,
    ): EducationEditState => {
      const newSchool =
        state.schools?.find((school) => school.id === action.payload?.value) ??
        new School();
      if (newSchool.name === '') newSchool.name = action.payload?.name;

      return {
        ...state,
        education: {
          ...state.education,
          school: newSchool,
        },
        formSchool:
          (newSchool!.id === '' || newSchool.pictureUrl === '') &&
          action.payload?.name?.length >= 3,
      };
    },
    setDropdownCourse: (
      state,
      action: PayloadAction<Course>,
    ): EducationEditState => ({
      ...state,
      education: {
        ...state.education,
        course: {
          ...state.education.course,
          ...action.payload,
        },
      },
    }),
    setDropdownCourseType: (
      state,
      action: PayloadAction<CourseType>,
    ): EducationEditState => ({
      ...state,
      education: {
        ...state.education,
        course: {
          ...state.education.course,
          courseType: action?.payload,
        },
      },
    }),
    setCourses: (
      state,
      action: PayloadAction<Course[]>,
    ): EducationEditState => ({ ...state, courses: action.payload }),
    setSchools: (
      state,
      action: PayloadAction<School[]>,
    ): EducationEditState => ({ ...state, schools: action.payload }),
  },
});

export default slice.reducer;

export const {
  setEducation,
  setDropdownSchool,
  setDropdownCourse,
  setDropdownCourseType,
  setCourses,
  setSchools,
} = slice.actions;