import React, {useEffect, useState} from 'react';
import {ROLES} from "../../common/auth/RoleConfig";
import { useAppDispatch, useAppSelector } from '../../common/store/hooks';
import { sortCV } from '../../common/utils/sorters';
import {currentProfileHasAnyRole} from "../../profile/RoleService";
import { DisplayOption, Project } from '../../users/model';
import {fetchUserCV} from "../../users/store/usersFacadeService";
import {updateCvCopy, updateMinimizeds} from "../store/slice";
import EditPdfContainer from "./EditPdfContainer";
import PdfContainer from "./PdfContainer";


export interface UserRoutingProps {
  match?: {
    path?: '/generate/:userId';
    params?: {
      userId?: string;
      modified?: string;
    };
  };
}

export const GenerateContainer = ({ match }: UserRoutingProps) => {
  const reduxDispatch = useAppDispatch();

  const [showPdf, setShowPdf] = useState(false);

  const cv = useAppSelector((s) => s?.users?.cv?.result);
  const user = useAppSelector((state) => state?.profile?.currentProfile?.result);
  const canManagePdf = currentProfileHasAnyRole(user, ROLES.NOT_CONSULTANT)

  const getMinimizedProjects = (projects?: Project[]) => {
    if(!projects) return [];
    const minimized: string[] = [];
    projects.forEach(p => {
      if(p.displayOption === DisplayOption.MINIMIZED && p.id) minimized.push(p.id);
    })
    return minimized;
  }

  useEffect(() => {
    if (
      match?.params?.userId !== 'modified' &&
      match?.params?.modified !== 'modified'
    ) {
      reduxDispatch(fetchUserCV(match?.params?.userId));
    }
  }, [reduxDispatch, match?.params?.modified, match?.params?.userId]);

  useEffect(() => {
    reduxDispatch(
      updateCvCopy(sortCV(cv, canManagePdf))
    );

    if (canManagePdf) {
      reduxDispatch(updateMinimizeds(getMinimizedProjects(cv?.projects)));
    }
  }, [reduxDispatch,cv])

  return !showPdf ? <EditPdfContainer doneEditing={setShowPdf}/> : <PdfContainer backToEditing={setShowPdf}/>;
};
