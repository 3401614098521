import {Card, CardContent, CardTitle} from "@axxes/design-system";
import React, {useEffect} from "react";
import { useAppDispatch, useAppSelector } from "../../../../common/store/hooks";
import {Certificate, UserCV} from "../../../../users/model";
import {SectionTypes} from "../../../models/models";
import {getInvisibles, getInvisibleSections} from "../../../store/selectors";
import {addInvisibleSection, removeInvisibleSection, updateCvCopy} from "../../../store/slice";
import EditorSortableContainer from "../common/EditorSortableContainer";

interface CertificatesViewProps {
    editingCV?: UserCV | undefined,
    onSelect: () => void;
}

export const CertificatesView = ({ editingCV, onSelect }: CertificatesViewProps) => {
    const dispatch = useAppDispatch();
    const invisibles = useAppSelector(getInvisibles);
    const invisbleSections = useAppSelector(getInvisibleSections);

    const updateReorderedCertificates = (certificates: Certificate[]) => {
        const newCV: UserCV = { ...editingCV, certificates };
        dispatch(updateCvCopy(newCV));
      };

    useEffect(() => {
        const visibles: Certificate[] | undefined= editingCV?.certificates?.filter((e:Certificate) => !invisibles.includes(e.id ?? ''));
        if(visibles?.length === 0) {
            if(!invisbleSections.includes(SectionTypes.CERTIFICATES)) dispatch(addInvisibleSection(SectionTypes.CERTIFICATES));
        }
        else {
            dispatch(removeInvisibleSection(SectionTypes.CERTIFICATES))
        }
    }, [invisibles]);

    return (
        <Card>
            <div onClick={onSelect}>
                <CardTitle customClasses={'axxes-edit-pdf-pdf-block-title'}>
                Certificates
                </CardTitle>
            </div>
            <CardContent>
                <EditorSortableContainer
                    items={editingCV?.certificates || []}
                    type={SectionTypes.CERTIFICATES}
                    updateReorderedItems={updateReorderedCertificates}
                />
            </CardContent>
        </Card>);
}
