import {Node} from "slate";
import { Client, Skill } from ".";
import { sortProjectTasks } from "../../common/utils/sorters";
import UserProjectDTO from "./dto-get/userProjectDTO";
import {mapRichTextToObject} from "./map";

export enum DisplayOption {
  STANDARD = "STANDARD",
  MINIMIZED = "MINIMIZED",
}

export class Project {
    id?: string;
    description?: Node[];
    role?: string;
    fromDate?: string;
    toDate?: string;
    client?: Client;
    clientId?: string;
    skills?: Skill[];
    tasks?: string[];
    city?: string;
    province?: string;
    country?: string;
    displayOption?: DisplayOption;
    priority?: number;

    constructor() {
        this.description = [{type: 'paragraph', children: [{ text: '' }]}] as Node[]; // Init empty state for Slate
        this.role = '';
        this.fromDate = '';
        this.toDate = '';
        this.client = new Client();
        this.skills = [];
        this.tasks = [];
        this.displayOption = DisplayOption.STANDARD;
        this.priority = 0;
    }

    static mapFromDTO(projectDTO: UserProjectDTO): Project {
        const desc = mapRichTextToObject(projectDTO?.description);
        const tasks = sortProjectTasks(projectDTO.tasks).map((task) => task.name!);
        return {
            ...projectDTO,
            client: Client.mapFromDTO(projectDTO.client || {}),
            description: desc,
            tasks,
            displayOption: !projectDTO.displayOption ? DisplayOption.STANDARD : projectDTO.displayOption,
        }
    }
}