import UserInfo from "../userInfo";

export default class UserInfoDTO {
    id?: string;
    city?: string;
    province?: string;
    country?: string;
    telephoneNumber?: string;
    primaryLanguage?: string;
    about?: string;
    pictureUrl?: string;
    birthDate?: string;

    static mapToDTO(userInfo: UserInfo) : UserInfoDTO {
        return {
            ...userInfo,
            about: JSON.stringify(userInfo.about || '')
        }
    }
}