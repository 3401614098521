import {FlexContainer, Input} from "@axxes/design-system";
import React from "react";

export interface EditNameFormProps<T> {
    newObj?: T;
    setNewObj?: (value: T) => void;
    open: boolean;
}


export function EditNameForm<T extends {name?: string}>({newObj, setNewObj, open}: EditNameFormProps<T>) {
    return (
        <div className={`axxes-cv-form__add ${open && '-open'}`}>
            <h5 className="axxes-border-title">
                <span className="axxes-border-title__text">Edit</span>
            </h5>
            <FlexContainer
                padding="0"
                direction="row"
                grow={1}
                customClasses="axxes-cv-form"
            >
                <FlexContainer
                    padding="0"
                    direction="column"
                    customClasses="axxes-cv-form__container"
                    grow={1}
                >
                    <Input
                        defaultValue={newObj?.name}
                        onChange={e =>
                            newObj && setNewObj?.({
                                ...newObj,
                                name: e.target.value
                            })
                        }
                    />
                </FlexContainer>
            </FlexContainer>
        </div>
    );}