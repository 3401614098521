import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { MultiResponse } from '../../common/models/multiResponse';
import { User } from '../../users/model';
import { ErrorResponse } from './../../common/models/errorResponse';
import { manageInitialState } from './state';

const slice = createSlice({
  name: 'manage',
  initialState: manageInitialState,
  reducers: {
    setSearchParam: (state, action: PayloadAction<string>) => ({
      ...state,
      search: { query: action.payload },
      usersResponse: {
        ...state.usersResponse,
        result: { ...state.usersResponse?.result, values: [] },
        isLoading: false,
        error: undefined,
      },
    }),
    fetchUsersResponse: (state) => ({
      ...state,
      usersResponse: {
        ...state.usersResponse,
        isLoading: true,
        error: undefined,
      },
    }),
    fetchUsersResponseSuccess: (
      state,
      action: PayloadAction<MultiResponse<User>>,
    ) => {
      let users = action.payload.values || [];
      if (action.payload.current && action.payload.current > 0) {
        users = [...(state.usersResponse?.result?.values || []), ...users];
      }
      return {
        ...state,
        usersResponse: {
          ...state.usersResponse,
          result: {
            ...state.usersResponse?.result,
            ...action.payload,
            values: users,
          },
          isLoading: false,
          error: undefined,
        },
      };
    },
    fetchUsersResponseFail: (state, action: PayloadAction<AxiosError<ErrorResponse>>) => ({
      ...state,
      usersResponse: {
        ...state.usersResponse,
        isLoading: false,
        error: action.payload,
      },
    }),
    updateFail: (state) => state,
  },
});

export default slice.reducer;

export const {
  setSearchParam,
  fetchUsersResponse,
  fetchUsersResponseSuccess,
  fetchUsersResponseFail,
  updateFail,
} = slice.actions;
