import Cookies from 'js-cookie';

export const getGuestToken = () => {
    return Cookies.get('guestJwt');
}

export const getShareClient = () => Cookies.get('shareClient');

export const getShareRequester = () => Cookies.get('shareRequester');

export const getShareCreated = () => Cookies.get('shareCreated');