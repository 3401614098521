import { Item } from '@axxes/design-system';
import { Client, Course, School, Skill } from "../../users/model";
import { TrainingCenter } from "../../users/model/trainingCenter";

export enum ModalTypes {
    DELETE,
    REPLACE,
    INFO,
}

export interface ModalState {
    open: boolean;
    content?: ModalContent,
    type?: ModalTypes,
}

export interface ModalContent
  extends BasicModalContent,
    DeleteContent,
    ReplaceContent,
    InfoContent {}

export interface BasicModalContent {
    onConfirm?: () => void;
    onCancel?: () => void;
    label?: string;
}

export interface DeleteContent extends BasicModalContent {
    name?: string;
}

export interface ReplaceContent extends BasicModalContent {
  onReplace?: (replaceWithId: string) => void;
  objToReplace?: Client | TrainingCenter | School | Course | Skill;
  dropDownItems?: Item[];
}

export interface InfoContent extends BasicModalContent {
  title?: string,
  content?: string | JSX.Element,
}