import { FlexContainer } from '@axxes/design-system';
import React from 'react'
import './share.scss';

function ShareError() {
    return (
        <FlexContainer customClasses='axxes-share-error' grow={1} padding='0'>
            <FlexContainer customClasses='axxes-share-error__container' direction='column' grow={1} padding='0'>
                <h1>Oops</h1>
                <p id='subtitle'>This link is invalid.</p>
                <p>Please request a new one.</p>
            </FlexContainer>
            <img src='/assets/images/Logo_Axxes_short.svg' alt='logo' />
        </FlexContainer>
    )
}

export default ShareError
