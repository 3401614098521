import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { APP_CONFIG } from '../../../app/config/config';
import { trainingDateFormat } from "../../../common/utils/dates";
import {Training} from '../../../users/model';
import { CVProps } from '../CV';
import Avatar from './Avatar';

import { DefaultStyles } from './classes';
import {treeToText} from "./richTextUtils";

const styles = StyleSheet.create({
  ...DefaultStyles,
    avatar_description: {
        width: '92%',
    }
});

export const Trainings = ({ cv, invisibles, minimizeds }: CVProps) => {

    const visibles: Training[] = cv?.trainings ?
        cv?.trainings?.filter((e: Training) => !invisibles.includes(e.id ?? '')) : [];

    return visibles.length > 0 ?
        <View style={styles.card}>
            <Text style={styles.card_title}>Trainings</Text>
            {cv?.trainings?.map((training: Training) => {
                return(
                    <Avatar
                        key={training.id}
                        pictureUrl={
                            training?.trainingCenter?.pictureUrl || APP_CONFIG.DEFAULT_IMAGE
                        }
                        content={
                            <View>
                                <Text style={styles.avatar_title}>{training?.trainingCourse?.name}</Text>
                                <Text>{training?.trainingCenter?.name}</Text>
                                <Text style={styles.avatar_text}>
                                    {trainingDateFormat(training)}
                                </Text>
                                {!minimizeds.includes(training.id ?? '') ?
                                    <View wrap={true} style={[{ flexWrap: 'wrap'}, styles.avatar_description]}>
                                        {treeToText(training?.description, styles).map((text) => {
                                            return text;
                                        })}
                                    </View> : null}
                            </View>
                        }
                    />
                )
            })}
        </View> : <React.Fragment/>;
};

export default Trainings;
