import {Document, Page, StyleSheet} from '@react-pdf/renderer';
import React from 'react';
import {UserCV} from '../../users/model';
import { getPdfElementsForSectionType } from '../generateUtils';
import {Page as CVPage, SectionTypes} from '../models/models'
import {DefaultStyles} from "./PDFElements/classes";
import Footer from './PDFElements/Footer';
import Header from './PDFElements/Header';

export interface CVProps {
  cv?: UserCV;
  invisibles: string[];
  minimizeds: string[];
}

interface MainCVProps extends CVProps {
  pages: CVPage[];
  invisibleSections: SectionTypes[]
}

const styles = StyleSheet.create({
  ...DefaultStyles,
  page: {
    paddingHorizontal: 30,
    paddingVertical: 70,
    fontSize: 10,
    fontFamily: 'objektiv-mk1',
    display: 'flex',
    flexShrink: 1,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

export const CV = ({cv, pages, invisibles, minimizeds, invisibleSections}: MainCVProps) => {

  return (
    <Document
      title={`CV_Preview`}
      author="Axxes"
      subject="CV"
      creator="Axxes CV Tool"
    >
      {pages.map((page, index) => {

        return (page.sections.filter(s => !invisibleSections.includes(s)).length > 0) ?
            (<Page size="A4" style={styles.page} wrap={true} key={`cvPage-${index}`}>
              <Header/>
              <React.Fragment>
                {cv ? (page.sections.map((s: SectionTypes) => getPdfElementsForSectionType(s, invisibles, minimizeds, cv))) : null}
              </React.Fragment>
              <Footer/>
            </Page>) : null
      })}
    </Document>
  );
};
