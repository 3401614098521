import { Button, Card, CardAction, CardContent, CardFooter, CardHeader, CardTitle, FlexContainer } from '@axxes/design-system';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import EditOverlay from '../../../common/components/EditOverlay/EditOverlay';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import { sortCertificates } from '../../../common/utils/sorters';
import { UserRoutingProps } from '../../containers/UserDetailContainer';
import { Certificate } from '../../model';
import { toggleSectionFold } from '../../store/slice';
import { deleteCertificate } from '../../store/usersFacadeService';
import UserCertificateEdit from './UserCertificateEdit';
import UserCertificateView from './UserCertificateView';

interface UserCertificatesProps extends UserRoutingProps {
  certificatesProp?: Certificate[];
}

export const UserCertificates = ({
  certificatesProp,
  match,
}: UserCertificatesProps) => {
  const dispatch = useAppDispatch();

  const printMode = useAppSelector(
    (state) => state?.common?.printMode,
  );

  const certificatesState = useAppSelector(
    (state) => state?.users?.certificates,
  );

  const isSaving = useAppSelector(
    (state) => state?.users?.certificates?.post?.isSaving || state?.users?.certificates?.put?.isSaving || state?.users?.trainingCenters?.post?.isSaving,
  );

  const isDeleting = useAppSelector(
    (state) => state?.users?.certificates?.delete?.isSaving,
  );

  const folded = useAppSelector((state) => state.users.foldedSections.includes('certificates'));

  const [addMode, setAddMode] = useState<boolean>(false);
  const [edits, setEdits] = useState<boolean[]>([]);
  const [mainEdit, setMainEdit] = useState<boolean>(false);

  const removeCertificate = (cert: Certificate) => {
    dispatch(deleteCertificate(cert.id!, match?.params?.userId, printMode));
  };

  const editMode = (index: number, status: boolean) => {
    const newEdits: boolean[] = [...edits];
    newEdits[index] = status;
    setEdits(newEdits);
    const filter: boolean[] = newEdits?.filter((edit) => !!edit);
    setMainEdit(filter?.length > 0);
  };

  const cancelCertificate = () => {
    setAddMode(false);
  };

  useEffect(() => {
    const newEdits: boolean[] = [];
    certificatesProp?.forEach(() => newEdits?.push(false));
    setEdits(newEdits);
  }, [certificatesProp]);


  useEffect(() => {
    if (
      certificatesState?.post?.result ||
      certificatesState?.put?.result ||
      certificatesState?.delete?.result
    ) {
      setMainEdit(false);
      setAddMode(false);
    }
  }, [certificatesState]);

  return (
    <div className={certificatesProp?.length === 0 ? 'hideOnPrint' : ''}>
      <Card>
        <div onClick={() => dispatch(toggleSectionFold('certificates'))}>
          <CardHeader customClasses='axxes-foldable-card__header'>
            <CardTitle>Certificates</CardTitle>
            <CardAction>
              <FontAwesomeIcon icon={folded ? faChevronDown : faChevronUp} />
            </CardAction>
          </CardHeader>
        </div>
        {!folded && (
          <>
            <CardContent customClasses='axxes-foldable-card__content'>
              <FlexContainer customClasses='axxes-cv-element-list' padding='0' direction='column'>
                {certificatesProp?.length === 0 && !addMode
                  ? 'You have not submitted any certificates.'
                  : sortCertificates(certificatesProp).map((certificate: Certificate, index: number) => {
                              return (
                                <EditOverlay
                                  isEditable={(!mainEdit && !addMode)}
                                  edit={edits[index]}
                                  setEdit={(status: boolean) => editMode(index, status)}
                                  key={index}
                                  viewElement={
                                    <UserCertificateView certificate={certificate} />
                                  }
                                  editElement={
                                    <div className="axxes-certificates__edit">
                                      <UserCertificateEdit
                                        isSaving={isSaving}
                                        isDeleting={isDeleting}
                                        index={index}
                                        certificateProp={certificate}
                                        remove={(cert: Certificate) =>
                                          removeCertificate(cert)
                                        }
                                        cancel={() => editMode(index, false)}
                                        creating={false}
                                      />
                                    </div>
                                  }
                                />
                              );
                            })
                }
                {addMode && (
                  <UserCertificateEdit
                    isSaving={isSaving}
                    isDeleting={isDeleting}
                    index={-1}
                    cancel={cancelCertificate}
                    creating={true}
                  />
                )}
              </FlexContainer>
            </CardContent>
            <CardFooter>
              <CardAction>
                {!addMode && !mainEdit && (
                  <div className="axxes-card-actions">
                    <Button
                      variant="ghost"
                      accent={true}
                      onClick={() => {
                        setAddMode(true);
                      }}
                    >
                      Add a certificate
                    </Button>
                  </div>
                )}
              </CardAction>
            </CardFooter>
          </>
        )}
      </Card>
    </div>
  );
};

export default UserCertificates;
