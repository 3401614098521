import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormType } from '../../../common/components/Form/Form';
import { Project } from '../../model';
import { Skill } from '../../model';

export interface Task {
  index?: number;
  task?: string;
}

interface AddSkill {
  open: boolean;
  skill?: Skill;
}

export interface ProjectEditState {
  form?: FormType;
  project?: Project;
  openClientForm?: boolean;
  addSkill?: AddSkill;
  editingTask: Task | null;
  editingTaskForm: FormType;
}

export const INITIAL_PROJECT_EDIT_STATE: ProjectEditState = {
  project: new Project(),
  openClientForm: false,
  addSkill: { open: false, skill: new Skill() },
  editingTask: null,
  editingTaskForm: {
    fields: {
      task: { value: '' },
    },
    errors: [],
  },
};

const slice = createSlice({
  name: 'ProjectEdit',
  initialState: INITIAL_PROJECT_EDIT_STATE,
  reducers: {
    setProject: (state, action: PayloadAction<Project>): ProjectEditState => ({
      ...state,
      project: action.payload,
    }),
    setTasks: {
      reducer: (state, action: PayloadAction<string[]>): ProjectEditState => ({
        ...state,
        project: {
          ...state.project,
          tasks: action.payload,
        },
      }),
      prepare: (tasks: string[]) => {
        const filteredTasks =
          tasks.length > 0 &&
          tasks.filter((task) => task !== null && task !== '');
        return { payload: filteredTasks || [] };
      },
    },
    openProjectClientForm: (
      state,
      action: PayloadAction<boolean>,
    ): ProjectEditState => ({ ...state, openClientForm: action.payload }),
    addSkill: (state, action: PayloadAction<AddSkill>): ProjectEditState => ({
      ...state,
      addSkill: action.payload,
    }),
    setEditingTask: (state, action: PayloadAction<Task | null>): ProjectEditState => ({
      ...state,
      editingTask: action?.payload,
      editingTaskForm: {
        ...state?.editingTaskForm,
        fields: {
          ...state?.editingTaskForm?.fields,
          task: {
            ...state?.editingTaskForm?.fields?.task,
            value: action?.payload?.task,
          },
        },
      },
    }),
    setEditingTaskForm: (
      state,
      action: PayloadAction<FormType>,
    ): ProjectEditState => ({ ...state, editingTaskForm: action.payload }),
  },
});

export default slice.reducer;

export const {
  setProject,
  setTasks,
  openProjectClientForm,
  addSkill,
  setEditingTask,
  setEditingTaskForm,
} = slice.actions;
