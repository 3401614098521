import { LanguageDTO } from './dto-get/languageDTO';

export enum LanguageLevel {
  BASIC = 'BASIC',
  INTERMEDIATE = 'INTERMEDIATE',
  FLUENT = 'FLUENT',
  NATIVE = 'NATIVE',
}

export class Language {
  id?: string;
  language?: string;
  understandingLevel?: LanguageLevel;
  speakingLevel?: LanguageLevel;
  writingLevel?: LanguageLevel;

  constructor() {
    this.id = '';
    this.language = '';
    this.understandingLevel = LanguageLevel.BASIC;
    this.speakingLevel = LanguageLevel.BASIC;
    this.writingLevel = LanguageLevel.BASIC;
  }

  static mapFromDTO(languageDTO: LanguageDTO) {
    return {
      ...languageDTO,
    };
  }
}
