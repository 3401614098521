import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Language } from '../../model/language';

export interface UserLanguagesState {
  addMode?: boolean;
  edits?: boolean[];
  mainEdit?: boolean;
}

export const INITIAL_LANGUAGES_STATE: UserLanguagesState = {
  addMode: false,
  edits: [],
  mainEdit: false,
};

const slice = createSlice({
  name: 'UserLanguages',
  initialState: INITIAL_LANGUAGES_STATE,
  reducers: {
    init: (state, action: PayloadAction<Language[]>): UserLanguagesState => ({
      ...state,
      edits: action?.payload?.map(() => false) || [],
      mainEdit: false,
    }),
    setEdit: (
      state,
      action: PayloadAction<{ index: number; status: boolean }>,
    ): UserLanguagesState => {
      const newEdits = [...(state.edits || [])];
      newEdits[action.payload.index] = action.payload.status;
      return {
        ...state,
        edits: newEdits,
        mainEdit: newEdits.filter((edit) => !!edit).length > 0,
      };
    },
    setAddMode: (
      state,
      action: PayloadAction<boolean>,
    ): UserLanguagesState => ({ ...state, addMode: action.payload }),
    setMainEdit: (
      state,
      action: PayloadAction<boolean>,
    ): UserLanguagesState => ({ ...state, mainEdit: action.payload }),
  },
});

export default slice.reducer;

export const { init, setEdit, setAddMode, setMainEdit } = slice.actions;
