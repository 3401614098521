import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormType } from "../../../common/components/Form/Form";
import { Validation } from "../../../common/utils/validations";
import { UserSkill } from './../../model/userSkill';

export interface UserSkillEditState {
    form: FormType;
    userSkill?: UserSkill;
}

export const INITIAL_USER_SKILL_EDIT_STATE: UserSkillEditState = {
    form: {
        fields: {
            name: { validations: [{ validation: Validation.Required }], value: '' },
            level: { validations: [{ validation: Validation.Required }], value: '' },
        },
        errors: [],
    },
    userSkill: new UserSkill(),
};

const slice = createSlice({
    name: 'UserSkillEdit',
    initialState: INITIAL_USER_SKILL_EDIT_STATE,
    reducers: {
        setForm: (state, action: PayloadAction<FormType>): UserSkillEditState => ({...state, form: action.payload}),
        setUserSkill: (state, action: PayloadAction<UserSkill | null | undefined>): UserSkillEditState => ({
            ...state,
            userSkill: action?.payload || new UserSkill(),
            form: {
                ...state?.form,
                fields: {
                    ...state?.form?.fields,
                    name: { ...state?.form?.fields?.name, value: action?.payload?.name },
                    level: { ...state?.form?.fields?.level, value: action?.payload?.level },
                },
            },
        })
    }
})

export default slice.reducer;

export const {setForm, setUserSkill} = slice.actions;