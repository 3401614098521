import {
  Button,
  Card,
  CardAction,
  CardContent,
  CardHeader,
  CardTitle,
  FlexContainer,
  Loading,
} from '@axxes/design-system';
import { closestCenter, DndContext, DragEndEvent, DragOverlay, DragStartEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { faChevronDown, faChevronUp, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayMoveImmutable } from 'array-move';
import React, { useEffect, useReducer } from 'react';

import { APP_CONFIG } from '../../../app/config/config';
import { ModalTypes } from '../../../common/models/modals';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import { showModal } from '../../../common/store/slice';
import { sortUserSkills } from '../../../common/utils/sorters';
import { toastError } from '../../../common/utils/util';
import { UserRoutingProps } from '../../containers/UserDetailContainer';
import { Skill, SkillLevelType, UserSkill } from '../../model';
import { toggleSectionFold } from '../../store/slice';
import {
  deleteUserSkill,
  postSkill,
  postUserSkill,
  putUserSkill,
  updateUserSkillPriorities,
} from '../../store/usersFacadeService';
import SkillLevelsTable from './SkillLevelsTable';
import UserSkillEdit from './UserSkillEdit';
import UserSkillSortableItem from './UserSkillSortableItem';
import userSkillsReducer, { init, INITIAL_USER_SKILLS_STATE, setAddMode, setDragging, setEditOrder, setMainEdit, setSaveState, setUserSkills } from './userSkillsState';
import UserSkillView from './UserSkillView';

interface UserSkillsProps extends UserRoutingProps {
  userSkillsProp?: UserSkill[];
  isLoading?: boolean;
}

const UserSkills = ({ userSkillsProp, match, isLoading }: UserSkillsProps) => {
  const reduxDispatch = useAppDispatch();

  const [state, dispatch] = useReducer(
    userSkillsReducer,
    INITIAL_USER_SKILLS_STATE,
  );

  const printMode = useAppSelector(
    (appState) => appState?.common?.printMode,
  );
  const folded = useAppSelector((appState) => appState.users.foldedSections.includes("skills"));
  const skillsState = useAppSelector(
    (appState) => appState?.users?.skills,
  );
  const userSkillsState = useAppSelector(
    (appState) => appState?.users?.userSkills,
  );
  const isSaving = useAppSelector(
    (appState) =>
      appState?.users?.userSkills?.post?.isSaving ||
      appState?.users?.userSkills?.put?.isSaving,
  );

  const isDeleting = useAppSelector(
    (appState) => appState?.users?.userSkills?.delete?.isSaving,
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const deleteSkill = (_userSkill: UserSkill) => {
    reduxDispatch(
      showModal(ModalTypes.DELETE, {
        label: 'skill',
        name: _userSkill.name,
        onConfirm: () =>
          reduxDispatch(
            deleteUserSkill(_userSkill.id!, match?.params?.userId, printMode),
          ),
      }),
    );
  };

  const saveUserSkill = (_userSkill?: UserSkill, _isNew?: boolean) => {
    if (!_userSkill) return;
    const exists = userSkillsProp!.findIndex((skill) => skill.name === _userSkill.name);
    if (exists > -1 && _isNew) {
      toastError(`Already added skill ${_userSkill.name}`);
    } else {
      if (_userSkill?.skillId && _userSkill?.name) {
        _isNew
          ? reduxDispatch(
              postUserSkill(_userSkill, match?.params?.userId, printMode),
            )
          : updateUserSkill(_userSkill);
      } else if (_userSkill?.name && !state.saveState?.data) {
        dispatch(setSaveState({ new: _isNew, data: _userSkill }));
        reduxDispatch(postSkill({ name: _userSkill.name } as Skill, printMode));
      }
    }
  };

  const updateUserSkill = (userSkill: UserSkill) => {
    reduxDispatch(
      putUserSkill(userSkill, match?.params?.userId, printMode),
    );
  }

  const cancelUserSkill = () => {
    dispatch(setAddMode(false));
    dispatch(setMainEdit(false));
  };

  const handleSortStart = ({active}: DragStartEvent) => {
    dispatch(setDragging(active.id))
  }

  const handleSortEnd = ({active, over}: DragEndEvent) => {
    dispatch(setDragging(null))
    if (active.id !== over?.id) {
      const oldIndex = state.userSkills?.findIndex((skill: UserSkill) => skill.id === active.id) || 0;
      const newIndex = state.userSkills?.findIndex((skill: UserSkill) => skill.id === over?.id) || 0;
      const reorderedSkills = arrayMoveImmutable<UserSkill>(state.userSkills || [], oldIndex, newIndex);
      dispatch(setUserSkills(reorderedSkills));
    }
  }

  const saveUpdatedOrder = () => {
    reduxDispatch(updateUserSkillPriorities(state.userSkills || [], match?.params?.userId, printMode));
  }

  const cancelReorder = () => {
    dispatch(setEditOrder(false));
    dispatch(setUserSkills(sortUserSkills(userSkillsProp)));
  }

  useEffect(() => {
    if (skillsState?.post?.result) {
      const _userSkill: UserSkill = { ...state.saveState?.data };
      _userSkill.skillId = skillsState.post.result.data.id;
      saveUserSkill(_userSkill, state?.saveState?.new === true);
    }
  }, [skillsState?.post?.result]);

  useEffect(() => {
    dispatch(init(sortUserSkills(userSkillsProp)));
  }, [userSkillsProp]);

  useEffect(() => {
    if (
      userSkillsState?.put?.result ||
      userSkillsState?.post?.result ||
      userSkillsState?.delete?.result
    ) {
      dispatch(setMainEdit(false));
      dispatch(setSaveState(null));
    }
  }, [userSkillsState]);

  return (
    <div className={userSkillsProp?.length === 0 ? 'hideOnPrint' : ''}>
      <div className="axxes-skills__container">
        <Card>
          <div onClick={() => reduxDispatch(toggleSectionFold('skills'))}>
            <CardHeader customClasses='axxes-foldable-card__header'>
              <CardTitle customClasses='axxes-skills-container__title'>
                Skills
                <Button
                  variant="subtle"
                  accent={true}
                  onClick={(event: MouseEvent) => {
                    event.stopPropagation();
                    reduxDispatch(
                      showModal(ModalTypes.INFO, {
                        title: 'Skill levels',
                        content: <SkillLevelsTable />,
                      }),
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Button>
              </CardTitle>
              <CardAction>
                <FontAwesomeIcon icon={folded ? faChevronDown : faChevronUp} />
              </CardAction>
            </CardHeader>
          </div>
          {!folded && <CardContent customClasses='axxes-foldable-card__content'>
            <div className="axxes-skills__card-content">
              <FlexContainer padding="0" customClasses="axxes-skills-container">
                {isLoading ? (
                  <FlexContainer padding="0" grow={1} direction="column">
                    <FlexContainer
                      customClasses="axxes-skill"
                      padding="0"
                      grow={1}
                      direction="row"
                    >
                      <Loading width={'90%'} />
                      <Loading width={'90%'} />
                    </FlexContainer>
                    <FlexContainer
                      customClasses="axxes-skill"
                      padding="0"
                      grow={1}
                      direction="row"
                    >
                      <Loading width={'90%'} />
                      <Loading width={'90%'} />
                    </FlexContainer>
                  </FlexContainer>
                ) : state?.userSkills?.length === 0 && !state.addMode ? (
                  'You have not submitted any skills.'
                ) : (
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleSortStart}
                    onDragEnd={handleSortEnd}
                  >
                    <SortableContext items={state.userSkills?.map(skill => ({id: skill.id || ''})) || []}>
                      {state?.userSkills?.map(
                        (userSkill: UserSkill, index: number) => (
                          <UserSkillSortableItem
                            key={index}
                            state={state}
                            listIndex={index}
                            dispatch={dispatch}
                            userSkill={userSkill}
                            skillsState={skillsState}
                            deleteUserSkill={deleteSkill}
                            saveUserSkill={saveUserSkill}
                            isSaving={isSaving}
                            isDeleting={isDeleting}
                          />
                        ),
                      )}
                      <DragOverlay className='axxes-skill--dragging'>
                        {state.draggingId ? (
                          <UserSkillView
                            editOrder={true}
                            userSkill={state.userSkills?.filter(
                              (userSkill: UserSkill) =>
                                userSkill.id === state.draggingId,
                            )[0] || new UserSkill()}
                          />
                        ) : null}
                      </DragOverlay>
                    </SortableContext>
                  </DndContext>
                )}
                {state.addMode && (
                  <FlexContainer
                    padding="0"
                    customClasses="axxes-skills-container__new"
                  >
                    <UserSkillEdit
                      index={-1}
                      skills={skillsState?.fetch?.result}
                      levels={
                        Object.keys(APP_CONFIG.SKILL_LEVEL) as SkillLevelType[]
                      }
                      saveSkill={(_userSkill?: UserSkill) =>
                        saveUserSkill(_userSkill, true)
                      }
                      cancelSkill={() => cancelUserSkill()}
                      isSaving={isSaving}
                      isDeleting={isDeleting}
                    />
                  </FlexContainer>
                )}
              </FlexContainer>

              {!state.addMode && !state.mainEdit && (
                <div className="axxes-card-actions">
                  {state.editOrder && (
                    <>
                      <Button
                        variant='ghost'
                        onClick={cancelReorder}
                      >
                        Cancel
                      </Button>
                      <Button
                        accent={true}
                        onClick={() => {
                            dispatch(setEditOrder(false));
                            saveUpdatedOrder();
                          }
                        }
                      >
                        Save
                      </Button>
                    </>
                  )}
                  {!state.editOrder && (
                    <>
                      {state.userSkills && state?.userSkills?.length > 1 && <Button
                        variant="subtle"
                        onClick={() =>
                          dispatch(setEditOrder(true))
                        }
                        isSaving={isSaving}
                      >
                        Edit order
                      </Button>}
                      <Button
                        variant="ghost"
                        accent={true}
                        onClick={() =>
                          dispatch(setAddMode(true))
                        }
                      >
                        Add a skill
                      </Button>
                    </>
                  )}
                </div>
              )}
            </div>
          </CardContent>}
        </Card>
      </div>
    </div>
  );
};

export default UserSkills;
