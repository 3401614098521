import React from 'react';
import { Node } from 'slate';
import { getLengthOfNodeArr } from '../common/utils/util';
import AboutMeView from "../users/components/About/AboutMeView";
import {UserCV} from "../users/model";
import {CertificatesView} from "./components/PdfEditor/CertificatesView/CertificatesView";
import {EducationsView} from "./components/PdfEditor/EducationsView/EducationsView";
import {LanguagesView} from "./components/PdfEditor/LanguagesView/LanguagesView";
import {ProjectsView} from "./components/PdfEditor/ProjectsView/ProjectsView";
import {SkillsView} from "./components/PdfEditor/SkillsView/SkillsView";
import {TrainingsView} from "./components/PdfEditor/TrainingsView/TrainingsView";
import {WeblinksView} from "./components/PdfEditor/WeblinksView/WeblinksView";
import About from "./components/PDFElements/About";
import Certificates from "./components/PDFElements/Certificates";
import Educations from "./components/PDFElements/Educations";
import Languages from "./components/PDFElements/Languages";
import Projects from "./components/PDFElements/Projects";
import Skills from "./components/PDFElements/Skills";
import Trainings from "./components/PDFElements/Trainings";
import Weblinks from "./components/PDFElements/Weblinks";
import {Page, SectionTypes} from "./models/models";


export const swapSections = (selectedSection: SectionTypes, pages: Page[], direction: number): Page[] => {
    const oldPage: Page | undefined = pages.find((p: Page) => p.sections.includes(selectedSection));
    if(oldPage) {
        const oldIndex: number = oldPage.sections.indexOf(selectedSection);
        const newIndex: number = oldIndex + direction;
        if(newIndex >= 0 && newIndex < oldPage.sections.length) {
            const affectedSection: SectionTypes = oldPage.sections[newIndex];
            if(affectedSection === SectionTypes.ABOUT_ME || affectedSection === SectionTypes.WEBLINKS) {
                return [];
            }
            const newSections: SectionTypes[] = oldPage.sections;
            newSections.splice(newIndex, 1, selectedSection);
            newSections.splice(oldIndex, 1, affectedSection);
            const newPages: Page[] = pages.map((p:Page) => {
                if(p.pageNumber === oldPage.pageNumber) {
                    if(p.pageNumber === 1) {
                        return {
                            sections: [SectionTypes.ABOUT_ME].concat(newSections.filter((s: SectionTypes) => s !== SectionTypes.ABOUT_ME)),
                            pageNumber: oldPage.pageNumber
                        }
                    } else if(p.pageNumber === pages.length) {
                        return {
                            sections: newSections.filter((s: SectionTypes) => s !== SectionTypes.WEBLINKS).concat([SectionTypes.WEBLINKS]),
                            pageNumber: oldPage.pageNumber
                        }
                    } else {
                        return ({
                            sections: newSections,
                            pageNumber: oldPage.pageNumber
                        });
                    }
                } else {
                    return p;
                }
            });
            return newPages;
        } else {
            return pages;
        }
    } else {
        return pages;
    }
}

export const moveToOtherPage = (selectedSection: SectionTypes, pages: Page[], direction: number): Page[] => {
    const fromPage:Page | undefined = pages.find((p: Page) => p.sections.includes(selectedSection));
    if(fromPage) {
        const newPageNr: number = fromPage.pageNumber+direction;
        if(newPageNr > 0 && newPageNr < (pages.length+1)) {
            const newPages:Page[] = pages.map((p: Page) => {
                if(p.pageNumber === newPageNr) {
                    const nPage: Page = {
                        sections: direction === 1 ? [selectedSection, ...p.sections] : [...p.sections, selectedSection],
                        pageNumber: p.pageNumber 
                    };
                    return nPage;
                } else if(p.pageNumber === fromPage.pageNumber) {
                    const nPage: Page = {
                        sections: p.sections.filter((s: SectionTypes) => s !== selectedSection),
                        pageNumber: p.pageNumber
                    };
                    return nPage;
                } else {
                    return p;
                }
            });
            return newPages;
        } else {
            return pages;
        }
    } else {
        return pages;
    }
}

export const createNewPage = (selectedSection: SectionTypes, pages: Page[]): Page[] => {
    const selectedPage: Page | undefined = pages.find((p: Page) => p.sections.includes(selectedSection));
    if(selectedPage) {
        const nPage: Page = {
            sections: [],
            pageNumber: selectedPage.pageNumber + 1
        }
        const nPageIndex: number = pages.findIndex((p: Page) => p.pageNumber === selectedPage.pageNumber + 1);
        const newPages: Page[] = pages.map((p: Page) => (
            {
                ...p,
                pageNumber: p.pageNumber > selectedPage.pageNumber ? p.pageNumber+1 : p.pageNumber
            }
        ));
        newPages.splice(nPageIndex, 0, nPage);
        return newPages;
    } else {
        return pages;
    }
}

export const getPdfElementsForSectionType = (sectionType: SectionTypes,  invisibles: string [], minimizeds: string [], usercv?: UserCV): JSX.Element => {
    switch(sectionType) {
        case SectionTypes.ABOUT_ME:
          return (<About key={sectionType} cv={usercv} invisibles={invisibles} minimizeds={minimizeds}/>);
        case SectionTypes.PROJECTS:
          return <Projects key={sectionType} cv={usercv} invisibles={invisibles} minimizeds={minimizeds}/>;
        case SectionTypes.EDUCATIONS:
          return <Educations key={sectionType} cv={usercv} invisibles={invisibles} minimizeds={minimizeds}/>;
        case SectionTypes.SKILLS:
          return <Skills key={sectionType} cv={usercv} invisibles={invisibles} minimizeds={minimizeds}/>;
        case SectionTypes.TRAININGS:
          return <Trainings key={sectionType} cv={usercv} invisibles={invisibles} minimizeds={minimizeds}/>;
        case SectionTypes.CERTIFICATES:
          return <Certificates key={sectionType} cv={usercv} invisibles={invisibles} minimizeds={minimizeds}/>;
        case SectionTypes.LANGUAGES:
          return <Languages key={sectionType} cv={usercv} invisibles={invisibles} minimizeds={minimizeds}/>;
        case SectionTypes.WEBLINKS:
          return <Weblinks key={sectionType} cv={usercv} invisibles={invisibles} minimizeds={minimizeds}/>;
        default: return <React.Fragment/>
    }
}

export const getEditorElementsForSectionType = (sectionType: SectionTypes, onSelectHandler: (s: SectionTypes) => void, editingCv?: UserCV): React.ReactNode => {
    switch(sectionType) {
        case SectionTypes.ABOUT_ME:
            return <AboutMeView userInfo={editingCv?.userInfo} user={editingCv?.user} isLoading={false}/>;
        case SectionTypes.PROJECTS:
            return <ProjectsView editingCV={editingCv} onSelect={() => onSelectHandler(sectionType)}/>;
        case SectionTypes.EDUCATIONS:
            return <EducationsView editingCV={editingCv} onSelect={() => onSelectHandler(sectionType)}/>;
        case SectionTypes.SKILLS:
            return <SkillsView  editingCV={editingCv} onSelect={() => onSelectHandler(sectionType)}/>;
        case SectionTypes.TRAININGS:
            return <TrainingsView  editingCV={editingCv} onSelect={() => onSelectHandler(sectionType)}/>;
        case SectionTypes.CERTIFICATES:
            return <CertificatesView  editingCV={editingCv} onSelect={() => onSelectHandler(sectionType)} />;
        case SectionTypes.LANGUAGES:
            return <LanguagesView editingCV={editingCv} onSelect={() => onSelectHandler(sectionType)}/>;
        case SectionTypes.WEBLINKS:
            return <WeblinksView editingCV={editingCv} onSelect={() => onSelectHandler(sectionType)}/>;
        default: return <React.Fragment/>
    }
}

export const formatDescriptionSummary = (description?: Node[]) => {
    const length = getLengthOfNodeArr(description);
    if (length > 0) {
        return `Description: ${length} characters`;
    }
    return 'No description';
}
