import {Button} from "@axxes/design-system";
import {faAngleDoubleDown, faAngleDoubleUp, faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import './UtilButtons.scss';

type SortingButtonProps = {
    swap: (dir: number) => void,
    jump: (dir: number) => void
}
export const SortingButtonGroup = ({swap, jump}: SortingButtonProps) => {

    return (
        <div className={'axxes-edit-pdf-sorting-column'}>
            <div className={'axxes-edit-pdf-sorting-column-within'}>
                <Button
                    variant={"ghost"}
                    customClasses={'axxes-edit-pdf-sorting-button'}
                    accent={true}
                    onClick={() => swap(-1)}
                ><FontAwesomeIcon icon={faAngleUp} /></Button>
                <div className={"axxes-edit-pdf-sorting-label"}>within page</div>
                <Button
                    variant={"ghost"}
                    customClasses={'axxes-edit-pdf-sorting-button'}
                    accent={true}
                    onClick={() => swap(1)}
                ><FontAwesomeIcon icon={faAngleDown} /></Button>
            </div>
            <div className={'axxes-edit-pdf-sorting-column-within'}>
                <Button
                    variant={"ghost"}
                    customClasses={'axxes-edit-pdf-sorting-button'}
                    accent={true}
                    onClick={() => jump(-1)}
                ><FontAwesomeIcon icon={faAngleDoubleUp} /></Button>
                <div className={"axxes-edit-pdf-sorting-label"}>to other page</div>
                <Button
                    variant={"ghost"}
                    customClasses={'axxes-edit-pdf-sorting-button'}
                    accent={true}
                    onClick={() => jump(1)}
                ><FontAwesomeIcon icon={faAngleDoubleDown} /></Button>
            </div>
        </div>
    )
}
