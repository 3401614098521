import {Card, CardContent, CardHeader, CardTitle, FlexContainer} from "@axxes/design-system";
import React from "react";
import UserWeblinkView from "../../users/components/UserWeblinks/UserWeblinkView";
import {UserLink} from "../../users/model/userLink";

export interface ShareWeblinksProps {
    userLinks?: UserLink[];
}

export const ShareWeblinks = ({userLinks}: ShareWeblinksProps) => {
    return (
        userLinks?.length === 0 ? null :
            <Card>
                <CardHeader>
                    <CardTitle>Weblinks</CardTitle>
                </CardHeader>
                <CardContent>
                    <FlexContainer padding="0" direction="row" customClasses="axxes-links">
                        {userLinks?.length === 0
                            ? null
                            : userLinks?.map((userLink: UserLink) => {
                                return (
                                    <UserWeblinkView userLink={userLink} key={userLink.id}/>
                                );
                            })}
                    </FlexContainer>
                </CardContent>
            </Card>
    );
}