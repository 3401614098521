import {
  Button,
  Card,
  CardAction,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  FlexContainer,
  Loading,
} from '@axxes/design-system';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { APP_CONFIG } from '../../../app/config/config';

import EditOverlay from '../../../common/components/EditOverlay/EditOverlay';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import { sortEducations } from '../../../common/utils/sorters';
import { UserRoutingProps } from '../../containers/UserDetailContainer';
import { Education } from '../../model';
import { toggleSectionFold } from '../../store/slice';
import { fetchCourses, fetchSchools } from '../../store/usersFacadeService';
import Avatar from '../Avatar/Avatar';
import AvatarSubtitle from '../Avatar/AvatarSubtitle';
import AvatarText from '../Avatar/AvatarText';
import AvatarTitle from '../Avatar/AvatarTitle';
import UserEducationsEdit from './UserEducationsEdit';
import UserEducationsView from './UserEducationsView';

interface UserEducationProps extends UserRoutingProps {
  educations?: Education[];
  isLoading?: boolean;
}

export const UserEducations = ({
  educations,
  isLoading,
}: UserEducationProps) => {
  const reduxDispatch = useAppDispatch();

  const educationsState = useAppSelector(
    (_state) => _state?.users?.educations,
  );
  const folded = useAppSelector((_state) => _state.users.foldedSections.includes('education'));

  const [editIndex, setEditIndex] = useState<number | null>(null);
  const isCreating = editIndex === -1;

  const toggleEdit = (index: number) => {
    editIndex === null ? setEditIndex(index) : setEditIndex(null);
  };

  const schools =
    useAppSelector((_state) => _state?.users?.schools?.fetch?.result) ??
    [];

  const courses =
    useAppSelector((_state) => _state?.users?.courses?.fetch?.result) ??
    [];

  const isSaving =
    educationsState?.post?.isSaving || educationsState?.put?.isSaving || false;
  const isDeleting = educationsState?.delete?.isSaving || false;

  useEffect(() => {
    reduxDispatch(fetchSchools());
    reduxDispatch(fetchCourses());
  }, [reduxDispatch]);

  useEffect(() => {
    if (educationsState?.post?.result) {
      setEditIndex(null);
    }
  }, [educationsState?.post?.result]);

  useEffect(() => {
    if (educationsState?.put?.result) {
      setEditIndex(null);
    }
  }, [educationsState?.put?.result]);

  useEffect(() => {
    if (educationsState?.delete?.result) {
      setEditIndex(null);
    }
  }, [educationsState?.delete?.result]);

  return (
    <div className={educations?.length === 0 ? 'hideOnPrint' : ''}>
      <Card>
        <div
          onClick={() =>
            reduxDispatch(toggleSectionFold('education'))
          }
        >
          <CardHeader customClasses="axxes-foldable-card__header">
            <CardTitle>Education</CardTitle>
            <CardAction>
              <FontAwesomeIcon icon={folded ? faChevronDown : faChevronUp} />
            </CardAction>
          </CardHeader>
        </div>
        {!folded && (
          <>
            <CardContent customClasses='axxes-foldable-card__content'>
              {isLoading ? (
                <Avatar photo={APP_CONFIG.DEFAULT_IMAGE} isLoading={isLoading}>
                  <AvatarTitle>
                    <Loading width="400px" />
                  </AvatarTitle>
                  <AvatarSubtitle>
                    <Loading width="300px" />
                  </AvatarSubtitle>
                  <AvatarText>
                    <Loading width="300px" />
                  </AvatarText>
                </Avatar>
              ) : (
                <FlexContainer customClasses='axxes-cv-element-list' padding='0' direction='column'>
                  {educations?.length === 0 && !isCreating
                    ? 'You have not submitted any educations.'
                    : sortEducations(educations)
                        .map((education: Education, index: number) => {
                          return (
                            <EditOverlay
                              isEditable={editIndex === null}
                              edit={editIndex === index}
                              setEdit={() => toggleEdit(index)}
                              key={index}
                              viewElement={<UserEducationsView education={education} />}
                              editElement={
                                <div className="axxes-educations__edit">
                                  <UserEducationsEdit
                                    schools={schools}
                                    courses={courses}
                                    isSaving={isSaving}
                                    isDeleting={isDeleting}
                                    index={index}
                                    education={education}
                                    cancel={() => toggleEdit(index)}
                                    creating={false}
                                  />
                                </div>
                              }
                            />
                          );
                        })}

                  {isCreating && (
                    <UserEducationsEdit
                      schools={schools}
                      courses={courses}
                      isSaving={isSaving}
                      isDeleting={isDeleting}
                      index={-1}
                      cancel={() => toggleEdit(-1)}
                      creating={true}
                      education={new Education()}
                    />
                  )}
                </FlexContainer>
              )}
            </CardContent>
            <CardFooter>
              <CardAction>
                {!isCreating && editIndex === null && (
                  <div className="axxes-card-actions">
                    <Button
                      variant="ghost"
                      accent={true}
                      onClick={() => toggleEdit(-1)}
                    >
                      Add an education
                    </Button>
                  </div>
                )}
              </CardAction>
            </CardFooter>
          </>
        )}
      </Card>
    </div>
  );
};

export default UserEducations;
