import { Button, CardContent, CardFooter, CardTitle } from '@axxes/design-system'
import React from 'react'
import { InfoContent } from '../../models/modals';

type InfoModalProps = {
    content?: InfoContent;
    onClose?: () => void;
}

function InfoModal({content, onClose}: InfoModalProps) {
    return (
      <div>
        {content?.title && <CardTitle>{content.title}</CardTitle>}
        {content?.content && (
          <CardContent customClasses="delete-modal__content">
            {typeof content.content === 'string' ? <p>{content.content}</p> : content.content}
          </CardContent>
        )}
        <CardFooter customClasses="delete-modal__buttons">
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </CardFooter>
      </div>
    );
}

export default InfoModal
