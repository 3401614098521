import { Button, FlexContainer } from '@axxes/design-system';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faEllipsisV, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type TaskViewProps = {
  task: string;
  setEdit: () => void;
  isDragDisabled?: boolean;
};

function TaskView({ task, setEdit, isDragDisabled }: TaskViewProps) {

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    isDragging,
  } = useSortable({id: task, disabled: isDragDisabled});

  const style = {
    transform: CSS.Transform.toString(transform),
  };

  return (
    <div 
      className={`task-item ${!isDragging ? isDragDisabled ? 'task-item__not-draggable' : 'task-item__draggable' : ''}`}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <FlexContainer direction='row' padding='0'>
        <div className='task-item__drag-icon'>
          <FontAwesomeIcon icon={faEllipsisV}/>
        </div>
        <div id="task">{task}</div>
      </FlexContainer>
      <Button variant="subtle" accent={true} onClick={setEdit}>
        <FontAwesomeIcon icon={faPen} />
      </Button>
    </div>
  )
}

export default TaskView;
