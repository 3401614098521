import {AxiosResponse} from "axios";
import {Api} from "../common/api/const";
import AppInfo from "./model/AppInfo";
import AppInfoDto from "./model/AppInfoDto";
import {updateAppInfo} from "./store/slice";

export const fetchAppInfo = () => (dispatch: any) => {
    AppInfoService.doFetchAppInfo()
        .then((response: AxiosResponse<AppInfoDto>) => {
            dispatch(updateAppInfo(AppInfo.mapFromDto(response.data)));
        });
};

class AppInfoService {

    static doFetchAppInfo = () => Api().get('/actuator/info')

}