import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { Config } from '../../common/api/const';
import { User } from '../../users/model/user';
import './grid.scss';

type UserRowProps = {
  user: User;
};
export const UserRow = ({ user }: UserRowProps) => {
  return (
    <Link className="axxes-manage-users__item" to={`/users/${user.id}`}>
      <div className="axxes-manage-users__picture">
        <img
          loading="lazy"
          src={`${Config.api}/users/${user.id}/picture/small`}
          alt="profile"
          onError={(e) =>
            (e.currentTarget.src = '/profile-grey.png')
          }
        />
      </div>
      <div className="axxes-manage-users__name">{user.name}</div>
      <div className="axxes-manage-users__jobTitle">{user.jobTitle}</div>
      <div className="axxes-manage-users__cv">
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </Link>
  );
};

export default UserRow;
