import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import {rolesContainAnyRoleOf} from "../../profile/RoleService";
import { AppState } from "../store";

interface AdminRouteProps extends Omit<RouteProps, "component"> {
  component: React.ElementType;
  currentUserRoles?: string[];
  accessibleBy: any[];
}

const AdminRoute: React.FC<AdminRouteProps> = ({
  component: Component,
  currentUserRoles,
  accessibleBy,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        const canManage = rolesContainAnyRoleOf(currentUserRoles || [], accessibleBy)
        if (canManage) {
          return <Component isAdmin={canManage} {...routeProps} />;
        } else {
          return <Redirect to="/" />
        }
      }}
    />
  );
};

const mapStateToProps = (state: AppState) => {
  return {
      currentUserRoles: state.profile?.currentProfile?.result?.roles,
  };
};

export default connect(mapStateToProps)(AdminRoute);
