import {Card, CardContent, CardHeader, CardTitle, FlexContainer} from "@axxes/design-system";
import React from "react";
import { sortCertificates } from "../../common/utils/sorters";
import UserCertificateView from "../../users/components/UserCertificates/UserCertificateView";
import {Certificate} from "../../users/model";

export interface ShareCertificatesProps {
    certificates?: Certificate[];
}

export const ShareCertificates = ({certificates}: ShareCertificatesProps) => {

    return (
            certificates?.length === 0 ? null :
            <Card>
                <CardHeader>
                    <CardTitle>Certificates</CardTitle>
                </CardHeader>
                <CardContent>
                    <FlexContainer customClasses='axxes-cv-element-list' padding='0' direction='column'>
                        {certificates?.length === 0
                            ? null
                            : sortCertificates(certificates).map((certificate: Certificate, index: number) => {
                                    return (
                                        <UserCertificateView certificate={certificate} key={certificate.id}/>
                                    );
                                })
                        }
                    </FlexContainer>
                </CardContent>
            </Card>
    );
}