import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { UserLink } from '../../../users/model/userLink';
import { CVProps } from '../CV';
import { DefaultStyles} from './classes';

const styles = StyleSheet.create({
  ...DefaultStyles,
  weblinks_view: {
    width: 'auto',
    marginRight: 8
  },
  weblinks_container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  weblinks_icon: {
    fontSize: 25,
    width: 40,
    textAlign: 'center',
  },
  weblinks_name: {
    fontWeight: 'medium',
  },
  weblinks_link: {
    fontWeight: 'light',
    fontSize: 7,
  },
});

export const Weblinks = ({ cv }: CVProps) => {

  return <>
    {cv?.links?.length !== 0
    ? <View wrap={false}>
          <Text style={styles.card_title}>Weblinks</Text>
          <View style={styles.card_content_row}>
            {cv?.links?.map((link: UserLink) => {
              const getUnicodeGlyph = (icon?: IconProp): string => {
                switch (icon) {
                  case 'globe':
                    return '';
                  case 'linkedin':
                    return '';
                  case 'home':
                    return '';
                  case 'info':
                    return '';
                  case 'newspaper':
                    return '';
                  case 'github':
                    return '';
                  default:
                    return '';
                }
              };

              const getIconFamily = (icon: string = 'globe') => {
                const brand = { fontFamily: 'fontawesome-brands' };
                const solid = { fontFamily: 'fontawesome-solid' };
                const brands: string[] = ['linkedin', 'github'];

                const style = brands.includes(icon) ? brand : solid;
                return style;
              };

              return (
                  <View key={link.id} style={styles.weblinks_view}>
                    <Link src={link.link!} style={[styles.link, { width: '100%' }]}>
                      <View style={styles.weblinks_container}>
                        <Text style={[styles.weblinks_icon, getIconFamily(link.icon)]}>
                          {getUnicodeGlyph(link.icon as IconProp)}
                        </Text>
                        <View>
                          <Text style={styles.weblinks_name}>{link.name}</Text>
                          <Text style={styles.weblinks_link}>{link.link}</Text>
                        </View>
                      </View>
                    </Link>
                  </View>
              );
            })}
          </View>
        </View>
    : null}
  </>
};

export default Weblinks;
