import {Button} from "@axxes/design-system";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import { useAppDispatch } from "../../../../common/store/hooks";
import {updateInvisibles} from "../../../store/slice";
import "./UtilButtons.scss"

type VisibilityButtonProps = {
    cvDataId?: string
    invisibles: string[]
}
export const VisibilityButton = ({cvDataId, invisibles}: VisibilityButtonProps) => {
    const dispatch = useAppDispatch();
    const [isInvisible, setIsInvisible] = useState<boolean>(false);

    useEffect(() => {
        if(cvDataId) setIsInvisible(invisibles.includes(cvDataId));
    }, [cvDataId, invisibles]);

    const changeVisibility = () => {
        if(cvDataId) {
            let newInvisbles: string[] = [];
            if (isInvisible) {
                newInvisbles = invisibles.filter((s: string) => s !== cvDataId);
            } else {
                newInvisbles = [...invisibles, cvDataId];
            }
            dispatch(updateInvisibles(newInvisbles));
        }
    }

    return (
        <Button
            variant={"ghost"}
            onClick={changeVisibility}
            customClasses={"axxes-visibility-minimization-button"}
        >
            <FontAwesomeIcon icon={isInvisible ?  faEyeSlash : faEye} />
        </Button>
    )
}
