import {Card, CardContent, CardTitle, FlexContainer} from "@axxes/design-system";
import React, {useEffect} from "react"
import { useAppDispatch, useAppSelector } from "../../../../common/store/hooks";
import {UserCV, UserSkill} from "../../../../users/model";
import {SectionTypes} from "../../../models/models";
import {getInvisibles, getInvisibleSections} from "../../../store/selectors";
import {addInvisibleSection, removeInvisibleSection} from "../../../store/slice";
import './skillsView.scss'
import {SkillsViewItem} from "./SkillsViewItem";

interface SkillsViewProps {
    editingCV?: UserCV;
    onSelect: () => void;
}

export const SkillsView = ({editingCV, onSelect}: SkillsViewProps) => {
    const dispatch = useAppDispatch();
    const invisibles = useAppSelector(getInvisibles);
    const invisbleSections = useAppSelector(getInvisibleSections);
    useEffect(() => {
        const visibles: UserSkill[] | undefined= editingCV?.skills?.filter((e:UserSkill) => !invisibles.includes(e.id ?? ''));
        if(visibles?.length === 0) {
            if(!invisbleSections.includes(SectionTypes.SKILLS)) dispatch(addInvisibleSection(SectionTypes.SKILLS));
        }
        else {
            dispatch(removeInvisibleSection(SectionTypes.SKILLS))
        }
    }, [invisibles]);

    return (
        <Card>
            <div onClick={onSelect}>
                <CardTitle customClasses={'axxes-edit-pdf-pdf-block-title'}>
                    Skills
                </CardTitle>
            </div>
            <CardContent>
                <FlexContainer
                    padding={'0'}
                    customClasses={'axxes-edit-pdf-skill'}
                >
                    {editingCV?.skills?.map(s => <SkillsViewItem key={s.id} invisibles={invisibles} skill={s}/>)}
                </FlexContainer>
            </CardContent>
        </Card>
    )
}
