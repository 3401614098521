import { Education } from "..";

export default class UserEducationDTOPut {
    id?: string;
    toDate?: string | null;
    fromDate?: string | null;
    description?: string;
    courseId?: string;
    schoolId?: string;
    certificateUrl?: string

    static mapToDTO(education: Education): UserEducationDTOPut {
        return {
            toDate: education?.toDate,
            fromDate: education?.fromDate,
            description: JSON.stringify(education?.description || ''),
            courseId: education?.course?.id,
            schoolId: education?.school?.id,
            certificateUrl: education.certificateUrl
        }
    }
}