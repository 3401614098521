import {Button, FlexContainer} from "@axxes/design-system";
import { faCogs, faFile, faHistory } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {ROLES} from "../../common/auth/RoleConfig";
import { useAppDispatch, useAppSelector } from "../../common/store/hooks";
import { sortCV } from "../../common/utils/sorters";
import {currentProfileHasAnyRole} from "../../profile/RoleService";
import {SortingButtonGroup} from "../components/PdfEditor/common/SortingButtonGroup";
import {createNewPage, getEditorElementsForSectionType, moveToOtherPage, swapSections} from "../generateUtils";
import { SectionTypes } from "../models/models";
import { getCvCopy, getPages, getSelectedSection } from "../store/selectors";
import { resetGeneratorPages, updateCvCopy, updateGeneratorPages, updateSelectedSection } from "../store/slice";
import './editPdfContainer.scss';

export interface EditPdfContainerProps {
    doneEditing: (val: boolean) => void,
}

export const EditPdfContainer = ({doneEditing}: EditPdfContainerProps) => {
    const dispatch = useAppDispatch();
    const editingCv = useAppSelector(getCvCopy);
    const pages = useAppSelector(getPages);
    const selectedSection = useAppSelector(getSelectedSection);
    const user = useAppSelector((state) => state?.profile?.currentProfile?.result);
    const canManagePdf = currentProfileHasAnyRole(user, ROLES.NOT_CONSULTANT)

    const changeSelectedSection = (s: SectionTypes) => {
        dispatch(updateSelectedSection(s));
    }
    const moveInsidePage = (direction: number) => {
        if(selectedSection) {
            const newPages = swapSections(selectedSection, pages, direction);
            if(newPages.length > 0) dispatch(updateGeneratorPages(newPages));
        }
    }

    const moveOutsidePage = (direction: number) => {
        if(selectedSection) {
            const newPages = moveToOtherPage(selectedSection, pages, direction);
            dispatch(updateGeneratorPages(newPages));
        }
    }

    const insertNewPage = () => {
        if(selectedSection) {
            const newPages = createNewPage(selectedSection, pages);
            dispatch(updateGeneratorPages(newPages));
        }
    }

    const resetOrder = () => {
        dispatch(resetGeneratorPages());
        dispatch(updateCvCopy(sortCV(editingCv, canManagePdf)));
    }

    return (
        <FlexContainer 
            padding='0' 
            direction='column' 
            grow={1}
        >
            <FlexContainer padding='0' customClasses='axxes-edit-pdf-action'>
                <Button
                    variant='ghost'
                    onClick={resetOrder}
                >
                    <FlexContainer padding='0'>
                        <FontAwesomeIcon icon={faHistory} />
                        <span>Reset Order</span>
                    </FlexContainer>
                </Button>

                <Button
                    accent={true}
                    onClick={() => doneEditing(true)}
                >
                    <FlexContainer padding='0'>
                        <FontAwesomeIcon icon={faCogs} />
                        <span>Generate</span>
                    </FlexContainer>
                </Button>
                
                <Button
                    variant='ghost'
                    onClick={insertNewPage}
                    disabled={!selectedSection}
                >
                    <FlexContainer padding='0'>
                        <FontAwesomeIcon icon={faFile} />
                        <span>Add new page</span>
                    </FlexContainer>
                </Button>
            </FlexContainer>
            <FlexContainer
                direction='column'
                customClasses='axxes-edit-pdf'
            >
                <div className="axxes-edit-pdf__pages-container">
                    {pages.map((page, index) =>
                        <div key={`page-${index}`}>
                            {page.sections.map(s =>
                                    <div key={s} className={`axxes-edit-pdf-pdf-block ${selectedSection === s ? 'axxes-edit-pdf-selected' : ''}`}>
                                        <div className={'axxes-edit-pdf-selected-card'}>{getEditorElementsForSectionType(s, changeSelectedSection, editingCv)}</div>
                                            {selectedSection === s ?(<SortingButtonGroup swap={moveInsidePage} jump={moveOutsidePage}/>) : null}
                                    </div>
                            )}
                            <div className={'axxes-edit-pdf-separator'}>Page {index+1}</div>
                        </div>
                    )}
                </div>   
            </FlexContainer>
        </FlexContainer>
    );
}

export default EditPdfContainer;

