import React, { FunctionComponent } from 'react';

const AvatarText: FunctionComponent = ({ children }) => {

    return (
        <div className="axxes-avatar__text">
            {children}
        </div>
    )
}

export default AvatarText;