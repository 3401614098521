import { Certificate } from "../certificate";

export default class CertificateDTO {
    id?: string;
    pictureUrl?: string;
    description?: string;
    name?: string;
    trainingCenterId?: string;
    certificateUrl?: string;
    dateOfAchievement?: string;
    dateOfExpiration?: string;

    static mapToDTO(certificate: Certificate) : CertificateDTO {
        return {
            id: certificate.id,
            pictureUrl: certificate.pictureUrl,
            description: JSON.stringify(certificate.description),
            name: certificate.name,
            trainingCenterId: certificate.trainingCenter?.id,
            certificateUrl: certificate.certificateUrl,
            dateOfAchievement: certificate.dateOfAchievement,
            dateOfExpiration: certificate.dateOfExpiration,
        }
    }
}