import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './app/App';
import store from './common/store';

import { MsalProvider } from '@azure/msal-react';
import { ShareApp } from './app/ShareApp';
import { loginRequest, msalInstance } from './common/auth/AuthConfig';
import './index.scss';

import { EventMessage, EventType } from '@azure/msal-browser';
import { AuthenticationResult } from '@azure/msal-common';
import { AxiosError } from 'axios';
import ErrorHandler from './common/api/errorHandler';
import { ErrorResponse } from './common/models/errorResponse';

const renderDOM = () => {
  const pathname = window.location.pathname;

  // If the user is requesting a shared CV, we render a limited version of the site
  if (pathname.startsWith('/share')) {
    return ReactDOM.render(
      <Provider store={store}>
        <ShareApp />
      </Provider>,
      document.getElementById('root'),
    );
  }

  msalInstance.handleRedirectPromise().then((tokenResponse: AuthenticationResult | null) => {
    const accounts = msalInstance.getAllAccounts();
    if (!tokenResponse || accounts.length === 0) {
      msalInstance.loginRedirect(loginRequest);
    }

    // If you have more than one account you're SOL.
    msalInstance.setActiveAccount(accounts[0]);

    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
      } else if (event.eventType === EventType.LOGIN_FAILURE || event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        msalInstance.loginRedirect(loginRequest);
      }
    });

    return ReactDOM.render(
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <App />
        </Provider>
      </MsalProvider>,
      document.getElementById('root'),
    );

  }).catch((err: AxiosError<ErrorResponse>) => {
    ErrorHandler.handle(
      'Something went wrong while logging you in. Please try again.', undefined, undefined, err,
    );
  });
};

renderDOM();


