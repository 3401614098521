import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from "../../model";
import UserInfo from "../../model/userInfo";

export interface AboutState {
  userInfo?: UserInfo;
  user?: User;
  edit?: boolean;
}

export const INITIAL_ABOUT_STATE: AboutState = {
  edit: false,
};

const slice = createSlice({
  name: 'About',
  initialState: INITIAL_ABOUT_STATE,
  reducers: {
    setUser: (state, action: PayloadAction<User | undefined>): AboutState => ({...state, user: action.payload}),
    setUserInfo: (state, action: PayloadAction<UserInfo | undefined>): AboutState => ({...state, userInfo: action.payload}),
    setEdit: (state, action: PayloadAction<boolean | undefined>): AboutState => ({...state, edit: action.payload}),
  }
})

export default slice.reducer;

export const {setUser, setUserInfo, setEdit} = slice.actions;