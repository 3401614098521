import UserDTO from "./dto-get/userDTO";

class User {
    id?: string;
    name?: string;
    email?: string;
    jobTitle?: string;

    static mapFromDTO(userDTO: UserDTO) {
        return {
            id: userDTO.id,
            name: userDTO.name,
            email: userDTO.email,
            jobTitle: userDTO.jobTitle,
        }
    }
}

export { User };