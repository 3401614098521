import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { arrayMoveImmutable } from 'array-move';
import React, { useState } from 'react';
import { useAppSelector } from '../../../../common/store/hooks';
import { SectionTypes } from '../../../models/models';
import { getInvisibles, getMinimizeds } from '../../../store/selectors';
import { CertificatesViewItem } from '../CertificatesView/CertificateViewItem';
import { EducationViewItem } from '../EducationsView/EducationViewItem';
import { ProjectViewItem } from '../ProjectsView/ProjectViewItem';
import { TrainingsViewItem } from '../TrainingsView/TrainingsViewItem';
import SortableItem from './SortableItem/SortableItem';
import SortableItemView from './SortableItem/SortableItemView';

type EditorSortableContainerProps<ItemType> = {
  items: ItemType[];
  type: SectionTypes;
  updateReorderedItems: (items: ItemType[]) => void;
};

function EditorSortableContainer<ItemType extends { id?: string }>({
  items,
  type,
  updateReorderedItems,
}: EditorSortableContainerProps<ItemType>) {
  const [draggingId, setDraggingId] = useState<string | null>(null);
  const invisibles = useAppSelector(getInvisibles);
  const minimizeds = useAppSelector(getMinimizeds);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { delay: 100, tolerance: 30 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = ({ active }: DragStartEvent) => {
    setDraggingId(active.id);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    setDraggingId(null);
    if (active.id === over?.id) return;
    let newItems = [...items];
    const oldIndex = items.findIndex((item) => item.id === active.id);
    const newIndex = items.findIndex((item) => item.id === over?.id);
    newItems = arrayMoveImmutable(newItems, oldIndex, newIndex);
    updateReorderedItems(newItems);
  };

  const ViewItem = ({ item, ...props }: { item: ItemType, invisibles: string[], minimizeds: string[] }): JSX.Element => {
    switch (type) {
      case SectionTypes.PROJECTS:
        return (
            <ProjectViewItem
              project={item}
              {...props}
            />
        );
      case SectionTypes.EDUCATIONS:
        return <EducationViewItem
          education={item}
          {...props}
        />
      case SectionTypes.CERTIFICATES:
        return <CertificatesViewItem 
          certificate={item}
          {...props}
        />
      case SectionTypes.TRAININGS:
        return <TrainingsViewItem 
          training={item}
          {...props}
        />
      default:
        return <></>;
    }
  };

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      sensors={sensors}
    >
      <SortableContext
        items={
          items.map((item) => ({
            id: item.id || '',
          })) || []
        }
        strategy={verticalListSortingStrategy}
      >
        {items.map((item) => (
            <SortableItem 
              key={item.id}
              id={item.id || ''} 
              isActive={item.id === draggingId} 
              isDragging={draggingId !== null}
              isInvisible={invisibles.includes(item.id ?? '')}
            >
              <ViewItem item={item} invisibles={invisibles} minimizeds={minimizeds} />
            </SortableItem>
        ))}
        <DragOverlay>
          {draggingId ? (
            <SortableItemView isDragging={true} isInvisible={invisibles.includes(draggingId)}>
              <ViewItem
                item={items.filter((item) => item.id === draggingId)[0]}
                invisibles={invisibles} 
                minimizeds={minimizeds}
              />
            </SortableItemView>
          ) : null}
        </DragOverlay>
      </SortableContext>
    </DndContext>
  );
}

export default EditorSortableContainer;
