import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { APP_CONFIG } from '../../../app/config/config';
import { dateAvatarFormat } from "../../../common/utils/dates";
import { CourseNames, Education } from '../../../users/model';
import { CVProps } from '../CV';
import Avatar from './Avatar';
import { DefaultStyles } from './classes';
import {treeToText} from "./richTextUtils";

const styles = StyleSheet.create({
  ...DefaultStyles,
    avatar_description: {
      ...DefaultStyles.avatar_description,
      width: '92%',
    }
});

export const Educations = ({ cv, invisibles, minimizeds }: CVProps) => {

    const visibles: Education[] = cv?.educations ?
          cv?.educations?.filter((e: Education) => !invisibles.includes(e.id ?? '')) : [];

    return visibles.length > 0 ? (
        <View style={styles.card}>
        <Text style={styles.card_title}>Education</Text>
            {visibles.map((education: Education) => {
                return (
                    <Avatar
                        key={education.id}
                        content={
                            <View wrap={true}>
                                <Text style={styles.avatar_title}>{`${education?.school?.name} - ${education?.course?.name}`}</Text>
                                <Text>{education?.course?.courseType && CourseNames[education?.course?.courseType]}</Text>
                                <Text style={styles.avatar_text}>
                                    {dateAvatarFormat(education)}
                                </Text>
                                {!minimizeds.includes(education.id ?? '') ?
                                    <View wrap={true} style={[{ flexWrap: 'wrap'}, styles.avatar_description]}>
                                        {treeToText(education?.description, styles).map((text) => {
                                            return text;
                                        })}
                                    </View> : null}
                            </View>
                        }
                        pictureUrl={education?.school?.pictureUrl || APP_CONFIG.DEFAULT_IMAGE}
                    />)
            })}
        </View>) : (<React.Fragment/>);
};

export default Educations;
