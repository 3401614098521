import { Api } from '../../common/api/const';
import store, { AppState } from '../../common/store';
import CertificateDTO from '../model/dto-get/certificateDTO';
import { LanguageDTO } from '../model/dto-get/languageDTO';
import UserEducationDTO from "../model/dto-get/userEducationDTO";
import UserInfoDTO from '../model/dto-get/userInfoDTO';
import UserProjectDTO from '../model/dto-get/userProjectDTO';
import UserSkillDTO from '../model/dto-get/userSkillDTO';
import UserTrainingDTO from "../model/dto-get/userTrainingDTO";
import WebLinkDTO from '../model/dto-get/webLinkDTO';

const getUserBasePath = (id?: string) => {
  const state: AppState = store.getState();
  const versionTag = state.users.selectedCvVersion?.tag;
  const userId = state.profile.currentProfile?.result?.id;

  if (versionTag && versionTag !== 'master') return `/users/${id || userId}/cv/${versionTag}`;
  return !id ? '/profile' : `/users/${id}`;
};

const getCvPath = (id?: string) => {
  const state: AppState = store.getState();
  const versionTag = state.users.selectedCvVersion?.tag;
  return `${getUserBasePath(id)}${versionTag === 'master' ? '/cv' : ''}`;
}

const getCvVersionsPath = (id?: string) => {
  return !id ? '/profile/cv/all' : `/users/${id}/cv/all`;
}

export class UsersApiService {
  static fetchUserCV = (userId?: string) => Api().get(getCvPath(userId));
  static fetchUserCvVersions = (userId?: string) => Api().get(getCvVersionsPath(userId));

  static copyCv = (id?: string, tag?: string) => Api().post(`/users/${id}/cv/copy`, { tag });
  static deleteCv= (id?: string, tag?: string) => Api().delete(`/users/${id}/cv/${tag}`);

  // skills
  static postUserSkill = (userSkillDTO: UserSkillDTO, userId?: string) =>
    Api().post(`${getUserBasePath(userId)}/skills/`, userSkillDTO);
  static putUserSkill = (userSkillDTO: UserSkillDTO, userId?: string) =>
    Api().put(
      `${getUserBasePath(userId)}/skills/${userSkillDTO.id}`,
      userSkillDTO,
    );
  static deleteUserSkill = (id: string, userId?: string) =>
    Api().delete(`${getUserBasePath(userId)}/skills/${id}`);

  // languages
  static postUserLanguage = (languageDTO: LanguageDTO, userId?: string) =>
    Api().post(`${getUserBasePath(userId)}/languages/`, languageDTO);
  static putUserLanguage = (
    id: string,
    languageDTO: LanguageDTO,
    userId?: string,
  ) => Api().put(`${getUserBasePath(userId)}/languages/${id}`, languageDTO);
  static deleteUserLanguage = (id: string, userId?: string) =>
    Api().delete(`${getUserBasePath(userId)}/languages/${id}`);

  // UserInfo
  static putUserInfo = (userInfo: UserInfoDTO, userId?: string) =>
    Api().put(`${getUserBasePath(userId)}/info`, userInfo);

  // Projects
  static postProject = (projectDTO: UserProjectDTO, userId?: string) =>
    Api().post(`${getUserBasePath(userId)}/projects`, projectDTO);
  static putProject = (projectDTO: UserProjectDTO, userId?: string) =>
    Api().put(
      `${getUserBasePath(userId)}/projects/${projectDTO.id}`,
      projectDTO,
    );
  static deleteProject = (id: string, userId?: string) =>
    Api().delete(`${getUserBasePath(userId)}/projects/${id}`);

  // Educations
  static postUserEducation = (education: UserEducationDTO, userId?: string) =>
    Api().post(`${getUserBasePath(userId)}/education`, education);
  static putUserEcucation = (
    id: string,
    education: UserEducationDTO,
    userId?: string,
  ) => Api().put(`${getUserBasePath(userId)}/education/${id}`, education);
  static deleteUserEducation = (id: string, userId?: string) =>
    Api().delete(`${getUserBasePath(userId)}/education/${id}`);

  // Certificates
  static postUserCertificate = (certificate: CertificateDTO, userId?: string) =>
    Api().post(`${getUserBasePath(userId)}/certificates`, certificate);
  static putUserCertificate = (
    id: string,
    certificate: CertificateDTO,
    userId?: string,
  ) => Api().put(`${getUserBasePath(userId)}/certificates/${id}`, certificate);
  static deleteUserCertificate = (id: string, userId?: string) =>
    Api().delete(`${getUserBasePath(userId)}/certificates/${id}`);

  // Trainings
  static postUserTraining = (training: UserTrainingDTO, userId?: string) =>
    Api().post(`${getUserBasePath(userId)}/trainings`, training);
  static putUserTraining = (id: string, training: UserTrainingDTO, userId?: string) =>
    Api().put(`${getUserBasePath(userId)}/trainings/${id}`, training);
  static deleteUserTraining = (id: string, userId?: string) =>
    Api().delete(`${getUserBasePath(userId)}/trainings/${id}`);

  // Weblinks
  static postUserLink = (userLink: WebLinkDTO, userId?: string) =>
    Api().post(`${getUserBasePath(userId)}/links`, userLink);
  static putUserLink = (id: string, userLink: WebLinkDTO, userId?: string) =>
    Api().put(`${getUserBasePath(userId)}/links/${id}`, userLink);
  static deleteUserLink = (id: string, userId?: string) =>
    Api().delete(`${getUserBasePath(userId)}/links/${id}`);
}
