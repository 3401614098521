import { Button, Card, CardContent, FlexContainer } from '@axxes/design-system';
import React from 'react';
import { useAppDispatch } from '../../common/store/hooks';
import { rebuildSearchIndex } from '../store/manageFacadeService';
import './dangerZoneContainer.scss'

export const DangerZoneContainer = () => {

  const dispatch = useAppDispatch();

  return (
    <FlexContainer
      direction="column"
      customClasses="axxes-manage__edit"
      padding="0"
    >
      <FlexContainer
        direction="row"
        padding="0"
      >
        <h1>Danger Zone</h1>
      </FlexContainer>
      <FlexContainer
        direction="column"
        padding="0"
        customClasses="axxes-manage__card"
      >
        <Card>
          <CardContent>
            <div className={"axxes__danger_zone__margin"}>
              <p>The buttons here can be potentially destructive. You better know what you're doing!</p>
            </div>

            <FlexContainer
              direction='column'
              padding='0'>
            <div>
                <h3>Rebuild Search Index</h3>
                <p>Nothing being returned by the search? Click the button below (ONCE) to drop and recreate the ElasticSearch Index.</p>
                <Button
                  accent={true}
                  onClick={dispatch(rebuildSearchIndex)}
                >
                  Rebuild Search Index
                </Button>
              </div>
            </FlexContainer>
          </CardContent>
        </Card>
      </FlexContainer>
    </FlexContainer>
  )
}
