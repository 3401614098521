import React from "react";
import { FunctionComponent } from "react";

const PrintWrapper: FunctionComponent = ({ children }) => {
    return (
        <div className="axxes-print__container">
            <div className="axxes-print__header">
            <a className="axxes-header__topbar__logo" href="/">
                    <img src={"https://axxes.com/wp-content/themes/axxes/img/layout/logo_new.png"} alt="logo" />
                </a>
        <br />
            </div>

            <div className="axxes-print__footer">
                <div>info@axxes.com</div>
                <div className="axxes-print__line">_</div>
                <div>www.axxes.com</div>
      </div>

            <table>

                <thead>
                    <tr>
                        <td>
                            <div className="axxes-print__header-space"/>
                        </td>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>
                            {children}
                        </td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr>
                        <td>
                            <div className="axxes-print__footer-space"/>
                        </td>
                    </tr>
                </tfoot>

            </table>
        </div>

    );
}

export default PrintWrapper;