import { Api } from "../../common/api/const";
import SkillDTO from "../model/dto-get/skillDTO";

export class SkillsApiService {
    static fetchSkills = () => Api().get(`/skills`);
    static postSkill = (skillDTO: SkillDTO) => Api().post(`/skills`, skillDTO);
    static putSkill = (skillDTO: SkillDTO) => Api().put(`/skills/${skillDTO?.id}`, skillDTO);
    static deleteSkill = (id: string) => Api().delete(`/skills/${id}`);
    static replace = (o: string, n: string) => Api().post(`/skills/${o}/to/${n}`);
}

