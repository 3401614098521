import { Button, Card, CardAction, CardContent, CardFooter, CardHeader, CardTitle, Loading, Timeline } from '@axxes/design-system';
import { DndContext, DragEndEvent, DragOverlay, DragStartEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayMoveImmutable } from 'array-move';
import React, {useEffect, useReducer, useState} from 'react';
import { APP_CONFIG } from '../../../app/config/config';
import {ROLES} from "../../../common/auth/RoleConfig";

import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import { sortProjects } from "../../../common/utils/sorters";
import {toggleMinimizeOfProjectInArray} from "../../../common/utils/util";
import {currentProfileHasAnyRole} from "../../../profile/RoleService";
import { UserRoutingProps } from '../../containers/UserDetailContainer';
import { DisplayOption, Project } from '../../model';
import { toggleSectionFold } from '../../store/slice';
import { putProject, updateProjectPriorities } from '../../store/usersFacadeService';
import Avatar from '../Avatar/Avatar';
import AvatarSubtitle from '../Avatar/AvatarSubtitle';
import AvatarText from '../Avatar/AvatarText';
import AvatarTitle from '../Avatar/AvatarTitle';
import UserProjectEdit from './UserProjectEdit';
import UserProjectItem from './UserProjectItem';
import UserProjectSortableItem from './UserProjectSortableItem';
import   userProjectsReducer, { init, INITIAL_PROJECTS_STATE, setDragging, setOriginalProjectOrder, setProjects } from './userProjectsState';

interface UserProjectProps extends UserRoutingProps {
  projectsProp?: Project[];
  isLoading?: boolean;
}

export const UserProjects = ({
  projectsProp, isLoading, match
}: UserProjectProps) => {
  const reduxDispatch = useAppDispatch();

  // Local state
  const [state, dispatch] = useReducer(
    userProjectsReducer,
    INITIAL_PROJECTS_STATE,
  );

  // API States
  const folded = useAppSelector((appState) => appState.users.foldedSections.includes('projects'));
  const currentProfile = useAppSelector((appState) => appState?.profile?.currentProfile?.result);
  const canManageOtherCv = currentProfileHasAnyRole(currentProfile, [ROLES.SALES, ROLES.HR, ROLES.ADMINISTRATOR])

  const isSaving = useAppSelector((appState) => (
    appState.users.clients?.post?.isSaving ||
    appState.users.projects?.post?.isSaving ||
    appState.users.projects?.put?.isSaving ||
    false
  ))
  const isDeleting = useAppSelector((appState) => appState.users.projects?.delete?.isSaving || false);
  const printMode = useAppSelector((appState) => appState.common.printMode);


  const [editIndex, setEditIndex] = useState<number | null>(null);
  const isCreating = editIndex === -1;

  const toggleEdit = (index: number) => {
    editIndex === null ? setEditIndex(index) : setEditIndex(null);
  };

  const isMobile = window.innerWidth <= 600;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const toggleMinimize = (project: Project) => {
    toggleMinimizeView(project);
    if (canManageOtherCv && !isMobile) {
      toggleMinimizeSave(project);
    }
  }

  const toggleMinimizeView = (project: Project) => {
    if (!state.projects) return;
    const newProjects = toggleMinimizeOfProjectInArray(state.projects, project);
    dispatch(setProjects(newProjects))
  }

  const toggleMinimizeSave = (project: Project) => {
    const savedProject = projectsProp?.find(p => p.id === project.id);
    if (!savedProject) return;
    reduxDispatch(
      putProject({
        ...savedProject,
        displayOption: savedProject.displayOption === DisplayOption.STANDARD ? DisplayOption.MINIMIZED : DisplayOption.STANDARD,
      }, 
      match?.params?.userId, 
      printMode),
    );
  };

  const getSavedMinimized = (project: Project) => {
    const savedProject = projectsProp?.find(p => p.id === project.id);
    if (!savedProject) return false;
    return savedProject.displayOption === DisplayOption.MINIMIZED;
  }

  const handleSortStart = ({active}: DragStartEvent) => dispatch(setDragging(active.id));

  const handleSortEnd = ({active, over}: DragEndEvent) => {
    dispatch(setDragging(null))
    if (!state.projects) return;
    if (active.id !== over?.id) {
      const oldIndex = state.projects.findIndex((p: Project) => p.id === active.id);
      const newIndex = state.projects.findIndex((p: Project) => p.id === over?.id);
      const reorderedProjects = arrayMoveImmutable<Project>(state.projects, oldIndex, newIndex);
      dispatch(setProjects(reorderedProjects));
    }
  }

  const cancelReorder = () => {
    if (!state.originalProjectOrder) return;
    dispatch(setProjects(state.originalProjectOrder));
    dispatch(setOriginalProjectOrder(null));
  }

  const saveUpdatedOrder = () => {
    if (!state.projects) return;
    reduxDispatch(updateProjectPriorities(state.projects, match?.params?.userId, printMode));
  }

  const resetOrder = () => {
    const sortedProjects = sortProjects(state.projects, false);
    dispatch(setProjects(sortedProjects));
  }

  useEffect(() => {
    let sortedProjects = sortProjects(projectsProp, canManageOtherCv);
    if (isMobile) {
      sortedProjects = sortedProjects?.map((project) => ({...project, displayOption: DisplayOption.MINIMIZED}));
    } else if (!canManageOtherCv) {
      sortedProjects = sortedProjects?.map((project) => ({...project, displayOption: DisplayOption.STANDARD}));
    }
    dispatch(init(sortedProjects));
  }, [projectsProp]);

  return (
    <div className={state?.projects?.length === 0 ? 'hideOnPrint' : ''}>
      <Card disableOnPrint={true}>
        <div onClick={() => reduxDispatch(toggleSectionFold('projects'))}>
          <CardHeader customClasses='axxes-foldable-card__header'>
            <CardTitle>Projects</CardTitle>
            <CardAction>
              <FontAwesomeIcon icon={folded ? faChevronDown : faChevronUp} />
            </CardAction>
          </CardHeader>
        </div>
        {!folded && (
          <>
            <CardContent customClasses='axxes-foldable-card__content'>
              {isLoading ? (
                <div className="axxes-project-view">
                  <div className="axxes-project-view__header">
                    <Avatar photo={APP_CONFIG.DEFAULT_IMAGE} isLoading={isLoading}>
                      <AvatarTitle>
                        <Loading width="200px" />
                      </AvatarTitle>
                      <AvatarSubtitle>
                        <Loading width="150px" />
                      </AvatarSubtitle>
                      <AvatarText>
                        <Loading width="150px" />
                      </AvatarText>
                    </Avatar>
                  </div>
                  <div className="axxes-project-view__content">
                    <Loading height="275px" />
                    <Loading height="50px" />
                    <Loading height="50px" />
                  </div>
                </div>
              ) : (
                <DndContext
                  sensors={sensors}
                  onDragStart={handleSortStart}
                  onDragEnd={handleSortEnd}
                  modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                >
                  <SortableContext items={state.projects?.map(p => ({id: p.id || ''})) || []}>
                    <div className="axxes-projects-list">
                      {state?.projects?.length === 0 && !isCreating
                        ? 'You have not submitted any projects.'
                        : state?.projects?.map((project: Project, index: number) => (
                          <UserProjectSortableItem
                            key={project.id}
                            project={project}
                            isEditing={editIndex === index}
                            toggleEdit={() => toggleEdit(index)}
                            isDeleting={isDeleting}
                            isSaving={isSaving}
                            toggleMinimize={() => toggleMinimize(project)}
                            isEditable={(editIndex === null) && (state.originalProjectOrder === null)}
                            disableMinimize={editIndex !== null}
                            canManageOtherCv={canManageOtherCv}
                            toggleMinimizeSave={() => toggleMinimizeSave(project)}
                            savedMinimized={getSavedMinimized(project)}
                            disabled={state.originalProjectOrder === null}
                            isActive={state.draggingId === project.id}
                          />
                        ))}
                        <DragOverlay className='axxes-projects-list__drag-overlay'>
                          {state.draggingId ? (
                            <UserProjectItem
                              project={state.projects?.find((p: Project) => p.id === state.draggingId) || new Project()}
                              isEditable={false}
                              isEditing={false}
                              isSaving={false}
                              isDeleting={false}
                              isDraggable={true}
                            />
                          ) : null}
                        </DragOverlay>

                        {isCreating && (
                          <div className="axxes-projects-list__item">
                            <div className="axxes-projects-list__item__fill-div" />
                            <Timeline />
                            <UserProjectEdit
                              creating={true}
                              isSaving={isSaving}
                              isDeleting={isDeleting}
                              cancel={() => toggleEdit(-1)}
                            />
                          </div>
                        )}
                    </div>
                  </SortableContext>
                </DndContext>
              )}
            </CardContent>
            {!isCreating && editIndex === null && <CardFooter>
              <CardAction>
                <div className="axxes-card-actions">
                	{state.originalProjectOrder && (
                    <>
                      <Button
                        variant='subtle'
                        onClick={resetOrder}
                        customClasses="axxes-skills-container__button"
                      >
                        Reset order
                      </Button>
                      <Button
                        variant='ghost'
                        onClick={cancelReorder}
                        customClasses="axxes-skills-container__button"
                      >
                        Cancel
                      </Button>
                      <Button
                        accent={true}
                        onClick={() => {
                            dispatch(setOriginalProjectOrder(null));
                            saveUpdatedOrder();
                          }
                        }
                        customClasses="axxes-skills-container__button"
                      >
                        Save
                      </Button>
                    </>
                  )}
                  {!state.originalProjectOrder && (
                    <>
                      {canManageOtherCv && state.projects && state.projects.length > 1 && <Button
                        variant="subtle"
                        onClick={() => dispatch(setOriginalProjectOrder([...(state.projects || [])]))}
                        isSaving={isSaving}
                      >
                        Edit Order
                      </Button>}
                      <Button
                        variant="ghost"
                        accent={true}
                        onClick={() => toggleEdit(-1)}
                      >
                        Add a project
                      </Button>
                    </>
                  )}
                </div>
              </CardAction>
            </CardFooter>}
          </>
        )}
      </Card>
    </div>
  );
};

export default UserProjects;
