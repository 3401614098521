import Axios from 'axios';

import { acquireAccessToken } from '../auth/AuthConfig';
import {getGuestToken} from "../auth/GuestConfig";

export const Config = {
  token: '',
  api: process.env.REACT_APP_BACKEND_URL,
  base: process.env.REACT_APP_AD_REDIRECT_URI
};

const axiosApiInstance = Axios.create({
  baseURL: Config.api,
});

export function Api() {
  return axiosApiInstance;
}

axiosApiInstance.interceptors.request.use(
  async config => {
    config.headers = {
      'Authorization': `Bearer ${await acquireAccessToken()}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    }
    return config;
  },
  error => {
    Promise.reject(error)
});

axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async (error) => {
  const originalRequest = error.config;
  if (error.response?.status === 403 && !originalRequest._retry) {
    originalRequest._retry = true;
    const accessToken = await acquireAccessToken();
    Axios.defaults.headers.common.Authorization = 'Bearer ' + accessToken;
    return axiosApiInstance(originalRequest);
  }
  return Promise.reject(error);
});

const guestApiInstance = Axios.create({
  baseURL: Config.api,
});

export function GuestApi() {
  return guestApiInstance;
}

guestApiInstance.interceptors.request.use(
    async config => {
      config.headers = {
        'Authorization': `Bearer ${getGuestToken()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      }
      return config;
    },
    error => {
      Promise.reject(error)
});

