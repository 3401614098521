import { Button, Timeline } from '@axxes/design-system';
import { faChevronDown, faChevronUp, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Toggle from 'react-toggle';
import EditOverlay from '../../../common/components/EditOverlay/EditOverlay';
import { projectIsExpandable } from '../../../common/utils/util';
import { DisplayOption, Project } from '../../model';
import UserProjectEdit from './UserProjectEdit';
import UserProjectView from './UserProjectView';

export interface UserProjectItemProps {
  project: Project
  toggleMinimize?: () => void;
  toggleMinimizeSave?: () => void;
  disableMinimize?: boolean;
  isEditing?: boolean;
  toggleEdit?: () => void;
  isSaving: boolean;
  isDeleting: boolean;
  isEditable?: boolean;
  canManageOtherCv?: boolean;
  savedMinimized?: boolean;
  isDraggable?: boolean;
  isActive?: boolean;
}

const UserProjectItem = ({
  project,
  toggleMinimize,
  disableMinimize,
  isEditing,
  toggleEdit,
  isDeleting,
  isSaving,
  isEditable,
  canManageOtherCv,
  toggleMinimizeSave,
  savedMinimized,
  isDraggable,
  isActive
}: UserProjectItemProps) => (
  <div className={`axxes-projects-list__item ${isActive ? '--active' : ''}`}>
    {!isDraggable ? projectIsExpandable(project) ? (
      <div className="axxes-projects-list__item__actions">
        <Button
          variant="subtle"
          onClick={toggleMinimize}
          disabled={disableMinimize}
        >
          <FontAwesomeIcon icon={project?.displayOption === DisplayOption.MINIMIZED ? faChevronDown : faChevronUp} title={project?.displayOption === DisplayOption.MINIMIZED ? 'Expand' : 'Collapse'} />
        </Button>
        {canManageOtherCv && (
          <div className="axxes-projects-list__item__toggle">
            <span>{savedMinimized ? "Minimized" : "Expanded"}</span>
            <Toggle 
              checked={savedMinimized} 
              onChange={toggleMinimizeSave} 
              disabled={disableMinimize || isSaving} 
              icons={false}
            />
          </div>
        )}
      </div>
    ) : (
      <div className="axxes-projects-list__item__fill-div" />
    ) : (
      <div className="axxes-projects-list__item__fill-div">
        <FontAwesomeIcon icon={faEllipsisV} />
      </div>
    )}
    <Timeline />
    <EditOverlay
      edit={!!isEditing}
      setEdit={() => toggleEdit?.()}
      isEditable={isEditable}
      customclass="axxes-projects-list__item__overlay"
      viewElement={<UserProjectView project={project} isDraggable={isDraggable} />}
      editElement={
        <UserProjectEdit
          creating={false}
          project={project}
          isSaving={isSaving}
          isDeleting={isDeleting}
          cancel={() => toggleEdit && toggleEdit()}
        />
      }
    />
  </div>
);

export default UserProjectItem;
