import {
  Button,
  Dropdown,
  FlexContainer,
    Item,
} from '@axxes/design-system';
import React, { useReducer } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useRouteMatch } from 'react-router-dom';
import CvTextArea from "../../../common/components/TextArea/CvTextarea";
import { dateToString } from "../../../common/utils/dates";

import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import * as yup from 'yup';
import CvDatePicker from '../../../common/components/DatePicker/DatePicker';
import ItemForm from '../../../common/components/ItemForm/ItemForm';
import { ModalTypes } from '../../../common/models/modals';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import { showModal } from '../../../common/store/slice';
import { DropdownSchema } from '../../../common/utils/validations';
import { updateSchool } from '../../../manage/store/manageFacadeService';
import { Course, CourseNames, CourseType, Education, School } from '../../model';
import {
  deleteUserEducation,
  postUserEducation,
  putUserEducation,
} from '../../store/usersFacadeService';
import   EducationReducer, {
  EducationEditState,
  INITIAL_EDUCATION_EDIT_STATE,
  setDropdownCourse,
  setDropdownCourseType,
  setDropdownSchool,
  setEducation,
} from './state';

type UserEducationsEditProps = {
  schools: School[];
  courses: Course[];
  index?: number;
  education: Education;
  isSaving: boolean;
  isDeleting: boolean;
  creating: boolean;
  cancel: () => void;
};

export interface MatchParams {
  userId: string;
}

const UserEducationsEdit = ({
  schools,
  courses,
  education,
  isDeleting,
  isSaving,
  creating = false,
  cancel,
}: UserEducationsEditProps) => {
  const { handleSubmit, errors, control, setValue } = useForm({
    criteriaMode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        school: DropdownSchema,
        course: DropdownSchema,
        courseType: DropdownSchema,
        fromDate: yup.string().required(),
      }),
    ),
  });

  const courseTypes: Item[] = Object.keys(CourseNames).map((item) => ({
    name: CourseNames[item as CourseType],
    value: item,
  }));

  const today = new Date();
  const [state, dispatch] = useReducer(
    EducationReducer,
    creating
      ? { ...INITIAL_EDUCATION_EDIT_STATE, schools, courses }
      : ({
          education,
          editing: false,
          formSchool: false,
          schools,
          courses,
        } as EducationEditState),
  );

  const printMode = useAppSelector(
    (appState) => appState?.common?.printMode,
  );

  const reduxDispatch = useAppDispatch();
  const match = useRouteMatch<MatchParams>('/users/:userId');

  const create = handleSubmit(() => {
      if (creating) {
      reduxDispatch(
        postUserEducation(state.education, match?.params.userId, printMode),
      );
    } else {
      const oldSchool = schools.find((s) => s.id === state.education?.school?.id);
      if (!_.isEqual(state.education?.school, oldSchool) && state.education.school && oldSchool) {
        reduxDispatch(updateSchool(state.education?.school))
      };
      reduxDispatch(
        putUserEducation(state.education, match?.params?.userId, printMode),
      );
    }
  });

  const remove = () => {
    reduxDispatch(
      showModal(ModalTypes.DELETE, {
        label: 'education',
        name: `${education.course?.name} - ${education.school?.name}`,
        onConfirm: () => {
          reduxDispatch(
            deleteUserEducation(
              state.education.id || '',
              match?.params?.userId,
              printMode,
            ),
          );
        },
      }),
    );
  };

  const getCourseTitle = (course?: Course): string => {
    if (!course) return '';
    return `${course.name} ${course.courseType ? `- ${CourseNames[course.courseType]}` : null}`;
  }

  return (
    <div>
      <div className="axxes-cv-form axxes-cv-form__container">
        <img
          className="axxes-cv-form__photo"
          alt="avatar-img"
          src={state?.education?.school?.pictureUrl || '/assets/images/default.png'}
        />
        <FlexContainer
          padding="0"
          direction="column"
          customClasses="axxes-cv-form__container"
          grow={1}
        >
          <Controller
            name="school"
            defaultValue={{
              name: state?.education?.school?.name || '',
              value: state?.education?.school?.id || '',
            }}
            control={control}
            render={({ onChange, value }) => (
              <Dropdown
                title="School"
                warning={errors.school}
                items={schools.map((school: School) => {
                  return { name: school.name, value: school.id } as Item;
                })}
                autoComplete={true}
                value={value}
                setValue={(i) => {
                  onChange(i);
                  dispatch(setDropdownSchool(i as Item));
                }}
                required={true}
              />
            )}
          />
          <ItemForm
            newItem={state?.education?.school}
            setNewItem={(school) => {
              dispatch(setEducation({ ...state.education, school }));
            }}
            open={state?.formSchool}
            title={
              !state.education?.school?.id
                ? 'New school'
                : 'Update school'
            }
          />
          <Controller
            name="course"
            defaultValue={{
              name: state?.education?.course?.name || '',
              value: state?.education?.course?.id || '',
            }}
            control={control}
            render={({ onChange, value }) => (
              <Dropdown
                items={courses.map((course: Course) => {
                  return { name: getCourseTitle(course), value: course.id } as Item;
                })}
                autoComplete={true}
                value={value}
                setValue={(i) => {
                  const course = courses.find(c => c.id === (i as Item).value) ?? new Course();
                  if (course.name === '') course.name = i.name;

                  onChange({name: course.name, value: course.id});
                  dispatch(setDropdownCourse(course));
                  
                  setValue("courseType", {
                    name: course.courseType ? CourseNames[course.courseType] : CourseNames.HIGH_SCHOOL,
                    value: course.courseType || "HIGH_SCHOOL"
                  })
                }}
                title="course"
                required={true}
                warning={errors.course}
              />
            )}
          />
          <Controller
            name="courseType"
            defaultValue={
              {
                name:
                  CourseNames[state.education.course?.courseType || CourseType.HIGH_SCHOOL],
                value: state.education.course?.courseType || CourseType.HIGH_SCHOOL
              } as Item
            }
            control={control}
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <Dropdown
                items={courseTypes}
                value={value}
                autoComplete={false}
                setValue={(i) => {
                  onChange(i);

                  const course = courses.find(c => c.name === state.education?.course?.name && c.courseType === (i as Item).value);
                  
                  if (course) {
                    dispatch(setDropdownCourse(course));
                  } else {
                    const type = (i as Item).value as CourseType;
                    dispatch(setDropdownCourseType(type));
                  } 
                }}
                title="Type"
                required={true}
                warning={errors.courseType}
              />
            )}
          />

          <div className="axxes-cv-form__dual-form">
            <Controller
              name="fromDate"
              defaultValue={
                state?.education?.fromDate
                  ? new Date(state?.education?.fromDate)
                  : null
              }
              control={control}
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <CvDatePicker
                  title="Start Date"
                  onChange={(date) => {
                    onChange(date);
                    dispatch(setEducation({
                      ...state.education,
                      fromDate: dateToString(date),
                    }));
                  }}
                  value={value}
                  maxDetail="year"
                  minDetail="decade"
                  maxDate={
                    state?.education?.toDate
                      ? new Date(state?.education?.toDate)
                      : undefined
                  }
                  required={true}
                  warning={errors.fromDate}
                />
              )}
            />
            <Controller
              name="toDate"
              defaultValue={
                state?.education?.toDate
                  ? new Date(state?.education?.toDate)
                  : null
              }
              control={control}
              render={({ onChange, value }) => (
                <CvDatePicker
                  title="End date"
                  onChange={(date) => {
                    onChange(date);
                    dispatch(setEducation({
                      ...state.education,
                      toDate: dateToString(date),
                    }));
                  }}
                  value={value}
                  maxDetail="year"
                  minDetail="decade"
                  minDate={
                    state?.education?.fromDate
                      ? new Date(state?.education?.fromDate)
                      : undefined
                  }
                  maxDate={today}
                />
              )}
            />
          </div>
            <Controller
                name="text"
                defaultValue={state?.education?.description || []}
                control={control}
                render={({ onChange, value }) => (
                    <CvTextArea
                        title="Description"
                        value={value}
                        onChange={(e) => {
                            onChange(e);
                            dispatch(setEducation({ ...state.education, description: e}));
                        }}
                    />
                )}
            />
        </FlexContainer>
      </div>
      <div className="axxes-cv-form__actions axxes-about__actions">
        {!creating && (
          <Button
            variant="subtle"
            accent={true}
            onClick={remove}
            disabled={isSaving}
            isSaving={isDeleting}
          >
            <span>Delete</span>
          </Button>
        )}
        <Button
          variant="subtle"
          onClick={cancel}
          disabled={isDeleting || isSaving}
        >
          <span>Cancel</span>
        </Button>
        <Button
          onClick={create}
          disabled={
            isDeleting ||
            errors.toDate ||
            errors.fromDate ||
            errors.school ||
            errors.course
          }
          accent={true}
          type="submit"
          isSaving={isSaving}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default UserEducationsEdit;
