import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Course, Training } from '../../model';
import { TrainingCenter } from '../../model/trainingCenter';

export interface TrainingEditState {
  training?: Training;
  openTrainingCenterForm?: boolean;
  trainingCenters?: TrainingCenter[];
  trainingCourses?: Course[];
}

export const INITIAL_TRAINING_EDIT_STATE: TrainingEditState = {
  training: new Training(),
  openTrainingCenterForm: false,
  trainingCenters: [],
  trainingCourses: [],
};

const slice = createSlice({
  name: 'TrainingEdit',
  initialState: INITIAL_TRAINING_EDIT_STATE,
  reducers: {
    setTraining: (
      state,
      action: PayloadAction<Training>,
    ): TrainingEditState => ({ ...state, training: action.payload }),
    setTrainingCenter: (
      state,
      action: PayloadAction<TrainingCenter>,
    ): TrainingEditState => ({
      ...state,
      training: {
        ...state.training,
        trainingCenter: action.payload,
      },
    }),
    setTrainingCourse: (
      state,
      action: PayloadAction<Course>,
    ): TrainingEditState => ({
      ...state,
      training: {
        ...state?.training,
        trainingCourse: action.payload,
      },
    }),
    openTrainingCenterForm: (
      state,
      action: PayloadAction<boolean>,
    ): TrainingEditState => ({
      ...state,
      openTrainingCenterForm: action.payload,
    }),
  },
});

export default slice.reducer;

export const {
  setTraining,
  setTrainingCenter,
  setTrainingCourse,
  openTrainingCenterForm,
} = slice.actions;
