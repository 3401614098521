import React, { useCallback, useMemo } from 'react';

import {
  Editable,
  Slate,
  useSlate,
  withReact,
} from 'slate-react';

import {
  createEditor,
  Editor,
  Element as SlateElement,
  Node,
  Transforms
} from 'slate';

import { faBold, faItalic, faListUl, faUnderline } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import isHotkey from 'is-hotkey';
import { withHistory } from 'slate-history';

import './cvTextarea.scss';

interface CvTextAreaProp {
  title: string;
  value: Node[];
  onChange: (value: Node[]) => void;
  required?: boolean;
  warning?: boolean;
  readonly?: boolean;
  readonlyPlaceholder?: string;
  maxLen?: number;
  actualLen?: number;
}

const HOTKEYS: any = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const LIST_TYPES: any = ['numbered-list', 'bulleted-list'];

const CvTextArea = ({
  title,
  value,
  onChange,
  required,
  warning,
  readonly,
  readonlyPlaceholder,
  maxLen,
  actualLen
}: CvTextAreaProp) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  return (
    <div
      className={`slate axxes-textarea ${warning ? 'axxes-textarea--error' : ''}
    ${readonly ? 'axxes-textarea--readonly' : ''}
    `}
    >
      <label
        className={`${
          value && (value[0] as any).children[0]?.text !== ''
            ? 'label--float'
            : ''
        }`}
      >
        {title}
        {required && '*'}
      </label>
      {value &&
        (value[0] as any).children[0]?.text === '' &&
        readonlyPlaceholder && (
          <label className="axxes-textarea__readonly-placeholder">
            {readonlyPlaceholder}
          </label>
        )}
      <Slate
        editor={editor}
        value={value}
        onChange={(_value) => {
          onChange(_value);
        }}
      >
        { !readonly && <div className="axxes-textarea__toolbar">
            {maxLen
                ? <span className={`axxes-textarea__counter${(actualLen || 0) > maxLen ? '--warning' : null}`}>
                  {`${actualLen || 0}/${maxLen}`}
                  </span>
                : null
            }
            <MarkButton format="bold" icon={faBold} />
            <MarkButton format="italic" icon={faItalic} />
            <MarkButton format="underline" icon={faUnderline} />
            { /* <MarkButton format="code" icon={faCode} />  
            <BlockButton format="heading-one" icon={faHeading} />
            <BlockButton format="heading-two" icon={faHeading} />
            <BlockButton format="block-quote" icon={faQuoteLeft} />
            <BlockButton format="numbered-list" icon={faListOl} /> */}
            <BlockButton format="bulleted-list" icon={faListUl} />
        </div> }
        <Editable
          className="axxes-textarea__editable"
          readOnly={readonly}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={(event: any) => {
            for (const hotkey in HOTKEYS) {
              if (event && hotkey && isHotkey(hotkey, event as any)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                if (mark) {
                  toggleMark(editor, mark);
                }
              }
            }
          }}
        />
      </Slate>
      <div className="axxes-textarea__warning" />
    </div>
  );
};

const Element = ({ attributes, children, element }: any) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h4 {...attributes}>{children}</h4>;
    case 'heading-two':
      return <h5 {...attributes}>{children}</h5>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }: any) => {
  return textType(leaf, children, attributes);
};

export const textType = (leaf: any, children: any, attributes: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const isBlockActive = (editor: any, format: any) => {
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  }) as any;
  return !!match;
};

const toggleBlock = (editor: any, format: any) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      LIST_TYPES.includes(
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type,
      ),
    split: true,
  });
  const newProperties: Partial<SlateElement> = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const BlockButton = ({ format, icon }: any) => {
  const editor = useSlate();
  return (
    <button
      className={`axxes-textarea__button ${
        isBlockActive(editor, format) && 'axxes-textarea__button--active'
      }`}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <i><FontAwesomeIcon icon={icon} /></i>
    </button>
  );
};

const toggleMark = (editor: any, format: any) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isMarkActive = (editor: any, format: any) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const MarkButton = ({ format, icon }: any) => {
  const editor = useSlate();
  return (
    <button
      className={`axxes-textarea__button ${
        isMarkActive(editor, format) && 'axxes-textarea__button--active'
      }`}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <i><FontAwesomeIcon icon={icon} /></i>
    </button>
  );
};

export default CvTextArea;

/*
>*/
