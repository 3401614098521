import {Node} from "slate";
import { Course } from ".";
import UserTrainingDTO from "./dto-get/userTrainingDTO";
import {mapRichTextToObject} from "./map";
import { TrainingCenter } from "./trainingCenter";

export class Training {
    id?: string;
    toDate?: string | null;
    fromDate?: string | null;
    description?: Node[];
    trainingCenter?: TrainingCenter;
    trainingCourse?: Course;
    certificateUrl?: string;

    constructor() {
        this.description = [{type: 'paragraph',children: [{ text: '' }]}]; // Init empty state for Slate
        this.trainingCenter = new TrainingCenter();
        this.trainingCourse = new Course();
        this.fromDate = null;
        this.toDate = null;
        this.certificateUrl = "";
    }

    static mapFromDTO(userTrainingDTO: UserTrainingDTO) {
        const desc = mapRichTextToObject(userTrainingDTO?.description);
        return {
            ...userTrainingDTO,
            trainingCourse: userTrainingDTO.course,
            description: desc
        }
    }
}