import { School } from "../school";

export default class SchoolDTO {
    id?: string;
    name?: string;
    pictureUrl?: string;
    postalCode?: string;
    
    static mapToDTO(school: School) : SchoolDTO {
        return {
            id: school.id,
            name: school.name,
            pictureUrl: school.pictureUrl,
        }
    }
}