import {Card, CardContent, CardHeader, CardTitle, FlexContainer, Loading} from "@axxes/design-system";
import React from "react";
import UserSkillView from "../../users/components/UserSkills/UserSkillView";
import {UserSkill} from "../../users/model";


export interface ShareUserSkillsProps {
    userSkills?: UserSkill[];
    isLoading?: boolean;
}

export const ShareUserSkills = ({userSkills, isLoading}: ShareUserSkillsProps) => {

    return (
        userSkills?.length === 0 ? null :
        <div className="axxes-skills__container">
            <Card>
                <CardHeader>
                    <CardTitle>Skills</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="axxes-skills__card-content">
                        <FlexContainer padding="0" customClasses="axxes-skills-container">
                            {isLoading ? (
                                <FlexContainer padding="0" grow={1} direction="column">
                                    <FlexContainer
                                        customClasses="axxes-skill"
                                        padding="0"
                                        grow={1}
                                        direction="row"
                                    >
                                        <Loading width={'90%'} />
                                        <Loading width={'90%'} />
                                    </FlexContainer>
                                    <FlexContainer
                                        customClasses="axxes-skill"
                                        padding="0"
                                        grow={1}
                                        direction="row"
                                    >
                                        <Loading width={'90%'} />
                                        <Loading width={'90%'} />
                                    </FlexContainer>
                                </FlexContainer>
                            ) : (

                                userSkills?.map((userSkill: UserSkill, index: number) => {
                                    return (
                                        <div  
                                            key={`shareSkill-${index}`}  
                                            className={
                                                'axxes-skills-container__edit false axxes-skills-container__share'
                                            }
                                        >
                                            <UserSkillView userSkill={userSkill} shareView={true}/>
                                        </div>
                                    );
                                })
                            )}
                        </FlexContainer>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}