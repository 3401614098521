import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {School} from "../../../users/model";


export interface EditSchoolsState {
    editingSchool?: School
}

export const INITIAL_EDIT_SCHOOLS_STATE: EditSchoolsState = {
    editingSchool: undefined,
}

const slice = createSlice({
  name: 'EditSchools',
  initialState: INITIAL_EDIT_SCHOOLS_STATE,
  reducers: {
    setSchool: (state, action: PayloadAction<School>): EditSchoolsState => ({
      ...state,
      editingSchool: action.payload,
    }),
    resetSchool: (state): EditSchoolsState => ({
      ...state,
      editingSchool: undefined,
    }),
  },
});

export default slice.reducer;

export const {setSchool, resetSchool} = slice.actions;