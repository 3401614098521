import { Button, Dropdown, FlexContainer, Input, Item } from '@axxes/design-system';
import {
  faCloud,
  faCopy,
  faExclamationTriangle,
  faFileDownload,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useReducer } from 'react';
import { useHistory } from 'react-router';
import Toggle from 'react-toggle';
import * as yup from 'yup';
import ErrorHandler from '../../../common/api/errorHandler';
import {ROLES} from "../../../common/auth/RoleConfig";
import { ModalTypes } from '../../../common/models/modals';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import { setPrintMode, showModal } from '../../../common/store/slice';
import { toastSucces } from '../../../common/utils/util';
import {currentProfileHasAnyRole} from "../../../profile/RoleService";
import { User } from '../../model';
import { ShareResponse } from '../../model/shareResponse';
import { setSelectedCvVersion } from '../../store/slice';
import {
  copyCv,
  createShareUrl,
  deleteCv,
  fetchUserCvVersions,
} from '../../store/usersFacadeService';
import { convertDisplayedVersionToTag } from '../../utils/util';
import './detailsHeader.scss';
import detailsHeaderReducer, { clearLink, closeRecipientForm, closeVersionForm, openRecipientForm, openVersionForm, setLink, setRecipientLoading, setRecipientValue, setVersionError, setVersionLoading, setVersionValue } from './detailsHeaderState'

interface DetailsHeaderProp {
  userId?: string;
  user?: User;
}

const versionValidation = yup.string().min(3).max(80).matches(/^[a-z0-9]+$/, "Version can only contain letters and numbers").label("Version");

function DetailsHeader({ userId, user }: DetailsHeaderProp) {
  const history = useHistory();
  const reduxDispatch = useAppDispatch();
  const [state, dispatch] = useReducer(detailsHeaderReducer, { userId: userId || '' });

  const cvVersions = useAppSelector(
    (appState) => appState.users.cvVersions?.result,
  );
  const selectedCvVersion = useAppSelector(
    (appState) => appState.users.selectedCvVersion,
  );

  const printMode = useAppSelector(
    (appState) => appState?.common?.printMode,
  );

  const currentProfile = useAppSelector((appState) => appState?.profile?.currentProfile?.result);
  const canManageOtherCv = currentProfileHasAnyRole(currentProfile, [ROLES.SALES, ROLES.HR, ROLES.ADMINISTRATOR])


  const getCvVersionFromTag = (versionTag: string) => {
    return cvVersions?.find(
      (version) =>
        version.tag === convertDisplayedVersionToTag(versionTag.trim()),
    );
  };

  const handleSubmit = () => {
    dispatch(clearLink());
    if (getCvVersionFromTag(state?.versionForm?.value || '')) {
      dispatch(setVersionError('Version already exists'));
      return;
    }
    versionValidation.validate(state?.versionForm?.value?.trim().toLowerCase())
      .then(() => {
        addVersion()
      })
      .catch((err) => {
        dispatch(setVersionError(err.errors));
      })
  }

  const addVersion = () => {
    dispatch(setVersionLoading(true));
    const tag = convertDisplayedVersionToTag(state?.versionForm?.value);
    copyCv(userId, tag)
      .then(() => {
        dispatch(closeVersionForm());
        reduxDispatch(fetchUserCvVersions(userId, tag));
      })
      .catch((err) => {
        dispatch(setVersionLoading(false));
        ErrorHandler.handle('Could not add version ' + state?.versionForm?.value);
      });
  };

  const cancelVersion = () => {
    dispatch(closeVersionForm());
  };

  const deleteVersion = () => {
    reduxDispatch(
      showModal(ModalTypes.DELETE, {
        label: 'CV version',
        name: selectedCvVersion?.displayTag,
        onConfirm: () => {
          dispatch(clearLink());
          deleteCv(userId, selectedCvVersion)
            .then(() => {
              reduxDispatch(fetchUserCvVersions(userId));
            })
            .catch((err) => {
              ErrorHandler.handle('Could not delete version');
            });
        },
      }),
    );
  };

  const generate = () => {
    dispatch(setRecipientLoading(true));
    createShareUrl(state?.recipientForm?.value || '', user?.id || '', selectedCvVersion?.tag || '')
      .then((response: ShareResponse) => {
        dispatch(closeRecipientForm());
        dispatch(setLink(response));
      })
      .catch((err) => {
        dispatch(setRecipientLoading(false));
        ErrorHandler.handle('Could not generate URL for: ' + state?.versionForm?.value);
      });
  }

  const cancelRecipient = () => dispatch(closeRecipientForm());

  const exportToPdf = () => {
    let path: string = '/generate';
    if (userId !== undefined) path = path + `/${userId}`;
    if (printMode) {
      path = path + '/modified';
    }
    history.push(path);
  };

  return (
    <FlexContainer
      direction='column'
      customClasses={canManageOtherCv ? "axxes-details-header" : "axxes-details-header__consultant"}
    >
      <FlexContainer 
        padding='0' 
        direction='row' 
        customClasses='axxes-details-header__container'
      >
        <FlexContainer padding='0' direction='column' grow={1} customClasses='axxes-details-header__left-column'>
          <FlexContainer direction='row' padding='0' customClasses='axxes-details-header__row'>
            {!state?.versionForm?.open && cvVersions && cvVersions.length > 1 && canManageOtherCv && (
              <FlexContainer
                padding="0"
                customClasses="axxes-details-header__version-container"
              >
                <Dropdown
                  customClasses={'axxes-details-header__input'}
                  items={cvVersions.map((version) => ({
                    value: version.tag || 'undefined',
                    name: version.displayTag || 'UNDEFINED VERSION',
                  }))}
                  value={{
                    value: selectedCvVersion?.tag || 'undefined',
                    name: selectedCvVersion?.displayTag || 'UNDEFINED VERSION',
                  }}
                  setValue={(item) => {
                    const value = (item as Item).value.toString();
                    if (value !== selectedCvVersion?.tag) dispatch(clearLink());
                    reduxDispatch(setSelectedCvVersion(getCvVersionFromTag(value)))
                  }}
                  title="Version"
                />
                {!state?.recipientForm?.open && (<>
                  <Button
                    onClick={deleteVersion}
                    customClasses={'axxes-details-header__button'}
                    variant="subtle"
                    accent={true}
                    disabled={selectedCvVersion?.tag === 'master'}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                  <div className="axxes-details-header__full-width">
                    <Button
                      variant="subtle"
                      onClick={() => dispatch(openVersionForm())}
                      customClasses={'axxes-details-header__button'}
                    >
                      Add version
                    </Button>
                  </div>
                </>)}
              </FlexContainer>
            )}
            {(state?.versionForm?.open ||
              !cvVersions ||
              cvVersions.length <= 1) && canManageOtherCv && (
              <FlexContainer direction={'column'} padding="0">
                <FlexContainer
                  padding="0"
                  grow={1}
                  direction="row"
                  customClasses="axxes-details-header__version-container"
                >
                  <Input
                    autoFocus={true}
                    value={state?.versionForm?.value}
                    onChange={(e) => {
                      const value = e.target.value;
                      dispatch(setVersionValue(value));
                    }}
                    customClasses={'axxes-details-header__input'}
                    name={'Version'}
                    placeholder={'Version'}
                    warning={state?.versionForm?.error !== undefined}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handleSubmit();
                      if (e.key === 'Escape') cancelVersion();
                    }}
                    disabled={state?.recipientForm?.open}
                  />

                  {cvVersions && cvVersions.length > 1 && (
                    <Button
                      onClick={cancelVersion}
                      customClasses={'axxes-details-header__button'}
                      variant="subtle"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  )}
                  <div className="axxes-details-header__full-width">
                    <Button
                      onClick={handleSubmit}
                      disabled={
                        !state?.versionForm?.value
                      }
                      accent={true}
                      customClasses={'axxes-details-header__button'}
                      isSaving={state?.versionForm?.isLoading}
                    >
                      Add version
                    </Button>
                  </div>
                </FlexContainer>
                <span className="axxes-details-header__error">
                  {state?.versionForm?.error}
                </span>
              </FlexContainer>
            )}
            {!state?.recipientForm?.open && canManageOtherCv && (
              <div className="axxes-details-header__full-width">
                <Button
                  variant="ghost"
                  onClick={() => {
                    dispatch(clearLink());
                    dispatch(openRecipientForm());
                  }}
                  customClasses={'axxes-details-header__button'}

                >
                  Generate Share URL
                </Button>
              </div>
            )}
          </FlexContainer>
          {state?.recipientForm?.open && (
            <FlexContainer
              padding="0"
              grow={1}
              direction="row"
              customClasses="axxes-details-header__recipient-container"
            >
              <Input
                autoFocus={true}
                value={state?.recipientForm?.value}
                onChange={(e) =>
                  dispatch(setRecipientValue(e.target.value))
                }
                customClasses={'axxes-details-header__input'}
                name={'Recipient'}
                placeholder={'Recipient'}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') generate();
                  if (e.key === 'Escape') cancelRecipient();
                }}
              />

              <Button
                onClick={cancelRecipient}
                customClasses={'axxes-details-header__button'}
                variant="subtle"
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>

              <div className="axxes-details-header__full-width">
                <Button
                  onClick={generate}
                  disabled={!state?.recipientForm?.value}
                  accent={true}
                  customClasses={'axxes-details-header__button'}
                  isSaving={state?.recipientForm?.isLoading}
                >
                  Generate
                </Button>
              </div>
            </FlexContainer>
          )}
        </FlexContainer>
        <FlexContainer
            padding="0"
            direction="row"
            customClasses="axxes-details-header__pdf-container"
          >
            {canManageOtherCv && (
              <FlexContainer
                padding="0"
                customClasses="axxes-details-header__sync"
              >
                <label htmlFor="sync">
                  {printMode ? 'Changes will be lost' : 'Syncing changes'}
                </label>
                <Toggle
                  checked={!printMode}
                  aria-label="sync"
                  id="sync"
                  className="axxes-details-header__sync-toggle"
                  icons={{
                    checked: <FontAwesomeIcon icon={faCloud} />,
                    unchecked: <FontAwesomeIcon icon={faExclamationTriangle} />,
                  }}
                  onChange={() => reduxDispatch(setPrintMode(!printMode))}
                />{' '}
              </FlexContainer>
            )}
            <Button onClick={exportToPdf} accent={true} variant="ghost">
              <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          </FlexContainer>
      </FlexContainer>
      {state?.link && (
          <FlexContainer
            direction={'row'}
            padding="0"
            customClasses="axxes-details-header__share"
          >
            <FontAwesomeIcon 
              icon={faTimes} 
              title='Dismiss link'
              className='axxes-details-header__share-close'
              onClick={() => dispatch(clearLink())} 
            />
            <span
              title='Copy link'
              onClick={() => {
                navigator.clipboard.writeText(state?.link || '');
                toastSucces('Copied!');
              }}
              className={'axxes-details-header__share-link'}
            >
              {state?.link}
            </span>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(state?.link || '');
                toastSucces('Copied!');
              }}
              accent={false}
              variant={'ghost'}
              customClasses={'axxes-details-header__share-copy'}
            >
              <FontAwesomeIcon icon={faCopy} />
            </Button>
          </FlexContainer>
        )}
    </FlexContainer>
  );
}

export default DetailsHeader;
