import {Button, FlexContainer} from '@axxes/design-system';
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {usePDF} from '@react-pdf/renderer';
import React, {useEffect} from 'react';
import ErrorHandler from '../../common/api/errorHandler';
import { useAppDispatch, useAppSelector } from '../../common/store/hooks';
import { setFooter } from '../../common/store/slice';
import {CV} from '../components/CV';
import {getCvCopy, getInvisibles, getInvisibleSections, getMinimizeds, getPages} from '../store/selectors';
import './PdfContainer.scss';

type PdfContainerProps = {
    backToEditing: (val:boolean) => void
}
export const PdfContainer = ({backToEditing}: PdfContainerProps) => {
    const reduxDispatch = useAppDispatch();
    const cv = useAppSelector(getCvCopy);
    const pages = useAppSelector(getPages);
    const invisibles = useAppSelector(getInvisibles);
    const minimizeds = useAppSelector(getMinimizeds);
    const invisibleSections = useAppSelector(getInvisibleSections)

    const [instance] = usePDF({document: <CV cv={cv} pages={pages} invisibles={invisibles} minimizeds={minimizeds} invisibleSections={invisibleSections}/>})

    useEffect(() => {
        reduxDispatch(setFooter(false));

        return () => {
            reduxDispatch(setFooter(true))
        };
    }, []);

    useEffect(() => {
        if(instance.error) {
            ErrorHandler.handle('PDF generation failed');
        }
    }, [instance.error])

    return (
        <FlexContainer
            grow={1}
            padding="0px"
            direction="column"
            customClasses='axxes-generate-container'
        >
            {instance.url ? (
                <FlexContainer
                    customClasses="axxes-generate__container"
                    grow={1}
                    padding="0px"
                    direction="column"
                >
                    <img src="https://images.aws.axxes.com/Logo_Axxes.png" alt='Logo' />
                    <span className='axxes-generate__text -ready'>Your PDF is ready</span>
                    <div className='axxes-generate__action-container'>
                        <a href={instance.url} download={`${cv?.user?.name} - EN`}>
                            <Button accent={true}>
                                <FontAwesomeIcon icon={faDownload}/>
                                <span>Download</span>
                            </Button>
                        </a>
                        <Button onClick={() => backToEditing(false)}>
                            <FontAwesomeIcon icon={faArrowLeft}/>
                            <span>Back to editor</span>
                        </Button>
                    </div>
                    <iframe className='axxes-generate__viewer' height="100%" width="100%" src={instance.url} title={`${cv?.user?.name} - EN`} />
                </FlexContainer>
            ) : (
                <FlexContainer
                    customClasses="axxes-generate__container"
                    grow={1}
                    padding="0px"
                    direction="column"
                >
                    <span className="axxes-generate__text -loading">Generating your PDF</span>
                </FlexContainer>
            )}
        </FlexContainer>
    );
};

export default PdfContainer;
