import { UserCV } from "../../users/model";
import { Page, SectionTypes } from "../models/models";

export type PdfGeneratorState = {
    pages: Page[],
    cvCopy?: UserCV,
    selectedSection?: SectionTypes,
    invisibles: string[],
    minimizeds: string[],
    invisibleSections: SectionTypes[]
}

export const InitialGeneratorState : PdfGeneratorState = {
    pages: [
        {
            sections: [SectionTypes.ABOUT_ME, SectionTypes.SKILLS],
            pageNumber: 1
        },
        {
            sections: [SectionTypes.PROJECTS],
            pageNumber: 2
        },
        {
            sections: [SectionTypes.EDUCATIONS, SectionTypes.CERTIFICATES],
            pageNumber: 3
        },
        {
            sections: [SectionTypes.TRAININGS, SectionTypes.LANGUAGES, SectionTypes.WEBLINKS],
            pageNumber: 4
        },
    ],
    invisibles: [],
    minimizeds: [],
    invisibleSections: []
}