import {
  DropdownOption,
  Footer,
  Header,
  HeaderActions,
  HeaderNav,
  RootContainer,
  UserDash,
} from '@axxes/design-system';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {fetchAppInfo} from "../appInfo/AppInfoService";
import { Config } from '../common/api/const';
import { msalInstance } from '../common/auth/AuthConfig';
import {ROLES} from "../common/auth/RoleConfig";
import MobileHeader from '../common/components/MobileHeader/MobileHeader';
import PrintWrapper from '../common/components/PrintWrapper/PrintWrapper';
import BreadCrumbsContainer from '../common/containers/BreadCrumbsContainer';
import ModalContainer from '../common/containers/ModalContainer';
import Routes from '../common/routes/Routes';
import {useAppDispatch, useAppSelector} from '../common/store/hooks';
import { registerFonts } from '../generate/containers/fontsFn';
import SearchContainer from '../manage/containers/SearchContainer';
import {currentProfileHasAnyRole} from "../profile/RoleService";
import './app.scss';
import { APP_CONFIG } from './config/config';

export const App = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state?.profile?.currentProfile?.result);
  const footer = useAppSelector((state) => state.common.footer);
  const appInfo = useAppSelector((state) => state.appInfo);
  const canManage = currentProfileHasAnyRole(user, [ROLES.HR, ROLES.ADMINISTRATOR])
  const canSearch = currentProfileHasAnyRole(user, ROLES.NOT_CONSULTANT)
  const isAdministrator = currentProfileHasAnyRole(user, [ROLES.ADMINISTRATOR])
  const headerNavs = APP_CONFIG.HEADER_NAVS.filter(
    (nav) => (!canManage && !nav.isSecured) || canManage,
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    registerFonts();
    dispatch(fetchAppInfo());
  }, []);

  return (
    <RootContainer>
      <Router>
        <PrintWrapper>
          <RootContainer>
            <Router>
              <div className="axxes-header">
                <Header logo="https://images.aws.axxes.com/Logo_Axxes.png">
                  <HeaderNav navLinks={headerNavs} />
                  <HeaderActions>
                    {canSearch && <SearchContainer />}
                    {isAdministrator && appInfo.version && <span>{appInfo.version}</span>}
                  </HeaderActions>
                  <UserDash
                    open={open}
                    setOpen={setOpen}
                    name={user?.name || ''}
                    photo={`${Config.api}/users/${user?.id}/picture/small`}
                  >
                    <DropdownOption onClick={() => msalInstance.logoutRedirect()}>
                      Logout
                    </DropdownOption>
                  </UserDash>
                </Header>
                <BreadCrumbsContainer />
              </div>
              <MobileHeader />
              <Routes />
              {footer && <Footer customClasses={'custom-footer'}>Copyright © Axxes {new Date().getFullYear()}</Footer>}
            </Router>
          </RootContainer>
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            pauseOnFocusLoss={true}
            draggable={true}
            pauseOnHover={true}
          />
          <ModalContainer />
        </PrintWrapper>
      </Router>
    </RootContainer>
  );
};

export default App;
