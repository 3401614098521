import {
  Button,
  Dropdown,
  FlexContainer,
  Input,
  Item,
} from '@axxes/design-system';
import {yupResolver} from "@hookform/resolvers/yup";
import _ from 'lodash';
import React, { useEffect, useReducer } from 'react';
import {Controller, useForm} from "react-hook-form";
import {useRouteMatch} from "react-router-dom";
import * as yup from "yup";
import { APP_CONFIG } from '../../../app/config/config';
import CvDatePicker from '../../../common/components/DatePicker/DatePicker';
import ItemForm from '../../../common/components/ItemForm/ItemForm';
import CvTextArea from "../../../common/components/TextArea/CvTextarea";
import { ModalTypes } from '../../../common/models/modals';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import { showModal } from '../../../common/store/slice';
import { dateToString } from "../../../common/utils/dates";
import { DropdownSchema } from "../../../common/utils/validations";
import { updateIssuer } from '../../../manage/store/manageFacadeService';
import { Certificate } from '../../model';
import { TrainingCenter } from '../../model/trainingCenter';
import {
    fetchTrainingCenters,
    postCertificate,
    putCertificate
} from '../../store/usersFacadeService';
import {MatchParams} from "../UserEducations/UserEducationsEdit";
import certificateReducer, { INITIAL_CERTIFICATE_EDIT_STATE, openTrainingCenterForm, setCertificate, setCertificateName } from './state';

type UserCertifcateEditProps = {
  index?: number;
  certificateProp?: Certificate;
  remove?: (certificate: Certificate) => void;
  cancel?: () => void;
  isSaving?: boolean;
  isDeleting?: boolean;
  creating: boolean;
};

const UserCertificateEdit = ({
  certificateProp,
  remove,
  cancel,
  isDeleting,
  isSaving,
  creating = false,
}: UserCertifcateEditProps) => {
  const reduxDispatch = useAppDispatch();

  const [state, dispatch] = useReducer(
    certificateReducer,creating ?
    INITIAL_CERTIFICATE_EDIT_STATE : ({
      certificate: certificateProp,
      openTrainingCenterForm: false
    })
  );

  const trainingCenters = useAppSelector(
    (appState) =>
      appState?.users?.trainingCenters?.fetch?.result || [],
  );

  useEffect(() => {
    reduxDispatch(fetchTrainingCenters());
  }, []);

  const { handleSubmit, errors, control } = useForm({
    criteriaMode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(
        yup.object().shape({
          name: yup.string().required(),
          issuer: DropdownSchema
        }),
    ),
  });

  const match = useRouteMatch<MatchParams>('/users/:userId');

  const printMode = useAppSelector(
      (appState) => appState?.common?.printMode,
  );

  const create = handleSubmit(() => {
      if (!state.certificate) return;
      if (creating) {
        reduxDispatch(postCertificate(state.certificate, match?.params?.userId, printMode));
      } else {
        const oldIssuer = trainingCenters.find(c => c.id === state.certificate?.trainingCenter?.id);
        if (!_.isEqual(state.certificate?.trainingCenter, oldIssuer) && state.certificate.trainingCenter && oldIssuer) {
          reduxDispatch(updateIssuer(state.certificate.trainingCenter))
        };
        reduxDispatch(putCertificate(state.certificate, match?.params?.userId, printMode));
      }
  });

  const removeCertificate = () => {
    reduxDispatch(showModal(ModalTypes.DELETE, {
      label: 'certificate',
      name: `${state?.certificate?.name} - ${state?.certificate?.trainingCenter?.name}`,
      onConfirm: () => {
          if (state.certificate) remove?.(state?.certificate);
      }
    }))
  }

  return (
  <div className="axxes-cv-form axxes-cv-form__container">
    <img
      className="axxes-cv-form__photo"
      alt="avatar-img"
      src={
        state.certificate?.trainingCenter?.pictureUrl ||
        APP_CONFIG.DEFAULT_IMAGE
      }
    />
    <FlexContainer padding="0" direction="column" grow={1}>
      <FlexContainer
        padding="0"
        direction="column"
        customClasses="axxes-cv-form__container"
        grow={1}
      >
        <Controller
          name="name"
          defaultValue={state?.certificate?.name || ''}
          control={control}
          render={({ onChange, value }) => (
              <Input
                  value={value}
                  onChange={(i) => {
                      onChange(i)
                      dispatch(setCertificateName(i.target.value));
                  }}
                  title="Name"
                  type="text"
                  required={true}
                  warning={errors.name}
              />
          )}
        />
        <Controller
            name="issuer"
            defaultValue={{
              name: state?.certificate?.trainingCenter?.name || '',
              value: state?.certificate?.trainingCenter?.id || '',
            } as Item}
            control={control}
            render={({ onChange, value }) => (
                <Dropdown
                    title="Issuer"
                    warning={errors.issuer}
                    items={trainingCenters?.map(
                        (tc) => ({ value: tc.id, name: tc.name } as Item),
                    ) || []
                    }
                    autoComplete={true}
                    value={value}
                    setValue={(newValue) => {
                      const item = newValue as Item;
                      onChange(item);
                        const newTrainingCenter =
                            trainingCenters?.find((center : TrainingCenter) => center.id === item.value) ??
                            new TrainingCenter(undefined, item.name, undefined);
                      dispatch(openTrainingCenterForm(newTrainingCenter));
                    }}
                    required={true}
                />
            )}
        />
        <ItemForm
          newItem={state?.certificate?.trainingCenter}
          setNewItem={(newTrainingCenter: TrainingCenter) => {
              dispatch(
                setCertificate({ 
                  ...state.certificate, 
                  trainingCenter: newTrainingCenter
                })
              )
            }
          }
          open={state?.openTrainingCenterForm === true}
          title={
            !state?.certificate?.trainingCenter?.id ? 'New issuer' : 'Update issuer'
          }
        />

        <div className="axxes-cv-form__dual-form">
            <Controller
                name="fromDate"
                defaultValue={
                    state?.certificate?.dateOfAchievement
                        ? new Date(state?.certificate?.dateOfAchievement)
                        : null
                }
                control={control}
                rules={{ required: true }}
                render={({ onChange, value }) => (
                    <CvDatePicker
                        title="Date Of Achievement"
                        onChange={(date) => {
                            onChange(date);
                            dispatch(
                              setCertificate(
                                {
                                  ...state.certificate,
                                  dateOfAchievement: dateToString(date)
                                }
                              )
                            );
                          }
                        }
                        value={value}
                        maxDetail="year"
                        minDetail="decade"
                        maxDate={
                            state?.certificate?.dateOfExpiration
                                ? new Date(state?.certificate?.dateOfExpiration)
                                : undefined
                        }
                        warning={errors.fromDate}
                    />
                )}
            />
            <Controller
                name="toDate"
                defaultValue={
                    state?.certificate?.dateOfExpiration
                        ? new Date(state?.certificate?.dateOfExpiration)
                        : null
                }
                control={control}
                rules={{ required: true }}
                render={({ onChange, value }) => (
                    <CvDatePicker
                        title="Date Of Expiration"
                        onChange={(date) => {
                            onChange(date);
                            dispatch(
                              setCertificate(
                                {
                                  ...state.certificate,
                                  dateOfExpiration: dateToString(date)
                                }
                              )
                            );
                          }
                        }
                        value={value}
                        maxDetail="year"
                        minDetail="decade"
                        minDate={
                            state?.certificate?.dateOfAchievement
                                ? new Date(state?.certificate?.dateOfAchievement)
                                : undefined
                        }
                        warning={errors.toDate}
                    />
                )}
            />
        </div>
          <Controller
              name="certificateUrl"
              defaultValue={state?.certificate?.certificateUrl || ''}
              control={control}
              render={({ onChange, value }) => (
                  <Input
                      value={value}
                      onChange={(i) => {
                          onChange(i)
                          dispatch(
                            setCertificate(
                              {
                                ...state.certificate,
                                certificateUrl: i.target.value
                              }
                            )
                          )
                        }
                      }
                      title="Certificate url"
                      type="text"
                  />
              )}
          />
        <Controller
            name="text"
            defaultValue={state?.certificate?.description}
            control={control}
            render={({ onChange, value }) => (
                <CvTextArea
                    title="Description"
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      dispatch(setCertificate({ ...state.certificate, description: e}));
                    }}
                />
            )}
        />
      </FlexContainer>

      <div className="axxes-cv-form__actions axxes-about__actions">
        {remove && (
          <Button
            disabled={isSaving}
            isSaving={isDeleting}
            variant="subtle"
            accent={true}
            onClick={removeCertificate}
          >
            <span>Delete</span>
          </Button>
        )}
        {cancel && (
          <Button
            disabled={isSaving || isDeleting}
            variant="subtle"
            onClick={() => {
              cancel();
            }}
          >
            <span>Cancel</span>
          </Button>
        )}
        <Button
          disabled={isDeleting || errors.name || errors.issuer}
          isSaving={isSaving}
          accent={true}
          type="submit"
          onClick={create}
        >
          Save
        </Button>
      </div>
    </FlexContainer>
  </div>
  );
};

export default UserCertificateEdit;
