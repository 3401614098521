import { Font } from '@react-pdf/renderer';
import faBrands from '../fonts/FontAwesomeBrands.ttf';
import faSolid from '../fonts/FontAwesomeSolid.ttf';
import font from '../fonts/ObjektivMk1_Trial_Rg.ttf';

import fontItalic from '../fonts/ObjektivMk1_Trial_It.ttf';
import fontLight from '../fonts/ObjektivMk1_Trial_Lt.ttf';
import fontLightItalic from '../fonts/ObjektivMk1_Trial_LtIt.ttf';
import fontMedium from '../fonts/ObjektivMk1_Trial_Md.ttf';
import fontMediumItalic from '../fonts/ObjektivMk1_Trial_MdIt.ttf';

export const registerFonts = () => {
  Font.register({
    family: 'objektiv-mk1',
    fonts: [
      {
        src: fontLight,
        fontWeight: 'light',
        fontStyle: 'normal',
      },
      {
        src: fontLightItalic,
        fontWeight: 'light',
        fontStyle: 'italic',
      },
      {
        src: font,
        fontWeight: 'normal',
        fontStyle: 'normal',
      },
      {
        src: fontItalic,
        fontWeight: 'normal',
        fontStyle: 'italic',
      },
      {
        src: fontMedium,
        fontWeight: 'medium',
        fontStyle: 'normal',
      },
      {
        src: fontMediumItalic,
        fontWeight: 'medium',
        fontStyle: 'italic',
      }
    ],
  });

  Font.register({
    family: 'fontawesome-solid',
    src: faSolid,
  });

  Font.register({
    family: 'fontawesome-brands',
    src: faBrands,
  });
};
