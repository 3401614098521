import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserCV } from '../../users/model/userCv';
import { Page, SectionTypes } from '../models/models';
import { InitialGeneratorState } from './state';

const slice = createSlice({
  name: 'generator',
  initialState: InitialGeneratorState,
  reducers: {
    updateGeneratorPages: (state, action: PayloadAction<Page[]>) => ({
      ...state,
      pages: action.payload,
    }),
    resetGeneratorPages: (state) => ({ ...state, cvCopy: state.cvCopy }),
    updateCvCopy: (state, action: PayloadAction<UserCV | undefined>) => ({
      ...state,
      cvCopy: action.payload,
    }),
    updateSelectedSection: (state, action: PayloadAction<SectionTypes>) => ({
      ...state,
      selectedSection:
        state.selectedSection === action.payload ? undefined : action.payload,
    }),
    updateInvisibles: (state, action: PayloadAction<string[]>) => ({
      ...state,
      invisibles: action.payload,
    }),
    updateMinimizeds: (state, action: PayloadAction<string[]>) => ({
      ...state,
      minimizeds: action.payload,
    }),
    addInvisibleSection: (state, action: PayloadAction<SectionTypes>) => ({
      ...state,
      invisibleSections: [...state.invisibleSections, action.payload],
    }),
    removeInvisibleSection: (state, action: PayloadAction<SectionTypes>) => ({
      ...state,
      invisibleSections: state.invisibleSections.filter(
        (s) => s !== action.payload,
      ),
    }),
  },
});

export default slice.reducer;

export const {
  updateGeneratorPages,
  resetGeneratorPages,
  updateCvCopy,
  updateSelectedSection,
  updateInvisibles,
  updateMinimizeds,
  addInvisibleSection,
  removeInvisibleSection,
} = slice.actions;
