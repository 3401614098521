import { Client, Course, Project, School, UserCV, UserSkill } from "../../users/model";
import { CvVersion } from "../../users/model/cvVersion";
import TaskDTO from '../../users/model/dto-get/taskDTO';
import { Language, LanguageLevel } from "../../users/model/language";
import { TrainingCenter } from "../../users/model/trainingCenter";
import { Certificate } from './../../users/model/certificate';
import { Education } from './../../users/model/education';
import { Training } from './../../users/model/training';

export function projectsSorter(a: Project, b: Project, withPriorities?: boolean): number {
    if (withPriorities && a.priority != null && b.priority != null && a.priority !== b.priority) {
        return a.priority - b.priority
    }

    // Sort alphabetically when no dates
    if (!a.fromDate && !a.toDate && !b.fromDate && !b.toDate) {
        const nameA = a.client?.name?.toLocaleLowerCase();
        const nameB = b.client?.name?.toLocaleLowerCase();
        if (!nameA || !nameB)
            return 0;
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    }

    // Projects with no dates sorting last.
    if (!a.fromDate && !a.toDate)
        return 1;
    if (!b.fromDate && !b.toDate)
        return -1;

    // Sort running projects before ended projects
    if (a.toDate && !b.toDate)
        return 1;
    if (!a.toDate && b.toDate)
        return -1;

    // Sort running projects on start date
    if (a.fromDate && !a.toDate && b.fromDate && !b.toDate)
        return new Date(b.fromDate).getTime() - new Date(a.fromDate).getTime();

    // Default sort on toDate
    return (
        new Date(b.toDate || Number.MAX_SAFE_INTEGER).getTime() -
        new Date(a.toDate || Number.MAX_SAFE_INTEGER).getTime() || 0
    );
}

export function sortAntiChronologically(a: Date, b: Date): number {
    return b.getTime() - a.getTime();
}
export function sortDateStringsAntiChronologically(a: string | undefined | null, b: string | undefined | null): number {
    // No date means someone was too lazy to fill in a date field, so we can assume this item should be lowest in the array.
    return sortAntiChronologically(new Date(a || 0), new Date(b || 0));
}

export function sortByName(a: Client | Course | TrainingCenter | School, b: Client | Course | TrainingCenter | School): number {
    const aName = a.name || "";
    const bName = b.name || "";

    if (aName === "") { // if a empty
        if (bName === "")
            return 0; // if both empty => equal
        else
            return 1; // priority to empty name
    }

    if (aName.toLowerCase() > bName.toLowerCase())
        return 1;
    else if (aName.toLowerCase() < bName.toLowerCase())
        return -1;
    return 0;
}

export function sortLanguagesOnField(languages: Language[], field: 'understandingLevel' | 'speakingLevel' | 'writingLevel'): Language[] {
    const languageOrder = Object.values(LanguageLevel);
    return [...languages].sort((a, b) => {
        if (!a[field] || !b[field])
            return 0;
        return languageOrder.indexOf(b[field]!) - languageOrder.indexOf(a[field]!);
    });
}

export const sortCvVersions = (cvVersions?: CvVersion[]): CvVersion[] => {
  if (!cvVersions) return [{ tag: 'master', displayTag: 'Master' }];
  return [...cvVersions].sort((a: CvVersion, b: CvVersion): number => {
    if (!a.tag || !b.tag) return 0;

    if (a.tag === 'master') return -1;
    if (b.tag === 'master') return 1;

    return a.tag.localeCompare(b.tag);
  });
};

export function sortProjectTasks (tasks?: TaskDTO[]): TaskDTO[] {
    if (!tasks) return [];
    return [...tasks].sort((a, b) => {
        if (!a.priority || !b.priority) return 0;
        return a.priority - b.priority
    })
}

// CV Sorters

export function sortCV (cv?: UserCV, withPriorities?: boolean): (UserCV | undefined) {
    if (!cv) return undefined;
    return {
        ...cv,
        skills: sortUserSkills(cv.skills),
        projects: sortProjects(cv.projects, withPriorities),
        educations: sortEducations(cv.educations),
        certificates: sortCertificates(cv.certificates),
        trainings: sortTrainings(cv.trainings),
        languages: sortLanguages(cv.languages),
    }
}

export function sortUserSkills (userSkills?: UserSkill[]): UserSkill[] {
    if (!userSkills) return [];
    return [...userSkills].sort((a, b) => {
        if(a.priority === undefined || b.priority === undefined) return 0;
        return a.priority - b.priority;
    })
}

export function sortProjects (projects?: Project[], withPriorities?: boolean): Project[] {
    if (!projects) return [];
    return [...projects].sort((a, b) => projectsSorter(a, b, withPriorities))
}

export function sortEducations (educations?: Education[]): Education[] {
    if (!educations) return [];
    return [...educations].sort((a, b) => sortDateStringsAntiChronologically(a.fromDate, b.fromDate));
}

export function sortCertificates (certificates?: Certificate[]): Certificate[] {
    if (!certificates) return [];
    return [...certificates].sort((a, b) => sortDateStringsAntiChronologically(a.dateOfAchievement, b.dateOfAchievement));
}

export function sortTrainings (trainings?: Training[]): Training[] {
    if (!trainings) return [];
    return [...trainings].sort((a, b) => sortDateStringsAntiChronologically(a.fromDate, b.fromDate));
}

export function sortLanguages(languages?: Language[]): Language[] {
    if (!languages) return [];
    const sortedOnWriting = sortLanguagesOnField(languages, 'writingLevel');
    const sortedOnWritingAndSpeaking = sortLanguagesOnField(sortedOnWriting, 'speakingLevel');
    return sortLanguagesOnField(sortedOnWritingAndSpeaking, 'understandingLevel');
}