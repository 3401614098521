import ProfileDTO from "./dto-get/profileDTO";

class Profile {
    id?: string;
    name?: string;
    email?: string;
    jobTitle?: string;
    roles?: string[];

    static mapFromDTO(profileDTO: ProfileDTO) {
        return {
            id: profileDTO.id,
            name: profileDTO.name,
            email: profileDTO.email,
            jobTitle: profileDTO.jobTitle,
            roles: profileDTO.roles
        }
    }
}

export { Profile };