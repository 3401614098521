import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { AuthenticationResult } from '@azure/msal-common';

export const isLocalhost = (window.location.hostname === 'localhost');
const redirectUri = isLocalhost ? 'http://localhost:3000' : process.env.REACT_APP_AD_REDIRECT_URI;
const clientId = isLocalhost ? 'ebcedd10-ef6c-4973-aacd-29617ee72da8' : process.env.REACT_APP_AD_CLIENT_ID as string;
const tenant = isLocalhost ? 'edd1c3b6-be87-43bb-92d4-7a911c5cee17' : process.env.REACT_APP_AD_TENANT;


export const msalConfig = {
  auth: {
    clientId,
    authority: 'https://login.microsoftonline.com/' + tenant,
    redirectUri,
    postLogoutRedirectUri: redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Your scopes parameter should be "[CLIENT_ID]/.default" When using MSAL.js and if you are not using graph api:
export const loginRequest = {
  scopes: [`${clientId}/.default`],
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const acquireAccessToken = async () => {
  const activeAccount = msalInstance.getActiveAccount();

  if (!activeAccount) {
    await msalInstance.loginRedirect(loginRequest);
    return "";
  }
  const request = {
    scopes: loginRequest.scopes,
    account: activeAccount,
  };

  return await msalInstance.acquireTokenSilent(request)
    .then((res: AuthenticationResult) => res.accessToken)
    .catch((err: InteractionRequiredAuthError) => {
      msalInstance.loginRedirect(loginRequest);
    });
};
