import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {Course} from "../../../users/model";
import { CourseType } from './../../../users/model/school';


export interface EditEducationCoursesState {
    editingCourse?: Course
}

export const INITIAL_EDIT_COURSES_STATE: EditEducationCoursesState = {
    editingCourse: undefined,
}

const slice = createSlice({
  name: 'EditEducationCourses',
  initialState: INITIAL_EDIT_COURSES_STATE,
  reducers: {
    setCourse: (
      state,
      action: PayloadAction<Course>,
    ): EditEducationCoursesState => ({
      ...state,
      editingCourse: action.payload,
    }),
    resetCourse: (state): EditEducationCoursesState => ({
      ...state,
      editingCourse: undefined,
    }),
    setCourseType: (
      state,
      action: PayloadAction<CourseType>,
    ): EditEducationCoursesState => ({
      ...state,
      editingCourse: {
        ...state.editingCourse,
        courseType: action?.payload,
      },
    }),
  },
});

export default slice.reducer;

export const {setCourse, resetCourse, setCourseType} = slice.actions;