import { Link, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { DefaultStyles } from './classes';

const styles = StyleSheet.create({
  ...DefaultStyles,
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 30,
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  pageNumber: {
    color: '#E9531D',
    fontSize: '9pt',
    fontWeight: 'bold',
  }
});

export const Footer = () => (
  <View fixed={true} style={styles.footer}>
    <Link src="mailto:info@axxes.com" style={styles.link}>
      <Text>info@axxes.com</Text>
    </Link>
    
    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed={true} />
    
    <Link src="www.axxes.com" style={styles.link}>
      <Text>www.axxes.com</Text>
    </Link>
  </View>
);

export default Footer;
