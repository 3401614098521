import { Button, FlexContainer, Input } from '@axxes/design-system';
import {
  faCheck,
  faTimes,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, useRef } from 'react';
import { AnyAction } from 'redux';
import { Form } from '../../../common/components/Form/Form';
import {
  ProjectEditState,
  setEditingTask,
  setEditingTaskForm,
  Task,
} from './userProjectEditState';

type ProjectTaskEditProps = {
  state: ProjectEditState;
  dispatch: Dispatch<AnyAction>;
  index?: number;
  saveTask: (task?: Task | null) => void;
  removeTask?: (task?: Task | null) => void;
  cancelTask?: () => void;
  isSaving?: boolean;
  isDeleting?: boolean;
};

function TaskEdit({
  index,
  saveTask,
  removeTask,
  cancelTask,
  isSaving,
  isDeleting,
  state,
  dispatch,
}: ProjectTaskEditProps) {
  const inputRef = useRef<HTMLInputElement>();

  const submit = () => {
    if(!state?.editingTask?.task && removeTask) {
      removeTask(state?.editingTask)
      return;
    }
    saveTask(state?.editingTask);
    inputRef.current?.focus();
  }

  return (
    <Form
      id={`projectTaskForm${index}`}
      form={state?.editingTaskForm}
      setForm={(payload) => dispatch(setEditingTaskForm(payload))}
      onSubmit={submit}
    >
      <FlexContainer padding="0" customClasses="axxes-edit-form">
        <FlexContainer customClasses="axxes-edit-form__input" padding="0">
          <Input
            name="task"
            title="Task"
            autoFocus={true}
            reference={inputRef}
            value={state?.editingTaskForm?.fields?.task?.value}
            onChange={(e) => {
              dispatch(setEditingTask({ index, task: e.target.value }));
            }}
            onKeyDown={(e) => {
              if (e?.key === 'Enter') {
                submit();
              }
              if (e?.key === 'Escape' && cancelTask) {
                cancelTask();
              }
            }}
          />
        </FlexContainer>
        <FlexContainer
          padding="0"
          direction="row"
          customClasses="axxes-edit-form__actions"
        >
          {removeTask && (
            <Button
              variant="subtle"
              accent={true}
              onClick={() => removeTask(state?.editingTask)}
              disabled={isSaving}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          )}
          {cancelTask && (
            <Button
              variant="subtle"
              onClick={() => cancelTask()}
              disabled={isSaving || isDeleting}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          )}

          <Button
            disabled={(state.form?.errors && state.form.errors.length > 0) || isDeleting}
            accent={true}
            type="submit"
            form={`projectTaskForm${index}`}
            isSaving={isSaving}
          >
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </FlexContainer>
      </FlexContainer>
    </Form>
  );
}

export default TaskEdit;
