import React from 'react';

function SkillLevelsTable() {
  return (
    <div className="axxes-skill-levels-table">
      <div>
        <span className="level">Junior</span>
        <span>
          Beheerst de basisconcepten, geen projectervaring met deze skill
        </span>
      </div>
      <div>
        <span className="level">Confirmed</span>
        <span>
          Basiskennis van deze technologie/skill, eerste projectervaring, je
          vindt zelfstandig je weg mits de nodige begeleiding
        </span>
      </div>
      <div>
        <span className="level">Advanced</span>
        <span>
          Doorgedreven kennis van de technologie/skill, volledig zelfstandig
          werk is perfect mogelijk
        </span>
      </div>
      <div>
        <span className="level">Expert</span>
        <span>
          Je bent dé expert binnen deze technologie, het heeft geen geheimen
          meer voor jou en je kan anderen hierover kennis bijbrengen
        </span>
      </div>
    </div>
  );
}

export default SkillLevelsTable;
