import {Card, CardContent, FlexContainer} from "@axxes/design-system";
import React from "react";
import {EditClients} from "../components/EditClients/EditClients";
import './manageClientDataContainer.scss'

export const ManageClientDataContainer = () => {
    return (
        <FlexContainer
            direction="column"
            customClasses="axxes-manage__edit"
            padding="0"
        >
            <FlexContainer
            direction="row"
            padding="0"
            >
                <h1>Manage Client Data</h1>
            </FlexContainer>
            <FlexContainer
                direction="column"
                padding="0"
                customClasses="axxes-manage__card"
            >
                <Card>
                    <CardContent>
                        <EditClients/>
                    </CardContent>
                </Card>
            </FlexContainer>
        </FlexContainer>
    )
}

