import { Button, Dropdown, FlexContainer, Item } from '@axxes/design-system';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, useRef } from 'react';
import { Control, Controller } from 'react-hook-form';
import { AnyAction } from 'redux';
import { Skill } from '../../model';
import { addSkill as addSkillAction } from './userProjectEditState';

type SkillEditProps = {
  addSkill?: { open: boolean; skill?: Skill };
  control: Control<Record<string, any>>;
  dispatch: Dispatch<AnyAction>;
  skills: Skill[];
  setSkill: (skill: Skill) => void;
  cancelSkill: () => void;
};

function SkillEdit({
  addSkill,
  control,
  dispatch,
  skills,
  setSkill,
  cancelSkill,
}: SkillEditProps) {
  const dropdownRef = useRef<HTMLInputElement>();
  return (
    <FlexContainer padding="0" customClasses="axxes-edit-form">
      <Controller
        name="skills"
        defaultValue={
          {
            value: addSkill?.skill?.id || '',
            name: addSkill?.skill?.name || '',
          } as Item
        }
        control={control}
        render={({ onChange, value }) => (
          <>
            <FlexContainer customClasses="axxes-edit-form__input" padding="0">
              <Dropdown
                reference={dropdownRef}
                items={
                  skills?.map(
                    (skill) =>
                      ({ value: skill.id, name: skill.name } as Item),
                  ) || []
                }
                autoComplete={true}
                autoFocus={true}
                value={value}
                setValue={(newValue) => {
                  const item = newValue as Item;
                  onChange(item);
                  dispatch(addSkillAction({
                    open: true,
                    skill: {
                      id: item?.value.toString(),
                      name: item?.name,
                    },
                  }));
                  dropdownRef.current?.focus();
                }}
                title="Skill"
                onKeyDown={(e) => {
                  if (e?.key === 'Enter' && addSkill?.skill) {
                    setSkill(addSkill?.skill);
                    onChange('');
                  }
                  if (e?.key === 'Escape') cancelSkill();
                }}
                typeAheadLen={1}
              />
            </FlexContainer>

            <FlexContainer
              padding="0"
              direction="row"
              customClasses="axxes-edit-form__actions"
            >
              <Button variant="subtle" onClick={() => cancelSkill()}>
                <FontAwesomeIcon icon={faTimes} />
              </Button>

              <Button
                accent={true}
                onClick={() => {
                  if (addSkill?.skill) setSkill(addSkill?.skill);
                  onChange('');
                  dropdownRef.current?.focus();
                }}
              >
                <FontAwesomeIcon icon={faCheck} />
              </Button>
            </FlexContainer>
          </>
        )}
      />
    </FlexContainer>
  );
}

export default SkillEdit;
