import { FlexContainer } from '@axxes/design-system';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { APP_CONFIG } from '../../../app/config/config';
import EditOverlay from '../../../common/components/EditOverlay/EditOverlay';
import { SkillLevelType, UserSkill } from '../../model';
import { UsersState } from '../../store/state';
import UserSkillEdit from './UserSkillEdit';
import { setEdit, UserSkillsState } from './userSkillsState';
import UserSkillView from './UserSkillView';


interface UserSkillSortableItemProps {
  state: UserSkillsState;
  listIndex: number;
  dispatch: Dispatch<AnyAction>;
  userSkill: UserSkill;
  skillsState: UsersState['skills'];
  deleteUserSkill: (userSkill: UserSkill) => void;
  saveUserSkill: (userSkill: UserSkill, isNew: boolean) => void;
  isSaving?: boolean;
  isDeleting?: boolean;
}

const UserSkillSortableItem = ({
    state,
    listIndex,
    dispatch,
    userSkill,
    skillsState,
    deleteUserSkill,
    saveUserSkill,
    isSaving,
    isDeleting,
  }: UserSkillSortableItemProps) => {

    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
    } = useSortable({id: userSkill.id!, disabled: !state.editOrder});

    const style = {
      transform: CSS.Transform.toString(transform),
    };

    return (
      <div
        className={`axxes-skill__sortable-item ${state.editOrder ? '--draggable' : ''}`}
        ref={setNodeRef} 
        style={style} 
        {...attributes} 
        {...listeners}
      >
        <FlexContainer
          direction="row"
          padding="0"
        >
          <EditOverlay
            isEditable={!state.mainEdit && !state.addMode && !state.editOrder}
            edit={!!state?.edits?.[listIndex]}
            setEdit={(status: boolean) =>
              dispatch(setEdit({ index: listIndex, status }))
            }
            key={listIndex}
            viewElement={
              <UserSkillView 
                userSkill={userSkill} 
                editOrder={state.editOrder} 
                isActive={state.draggingId === userSkill.id} 
              />
            }
            customclass={`axxes-skills-container__edit ${
              state?.edits?.[listIndex] && 'axxes-skills-container__edit--active'
            }`}
            editElement={
              <UserSkillEdit
                index={listIndex}
                skills={skillsState?.fetch?.result}
                userSkill={userSkill}
                levels={Object.keys(APP_CONFIG.SKILL_LEVEL) as SkillLevelType[]}
                removeSkill={(_userSkill?: UserSkill) => {
                  if (_userSkill) deleteUserSkill(_userSkill);
                }}
                cancelSkill={() =>
                  dispatch(setEdit({ index: listIndex, status: false }))
                }
                saveSkill={(_userSkill?: UserSkill) => {
                  if (_userSkill) saveUserSkill(_userSkill, false);
                }}
                isSaving={isSaving}
                isDeleting={isDeleting}
              />
            }
          />
        </FlexContainer>
      </div>
    );
  }

export default UserSkillSortableItem;
