import {AxiosResponse} from "axios";
import {UserCV} from "../../users/model";
import UserCvDTO from "../../users/model/dto-get/userCvDTO";
import { fetchSharedCvFail, fetchSharedCvSuccess } from "../../users/store/slice";
import {ShareApiService} from "./shareApiService";


export const fetchSharedCv = (cvId: string) => (dispatch: any) => {
    ShareApiService.fetchSharedCv(cvId)
        .then((response: AxiosResponse<UserCvDTO>) => {
            dispatch(fetchSharedCvSuccess(UserCV.mapFromDto(response.data)));
        })
        .catch((err) => {
            dispatch(fetchSharedCvFail(err));
        });
}