import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Config } from '../../../common/api/const';
import { ShareResponse } from './../../model/shareResponse';

export interface DetailsHeaderState {
  userId: string;
  link?: string | null;
  versionForm?: {
    open?: boolean;
    error?: string;
    value?: string;
    isLoading?: boolean;
  };
  recipientForm?: {
    open?: boolean;
    value?: string;
    isLoading?: boolean;
  }
}

export const INITIAL_DETAILS_HEADER_STATE: DetailsHeaderState = {
  userId: '',
};

const slice = createSlice({
  name: 'DetailsHeader',
  initialState: INITIAL_DETAILS_HEADER_STATE,
  reducers: {
    setVersionValue: (
      state,
      action: PayloadAction<string | undefined>,
    ): DetailsHeaderState => ({
      ...state,
      versionForm: {
        ...state.versionForm,
        value: action?.payload,
        error: undefined,
      },
    }),
    openVersionForm: (state): DetailsHeaderState => ({
      ...state,
      versionForm: {
        open: true,
        value: '',
        error: undefined,
        isLoading: false,
      },
    }),
    closeVersionForm: (state): DetailsHeaderState => ({
      ...state,
      versionForm: {
        ...state.versionForm,
        open: false,
        value: '',
        error: undefined,
        isLoading: false,
      },
    }),
    setVersionError: (
      state,
      action: PayloadAction<string | undefined>,
    ): DetailsHeaderState => ({
      ...state,
      versionForm: {
        ...state.versionForm,
        error: action.payload,
      },
    }),
    setVersionLoading: (
      state,
      action: PayloadAction<boolean>,
    ): DetailsHeaderState => ({
      ...state,
      versionForm: {
        ...state.versionForm,
        isLoading: action?.payload,
      },
    }),
    setRecipientValue: (
      state,
      action: PayloadAction<string | undefined>,
    ): DetailsHeaderState => ({
      ...state,
      recipientForm: {
        ...state.recipientForm,
        value: action?.payload,
      },
    }),
    openRecipientForm: (state): DetailsHeaderState => ({
      ...state,
      recipientForm: {
        open: true,
        value: '',
        isLoading: false,
      },
    }),
    closeRecipientForm: (state): DetailsHeaderState => ({
      ...state,
      recipientForm: {
        ...state.recipientForm,
        open: false,
        value: '',
        isLoading: false,
      },
    }),
    setRecipientLoading: (
      state,
      action: PayloadAction<boolean>,
    ): DetailsHeaderState => ({
      ...state,
      recipientForm: {
        ...state.recipientForm,
        isLoading: action?.payload,
      },
    }),
    setLink: (
      state,
      action: PayloadAction<ShareResponse>,
    ): DetailsHeaderState => ({
      ...state,
      link: `${Config.base}/share/${action.payload.cvId}/${action.payload.token}`,
    }),
    clearLink: (state): DetailsHeaderState => ({
      ...state,
      link: null,
    }),
  },
});

export default slice.reducer;

export const {
  setVersionValue,
  openVersionForm,
  closeVersionForm,
  setVersionError,
  setVersionLoading,
  setRecipientValue,
  openRecipientForm,
  closeRecipientForm,
  setRecipientLoading,
  setLink,
  clearLink,
} = slice.actions;