import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    FlexContainer,
    Loading
} from "@axxes/design-system";
import React from "react";
import UserLanguageView from "../../users/components/UserLanguages/UserLanguageView";
import {Language} from "../../users/model/language";

export interface ShareLanguagesProps {
    languages?: Language[];
    isLoading?: boolean;
}

export const ShareLanguages = ({languages, isLoading}: ShareLanguagesProps) => {
    return (
        languages?.length === 0 ? null :
            <Card disableOnPrint={true}>
                <CardHeader>
                    <CardTitle>Languages</CardTitle>
                </CardHeader>
                <CardContent>
                    {isLoading ? (
                        <FlexContainer padding="0" customClasses="axxes-language-view">
                            <Loading width="200px" />
                        </FlexContainer>
                    ) : (
                        <FlexContainer padding='0' direction='column' customClasses="axxes-languages">
                            {languages?.length === 0
                             ? null
                             : (
                                <div className="axxes-language axxes-language__header">
                                    <div />
                                    <FlexContainer padding='0' customClasses='axxes-language__tag-container '>
                                        <svg/>
                                        <FlexContainer 
                                        padding='0' 
                                        grow={1} 
                                        customClasses='axxes-language__tag axxes-language__title'
                                        >
                                        <span>Understanding</span>
                                        </FlexContainer>
                                    </FlexContainer>
                                    
                                    <FlexContainer padding='0' customClasses='axxes-language__tag-container '>
                                        <svg/>
                                        <FlexContainer 
                                        padding='0' 
                                        grow={1} 
                                        customClasses='axxes-language__tag axxes-language__title'
                                        >
                                        <span>Speaking</span>
                                        </FlexContainer>
                                    </FlexContainer>

                                    <FlexContainer padding='0' customClasses='axxes-language__tag-container '>
                                        <svg/>
                                        <FlexContainer 
                                        padding='0' 
                                        grow={1} 
                                        customClasses='axxes-language__tag axxes-language__title'
                                        >
                                        <span>Writing</span>
                                        </FlexContainer>
                                    </FlexContainer>
                                </div>
                            )}
                            {languages?.map((language: Language, index: number) => (
                                <UserLanguageView language={language} key={language.id}/>
                            ))}
                        </FlexContainer>
                    )}
                </CardContent>
            </Card>
    );
}