import { FlexContainer } from '@axxes/design-system';
import React from 'react';
import './NotFound.scss';

const NotFound = () => (
  <FlexContainer customClasses='axxes-notfound__container' direction='column' >
    <h1>Not Found</h1>
    <p>This page could not be found</p>
  </FlexContainer>
);

export default NotFound;
