import { AxiosRequestConfig } from 'axios';

import { Api } from '../../common/api/const';
import { User } from '../../users/model';
import { HttpFetchParams } from './../../common/models/fetchParams';

export class ManageApiService {
  static fetchUsers = (params?: HttpFetchParams) => Api().get(`/users`, { params } as AxiosRequestConfig);
  static fetchUser = (id: string) => Api().get<User>(`/users/${id}`);
  static fetchPictureSmall = (id?: string) =>
    Api().get('/users/' + id + '/picture/small', { responseType: 'blob'});
  static rebuildSearchIndex = () => Api().post('/management/search');

}
