import React, { FunctionComponent } from 'react';

const AvatarTitle: FunctionComponent = ({ children }) => {

    return (
        <h4 className="axxes-avatar__title">
            {children}
        </h4>
    )
}

export default AvatarTitle;