import SchoolDTO from './dto-get/schoolDTO';

export enum CourseNames {
  HIGH_SCHOOL = 'High School',
  BACHELOR = 'Bachelor',
  MASTER = 'Master',
  DOCTORATE = 'Doctorate',
  POSTDOCTORAL = 'Postdoctoral',
}

export enum CourseType {
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  BACHELOR = 'BACHELOR',
  MASTER = 'MASTER',
  DOCTORATE = 'DOCTORATE',
  POSTDOCTORAL = 'POSTDOCTORAL'
}

export class School {
  id?: string;
  name?: string;
  pictureUrl?: string;
  url?: string;

  constructor() {
    this.id = '';
    this.name = '';
    this.pictureUrl = '';
    this.url = '';
  }

  static mapFromDto(school: SchoolDTO) {
    return {
      ...school,
    };
  }
}
