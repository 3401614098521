import {Course} from "../course";
import { Training } from "../training";
import {TrainingCenter} from "../trainingCenter";
import CourseDTO from "./courseDTO";
import TrainingCenterDTO from "./trainingCenterDTO";

export default class UserTrainingDTO {
    id?: string;
    toDate?: string | null;
    fromDate?: string | null;
    description?: string;
    trainingCenter?: TrainingCenterDTO;
    trainingCenterId?: string;
    trainingCourseId?: string;
    course?: CourseDTO;
    certificateUrl?: string;

    static mapToDTO(training: Training) : UserTrainingDTO {

        return {
            id: training.id,
            toDate: training?.toDate,
            fromDate: training?.fromDate,
            description: JSON.stringify(training?.description || ''),
            trainingCenter: TrainingCenterDTO.mapToDTO(training?.trainingCenter || new TrainingCenter()),
            trainingCenterId: training?.trainingCenter?.id || '',
            trainingCourseId: training?.trainingCourse?.id || '',
            course: CourseDTO.mapToDTO(training?.trainingCourse || new Course()),
            certificateUrl: training.certificateUrl,
        }
    }
}