import React from "react"
import {APP_CONFIG} from "../../../../app/config/config";
import { certificateDateFormat } from "../../../../common/utils/dates";
import Avatar from "../../../../users/components/Avatar/Avatar";
import AvatarSubtitle from "../../../../users/components/Avatar/AvatarSubtitle";
import AvatarText from "../../../../users/components/Avatar/AvatarText";
import AvatarTitle from "../../../../users/components/Avatar/AvatarTitle";
import {Certificate} from "../../../../users/model";
import { formatDescriptionSummary } from "../../../generateUtils";
import {MinimizeButton} from "../common/MinimizeButton";
import {VisibilityButton} from "../common/VisibilityButton";
import '../pdfEditor.scss'

interface CertificateViewItemProps {
    certificate: Certificate
    invisibles: string[],
    minimizeds:string[]
}

export const CertificatesViewItem = ({certificate, invisibles, minimizeds} : CertificateViewItemProps) => {
    return (
        <div className={"axxes-project-item-container"}>
            <div className={`axxes-project-item-view`}>
                <Avatar
                    photo={
                        certificate?.trainingCenter?.pictureUrl || APP_CONFIG.DEFAULT_IMAGE
                    }
                >
                    <AvatarTitle>{certificate?.name}</AvatarTitle>
                    <AvatarSubtitle>{certificate?.trainingCenter?.name}</AvatarSubtitle>
                    <AvatarText>{certificate?.certificateUrl}</AvatarText>
                    <AvatarText>
                        {certificate && certificateDateFormat(certificate)}
                    </AvatarText>
                </Avatar>
                <div className="axxes-project-item-view__content">
                    {minimizeds.includes(certificate.id ?? '') ? <span className='axxes-edit-pdf__minimized'>MINIMIZED</span> : null}
                    <span>Description: {formatDescriptionSummary(certificate.description)}</span>
                </div>
            </div>
            <div className='axxes-project-item-view__actions'>
                <VisibilityButton cvDataId={certificate.id} invisibles={invisibles}/>
                <MinimizeButton cvDataId={certificate.id} minimizeds={minimizeds}/>
            </div>
        </div>
    );
}
