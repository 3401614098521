import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Course} from "../../../users/model";


export interface EditTrainingCoursesState {
    editingCourse?: Course
}

export const INITIAL_EDIT_COURSES_STATE: EditTrainingCoursesState = {
    editingCourse: undefined,
}

const slice = createSlice({
  name: 'EditTrainingCourse',
  initialState: INITIAL_EDIT_COURSES_STATE,
  reducers: {
    setCourse: (
      state,
      action: PayloadAction<Course>,
    ): EditTrainingCoursesState => ({
      ...state,
      editingCourse: action.payload,
    }),
    resetCourse: (state): EditTrainingCoursesState => ({
      ...state,
      editingCourse: undefined,
    }),
  },
});

export default slice.reducer;

export const {setCourse, resetCourse} = slice.actions;