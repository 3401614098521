import { capitalizeEveryWord } from "../../common/utils/strings";

export const convertDisplayedVersionToTag = (displayTag?: string): string => {
  if (!displayTag) return '';
  return displayTag.trim().replace(/\s+/g, '-').toLowerCase();
}

export const convertVersionTagToDisplay = (tag?: string): string => {
  if (!tag) return '';
  const displayTag = tag.replace(/-/g, ' ');
  return capitalizeEveryWord(displayTag);
}