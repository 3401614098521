import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

interface SortableItemViewProps {
    isActive?: boolean;
    isDragging?: boolean;
    isInvisible?: boolean;
}

const SortableItemView: React.FunctionComponent<SortableItemViewProps> = ({children, isActive, isDragging, isInvisible}) => {
    return (
        <div className={`axxes-sortable-item ${isActive ? '--active' : ''} ${isDragging ? '--dragging' : ''} ${isInvisible ? 'axxes-edit-pdf-invisible': ''}`}>
            <div className="axxes-sortable-item__drag-handle">
                <FontAwesomeIcon icon={faEllipsisV} />
            </div>
            <div className="axxes-sortable-item__children-container">
                {children}
            </div>
        </div>
    )
}

export default SortableItemView
