import { FlexContainer } from '@axxes/design-system';
import { faBook, faBullhorn, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Language } from '../../model/language';

import './userLanguage.scss';
import UserLanguageTag from './UserLanguageTag';

type UserLanguageViewProp = {
  language?: Language;
};

const UserLanguageView = ({ language }: UserLanguageViewProp) => {
  return (
    <div className="axxes-language">
      <div className="axxes-language__title-container">
        <span className="axxes-language-view__print-title axxes-language__title">
          {language?.language}
        </span>
      </div>
      <FlexContainer padding='0' customClasses='axxes-language__tag-container'>
        <FontAwesomeIcon icon={faBook} size='2x' />
        <UserLanguageTag
          title={language?.understandingLevel}
          level={language?.understandingLevel}
        />
      </FlexContainer>
      <FlexContainer padding='0' customClasses='axxes-language__tag-container'>
        <FontAwesomeIcon icon={faBullhorn} size='2x' />
        <UserLanguageTag
          title={language?.speakingLevel}
          level={language?.speakingLevel}
        />
      </FlexContainer>
      <FlexContainer padding='0' customClasses='axxes-language__tag-container'>
        <FontAwesomeIcon icon={faPencilAlt} size='2x' />
        <UserLanguageTag
          title={language?.writingLevel}
          level={language?.writingLevel}
        />
      </FlexContainer>
    </div>
  );
};

export default UserLanguageView;
